const TRANSLATION = {
  buyModal: {
    afterFree: 'Setelah uji coba gratis Anda',
    btn: 'Cobalah Gratis',
    freeTrial: 'Uji Coba Gratis 3 Hari',
    monthLabel: 'bulan',
    selectLabel: 'Pilih rencana',
    title: 'Paket Berlangganan',
    yearLabel: 'tahun',
  },
  contact: {
    block1:
      'Untuk penyesuaian, pertanyaan, atau saran, silakan hubungi kami di contact@noam.tools. Kami akan segera merespons.',
    block2: 'Dukung kami dengan memberi peringkat pada kami di toko Ekstensi Google Apps di ★★★★★.',
    block3: 'Bantu orang lain dengan membagikan ekstensi ini:',
    title: 'Hubungi kami',
  },
  help: {
    AquickOverviewOfNoam: 'Ikhtisar Singkat Noam',
    ChatPDF: 'Obrolan PDF',
    ChatPDF2: 'ObrolanPDF',
    FavoritesManagement: 'Manajemen Favorit',
    Fixedplugin: 'Memperbaiki Plugin',
    Focusing: 'Fokus',
    Free: 'Bebas',
    FreeTrialBenefits: 'Manfaat Uji Coba Gratis',
    GoWithWikipedia: 'Gunakan Wikipedia',
    GoWithYouTube: 'Gunakan YouTube',
    HoverTranslation: 'Arahkan Terjemahan',
    Howtouse: 'Cara Menggunakan',
    ImageTranslation: 'Terjemahan Gambar',
    InstantTranslation: 'Terjemahan Instan',
    Membership: 'Keanggotaan',
    NoamHelp: 'Noam membantu Anda menerjemahkan dan meringkas',
    PDFparallelTranslation: 'Terjemahan Paralel PDF',
    ParallelTranslation: 'Terjemahan Paralel',
    SkipAllSteps: 'Lewati Semua Langkah',
    Summary: 'Ringkasan',
    Translation: 'Terjemahan',
    TryItForFree: 'Cobalah Secara Gratis',
    Video: 'Video',
    VideoSummary: 'Ringkasan Video',
    VideoTranslation: 'Terjemahan Video',
    WebSummary: 'Ringkasan Web',
    WordSelectionTranslation: 'Terjemahan Pemilihan Kata',
    benefits: 'Kami memiliki keuntungan menunggu Anda!',
    dayFree: 'Uji Coba Gratis 3 Hari',
    done: 'Selesai',
    next: 'Berikutnya',
    pin: 'Pin Noam untuk Akses Lebih Mudah～～',
    planIsNotExist: 'Paket tidak tersedia',
    startWiki: 'Mari kita mulai dengan Wikimedia!',
    startYoutube: 'Mari kita mulai dengan YouTube!',
    try: 'Cobalah',
    tryNow: 'Cobalah sekarang!',
  },
  home: {
    PDFTranslate: {
      desc: 'Terjemahan Bilingual + Obrolan PDF',
      sub1: {
        desc: 'Membaca bilingual memungkinkan Anda membandingkan teks asli dan terjemahan secara berdampingan, sehingga meningkatkan efisiensi membaca.',
        title: 'Membaca Bilingual',
      },
      sub2: {
        desc: 'AI menganalisis dan merangkum, serta dapat menjawab pertanyaan Anda berdasarkan konten PDF.',
        title: 'Obrolan PDF',
      },
      sub3: {
        desc: 'Terjemahan PDF bertenaga AI untuk akurasi dan efisiensi yang lebih baik.',
        title: 'Akurasi dan Efisiensi',
      },
      title: 'Terjemahan PDF',
    },
    aiAssistant: {
      desc: 'Menjadikan Terjemahan Web Lebih Cerdas dan Nyaman',
      panel1: {
        desc: 'Terjemahan referensi silang bilingual membantu Anda membandingkan teks asli dengan terjemahan sehingga Anda dapat memahami lebih baik dan meningkatkan efisiensi membaca Anda.',
        title: 'Terjemahan Bilingual',
      },
      panel2: {
        left: {
          desc: 'Saat menjelajahi halaman web, arahkan kursor ke konten yang perlu Anda terjemahkan untuk melihat terjemahannya.',
          title: 'Arahkan Terjemahan',
        },
        right: {
          desc: 'Saat menjelajahi halaman web, pilih teks yang ingin Anda terjemahkan untuk melihat terjemahannya.',
          title: 'Terjemahan Pemilihan Teks',
        },
      },
      panel3: {
        one: {
          desc: 'Terjemahkan dan rangkum halaman web dan konten video kapan saja, di mana saja, untuk pengalaman yang lebih lancar!',
          title: 'Tunjuk dan Klik',
        },
        three: {
          desc: 'Model bahasa besar yang digerakkan oleh AI memastikan keakuratan dan konsistensi terjemahan untuk pengalaman membaca yang lebih baik.',
          title: 'Didorong oleh AI',
        },
        two: {
          desc: 'Cakupan penuh multi-bahasa, bacaan bebas hambatan bilingual, terjemahan sekali klik - rasakan perbedaannya!',
          title: 'Lebih Komprehensif dan Nyaman',
        },
      },
      panel4: {
        tab1: {
          desc: 'Pakar efisiensi Anda - menggunakan ChatGPT, Noam menerjemahkan dan merangkum konten video YouTube untuk menghemat waktu Anda!',
          key: 'Video',
          title: 'Terjemahan & Ringkasan Video',
        },
        tab2: {
          desc: 'Klik untuk menerjemahkan gambar saat menjelajahi web.',
          key: 'Gambar',
          title: 'Terjemahan Gambar',
        },
        tab3: {
          desc: 'Ekstrak informasi penting secara instan dari halaman web dan berikan ringkasan, sehingga meningkatkan efisiensi membaca Anda.',
          key: 'jaring',
          title: 'Terjemahan & Ringkasan Web',
        },
        tag1: 'Sederhana',
        tag2: 'Efisien',
        tag3: 'Mudah Dipahami',
      },
      title: 'Asisten AI',
    },
    getStart: 'Memulai',
    getStartedForFree: 'Mulailah secara gratis',
    howItWork: {
      desc: 'Noam mengintegrasikan model AI untuk memberikan pengalaman cerdas kepada pengguna untuk terjemahan dan ringkasan halaman web lintas bahasa, terjemahan PDF dan gambar, terjemahan dan ringkasan video',
      title: 'Bagaimana cara kerjanya',
    },
    lastBlock: { desc: 'Terjemahan AI yang lebih baik untuk pengalaman membaca yang lebih lancar' },
    top: {
      desc: 'Noam adalah ekstensi browser AI gratis yang membantu Anda menerjemahkan dan meringkas halaman web, video, dan konten PDF.\\n Bergabunglah dengan Noam dan rasakan keajaibannya!',
      title1: 'Satu Keajaiban',
      title2: 'Satu Klik',
    },
    userReviews: { desc: 'Ulasan bintang lima di Toko Web Chrome', title: 'Ulasan Pengguna' },
  },
  installTips: {
    btn: 'Coba Sekarang',
    text: 'Instal "Plugin Browser Noam" untuk membuka lebih banyak fitur! Referensi silang web,\\n ringkasan video web, dan banyak lagi!',
  },
  locale: {
    af: 'Afrikanas',
    am: 'Amharik',
    ar: 'Arab',
    as: 'orang Assam',
    auto: 'Deteksi otomatis',
    az: 'Azerbaijan',
    ba: 'Bashkir',
    bg: 'Bulgaria',
    bn: 'Bangla',
    bo: 'Tibet',
    bs: 'Bosnia',
    ca: 'Katalan',
    cs: 'Ceko',
    cy: 'bahasa Wales',
    da: 'Denmark',
    de: 'Jerman',
    dsb: 'Sorbia Bawah',
    dv: 'Divehi',
    el: 'Orang yunani',
    en: 'Bahasa inggris',
    es: 'Spanyol',
    et: 'Estonia',
    eu: 'Basque',
    fa: 'Persia',
    fi: 'Finlandia',
    fil: 'Filipina',
    fj: 'Fiji',
    fr: 'Perancis',
    frCA: 'Prancis (Kanada)',
    ga: 'Irlandia',
    gl: 'Galisia',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'rumah',
    he: 'Ibrani',
    hi: 'Hindi',
    hr: 'Kroasia',
    hsb: 'Sorbia Atas',
    ht: 'Kreol Haiti',
    hu: 'Hongaria',
    hy: 'orang Armenia',
    id: 'Indonesia',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'Islandia',
    it: 'Italia',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latin)',
    ja: 'Jepang',
    ka: 'orang Georgia',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdi (Utara)',
    kn: 'Kannada',
    ko: 'Korea',
    ku: 'Kurdi (Tengah)',
    ky: 'Kirgistan',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'Lithuania',
    lug: 'Ganda',
    lv: 'Latvia',
    lzh: 'Cina (Sastra)',
    mai: 'Maithili',
    mg: 'Malagasi',
    mi: 'Māori',
    mk: 'Makedonia',
    ml: 'Malayalam',
    mnCyrl: 'Mongolia (Sirilik)',
    mnMong: 'Mongolia (Tradisional)',
    mr: 'Marathi',
    ms: 'Melayu',
    mt: 'Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Burma)',
    nb: 'Norwegia',
    ne: 'Nepal',
    nl: 'Belanda',
    noSearchLang: 'Bahasa tidak didukung',
    nso: 'Sesotho di Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Polandia',
    placeholder: 'Mencari',
    prs: 'Dari',
    ps: 'pasto',
    pt: 'Portugis (Brasil)',
    ptPT: 'Portugis (Portugal)',
    ro: 'Rumania',
    ru: 'Rusia',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'bahasa Slowakia',
    sl: 'Slovenia',
    sm: 'Samoa',
    sn: 'Shona',
    so: 'Somalia',
    sq: 'bahasa Albania',
    srCyrl: 'Serbia (Sirilik)',
    srLatn: 'Serbia (Latin)',
    st: 'Sesotho',
    sv: 'Swedia',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'harimaunya',
    tk: 'orang Turkmenistan',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tonga',
    tr: 'Turki',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uyghur',
    uk: 'Ukraina',
    ur: 'bahasa Urdu',
    uz: 'Uzbekistan (Latin)',
    vi: 'Vietnam',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Bahasa Kanton (Tradisional)',
    zh: 'Cina',
    zhHans: 'Bahasa Cina Sederhana',
    zhHant: 'Tradisional Cina',
    zu: 'Zulu',
  },
  login: {
    and: 'Dan',
    btn: 'Masuk dengan Google',
    btn1: 'Masuk Sekarang',
    desc: 'Masuk untuk membuka lebih banyak pengalaman',
    policy: 'Kebijakan Privasi',
    terms: 'Ketentuan Penggunaan',
    tips: 'Dengan mendaftar, saya setuju dengan',
    title: 'Selamat datang di Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Mengikuti', twitter: 'Twitter' },
    legal: { policy: 'Kebijakan Privasi', terms: 'Ketentuan Layanan', title: 'Legal' },
    product: {
      image: 'Terjemahan Gambar',
      pdf: 'Terjemahan PDF',
      title: 'Produk',
      video: 'Terjemahan & Ringkasan Video',
      web: 'Terjemahan & Ringkasan Web',
    },
    resources: { title: 'Sumber daya' },
  },
  pageHeader: {
    contact: 'Kontak',
    help: 'Membantu',
    logout: 'Keluar',
    logoutSucc: 'Keluar.',
    pricing: 'Harga',
    settings: 'Pengaturan',
    signUp: 'Masuk',
  },
  pdf: {
    autoDetect: 'Deteksi Otomatis',
    cancelBtn: 'Membatalkan',
    cancelUpload: 'Membatalkan',
    chatPdf: {
      Search: 'Mencari',
      cancel: 'Membatalkan',
      chatTitle: 'Selamat datang di Noam! Berikut ringkasan PDF Anda:',
      clear: 'Jernih',
      clearContent:
        'Ini akan memulai percakapan baru. Percakapan saat ini tidak akan disimpan. Apa kamu yakin?',
      clearDialogTitle: 'Mohon Konfirmasi',
      clearSucc: 'Dibersihkan',
      copy: 'Menyalin',
      copySucc: 'Disalin',
      delete: 'Menghapus',
      deleteCancel: 'Membatalkan',
      deleteContent: 'PDF ini tidak dapat dipulihkan setelah dihapus. Apa kamu yakin?',
      deleteOk: 'Menghapus',
      deleteSuccess: 'Dihapus',
      deleteTitle: 'Konfirmasi Hapus',
      expand: 'Runtuh',
      historyTitle: 'Sejarah',
      listError: 'Gagal memuat daftar bacaan. Silakan coba lagi nanti, atau hubungi dukungan.',
      loading: 'Memuat...',
      noDataDesc: 'Belum ada sejarah.',
      noMore: 'Tidak ada lagi konten.',
      placeholder: 'Tanyakan apa pun tentang PDF ini',
      summaryTitle: 'Anda mungkin tertarik dengan pertanyaan-pertanyaan ini:',
    },
    collectionEmpty: 'Koleksi Anda kosong.',
    collectionTab: 'Catatan',
    dataLoading: 'Memuat...',
    dialogContent: 'Apakah Anda yakin ingin menghapus ini?',
    dialogTitle: 'Mohon Konfirmasi',
    download: {
      both: 'Dwibahasa',
      btn: 'Menyimpan',
      cancel:
        'Menutup jendela ini akan membatalkan pengunduhan PDF. Apakah Anda yakin ingin membatalkan?',
      desc: 'Simpan ke Perangkat: Mengunduh terjemahan sebagai gambar; Simpan & Cetak: Mengunduh terjemahan dalam format asli, dengan dukungan penyalinan.',
      download: 'Unduh',
      downloadMsg1: 'Kemajuan Penerjemahan PDF:',
      downloadMsg2: 'Perkiraan Waktu Tersisa:',
      downloadSucc: 'PDF sudah siap! Klik untuk menyimpan.',
      downloadTip:
        'Mengunduh PDF. Jangan tutup jendela ini untuk menghindari kesalahan pengunduhan.',
      error: 'Gagal menyimpan. Coba cetak saja.',
      fileName: 'Nama File:',
      fileType: 'Jenis Berkas:',
      loading: 'Penghematan...',
      loading2: 'Memuat...',
      minute: 'Menit...',
      msg1: 'Beberapa terjemahan dalam dokumen ini lebih panjang dari teks aslinya, dan mungkin tumpang tindih dengan file yang diunduh. Kami merekomendasikan membaca di halaman web.',
      msg2: '1. Terjemahan belum selesai. Silakan unduh setelah terjemahan selesai.',
      msg3: '2. Beberapa terjemahan terlalu panjang. Baca di halaman web untuk menghindari teks yang tumpang tindih.',
      msg4: 'Tugas penerjemahan sedang berlangsung. Silakan coba lagi setelah selesai.',
      progress:
        'Menerjemahkan PDF. Kemajuan: {{kemajuan}}, Perkiraan waktu tersisa: {{menit}} menit...',
      save: 'Simpan ke Perangkat',
      savePrint: 'Simpan & Cetak',
      success: 'Diunduh',
      trans: 'Hanya Terjemahan',
      transContinue: 'Lanjutkan Menerjemahkan',
    },
    downloadBtn: 'Unduh',
    dragTipModal: {
      black: 'ruang angkasa',
      leftKey: 'tombol kiri',
      ok: 'Mengerti',
      press: 'Tekan',
      tips: 'untuk menyeret dan melihat.',
    },
    entry: 'PDF',
    fileErrMsg: 'Gagal membaca file. Silakan coba lagi nanti, atau hubungi dukungan.',
    fileMsg: 'Ukuran file saat ini:',
    fileMsg2: 'Silakan unggah file yang lebih kecil dari 300MB.',
    freeBannerBtn: 'Meningkatkan',
    freeBannerTips: 'Batas Terjemahan PDF Gratis Tercapai',
    guide: {
      placeholder: 'Rekatkan tautan PDF di sini',
      start: 'Mulai Menerjemahkan',
      text1: 'Membaca Bilingual',
      text2:
        'Baca dalam dua bahasa untuk membandingkan teks asli dan terjemahan secara berdampingan, sehingga meningkatkan efisiensi Anda.',
      text3: 'Ngobrol Dengan PDF Anda',
      text4:
        'Analisis dan ringkasan yang didukung AI. Ajukan pertanyaan tentang PDF Anda, dan dapatkan jawaban instan.',
      text5: 'Akurat & Efisien',
      text6: 'Terjemahan PDF bertenaga AI untuk akurasi dan efisiensi yang lebih baik.',
      title1: 'Terjemahan Bilingual',
      title2: 'Ngobrol Dengan PDF Anda',
      toast: 'Silakan masukkan tautan PDF online yang valid yang diakhiri dengan .pdf',
    },
    hasTransTask: 'Tugas penerjemahan sedang berlangsung. Harap tunggu.',
    hoverTrans: 'Arahkan Arahkan untuk Menerjemahkan Paragraf',
    initial: 'Terjemahkan PDF',
    installText: 'Solusi terpadu untuk terjemahan sehari-hari.',
    installText2: 'Silakan instal ekstensinya terlebih dahulu',
    installTips:
      'Instal ekstensi untuk terjemahan PDF yang lebih banyak, dan terjemahan situs web yang lebih lancar:',
    kw: 'pokok',
    link: 'link',
    listError: 'Gagal memuat PDF. Silakan coba lagi nanti, atau hubungi dukungan.',
    memoTotalCount: 'jumlah 1 barang',
    new: 'berkas baru',
    noId: 'ID berkas tidak ditemukan.',
    notPdf: 'Hanya file PDF.',
    okBtn: 'Menghapus',
    okBtn2: 'Mengerti',
    original: 'Asli',
    parallel: 'Paralel',
    parseErrDialogContent:
      'Tautan PDF ini tidak dapat diterjemahkan secara langsung. Silakan unduh PDF dan unggah untuk diterjemahkan.',
    parseErrTips: 'Gagal menguraikan file',
    parseFileToast: 'Gagal menguraikan file. Silakan coba lagi nanti, atau hubungi dukungan.',
    pdfLoading: 'Mengurai...',
    searchPlaceholder: 'Mencari',
    selectTrans: 'Pilih Teks untuk Diterjemahkan',
    shareBtn: 'Membagikan',
    shareBtnText1: 'Hasilkan Tautan Bagikan',
    shareBtnText2: 'Perbarui Konten Bersama',
    shareModal: {
      cancel: 'Membatalkan',
      copy: 'Menyalin',
      copySucc: 'Tautan Disalin',
      custom: 'Sesuaikan:',
      errMsg:
        'Konten yang dipilih belum sepenuhnya diterjemahkan. Harap terjemahkan sebelum berbagi.',
      gen: 'Membagikan',
      modify: 'Sunting',
      notSupport: 'Bahasa ini saat ini tidak didukung.',
      page: 'Halaman',
      pageNuErr: 'Silakan masukkan nomor halaman yang valid.',
      range: 'Cakupan:',
      remain: 'tersisa',
      remain1: 'Total perkiraan waktu:',
      search: 'Mencari',
      settings: 'Bagikan Pengaturan:',
      shareSetting: 'Bagikan Pengaturan',
      shareText: 'Membagikan',
      shareTips: 'Bagikan tautan PDF yang diterjemahkan dengan teman-teman Anda:',
      sourceLang: 'Dari:',
      target: 'Ke:',
      text1: 'Bagikan Pengaturan',
      text2: 'Hasilkan Tautan Bagikan',
      totalPage: 'Jumlahnya 1 halaman',
      trans: 'Menerjemahkan',
      translateTips:
        'Tip: Jika terjemahan memakan waktu cukup lama, Anda dapat menutup jendela ini dan membagikannya nanti.',
      translating: 'Menerjemahkan PDF...',
      update: 'Perbarui Tautan Bagikan',
    },
    shareMsg1: 'Bagikan tautan yang dihasilkan.',
    shareMsg2: 'Konten bersama diperbarui.',
    shareMsg3: 'Gagal membuat tautan berbagi. Silakan coba lagi nanti, atau hubungi dukungan.',
    shareMsg4:
      'Gagal memperbarui konten yang dibagikan. Silakan coba lagi nanti, atau hubungi dukungan.',
    shareTips: 'Terjemahan PDF Anda sudah siap! Bagikan dengan teman Anda.',
    start: 'Mulai Uji Coba Gratis Anda',
    thumbnail: 'Daftar isi',
    toast1: 'Memuat PDF. Silakan coba lagi nanti.',
    toast2: 'Konten PDF terlalu besar. Dialog saat ini tidak didukung.',
    toast3: 'Mengunduh. Silakan coba lagi setelah pengunduhan selesai.',
    toolbar: { Adjust: 'Menyesuaikan', autoFit: 'Pas Otomatis' },
    trans: 'Terjemahan',
    transSucc: 'Terjemahan berhasil!',
    unLoginToast: 'Silakan masuk untuk menggunakan fitur ini.',
    uploadBtn: 'Mengunggah',
    uploadErr: 'Gagal mengunggah file. Silakan coba lagi nanti, atau hubungi dukungan.',
    uploadMsg1: 'Seret & letakkan file Anda di sini.',
    uploadMsg2: 'Jenis File yang Didukung: PDF |  Ukuran File Maks: 100MB',
    uploadMsg3: 'Klik untuk memilih atau drag & drop file di sini.',
    uploading: 'Mengunggah',
    uploadingMsg: 'Mengunggah. Harap tunggu.',
    uploadingMsg1: 'File sedang diunggah. Silakan coba lagi nanti.',
    uploadingMsg2: 'Sebuah file sedang dikonversi. Silakan coba lagi nanti.',
    uploadingMsg3:
      'Permintaan kemajuan konversi gagal. Silakan coba lagi nanti, atau hubungi dukungan.',
    uploadingMsg4:
      'Noam tidak dapat mengakses file lokal. Harap unggah PDF secara manual untuk diterjemahkan.',
    uploadingMsg5: 'Gagal mengambil URL file. Silakan coba lagi nanti, atau hubungi dukungan.',
    video: 'video',
    vipBannerBtn: 'Meningkatkan',
    vipBannerTips: 'Anda telah mencapai batas terjemahan PDF untuk bulan ini.',
    webTrans: 'Referensi Silang Penuh',
  },
  pricing: {
    FAQ: 'Pertanyaan Umum',
    FQAObj: {
      a1: 'Ya, Noam menawarkan paket gratis yang dapat Anda gunakan segera.',
      a2: 'Kami menawarkan tiga paket keanggotaan: Pro, Pro+, dan Enterprise. Anda dapat memilih untuk membayar bulanan atau tahunan. Penagihan tahunan menawarkan penghematan yang signifikan.',
      a3: 'Semua fitur tersedia di kedua paket. Perbedaan utamanya adalah biaya: biaya langganan tahunan lebih murah secara keseluruhan. Langganan bulanan ditagih setiap bulan, sedangkan paket tahunan ditagih setahun sekali.',
      a4: 'Anda dapat membatalkan langganan Anda kapan saja di Pengaturan Akun Anda.',
      q1: 'Apakah ada paket gratis?',
      q2: 'Paket harga dan opsi pembayaran apa yang tersedia?',
      q3: 'Apa perbedaan berlangganan bulanan vs tahunan?',
      q4: 'Bagaimana cara membatalkan langganan saya?',
    },
    around: 'Sekitar',
    billedMonthly: 'Ditagih Bulanan',
    billedYearly: 'Ditagih Setiap Tahun',
    day: 'hari',
    desc: 'Bandingkan Paket & Pilih yang Terbaik',
    detailedCompare: 'Bandingkan Paket',
    discount: 'Diskon 20%.',
    enterprise: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: 'Banyak Pengguna',
      benefit3: 'Solusi yang Disesuaikan',
      benefit5: 'Manajer Akun Khusus',
      btn: 'Hubungi kami',
      recommendText: 'Paket Khusus（≥3人）',
      talkToUs: 'Hubungi kami',
    },
    free: {
      benefit1: 'Terjemahan instan 100 kali/hari',
      benefit2: 'Terjemahan & Ringkasan Situs Web',
      benefit3: '30 Terjemahan Gambar',
      benefit4: 'Terjemahan & Ringkasan Video',
      benefit5: '10 Terjemahan PDF',
      benefit6: '5.000 token',
      btn: 'Cobalah Gratis',
      recommendText: 'Uji Coba Gratis',
    },
    imageTranslation: 'Terjemahan Gambar',
    month: 'bulan',
    monthly: 'Bulanan',
    pageTitle: 'Harga',
    pdf: {
      chat: 'Pertanyaan Obrolan PDF',
      maxPage: 'Maks. Halaman per PDF',
      translation: 'Terjemahan PDF',
    },
    preMonth: 'Per bulan',
    pro: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: '1.000.000 token/bulan',
      benefit3: 'Terjemahan & Ringkasan Situs Web',
      benefit4: '200 Terjemahan Gambar',
      benefit5: 'Terjemahan & Ringkasan Video',
      benefit6: '200 Terjemahan PDF',
      btn: 'Memulai',
      recommendText: 'Paling Populer',
    },
    proPlus: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: '3.000.000 token/bulan',
      benefit3: 'Terjemahan & Ringkasan Situs Web',
      benefit4: '500 Terjemahan Gambar',
      benefit5: 'Terjemahan & Ringkasan Video',
      benefit6: '500 Terjemahan PDF',
      recommendText: 'Nilai Terbaik',
    },
    video: { summary: 'Ringkasan Video', translation: 'Terjemahan Video' },
    web: {
      contrastiveTranslation: 'Terjemahan Bilingual',
      dictionaryTranslation: 'Terjemahan Seleksi',
      hoverTranslation: 'Arahkan Terjemahan',
      instantTranslation: 'Terjemahan Instan',
      summary: 'Ringkasan Halaman Web',
    },
    yearly: 'Tahunan',
  },
  privacy: {
    block1:
      'Noam ("Noam," "kami," "milik kami," atau "kami") menghormati privasi Anda dan berkomitmen untuk melindungi informasi apa pun yang kami peroleh dari Anda atau tentang Anda. Kebijakan Privasi ini menjelaskan praktik kami mengenai pengumpulan atau penggunaan informasi pribadi dari penggunaan Anda terhadap situs web, aplikasi, dan layanan kami (secara kolektif disebut "Layanan"). Kebijakan Privasi ini tidak berlaku untuk konten yang kami tangani atas nama pelanggan produk bisnis kami, seperti API kami. Penggunaan data ini tunduk pada perjanjian pelanggan kami, yang mencakup akses dan penggunaan produk ini.',
    block10:
      'Kami menerapkan langkah-langkah teknis, administratif, dan organisasi yang wajar secara komersial untuk melindungi informasi pribadi online dan offline dari kehilangan, penyalahgunaan, dan akses tidak sah, pengungkapan, perubahan, atau tindakan bermusuhan. Namun, tidak ada transmisi internet atau email yang dapat menjamin keamanan atau keakuratan sepenuhnya. Khususnya, email yang dikirimkan kepada kami atau dari kami mungkin tidak aman. Jadi, Anda harus sangat berhati-hati saat memutuskan informasi apa yang akan dikirimkan kepada kami melalui layanan atau email. Selain itu, kami tidak bertanggung jawab atas hambatan apa pun terhadap pengaturan privasi atau tindakan keamanan dalam layanan atau pengaturan keamanan atau tindakan keamanan Situs Web Pihak Ketiga. Kami akan menyimpan informasi pribadi Anda selama diperlukan untuk menyediakan layanan kepada Anda atau untuk tujuan bisnis sah lainnya, seperti menyelesaikan perselisihan, alasan keamanan dan keselamatan, atau mematuhi kewajiban hukum kami. Lamanya waktu kami menyimpan informasi pribadi akan bergantung pada banyak faktor, seperti jumlah, sifat, sensitivitas informasi, potensi risiko penggunaan atau pengungkapan tanpa izin, tujuan kami memproses informasi tersebut, dan pertimbangan mengikat lainnya secara hukum.',
    block11:
      'Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Ketika kami melakukannya, kami akan memposting versi terbaru di halaman ini, kecuali diwajibkan lain oleh hukum yang berlaku.',
    block12:
      'Jika Anda memiliki pertanyaan atau kekhawatiran yang belum terjawab tentang Kebijakan Privasi ini, silakan hubungi layanan pelanggan kami. E-mail:',
    block2:
      'Kami mengumpulkan informasi pribadi berikut ("Informasi Pribadi") yang berkaitan dengan Anda:',
    block3:
      'Informasi yang Anda Berikan: Saat Anda membuat akun untuk menggunakan layanan kami atau berkomunikasi dengan kami, kami mengumpulkan informasi pribadi sebagai berikut:',
    block4:
      'Informasi Pribadi yang Secara Otomatis Diterima dari Penggunaan Layanan oleh Anda: Saat Anda mengakses, menggunakan, atau berinteraksi dengan layanan, kami menerima informasi berikut tentang akses, penggunaan, atau interaksi Anda ("Informasi Teknis"):',
    block5: 'Kami dapat menggunakan informasi pribadi untuk tujuan berikut:',
    block6:
      'Ringkasan atau Informasi De-identifikasi: Kami dapat mengumpulkan atau mende-identifikasi informasi pribadi untuk mencegah penggunaan kembali untuk tujuan identifikasi dan menggunakan informasi tersebut untuk menganalisis efektivitas layanan kami, meningkatkan dan menambahkan fitur pada layanan kami, melakukan penelitian, dan untuk tujuan serupa . Selain itu, kami dapat menganalisis perilaku umum dan karakteristik pengguna layanan kami dan membagikan informasi gabungan dengan pihak ketiga, mempublikasikan informasi gabungan tersebut, atau menyediakannya secara umum. Kami dapat mengumpulkan informasi agregat melalui layanan, melalui cookie, dan melalui cara lain yang dijelaskan dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan informasi yang tidak teridentifikasi dalam bentuk anonim atau tidak teridentifikasi, dan kami tidak akan mencoba mengidentifikasi ulang informasi tersebut kecuali diwajibkan oleh hukum.',
    block7:
      'Dalam kasus tertentu, kami dapat memberikan informasi pribadi Anda kepada pihak ketiga tanpa pemberitahuan lebih lanjut kepada Anda, kecuali diwajibkan oleh hukum:',
    block8:
      'Tergantung pada lokasi geografis Anda, individu di Wilayah Ekonomi Eropa, Inggris, dan secara global mungkin memiliki hak hukum tertentu mengenai informasi pribadi mereka. Misalnya, Anda mungkin berhak untuk:',
    block9:
      'Layanan ini mungkin berisi tautan ke situs web lain yang tidak dioperasikan atau dikendalikan oleh Noam, termasuk layanan media sosial (“Situs Web Pihak Ketiga”). Informasi yang Anda bagikan dengan Situs Web Pihak Ketiga akan diatur oleh kebijakan privasi khusus dan ketentuan layanan Situs Web Pihak Ketiga, bukan oleh Kebijakan Privasi ini. Kami menyediakan tautan ini untuk kenyamanan dan tidak menyiratkan dukungan atau peninjauan kami terhadap situs web ini. Silakan menghubungi Situs Web Pihak Ketiga secara langsung untuk mendapatkan informasi tentang kebijakan privasi dan ketentuan mereka.',
    subBlock1:
      'Informasi Akun: Saat Anda membuat akun di platform kami, kami mengumpulkan informasi terkait akun Anda, termasuk nama Anda, informasi kontak, kredensial akun, informasi kartu pembayaran, dan riwayat transaksi ("Informasi Akun").',
    subBlock10: 'Menyediakan, mengelola, memelihara, dan/atau menganalisis layanan;',
    subBlock11: 'Berkomunikasi dengan Anda;',
    subBlock12: 'Mengembangkan produk dan layanan baru;',
    subBlock13:
      'Mencegah penipuan, aktivitas kriminal, atau penyalahgunaan layanan kami, dan melindungi keamanan sistem, arsitektur, dan jaringan TI kami;',
    subBlock14: 'Melakukan transfer bisnis;',
    subBlock15:
      'Mematuhi kewajiban hukum dan proses hukum, melindungi hak, privasi, keamanan, atau properti kami, serta afiliasi kami, Anda, atau pihak ketiga lainnya.',
    subBlock16:
      'Pemasok dan Penyedia Layanan: Untuk membantu kami memenuhi kebutuhan operasional bisnis dan menjalankan layanan dan fungsi tertentu, kami dapat memberikan informasi pribadi kepada pemasok dan penyedia layanan, termasuk penyedia layanan hosting, penyedia layanan cloud, dan penyedia layanan teknologi informasi lainnya, perangkat lunak komunikasi email , dan penyedia layanan analisis web, dll. Mitra ini akan mengakses, memproses, atau menyimpan informasi pribadi hanya atas arahan kami saat menjalankan tugas mereka untuk kami.',
    subBlock17:
      'Transfer Bisnis: Jika kami terlibat dalam transaksi strategis, reorganisasi, kebangkrutan, pengambilalihan kebangkrutan, atau transisi layanan ke penyedia lain (secara kolektif disebut "Transaksi"), informasi pribadi Anda dan informasi lainnya dapat dibagikan dengan mitra transaksi dan pihak lain yang membantu dalam Transaksi selama uji tuntas dan dialihkan sebagai bagian dari Transaksi bersama dengan aset lainnya kepada perusahaan penerus atau afiliasi.',
    subBlock18:
      'Persyaratan Hukum: Kami dapat membagikan informasi pribadi Anda dengan otoritas pemerintah, rekan industri, atau pihak ketiga lainnya, termasuk informasi interaksi Anda dengan layanan kami, (1) jika diwajibkan oleh hukum atau jika kami yakin tindakan tersebut diperlukan untuk mematuhi kewajiban hukum; (2) untuk melindungi dan mempertahankan hak atau properti kami; (3) jika kami secara sepihak menentukan adanya pelanggaran terhadap ketentuan, kebijakan, atau hukum kami; (4) untuk mendeteksi atau mencegah penipuan atau aktivitas ilegal lainnya; (5) untuk melindungi produk, karyawan, atau pengguna kami, atau keselamatan, keamanan, integritas publik; atau (vi) untuk membela diri dari tanggung jawab hukum.',
    subBlock19: 'Akses informasi pribadi Anda dan informasi tentang cara pemrosesannya.',
    subBlock2:
      'Konten Pengguna: Saat Anda menggunakan layanan kami, kami mengumpulkan informasi pribadi dari masukan Anda, unggahan file, atau umpan balik yang diberikan kepada kami ("Konten").',
    subBlock20: 'Minta penghapusan informasi pribadi Anda dari catatan kami.',
    subBlock21: 'Perbaiki atau perbarui informasi pribadi Anda.',
    subBlock22: 'Mentransfer informasi pribadi Anda ke pihak ketiga (portabilitas data).',
    subBlock23: 'Batasi cara kami memproses informasi pribadi Anda.',
    subBlock24:
      'Mencabut persetujuan Anda—jika kami mengandalkan persetujuan sebagai dasar hukum untuk pemrosesan, Anda dapat membatalkan persetujuan Anda kapan saja.',
    subBlock25: 'Menolak cara kami memproses informasi pribadi Anda.',
    subBlock26: 'Meningkatkan layanan kami dan melakukan penelitian;',
    subBlock3:
      'Informasi Komunikasi: Jika Anda mengirimkan pesan kepada kami, kami akan mengumpulkan nama Anda, informasi kontak, dan isi pesan Anda ("Informasi Komunikasi").',
    subBlock4:
      'Informasi Media Sosial: Kami memiliki halaman di situs media sosial seperti Instagram, Facebook, Medium, Twitter, YouTube, dan LinkedIn. Saat Anda berinteraksi dengan halaman media sosial kami, kami mengumpulkan informasi pribadi yang Anda pilih untuk diberikan kepada kami, seperti rincian kontak Anda ("Informasi Sosial"). Selain itu, perusahaan yang menghosting halaman media sosial kami dapat memberi kami kumpulan informasi dan analisis tentang aktivitas media sosial kami.',
    subBlock5:
      'ERROR: [Log Data: Information automatically sent by your browser when using our services. Log data includes your Internet Protocol (IP) address, browser type and settings, the date and time of your requests, and how you interact with our website.]',
    subBlock6:
      'Data Penggunaan: Kami dapat secara otomatis mengumpulkan informasi tentang penggunaan Anda atas layanan, seperti jenis konten yang Anda lihat atau berinteraksi dengannya, fitur yang Anda gunakan, tindakan yang Anda ambil, serta zona waktu, negara, tanggal dan waktu Anda. akses, agen pengguna dan versi, jenis komputer atau perangkat seluler, dan koneksi komputer Anda.',
    subBlock7:
      'Informasi Perangkat: Ini mencakup nama perangkat, sistem operasi, pengidentifikasi perangkat, dan browser yang Anda gunakan. Informasi yang dikumpulkan mungkin bergantung pada jenis perangkat yang Anda gunakan dan pengaturannya.',
    subBlock8:
      'Cookie: Kami menggunakan cookie untuk mengoperasikan dan mengelola layanan kami dan meningkatkan pengalaman Anda. "Cookie" adalah informasi yang dikirim oleh situs web yang Anda kunjungi ke browser Anda. Anda dapat mengatur browser Anda untuk menerima semua cookie, menolak semua cookie, atau memberi tahu Anda ketika cookie disediakan sehingga Anda dapat memutuskan apakah akan menerimanya setiap saat. Namun, menolak cookie dapat mencegah Anda menggunakan atau memberikan dampak negatif terhadap tampilan atau fungsi area atau fitur tertentu di situs web. Untuk informasi lebih lanjut tentang cookie, silakan kunjungi Semua Tentang Cookie.',
    subBlock9:
      'Analisis: Kami dapat menggunakan berbagai produk analisis online yang menggunakan cookie untuk membantu kami menganalisis cara pengguna menggunakan layanan kami dan meningkatkan pengalaman Anda saat menggunakan layanan.',
    subTitle1: 'Informasi Pribadi yang Kami Kumpulkan',
    subTitle2: 'Bagaimana Kami Menggunakan Informasi Pribadi',
    subTitle3: 'Pengungkapan Informasi Pribadi',
    subTitle4: 'Hak Anda',
    subTitle5: 'Tautan ke Situs Web Lain',
    subTitle6: 'Keamanan dan Penyimpanan Informasi',
    subTitle7: 'Modifikasi Kebijakan Privasi',
    subTitle8: 'Cara Menghubungi Kami',
    title: 'Kebijakan Privasi',
    updateTime: 'Diperbarui: 15 Maret 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Terima Semua',
    text: 'Untuk meningkatkan pengalaman Anda, kami menggunakan cookie untuk mempersonalisasi konten dan layanan.',
    title: 'Situs web ini menggunakan cookie',
  },
  slogen: 'Asisten AI termudah',
  stripe: {
    contact: 'Hubungi kami di contact@noam.tools jika ada pertanyaan!',
    fail: {
      btn: 'Mencoba kembali',
      text1: 'Kami tidak dapat memproses pembayaran Anda.',
      text2: 'Periksa koneksi Anda dan coba lagi.',
      title: 'Pembayaran gagal',
    },
    succ: {
      btn: 'Ayo pergi',
      text1: 'Selamat datang di Noam,',
      text2: 'mari kita mulai!',
      title: 'Selamat',
    },
  },
  terms: {
    block1: 'Terima kasih telah menggunakan Noam!',
    block10:
      '(a) Biaya dan Penagihan. Anda akan membayar seluruh biaya (“Biaya”) sesuai dengan harga dan ketentuan pada halaman harga yang berlaku atau sesuai kesepakatan tertulis di antara kita. Kami berhak memperbaiki kesalahan atau kesalahan penetapan harga meskipun kami telah menerbitkan faktur atau menerima pembayaran. Anda akan memberikan informasi penagihan yang lengkap dan akurat, termasuk metode pembayaran yang valid dan resmi. Kami akan menagih metode pembayaran Anda secara berkala sesuai kesepakatan, namun kami dapat mengubah tanggal penagihan secara wajar. Anda memberi wewenang kepada Noam, afiliasinya, dan pemroses pembayaran pihak ketiga untuk menagih metode pembayaran Anda. Jika pembayaran Anda gagal, kami akan memberi Anda pemberitahuan tertulis dan dapat menangguhkan akses ke Layanan hingga pembayaran diterima. Kecuali ditentukan lain dalam perjanjian ini, pembayaran tidak dapat dikembalikan.',
    block11:
      '(b) Pajak. Kecuali dinyatakan lain, Biaya tidak termasuk pajak federal, negara bagian, lokal, dan luar negeri, bea, dan penilaian serupa ("Pajak"). Anda bertanggung jawab atas semua Pajak yang terkait dengan pembelian Anda, tidak termasuk Pajak berdasarkan pendapatan bersih kami yang akan kami tagih kepada Anda. Anda setuju untuk segera membayar Pajak tersebut dan memberi kami pernyataan privasi yang sah secara hukum dan memperoleh persetujuan yang diperlukan untuk menangani data tersebut, dan Anda menjamin kepada kami bahwa Anda menangani data tersebut sesuai dengan hukum yang berlaku.',
    block12:
      '(c) Perubahan Harga. Kami dapat mengubah harga kami dengan memberi tahu Anda melalui akun Anda dan/atau situs web kami. Kenaikan harga akan berlaku segera setelah diposting. Perubahan harga apa pun akan berlaku pada Biaya akun Anda setelah tanggal efektif perubahan.',
    block13:
      '(d) Sengketa dan Keterlambatan Pembayaran. Jika Anda ingin menyengketakan Biaya atau Pajak apa pun, silakan hubungi contact@noam.tools dalam waktu tiga puluh (30) hari sejak tanggal faktur.',
    block14:
      '(e) Penggunaan Gratis. Anda tidak boleh membuat banyak akun untuk mendapatkan kredit gratis. Jika kami menentukan bahwa Anda tidak menggunakan kredit gratis dengan itikad baik, kami mungkin membebankan biaya standar kepada Anda atau berhenti memberikan akses ke Layanan.',
    block15:
      '(a) Kerahasiaan. Anda mungkin memiliki akses ke informasi rahasia Noam. Anda hanya dapat menggunakan informasi rahasia sebagaimana diizinkan berdasarkan ketentuan ini untuk tujuan penggunaan Layanan. Anda tidak boleh mengungkapkan informasi rahasia kepada pihak ketiga mana pun, dan Anda akan melindungi informasi rahasia dengan cara yang tidak kalah protektifnya dengan melindungi informasi rahasia serupa milik Anda, setidaknya dengan kehati-hatian yang wajar. Informasi rahasia adalah informasi non-publik yang ditetapkan sebagai rahasia oleh Noam atau yang seharusnya diperlakukan sebagai rahasia dalam kondisi tertentu, termasuk perangkat lunak, spesifikasi, dan informasi bisnis non-publik lainnya. Informasi rahasia tidak mencakup informasi yang: (1) tersedia secara umum untuk publik tanpa kesalahan Anda; (2) yang Anda miliki tanpa kewajiban kerahasiaan apa pun sebelum menerimanya berdasarkan ketentuan ini; (3) diungkapkan secara sah kepada Anda oleh pihak ketiga tanpa kewajiban kerahasiaan apa pun; atau (4) dikembangkan secara mandiri oleh Anda tanpa menggunakan informasi rahasia. Anda dapat mengungkapkan informasi rahasia jika diwajibkan oleh hukum, pengadilan, atau perintah pemerintah lainnya, namun Anda harus memberikan pemberitahuan tertulis sebelumnya kepada Noam dengan cara yang wajar dan, sejauh mungkin, melakukan upaya wajar untuk membatasi ruang lingkup pengungkapan, termasuk membantu kami dalam menentang permintaan pengungkapan.',
    block16:
      '(b) Keamanan. Anda harus menerapkan langkah-langkah yang wajar dan tepat untuk membantu menjaga akses Anda dan penggunaan Layanan. Jika Anda menemukan kerentanan atau pelanggaran apa pun terkait dengan penggunaan Layanan oleh Anda, Anda harus segera menghubungi Noam dan memberikan rincian kerentanan atau pelanggaran tersebut.',
    block17:
      '((c) Pemrosesan Data Pribadi. Jika Anda menggunakan Layanan untuk memproses data pribadi, Anda harus memberikan pernyataan privasi yang memadai secara hukum dan mendapatkan persetujuan yang diperlukan untuk memproses data tersebut, dan Anda menjamin kepada kami bahwa Anda menangani data tersebut sesuai dengan hukum yang berlaku .',
    block18:
      '(a) Penghentian; Penangguhan. Ketentuan ini akan berlaku sejak pertama kali Anda menggunakan Layanan dan akan tetap berlaku hingga diakhiri. Anda dapat mengakhiri ketentuan ini kapan saja karena alasan apa pun dengan menghentikan penggunaan Layanan dan Konten. Kami dapat mengakhiri ketentuan ini dengan pemberitahuan sebelumnya karena alasan apa pun. Kami dapat segera mengakhiri ketentuan ini dengan memberi tahu Anda jika Anda melanggar Pasal 2 (Persyaratan Penggunaan), Pasal 5 (Kerahasiaan, Keamanan, dan Perlindungan Data), Pasal 8 (Penyelesaian Sengketa), atau Pasal 9 (Ketentuan Umum), atau jika kami hubungan dengan penyedia teknologi pihak ketiga mana pun di luar kendali kami berubah, atau untuk mematuhi hukum atau permintaan pemerintah. Kami dapat menangguhkan akses Anda ke Layanan jika Anda gagal mematuhi ketentuan ini, atau jika penggunaan Anda menimbulkan risiko keamanan bagi kami atau pihak ketiga mana pun, atau jika kami menduga penggunaan Anda bersifat penipuan atau dapat membuat kami atau pihak ketiga mana pun bertanggung jawab. .',
    block19:
      '(b) Dampak Pengakhiran. Setelah penghentian, Anda akan berhenti menggunakan Layanan dan segera mengembalikan atau menghancurkan informasi rahasia apa pun sebagaimana diarahkan oleh kami. Ketentuan-ketentuan dalam ketentuan-ketentuan ini yang menurut sifatnya harus tetap berlaku setelah pengakhiran atau habis masa berlakunya, termasuk namun tidak terbatas pada Bagian 3 dan 5-9, akan tetap berlaku.',
    block2:
      'Ketentuan Penggunaan ini berlaku untuk penggunaan Anda atas layanan Noam, termasuk antarmuka pemrograman aplikasi, perangkat lunak, alat, layanan pengembang, data, dokumentasi, dan situs web kami (secara kolektif disebut sebagai "Layanan"). Dengan menggunakan Layanan kami, Anda setuju untuk mematuhi ketentuan ini. Kebijakan Privasi kami menjelaskan cara kami mengumpulkan dan menggunakan informasi pribadi.',
    block20:
      '(a) Ganti Rugi. Anda akan membela, mengganti kerugian, dan membebaskan kami, afiliasi kami, dan personel kami dari dan terhadap klaim, kerugian, dan biaya apa pun (termasuk biaya pengacara) yang timbul dari atau terkait dengan penggunaan Anda atas Layanan, termasuk Konten Anda, Anda penggunaan produk atau layanan yang terkait dengan Layanan, dan pelanggaran Anda terhadap ketentuan ini atau hukum yang berlaku.',
    block21:
      '(b) Penafian. Layanan disediakan "sebagaimana adanya." Sejauh diizinkan oleh undang-undang, kami dan afiliasi serta pemberi lisensi kami tidak membuat pernyataan atau jaminan apa pun mengenai Layanan dan tidak memberikan jaminan, termasuk namun tidak terbatas pada jaminan kelayakan untuk diperdagangkan, kesesuaian untuk tujuan tertentu, kualitas yang memuaskan, tidak adanya pelanggaran. , dan kenikmatan yang tenang, dan jaminan apa pun yang timbul selama transaksi atau penggunaan perdagangan. Kami tidak menjamin bahwa Layanan tidak akan terganggu, akurat, atau bebas dari kesalahan, atau bahwa Konten apa pun akan aman, tidak hilang, atau tidak diubah.',
    block22:
      '(c) Batasan Tanggung Jawab. Baik kami, afiliasi kami, maupun pemberi lisensi kami tidak bertanggung jawab atas segala kerugian tidak langsung, insidental, khusus, konsekuensial, atau hukuman, termasuk hilangnya keuntungan, hilangnya reputasi, hilangnya penggunaan, hilangnya data, atau kerugian tidak berwujud lainnya, bahkan jika kami telah diberitahu tentang kemungkinan kerusakan tersebut. Berdasarkan ketentuan ini, total tanggung jawab kami tidak akan melebihi Biaya yang Anda bayarkan ke Layanan dalam dua belas (12) bulan sebelum klaim atau seratus dolar AS ($100), mana saja yang lebih besar. Batasan dalam bagian ini berlaku sejauh yang diizinkan oleh hukum yang berlaku.',
    block23:
      'Ketentuan dan penggunaan Layanan oleh Anda diatur oleh undang-undang Amerika Serikat, kecuali aturan pertentangan undang-undang AS. Penggunaan Anda atas Aplikasi mungkin juga tunduk pada hukum lokal, negara bagian, nasional, atau internasional lainnya. Segala tindakan atau proses hukum terkait dengan Ketentuan ini akan diajukan secara eksklusif ke pengadilan Amerika Serikat, dan Anda setuju untuk tunduk pada yurisdiksi pribadi pengadilan tersebut.',
    block24:
      '(a) Hubungan Para Pihak. Noam dan Anda adalah kontraktor independen, dan tidak ada pihak yang mempunyai kekuasaan untuk mewakili atau mengikat pihak lain atau memikul kewajiban untuk pihak lain tanpa persetujuan tertulis sebelumnya dari pihak lainnya.',
    block25:
      '(b) Penggunaan Merek. Anda tidak boleh menggunakan nama, logo, atau merek dagang Noam atau afiliasinya tanpa izin tertulis sebelumnya dari kami.',
    block26:
      'Jika ada ketentuan dalam ketentuan ini yang dianggap tidak sah, ilegal, atau tidak dapat dilaksanakan, ketentuan lainnya akan tetap mempunyai kekuatan dan pengaruh penuh.',
    block3:
      'Jika Anda berusia di bawah 18 tahun, Anda harus mendapatkan izin dari orang tua atau wali sah Anda untuk menggunakan Layanan. Jika Anda menggunakan Layanan atas nama orang atau entitas lain, Anda harus diberi wewenang untuk menerima ketentuan ini atas nama mereka. Anda harus memberikan informasi yang akurat dan lengkap untuk mendaftar akun. Anda tidak boleh memberikan kredensial akses atau akun Anda kepada siapa pun di luar organisasi Anda, dan Anda bertanggung jawab atas semua aktivitas yang dilakukan menggunakan kredensial Anda.',
    block4:
      '(a) Penggunaan Layanan. Anda dapat mengakses Layanan berdasarkan ketentuan ini, dan kami memberi Anda hak non-eksklusif untuk menggunakan Layanan. Saat menggunakan Layanan, Anda akan mematuhi ketentuan ini dan semua hukum yang berlaku. Kami dan afiliasi kami memiliki semua hak, kepemilikan, dan kepentingan dalam Layanan.',
    block5:
      '(b) Umpan balik. Kami menyambut umpan balik, komentar, ide, saran, dan perbaikan. Jika Anda menyediakan konten tersebut, kami dapat menggunakannya tanpa batasan apa pun dan tanpa kompensasi kepada Anda.',
    block6:
      '(c) Pembatasan. Anda tidak boleh: (1) menggunakan Layanan dengan cara yang melanggar, menyalahgunakan, atau melanggar hak orang lain; (2) merekayasa balik, mendekompilasi, membongkar, menerjemahkan, atau berupaya menemukan kode sumber atau komponen dasar model, algoritma, dan sistem Layanan (kecuali pembatasan tersebut dilarang oleh hukum yang berlaku); (3) menggunakan keluaran Layanan untuk mengembangkan model yang bersaing dengan Noam; (4) mengekstrak data atau output dari Layanan menggunakan metode otomatis atau pemrograman apa pun kecuali diizinkan melalui API, termasuk scraping, pengumpulan web, atau ekstraksi data web; (5) menyatakan keluaran Layanan sebagai hasil buatan padahal sebenarnya tidak, atau melanggar kebijakan penggunaan kami; (6) membeli, menjual, atau mentransfer kunci API tanpa persetujuan kami sebelumnya; atau (7) memberi kami informasi pribadi apa pun mengenai anak-anak di bawah 13 tahun atau usia persetujuan digital yang berlaku. Anda akan mematuhi batasan tarif dan persyaratan lainnya dalam dokumentasi kami.',
    block7:
      '(d) Layanan Pihak Ketiga. Perangkat lunak, layanan, atau produk pihak ketiga lainnya yang terkait dengan Layanan diatur oleh ketentuannya sendiri, dan kami tidak bertanggung jawab atas produk pihak ketiga.',
    block8:
      '(a) Konten Anda. Anda dapat memberikan masukan ("Input") ke Layanan dan menerima keluaran ("Output") yang dihasilkan dan dikembalikan berdasarkan Masukan tersebut (secara kolektif disebut sebagai "Konten"). Di antara para pihak, dan sejauh diizinkan oleh hukum yang berlaku, Anda memiliki seluruh Masukan. Noam dapat menggunakan Konten untuk menyediakan dan memelihara Layanan, mematuhi hukum yang berlaku, dan menegakkan kebijakan kami. Anda bertanggung jawab atas Konten tersebut, termasuk memastikan bahwa Konten tersebut tidak melanggar hukum yang berlaku atau ketentuan ini.',
    block9:
      '(b) Akurasi. Kecerdasan buatan dan pembelajaran mesin merupakan bidang penelitian yang berkembang pesat. Kami terus berupaya meningkatkan layanan kami agar lebih akurat, andal, aman, dan bermanfaat. Mengingat sifat pembelajaran mesin yang bersifat probabilistik, penggunaan layanan kami dapat menghasilkan keluaran yang salah dalam kasus tertentu, yang mungkin tidak secara akurat mencerminkan individu, tempat, atau fakta sebenarnya. Anda harus menilai dengan tepat keakuratan keluaran apa pun berdasarkan kasus penggunaan Anda, termasuk dengan meninjau keluaran secara manual.',
    subTitle1: 'Pendaftaran dan Akses',
    subTitle10: 'Keterpisahan',
    subTitle2: 'Persyaratan Penggunaan',
    subTitle3: 'Isi',
    subTitle4: 'Biaya dan Pembayaran',
    subTitle5: 'Kerahasiaan, Keamanan, dan Perlindungan Data',
    subTitle6: 'Jangka Waktu dan Penghentian',
    subTitle7: 'Ganti Rugi; Penafian; Batasan Tanggung Jawab',
    subTitle8: 'Hukum dan Yurisdiksi yang Mengatur',
    subTitle9: 'Ketentuan Umum',
    title: 'Ketentuan Layanan',
    updateTime: 'Diperbarui: 15 Maret 2024',
  },
  trialModal: {
    btn: 'Mulai Sekarang（Hanya $0,99）',
    chat: 'ObrolanPDF',
    desc1: 'Setelah uji coba, Anda akan dikenakan biaya tahunan sebesar $180',
    desc2: 'Batalkan Kapan Saja',
    focus: 'Fokus',
    hover: 'Arahkan terjemahan',
    image: 'Terjemahan gambar',
    installBtn: 'Instal sekarang untuk menerima hadiah Anda',
    memo: 'Manajemen favorit',
    pdf: 'Terjemahan paralel PDF',
    realtime: 'Terjemahan instan',
    select: 'Terjemahan pemilihan kata',
    title: 'Hadiah selamat datang',
    trialText: 'Uji Coba 7 Hari',
    video: 'Terjemahan video',
    videoSummary: 'Ringkasan video',
    web: 'Terjemahan Paralel',
    webSummary: 'Ringkasan web',
  },
  upgradeModal: {
    btn: 'Tingkatkan Sekarang',
    chat: {
      free: 'Anda telah mencapai batas ChatPDF',
      pro: 'Anda telah mencapai batas ChatPDF untuk bulan ini',
    },
    contactStr: 'Silakan tunggu reset berikutnya, atau hubungi kami di contact@noam.tools.',
    limitAlertStr:
      'Anda telah mencapai batas paket gratis Anda. Tingkatkan versi untuk melanjutkan.',
    more: 'Lihat Paket',
    pageLimit: { free: 'Anda telah mencapai batas halaman PDF' },
    title: 'Pengingat',
    upgradeAlertStr: 'Anda telah mencapai batas paket gratis Anda. Tingkatkan untuk melanjutkan.',
    upload: {
      free: 'Anda telah mencapai batas terjemahan PDF Anda',
      pro: 'Anda telah mencapai batas terjemahan PDF untuk bulan ini',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Bergabunglah Sekarang',
      label: 'Manfaat Pengguna Baru:',
      remain: 'Hanya tersisa 30',
      text: 'Hanya 100 Tempat yang Tersedia! Raih Milikmu Sekarang!',
    },
  },
}

export default TRANSLATION
