const TRANSLATION = {
  buyModal: {
    afterFree: 'Ücretsiz denemenizin ardından',
    btn: 'Ücretsiz Deneyin',
    freeTrial: '3 Günlük Ücretsiz Deneme',
    monthLabel: 'ay',
    selectLabel: 'Bir plan seçin',
    title: 'Abonelik Planı',
    yearLabel: 'yıl',
  },
  contact: {
    block1:
      'Özelleştirme, soru veya önerileriniz için lütfen contact@noam.tools adresinden bizimle iletişime geçin. Derhal yanıt vereceğiz.',
    block2:
      '★★★★★ adresindeki Google Apps Uzantı mağazasında bizi derecelendirerek bizi destekleyin.',
    block3: 'Bu uzantıyı paylaşarak başkalarına yardımcı olun:',
    title: 'Bize Ulaşın',
  },
  help: {
    AquickOverviewOfNoam: "Noam'a Hızlı Bir Bakış",
    ChatPDF: "Sohbet PDF'si",
    ChatPDF2: 'SohbetPDF',
    FavoritesManagement: 'Favoriler Yönetimi',
    Fixedplugin: 'Sabit Eklenti',
    Focusing: 'Odaklanma',
    Free: 'Özgür',
    FreeTrialBenefits: 'Ücretsiz Deneme Avantajları',
    GoWithWikipedia: "Vikipedi'ye git",
    GoWithYouTube: "YouTube'u kullanın",
    HoverTranslation: 'Vurgulu Çeviri',
    Howtouse: 'Nasıl Kullanılır',
    ImageTranslation: 'Görüntü Çevirisi',
    InstantTranslation: 'Anında Çeviri',
    Membership: 'Üyelik',
    NoamHelp: 'Noam tercüme etmenize ve özetlemenize yardımcı olur',
    PDFparallelTranslation: 'PDF Paralel Çeviri',
    ParallelTranslation: 'Paralel Çeviri',
    SkipAllSteps: 'Tüm Adımları Atla',
    Summary: 'Özet',
    Translation: 'Çeviri',
    TryItForFree: 'Ücretsiz Deneyin',
    Video: 'Video',
    VideoSummary: 'Video Özeti',
    VideoTranslation: 'Video Çevirisi',
    WebSummary: 'Web Özeti',
    WordSelectionTranslation: 'Kelime Seçimi Çevirisi',
    benefits: 'Sizi bekleyen avantajlarımız var!',
    dayFree: '3 Günlük Ücretsiz Deneme',
    done: 'Tamamlamak',
    next: 'Sonraki',
    pin: 'Daha Kolay Erişim İçin Pin Noam～～',
    planIsNotExist: 'Plan kullanılamıyor',
    startWiki: "Wikimedia'yla başlayalım!",
    startYoutube: "YouTube'la başlayalım!",
    try: 'Deneyin',
    tryNow: 'Şimdi deneyin!',
  },
  home: {
    PDFTranslate: {
      desc: 'İki Dilli Çeviri + Sohbet PDF',
      sub1: {
        desc: 'İki dilli okuma, orijinal metni ve çeviriyi yan yana karşılaştırmanıza olanak tanıyarak okuma verimliliğini artırır.',
        title: 'İki Dilli Okuma',
      },
      sub2: {
        desc: 'Yapay zeka analiz eder, özetler ve PDF içeriğine göre sorularınızı yanıtlayabilir.',
        title: "Sohbet PDF'si",
      },
      sub3: {
        desc: 'Daha fazla doğruluk ve verimlilik için yapay zeka destekli PDF çevirisi.',
        title: 'Doğruluk ve Verimlilik',
      },
      title: 'PDF Çevirisi',
    },
    aiAssistant: {
      desc: 'Web Çevirisini Daha Akıllı ve Daha Kullanışlı Hale Getirme',
      panel1: {
        desc: 'İki dilli çapraz referanslı çeviriler, orijinal metni çeviriyle karşılaştırmanıza yardımcı olur, böylece daha iyi anlayabilir ve okuma verimliliğinizi artırabilirsiniz.',
        title: 'İki Dilli Çeviri',
      },
      panel2: {
        left: {
          desc: 'Bir web sayfasına göz atarken, çeviriyi görmek için çevirmeniz gereken içeriğin üzerine gelin.',
          title: 'Vurgulu Çeviri',
        },
        right: {
          desc: 'Bir web sayfasına göz atarken çevirisini görmek için çevirmek istediğiniz metni seçin.',
          title: 'Metin Seçimi Çevirisi',
        },
      },
      panel3: {
        one: {
          desc: 'Daha sorunsuz bir deneyim için web sayfalarını ve video içeriğini istediğiniz zaman, istediğiniz yerde çevirin ve özetleyin!',
          title: 'İşaretle ve Tıkla',
        },
        three: {
          desc: 'Yapay zeka destekli büyük dil modeli, gelişmiş bir okuma deneyimi için çeviri doğruluğu ve tutarlılığı sağlar.',
          title: 'Yapay Zeka Odaklı',
        },
        two: {
          desc: 'Çok dilli tam kapsam, iki dilli engelsiz okuma, tek tıklamayla çeviri - farkı yaşayın!',
          title: 'Daha Kapsamlı ve Kullanışlı',
        },
      },
      panel4: {
        tab1: {
          desc: "Verimlilik uzmanınız - Noam, ChatGPT'yi kullanarak size zaman kazandırmak için YouTube video içeriğini çevirir ve özetler!",
          key: 'Video',
          title: 'Video Çevirisi ve Özeti',
        },
        tab2: {
          desc: "Web'de gezinirken görüntüleri çevirmek için tıklayın.",
          key: 'Resim',
          title: 'Resim Çevirisi',
        },
        tab3: {
          desc: 'Web sayfalarından önemli bilgileri anında çıkarır ve bir özet sunarak okuma verimliliğinizi artırır.',
          key: 'ağ',
          title: 'Web Çevirisi ve Özeti',
        },
        tag1: 'Basit',
        tag2: 'Verimli',
        tag3: 'Anlaşılması Kolay',
      },
      title: 'Yapay Zeka Asistanı',
    },
    getStart: 'Başlayın',
    getStartedForFree: 'Ücretsiz başlayın',
    howItWork: {
      desc: 'Noam, kullanıcılara diller arası web sayfası çevirisi ve özetleme, PDF ve görüntü çevirisi, video çevirisi ve özetleme için akıllı bir deneyim sunmak amacıyla yapay zeka modellerini entegre ediyor',
      title: 'Nasıl çalışır?',
    },
    lastBlock: { desc: 'Daha akıcı bir okuma deneyimi için daha iyi yapay zeka çevirisi' },
    top: {
      desc: "Noam, web sayfalarını, videoları ve PDF içeriğini çevirmenize ve özetlemenize yardımcı olan ücretsiz bir AI tarayıcı uzantısıdır.\\n Noam'a katılın ve sihri deneyimleyin!",
      title1: 'Bir Büyü',
      title2: 'Tek Tıklama',
    },
    userReviews: {
      desc: "Chrome Web Mağazası'ndaki beş yıldızlı incelemeler",
      title: 'Kullanıcı Yorumları',
    },
  },
  installTips: {
    btn: 'Şimdi Deneyin',
    text: 'Daha fazla özelliğin kilidini açmak için "Noam Tarayıcı Eklentisi"ni yükleyin! Web\'de çapraz referanslama,\\n web videosu özetleme ve daha fazlası!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amharca',
    ar: 'Arapça',
    as: 'Assam dili',
    auto: 'Otomatik algılama',
    az: 'Azerice',
    ba: 'Başkurt',
    bg: 'Bulgarca',
    bn: 'Bangladeş',
    bo: 'Tibetçe',
    bs: 'Boşnakça',
    ca: 'Katalanca',
    cs: 'Çek',
    cy: 'Galce',
    da: 'Danimarka',
    de: 'Almanca',
    dsb: 'Aşağı Sorbca',
    dv: 'Divehi',
    el: 'Yunan',
    en: 'İngilizce',
    es: 'İspanyol',
    et: 'Estonca',
    eu: 'Bask',
    fa: 'Farsça',
    fi: 'Fince',
    fil: 'Filipinli',
    fj: 'Fiji dili',
    fr: 'Fransızca',
    frCA: 'Fransızca (Kanada)',
    ga: 'İrlandalı',
    gl: 'Galiçyaca',
    gom: 'Konkani',
    gu: 'Gujarati dili',
    ha: 'Hausa',
    he: 'İbranice',
    hi: 'Hintçe',
    hr: 'Hırvat',
    hsb: 'Yukarı Sorbca',
    ht: 'Haiti Kreyolu',
    hu: 'Macarca',
    hy: 'Ermeni',
    id: 'Endonezya dili',
    ig: 'İbo',
    ikt: 'Inuinnaqtun',
    is: 'İzlandaca',
    it: 'İtalyan',
    iu: 'İnuitçe',
    iuLatn: 'İnuitçe (Latince)',
    ja: 'Japonca',
    ka: 'Gürcüce',
    kk: 'Kazak',
    km: 'Kmer',
    kmr: 'Kürtçe (Kuzey)',
    kn: 'Kannadaca',
    ko: 'Korece',
    ku: 'Kürtçe (Merkez)',
    ky: 'Kırgız',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Litvanyalı',
    lug: 'Ganda',
    lv: 'Letonca',
    lzh: 'Çince (Edebi)',
    mai: 'Maithili',
    mg: 'Madagaskarca',
    mi: 'Maori',
    mk: 'Makedonca',
    ml: 'Malayalamca',
    mnCyrl: 'Moğolca (Kiril)',
    mnMong: 'Moğolca (Geleneksel)',
    mr: 'Marathi',
    ms: 'Malayca',
    mt: 'Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmanya)',
    nb: 'Norveççe',
    ne: 'Nepalce',
    nl: 'Flemenkçe',
    noSearchLang: 'Dil desteklenmiyor',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pencap',
    pl: 'Lehçe',
    placeholder: 'Aramak',
    prs: 'Dari',
    ps: 'Peştuca',
    pt: 'Portekizce (Brezilya)',
    ptPT: 'Portekizce (Portekiz)',
    ro: 'Romen',
    ru: 'Rusça',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovence',
    sm: 'Samoalı',
    sn: 'Şona',
    so: 'Somalili',
    sq: 'Arnavut',
    srCyrl: 'Sırpça (Kiril)',
    srLatn: 'Sırpça (Latince)',
    st: 'Sesotho',
    sv: 'İsveççe',
    sw: 'Svahili',
    ta: 'Tamilce',
    te: 'Telugu dili',
    th: 'Tay dili',
    ti: 'Tigrinya',
    tk: 'Türkmence',
    tlhLatn: 'Klingonca (Latince)',
    tlhPiqd: 'Klingonca (pIqaD)',
    tn: 'Setsvana',
    to: 'Tongalı',
    tr: 'Türkçe',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uygur',
    uk: 'Ukrayna',
    ur: 'Urduca',
    uz: 'Özbekçe (Latince)',
    vi: 'Vietnam',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonca (Geleneksel)',
    zh: 'Çince',
    zhHans: 'Basitleştirilmiş Çince',
    zhHant: 'Çince Geleneksel',
    zu: 'Zuluca',
  },
  login: {
    and: 'Ve',
    btn: 'Google ile Giriş Yapın',
    btn1: 'Şimdi Giriş Yapın',
    desc: 'Daha fazla deneyimin kilidini açmak için giriş yapın',
    policy: 'Gizlilik Politikası',
    terms: 'Kullanım Koşulları',
    tips: 'Kaydolarak şunu kabul ediyorum:',
    title: "Noam'a hoş geldiniz",
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Takip etmek', twitter: 'heyecan' },
    legal: { policy: 'Gizlilik Politikası', terms: 'Hizmet Şartları', title: 'Yasal' },
    product: {
      image: 'Görüntü Çevirisi',
      pdf: 'PDF Çevirisi',
      title: 'Ürünler',
      video: 'Video Çevirisi ve Özeti',
      web: 'Web Çevirisi ve Özeti',
    },
    resources: { title: 'Kaynaklar' },
  },
  pageHeader: {
    contact: 'Temas etmek',
    help: 'Yardım',
    logout: 'Oturumu Kapat',
    logoutSucc: 'Oturumu kapattım.',
    pricing: 'Fiyatlandırma',
    settings: 'Ayarlar',
    signUp: 'Giriş yapmak',
  },
  pdf: {
    autoDetect: 'Otomatik Algıla',
    cancelBtn: 'İptal etmek',
    cancelUpload: 'İptal etmek',
    chatPdf: {
      Search: 'Aramak',
      cancel: 'İptal etmek',
      chatTitle: "Noam'a hoş geldiniz! İşte PDF'nizin bir özeti:",
      clear: 'Temizlemek',
      clearContent: 'Bu yeni bir konuşma başlatacak. Mevcut görüşme kaydedilmeyecek. Emin misin?',
      clearDialogTitle: 'Lütfen Onaylayın',
      clearSucc: 'Temizlendi',
      copy: 'Kopyala',
      copySucc: 'Kopyalandı',
      delete: 'Silmek',
      deleteCancel: 'İptal etmek',
      deleteContent: 'Bu PDF silindikten sonra kurtarılamaz. Emin misin?',
      deleteOk: 'Silmek',
      deleteSuccess: 'Silindi',
      deleteTitle: 'Silmeyi Onayla',
      expand: 'Yıkılmak',
      historyTitle: 'Tarih',
      listError:
        'Okuma listesi yüklenemedi. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
      loading: 'Yükleniyor...',
      noDataDesc: 'Henüz geçmişi yok.',
      noMore: 'Daha fazla içerik yok.',
      placeholder: 'Bu PDF hakkında herhangi bir şey sorun',
      summaryTitle: 'Bu sorular ilginizi çekebilir:',
    },
    collectionEmpty: 'Koleksiyonunuz boş.',
    collectionTab: 'Notlar',
    dataLoading: 'Yükleniyor...',
    dialogContent: 'Bunu silmek istediğinizden emin misiniz?',
    dialogTitle: 'Lütfen Onaylayın',
    download: {
      both: 'İki dilli',
      btn: 'Kaydetmek',
      cancel:
        'Bu pencerenin kapatılması PDF indirme işlemini iptal edecektir. İptal etmek istediğinizden emin misiniz?',
      desc: 'Cihaza Kaydet: Çevirileri resim olarak indirir; Kaydet ve Yazdır: Çevirileri kopyalama desteğiyle orijinal formatta indirir.',
      download: 'İndirmek',
      downloadMsg1: 'PDF Çeviri İlerlemesi:',
      downloadMsg2: 'Tahmini Kalan Süre:',
      downloadSucc: "PDF'niz hazır! Kaydetmek için tıklayın.",
      downloadTip: 'PDF indiriliyor. İndirme hatalarını önlemek için bu pencereyi kapatmayın.',
      error: 'Kaydedilemedi. Bunun yerine yazdırmayı deneyin.',
      fileName: 'Dosya adı:',
      fileType: 'Dosya Türü:',
      loading: 'Kaydediliyor...',
      loading2: 'Yükleniyor...',
      minute: 'Dakikalar...',
      msg1: 'Bu belgedeki bazı çeviriler orijinal metinden daha uzundur ve indirilen dosyada çakışabilir. Web sayfasında okumanızı öneririz.',
      msg2: '1. Çeviri henüz tamamlanmadı. Lütfen çeviri bittikten sonra indirin.',
      msg3: '2. Bazı çeviriler çok uzun. Metin çakışmasını önlemek için web sayfasında okuyun.',
      msg4: 'Çeviri görevleri devam ediyor. Lütfen tamamlandıktan sonra tekrar deneyin.',
      progress:
        "PDF'yi çeviriyorum. İlerleme: {{progress}}, Kalan tahmini süre: {{dakika}} dakika...",
      save: 'Cihaza Kaydet',
      savePrint: 'Kaydet ve Yazdır',
      success: 'İndirildi',
      trans: 'Yalnızca Çeviri',
      transContinue: 'Çeviriye Devam Et',
    },
    downloadBtn: 'İndirmek',
    dragTipModal: {
      black: 'uzay',
      leftKey: 'sol düğme',
      ok: 'Anladım',
      press: 'Basmak',
      tips: 'sürükleyip görüntülemek için',
    },
    entry: "PDF'ler",
    fileErrMsg:
      'Dosya okuma başarısız oldu. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    fileMsg: 'Geçerli dosya boyutu:',
    fileMsg2: "Lütfen 300 MB'tan küçük bir dosya yükleyin.",
    freeBannerBtn: 'Güncelleme',
    freeBannerTips: 'Ücretsiz PDF Çevirileri Sınırına Ulaşıldı',
    guide: {
      placeholder: 'PDF bağlantısını buraya yapıştırın',
      start: 'Çeviriye Başla',
      text1: 'İki Dilli Okuma',
      text2:
        'Orijinal metni ve çeviriyi yan yana karşılaştırmak için iki dilde okuyun, böylece verimliliğinizi artırın.',
      text3: "PDF'nizle Sohbet Edin",
      text4:
        "Yapay zeka destekli analiz ve özetleme. PDF'nizle ilgili sorular sorun ve anında yanıt alın.",
      text5: 'Doğru ve Verimli',
      text6: 'Daha fazla doğruluk ve verimlilik için yapay zeka destekli PDF çevirisi.',
      title1: 'İki Dilli Çeviri',
      title2: "PDF'nizle Sohbet Edin",
      toast: 'Lütfen .pdf ile biten geçerli bir çevrimiçi PDF bağlantısı girin',
    },
    hasTransTask: 'Bir çeviri görevi devam ediyor. Lütfen bekleyin.',
    hoverTrans: 'Paragrafları Çevirmek İçin Üzerine Gelin',
    initial: "PDF'yi çevir",
    installText: 'Günlük çeviriler için tek adresli çözümünüz.',
    installText2: 'Lütfen önce uzantıyı yükleyin',
    installTips:
      'Daha da fazla PDF çevirisi ve daha sorunsuz web sitesi çevirileri için uzantıyı yükleyin:',
    kw: 'zımba',
    link: 'bağlantı',
    listError:
      "PDF'ler yüklenemedi. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.",
    memoTotalCount: 'Toplam 1 öğe',
    new: 'yeni dosya',
    noId: 'Dosya kimliği bulunamadı.',
    notPdf: 'Yalnızca PDF dosyaları.',
    okBtn: 'Silmek',
    okBtn2: 'Anladım',
    original: 'Orijinal',
    parallel: 'Paralel',
    parseErrDialogContent:
      "Bu PDF bağlantısı doğrudan çevrilemez. Lütfen PDF'yi indirin ve çeviri için yükleyin.",
    parseErrTips: 'Dosya ayrıştırılamadı',
    parseFileToast:
      'Dosya ayrıştırılamadı. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    pdfLoading: 'Ayrıştırma...',
    searchPlaceholder: 'Aramak',
    selectTrans: 'Çevrilecek Metni Seçin',
    shareBtn: 'Paylaşmak',
    shareBtnText1: 'Paylaşım Bağlantısı Oluştur',
    shareBtnText2: 'Paylaşılan İçeriği Güncelle',
    shareModal: {
      cancel: 'İptal etmek',
      copy: 'Kopyala',
      copySucc: 'Bağlantı Kopyalandı',
      custom: 'Özelleştirmek:',
      errMsg: 'Seçilen içerik henüz tam olarak çevrilmedi. Lütfen paylaşmadan önce tercüme edin.',
      gen: 'Paylaşmak',
      modify: 'Düzenlemek',
      notSupport: 'Bu dil şu anda desteklenmiyor.',
      page: 'Sayfalar',
      pageNuErr: 'Lütfen geçerli bir sayfa numarası girin.',
      range: 'Kapsam:',
      remain: 'geriye kalan',
      remain1: 'Toplam tahmini süre:',
      search: 'Aramak',
      settings: 'Paylaşım Ayarları:',
      shareSetting: 'Paylaşım Ayarları',
      shareText: 'Paylaşmak',
      shareTips: 'Çevrilen PDF bağlantısını arkadaşlarınızla paylaşın:',
      sourceLang: 'İtibaren:',
      target: 'İle:',
      text1: 'Paylaşım Ayarları',
      text2: 'Paylaşım Bağlantısı Oluştur',
      totalPage: 'toplam 1 sayfa',
      trans: 'Çevir',
      translateTips:
        'İpucu: Çeviri uzun sürüyorsa bu pencereyi kapatıp daha sonra paylaşabilirsiniz.',
      translating: 'PDF çevriliyor...',
      update: 'Paylaşma Bağlantısını Güncelle',
    },
    shareMsg1: 'Paylaşma bağlantısı oluşturuldu.',
    shareMsg2: 'Paylaşılan içerik güncellendi.',
    shareMsg3:
      'Paylaşım bağlantısı oluşturulamadı. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    shareMsg4:
      'Paylaşılan içerik güncellenemedi. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    shareTips: 'PDF çeviriniz hazır! Arkadaşlarınızla paylaşın.',
    start: 'Ücretsiz Denemenizi Başlatın',
    thumbnail: 'İçindekiler',
    toast1: 'PDF yükleniyor. Lütfen daha sonra tekrar deneyin.',
    toast2: 'PDF içeriği çok büyük. İletişim kutusu şu anda desteklenmiyor.',
    toast3: 'İndiriliyor. Lütfen indirme işlemi tamamlandıktan sonra tekrar deneyin.',
    toolbar: { Adjust: 'Ayarlamak', autoFit: 'Otomatik Sığdır' },
    trans: 'Çeviri',
    transSucc: 'Çeviri başarılı!',
    unLoginToast: 'Bu özelliği kullanmak için lütfen giriş yapın.',
    uploadBtn: 'Yüklemek',
    uploadErr:
      'Dosya yükleme başarısız oldu. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    uploadMsg1: 'Dosyanızı buraya sürükleyip bırakın.',
    uploadMsg2: 'Desteklenen Dosya Türleri: PDF |  Maksimum Dosya Boyutu: 100MB',
    uploadMsg3: 'Dosyaları seçmek için tıklayın veya buraya sürükleyip bırakın.',
    uploading: 'Yükleniyor',
    uploadingMsg: 'Yükleniyor. Lütfen bekleyin.',
    uploadingMsg1: 'Dosya yükleniyor. Lütfen daha sonra tekrar deneyin.',
    uploadingMsg2: 'Şu anda bir dosya dönüştürülüyor. Lütfen daha sonra tekrar deneyin.',
    uploadingMsg3:
      'Dönüşüm ilerleme isteği başarısız oldu. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    uploadingMsg4:
      'Noam yerel dosyalara erişemiyor. Lütfen çeviri için manuel olarak bir PDF yükleyin.',
    uploadingMsg5:
      "Dosya URL'si alınamadı. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.",
    video: 'video',
    vipBannerBtn: 'Güncelleme',
    vipBannerTips: 'Bu ayki PDF çeviri sınırınıza ulaştınız.',
    webTrans: 'Tam Çapraz Referans',
  },
  pricing: {
    FAQ: 'SSS',
    FQAObj: {
      a1: 'Evet, Noam hemen kullanabileceğiniz ücretsiz bir plan sunuyor.',
      a2: 'Üç üyelik planı sunuyoruz: Pro, Pro+ ve Enterprise. Aylık veya yıllık ödeme yapmayı seçebilirsiniz. Yıllık faturalandırma önemli tasarruflar sağlar.',
      a3: 'Tüm özellikler her iki planda da mevcuttur. Temel fark maliyettir: Yıllık aboneliklerin maliyeti genel olarak daha düşüktür. Aylık abonelikler her ay faturalandırılırken yıllık planlar yılda bir kez faturalandırılır.',
      a4: 'Aboneliğinizi istediğiniz zaman Hesap Ayarlarınızdan iptal edebilirsiniz.',
      q1: 'Ücretsiz bir plan var mı?',
      q2: 'Hangi fiyatlandırma planları ve ödeme seçenekleri mevcut?',
      q3: 'Aylık abone olmakla yıllık abone olmak arasındaki fark nedir?',
      q4: 'Aboneliğimi nasıl iptal edebilirim?',
    },
    around: 'Etrafında',
    billedMonthly: 'Aylık Faturalandırılır',
    billedYearly: 'Yıllık Faturalandırılır',
    day: 'gün',
    desc: 'Planları Karşılaştırın ve En İyisini Seçin',
    detailedCompare: 'Planları Karşılaştır',
    discount: '%20 İndirim',
    enterprise: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: 'Çoklu Kullanıcı',
      benefit3: 'Özel Çözümler',
      benefit5: 'Özel Hesap Yöneticisi',
      btn: 'Bize Ulaşın',
      recommendText: 'Özel Planlar（≥3人）',
      talkToUs: 'İletişime Geçin',
    },
    free: {
      benefit1: 'Günde 100 kez anında çeviri',
      benefit2: 'Web Sitesi Çevirisi ve Özeti',
      benefit3: '30 Resim Çevirisi',
      benefit4: 'Video Çevirisi ve Özeti',
      benefit5: '10 PDF Çevirisi',
      benefit6: '5.000 jeton',
      btn: 'Ücretsiz Deneyin',
      recommendText: 'Ücretsiz Deneme',
    },
    imageTranslation: 'Görüntü Çevirisi',
    month: 'ay',
    monthly: 'Aylık',
    pageTitle: 'Fiyatlandırma',
    pdf: {
      chat: 'PDF Sohbet Sorguları',
      maxPage: 'Maks. PDF başına sayfa sayısı',
      translation: 'PDF Çevirisi',
    },
    preMonth: 'Aylık',
    pro: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: '1.000.000 jeton/ay',
      benefit3: 'Web Sitesi Çevirisi ve Özeti',
      benefit4: '200 Resim Çevirisi',
      benefit5: 'Video Çevirisi ve Özeti',
      benefit6: '200 PDF Çevirisi',
      btn: 'Başlayın',
      recommendText: 'En Popüler',
    },
    proPlus: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: '3.000.000 jeton/ay',
      benefit3: 'Web Sitesi Çevirisi ve Özeti',
      benefit4: '500 Resim Çevirisi',
      benefit5: 'Video Çevirisi ve Özeti',
      benefit6: '500 PDF Çevirisi',
      recommendText: 'En İyi Değer',
    },
    video: { summary: 'Video Özeti', translation: 'Video Çevirisi' },
    web: {
      contrastiveTranslation: 'İki Dilli Çeviri',
      dictionaryTranslation: 'Seçim Çevirisi',
      hoverTranslation: 'Vurgulu Çeviri',
      instantTranslation: 'Anında Çeviri',
      summary: 'Web Sayfası Özeti',
    },
    yearly: 'Yıllık',
  },
  privacy: {
    block1:
      'Noam ("Noam", "biz", "bizim" veya "bize") gizliliğinize saygı duyar ve sizden veya sizin hakkınızda elde ettiğimiz her türlü bilgiyi korumayı taahhüt eder. Bu Gizlilik Politikası, web sitemizi, uygulamalarımızı ve hizmetlerimizi (topluca "Hizmetler") kullanımınızdan kaynaklanan kişisel bilgilerin toplanması veya kullanılmasıyla ilgili uygulamalarımızı açıklamaktadır. Bu Gizlilik Politikası, API\'miz gibi ticari ürün müşterilerimiz adına işlediğimiz içerikler için geçerli değildir. Bu verilerin kullanımı, bu ürünlere erişimi ve bunların kullanımını kapsayan müşteri sözleşmemize tabidir.',
    block10:
      'Çevrimiçi ve çevrimdışı kişisel bilgileri kayıp, kötüye kullanım ve yetkisiz erişim, ifşa, değiştirme veya düşmanca eylemlere karşı korumak için ticari açıdan makul teknik, idari ve organizasyonel önlemler uyguluyoruz. Ancak hiçbir internet veya e-posta iletimi tam güvenliği veya doğruluğu garanti edemez. Özellikle bize gönderilen veya bizden gönderilen e-postalar güvenli olmayabilir. Bu nedenle, hizmet veya e-posta yoluyla bize hangi bilgileri göndereceğinize karar verirken özellikle dikkatli olmalısınız. Ayrıca, hizmetteki gizlilik ayarlarının veya güvenlik önlemlerinin veya Üçüncü Taraf Web Sitelerinin güvenlik ayarlarının veya güvenlik önlemlerinin engellenmesinden sorumlu değiliz. Kişisel bilgilerinizi, size hizmet sunmamız için veya anlaşmazlıkları çözmek, güvenlik ve emniyet nedenleri veya yasal yükümlülüklerimize uymak gibi diğer meşru iş amaçları için gerekli olduğu sürece saklayacağız. Kişisel bilgileri sakladığımız sürenin uzunluğu, bilgilerin miktarı, niteliği, hassasiyeti, yetkisiz kullanım veya ifşanın potansiyel riskleri, bilgileri işleme amaçlarımız ve diğer yasal olarak bağlayıcı hususlar gibi birçok faktöre bağlı olacaktır.',
    block11:
      'Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Bunu yaptığımızda, yürürlükteki kanunlar aksini gerektirmedikçe, bu sayfada güncellenmiş bir versiyon yayınlayacağız.',
    block12:
      'Bu Gizlilik Politikasıyla ilgili yanıtlanmamış sorularınız veya endişeleriniz varsa lütfen müşteri hizmetlerimizle iletişime geçin. E-posta:',
    block2: 'Sizinle ilgili aşağıdaki kişisel bilgileri ("Kişisel Bilgiler") topluyoruz:',
    block3:
      'Tarafınızdan Sağlanan Bilgiler: Hizmetlerimizi kullanmak veya bizimle iletişim kurmak için bir hesap oluşturduğunuzda, kişisel bilgileri aşağıdaki şekilde toplarız:',
    block4:
      'Hizmetleri Kullanımınızdan Otomatik Olarak Alınan Kişisel Bilgiler: Hizmetlere eriştiğinizde, bunları kullandığınızda veya bunlarla etkileşimde bulunduğunuzda, erişiminiz, kullanımınız veya etkileşiminiz hakkında aşağıdaki bilgileri alırız ("Teknik Bilgiler"):',
    block5: 'Kişisel bilgileri aşağıdaki amaçlarla kullanabiliriz:',
    block6:
      'Özet veya Kimlik Bilgilerinden Arındırılmış Bilgiler: Kimlik belirleme amacıyla yeniden kullanılmasını önlemek için kişisel bilgileri toplayabilir veya kimlik bilgilerini kaldırabiliriz ve bu bilgileri hizmetlerimizin etkinliğini analiz etmek, hizmetlerimizi geliştirmek ve bunlara özellikler eklemek, araştırma yapmak ve benzer amaçlarla kullanabiliriz. . Ayrıca, hizmet kullanıcılarımızın genel davranışlarını ve özelliklerini analiz edebilir ve toplu bilgileri üçüncü taraflarla paylaşabilir, bu tür toplu bilgileri yayınlayabilir veya genel kullanıma sunabiliriz. Toplu bilgileri hizmetler aracılığıyla, çerezler aracılığıyla ve bu Gizlilik Politikasında açıklanan diğer yollarla toplayabiliriz. Kimlik bilgileri kaldırılmış bilgileri anonim veya kimlik bilgileri kaldırılmış bir biçimde saklayacağız ve kullanacağız ve yasalar gerektirmedikçe bu tür bilgileri yeniden tanımlama girişiminde bulunmayacağız.',
    block7:
      'Bazı durumlarda, kanunen zorunlu kılınmadığı sürece, kişisel bilgilerinizi size önceden bildirimde bulunmaksızın üçüncü taraflara verebiliriz:',
    block8:
      'Coğrafi konumunuza bağlı olarak, Avrupa Ekonomik Alanı, Birleşik Krallık ve dünya genelindeki bireyler, kişisel bilgileriyle ilgili belirli yasal haklara sahip olabilir. Örneğin, aşağıdaki haklara sahip olabilirsiniz:',
    block9:
      'Hizmet, sosyal medya hizmetleri de dahil olmak üzere Noam tarafından işletilmeyen veya kontrol edilmeyen diğer web sitelerine ("Üçüncü Taraf Web Siteleri") bağlantılar içerebilir. Üçüncü Taraf Web Siteleriyle paylaştığınız bilgiler, bu Gizlilik Politikasına değil, Üçüncü Taraf Web Sitelerinin özel gizlilik politikalarına ve hizmet koşullarına tabi olacaktır. Bu bağlantıları kolaylık sağlamak amacıyla sağlıyoruz ve bu web sitelerini onayladığımızı veya incelediğimizi ima etmiyoruz. Gizlilik politikaları ve koşulları hakkında bilgi almak için lütfen Üçüncü Taraf Web Siteleriyle doğrudan iletişime geçin.',
    subBlock1:
      'Hesap Bilgileri: Platformumuzda bir hesap oluşturduğunuzda, adınız, iletişim bilgileriniz, hesap kimlik bilgileriniz, ödeme kartı bilgileriniz ve işlem geçmişiniz dahil olmak üzere hesabınızla ilgili bilgileri ("Hesap Bilgileri") toplarız.',
    subBlock10: 'Hizmetlerin sağlanması, yönetilmesi, sürdürülmesi ve/veya analiz edilmesi;',
    subBlock11: 'Sizinle iletişim kurmak;',
    subBlock12: 'Yeni ürün ve hizmetler geliştirmek;',
    subBlock13:
      'Dolandırıcılığı, suç faaliyetlerini veya hizmetlerimizin kötüye kullanımını önlemek ve BT sistemlerimizin, mimarimizin ve ağlarımızın güvenliğini korumak;',
    subBlock14: 'Ticari transferlerin gerçekleştirilmesi;',
    subBlock15:
      'Yasal yükümlülüklere ve yasal işlemlere uymak, bizim haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizin yanı sıra bağlı kuruluşlarımızın, sizin veya diğer üçüncü tarafların haklarını korumak.',
    subBlock16:
      'Tedarikçiler ve Hizmet Sağlayıcılar: İşletmenin operasyonel ihtiyaçlarını karşılamamızda ve belirli hizmet ve işlevleri yerine getirmemizde bize yardımcı olmak için, barındırma hizmeti sağlayıcıları, bulut hizmeti sağlayıcıları ve diğer bilgi teknolojisi hizmet sağlayıcıları, e-posta iletişim yazılımı dahil olmak üzere tedarikçilere ve hizmet sağlayıcılara kişisel bilgiler sağlayabiliriz. ve web analizi hizmet sağlayıcıları vb. Bu ortaklar, bizim için görevlerini yerine getirirken kişisel bilgilere yalnızca bizim yönlendirmemiz doğrultusunda erişecek, işleyecek veya saklayacaklardır.',
    subBlock17:
      'Ticari Transferler: Stratejik işlemler, yeniden yapılanmalar, iflaslar, iflas devralmaları veya başka bir sağlayıcıya hizmet geçişi (toplu olarak, "İşlemler") gerçekleştirmemiz durumunda, kişisel bilgileriniz ve diğer bilgileriniz işlem muhatapları ve İşlemlere yardımcı olan diğer taraflarla paylaşılabilir. Durum tespiti sırasında ve İşlemlerin bir parçası olarak diğer varlıklarla birlikte bir halef veya bağlı şirkete devredilir.',
    subBlock18:
      'Yasal Gereksinimler: Hizmetlerimizle etkileşim bilgileriniz de dahil olmak üzere kişisel bilgilerinizi devlet yetkilileriyle, sektördeki meslektaşlarımızla veya diğer üçüncü taraflarla paylaşabiliriz; (1) yasaların gerektirmesi halinde veya yasal yükümlülüklere uymak için bu tür bir eylemin gerekli olduğuna inanırsak; (2) haklarımızı veya mülkiyetimizi korumak ve savunmak; (3) şartlarımızın, politikalarımızın veya yasalarımızın ihlal edildiğini tek taraflı olarak tespit edersek; (4) dolandırıcılığı veya diğer yasa dışı faaliyetleri tespit etmek veya önlemek; (5) ürünlerimizi, çalışanlarımızı veya kullanıcılarımızı ya da kamu güvenliğini, emniyetini, bütünlüğünü korumak; veya (vi) yasal sorumluluğa karşı savunma yapmak.',
    subBlock19: 'Kişisel bilgilerinize ve bunların nasıl işlendiğine ilişkin bilgilere erişin.',
    subBlock2:
      'Kullanıcı İçeriği: Hizmetlerimizi kullandığınızda, girdilerinizden, dosya yüklemelerinizden veya bize sağladığınız geri bildirimlerden ("İçerik") kişisel bilgiler toplarız.',
    subBlock20: 'Kişisel bilgilerinizin kayıtlarımızdan silinmesini talep edin.',
    subBlock21: 'Kişisel bilgilerinizi düzeltin veya güncelleyin.',
    subBlock22: 'Kişisel bilgilerinizi üçüncü bir tarafa aktarın (veri taşınabilirliği).',
    subBlock23: 'Kişisel bilgilerinizi işleme şeklimizi sınırlayın.',
    subBlock24:
      'Onayınızı geri çekin; işlemenin yasal dayanağı olarak onaya dayandığımız durumlarda, onayınızı istediğiniz zaman geri çekebilirsiniz.',
    subBlock25: 'Kişisel bilgilerinizi nasıl işlediğimize itiraz edin.',
    subBlock26: 'Hizmetlerimizi geliştirmek ve araştırma yapmak;',
    subBlock3:
      'İletişim Bilgileri: Bize mesaj gönderirseniz adınızı, iletişim bilgilerinizi ve mesajlarınızın içeriğini ("İletişim Bilgileri") toplayacağız.',
    subBlock4:
      'Sosyal Medya Bilgileri: Instagram, Facebook, Medium, Twitter, YouTube ve LinkedIn gibi sosyal medya sitelerinde sayfalarımız bulunmaktadır. Sosyal medya sayfalarımızla etkileşimde bulunduğunuzda, iletişim bilgileriniz gibi bize vermeyi seçtiğiniz kişisel bilgileri ("Sosyal Bilgiler") toplarız. Ayrıca sosyal medya sayfalarımızı barındıran şirketler bize sosyal medya faaliyetlerimiz hakkında toplu bilgi ve analiz sağlayabilir.',
    subBlock5:
      'Günlük Verileri: Hizmetlerimizi kullanırken tarayıcınız tarafından otomatik olarak gönderilen bilgiler. Günlük verileri İnternet Protokolü (IP) adresinizi, tarayıcı türünüzü ve ayarlarınızı, taleplerinizin tarih ve saatini ve web sitemizle nasıl etkileşimde bulunduğunuzu içerir.',
    subBlock6:
      'Kullanım Verileri: Görüntülediğiniz veya etkileşimde bulunduğunuz içerik türleri, kullandığınız özellikler, gerçekleştirdiğiniz eylemler ve ayrıca saat diliminiz, ülkeniz, tarih ve saatiniz gibi hizmeti kullanımınızla ilgili bilgileri otomatik olarak toplayabiliriz. erişim, kullanıcı aracısı ve sürümü, bilgisayar veya mobil cihaz türü ve bilgisayar bağlantınız.',
    subBlock7:
      'Cihaz Bilgileri: Bu, cihazın adını, işletim sistemini, cihaz tanımlayıcısını ve kullandığınız tarayıcıyı içerir. Toplanan bilgiler kullandığınız cihazın türüne ve ayarlarına bağlı olabilir.',
    subBlock8:
      'Çerezler: Hizmetlerimizi işletmek ve yönetmek ve deneyiminizi geliştirmek için çerezleri kullanırız. "Çerez", ziyaret ettiğiniz bir web sitesi tarafından tarayıcınıza gönderilen bilgilerdir. Tarayıcınızı tüm çerezleri kabul edecek, tüm çerezleri reddedecek veya bir çerez sağlandığında size bildirimde bulunacak şekilde ayarlayabilirsiniz; böylece her seferinde kabul edip etmeyeceğinize karar verebilirsiniz. Ancak çerezlerin reddedilmesi, web sitesinin belirli alanlarının veya özelliklerinin kullanımını engelleyebilir veya görüntülenmesini veya işlevselliğini olumsuz etkileyebilir. Çerezler hakkında daha fazla bilgi için lütfen Çerezler Hakkında Her Şey sayfasını ziyaret edin.',
    subBlock9:
      'Analitikler: Kullanıcıların hizmetlerimizi nasıl kullandıklarını analiz etmemize ve hizmetleri kullanırken deneyiminizi iyileştirmemize yardımcı olması için çerezleri kullanan çeşitli çevrimiçi analiz ürünlerini kullanabiliriz.',
    subTitle1: 'Topladığımız Kişisel Bilgiler',
    subTitle2: 'Kişisel Bilgileri Nasıl Kullanıyoruz',
    subTitle3: 'Kişisel Bilgilerin Açıklanması',
    subTitle4: 'Haklarınız',
    subTitle5: 'Diğer Web Sitelerine Bağlantılar',
    subTitle6: 'Bilgilerin Güvenliği ve Saklanması',
    subTitle7: 'Gizlilik Politikasının Değiştirilmesi',
    subTitle8: 'Bize Nasıl Ulaşabilirsiniz?',
    title: 'Gizlilik Politikası',
    updateTime: 'Güncelleme: 15 Mart 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Tümünü Kabul Et',
    text: 'Deneyiminizi geliştirmek amacıyla içerik ve hizmetleri kişiselleştirmek için çerezleri kullanıyoruz.',
    title: 'Bu web sitesi çerezleri kullanıyor',
  },
  slogen: 'En kolay AI asistanı',
  stripe: {
    contact: 'Sorularınız için contact@noam.tools adresinden bize ulaşın!',
    fail: {
      btn: 'Yeniden dene',
      text1: 'Ödemenizi işleme koyamadık.',
      text2: 'Bağlantınızı kontrol edip tekrar deneyin.',
      title: 'Ödeme başarısız oldu',
    },
    succ: {
      btn: 'Hadi gidelim',
      text1: "Noam'a hoş geldiniz,",
      text2: 'hadi başlayalım!',
      title: 'Tebrikler',
    },
  },
  terms: {
    block1: "Noam'ı kullandığınız için teşekkür ederiz!",
    block10:
      '(a) Ücretler ve Faturalandırma. Tüm ücretleri ("Ücretler") geçerli fiyatlandırma sayfasındaki fiyatlandırma ve şartlara göre veya aramızda yazılı olarak mutabakata varıldığı şekilde ödeyeceksiniz. Fatura düzenlemiş veya ödeme almış olsak bile fiyatlandırma hatalarını veya hatalarını düzeltme hakkımızı saklı tutuyoruz. Geçerli ve yetkili bir ödeme yöntemi de dahil olmak üzere tam ve doğru fatura bilgilerini sağlayacaksınız. Ödeme yönteminizden, mutabakata varıldığı şekilde periyodik olarak ücret alacağız ancak fatura tarihini makul ölçüde değiştirebiliriz. Noam\'a, bağlı kuruluşlarına ve üçüncü taraf ödeme işleyicilerine, ödeme yönteminizden ücret alma yetkisi verirsiniz. Ödemeniz başarısız olursa, size yazılı bildirimde bulunacağız ve ödeme alınana kadar Hizmete erişimi askıya alabiliriz. Bu sözleşmede aksi belirtilmedikçe yapılan ödemeler iade edilmez.',
    block11:
      '(b) Vergiler. Aksi belirtilmediği sürece, Ücretlere herhangi bir federal, eyalet, yerel ve yabancı vergi, harç ve benzeri tahakkuklar ("Vergiler") dahil değildir. Size fatura edeceğimiz net gelire dayalı Vergiler hariç, satın alma işlemlerinizle ilgili tüm Vergilerden siz sorumlusunuz. Bu tür Vergileri derhal ödemeyi ve bize yasal olarak geçerli gizlilik bildirimlerini sunmayı ve bu tür verileri işlemek için gerekli izinleri almayı kabul edersiniz ve bu tür verileri geçerli yasalara uygun olarak kullandığınızı bize garanti edersiniz.',
    block12:
      '(c) Fiyat Değişiklikleri. Hesabınız ve/veya web sitemiz aracılığıyla size bildirimde bulunarak fiyatlarımızı değiştirebiliriz. Fiyat artışları yayınlandıktan hemen sonra geçerli olacaktır. Herhangi bir fiyat değişikliği, değişikliğin geçerlilik tarihinden sonra hesabınızdaki Ücretlere uygulanacaktır.',
    block13:
      '(d) Anlaşmazlıklar ve Gecikmiş Ödemeler. Herhangi bir Ücret veya Vergiye itiraz etmek istiyorsanız lütfen fatura tarihinden itibaren otuz (30) gün içinde contact@noam.tools ile iletişime geçin.',
    block14:
      '(e) Ücretsiz Kullanım. Ücretsiz kredi almak için birden fazla hesap oluşturamazsınız. Ücretsiz kredileri iyi niyetle kullanmadığınızı tespit edersek, sizden standart ücretler talep edebilir veya Hizmete erişim sağlamayı durdurabiliriz.',
    block15:
      "(a) Gizlilik. Noam'ın gizli bilgilerine erişiminiz olabilir. Gizli bilgileri yalnızca bu koşullar altında izin verildiği ölçüde Hizmeti kullanmak amacıyla kullanabilirsiniz. Gizli bilgileri herhangi bir üçüncü tarafa ifşa edemezsiniz ve gizli bilgileri, kendi benzer gizli bilgilerinizi koruduğunuzdan daha az koruyucu olmayan bir şekilde, en azından makul bir özenle koruyacaksınız. Gizli bilgiler, yazılım, spesifikasyonlar ve diğer kamuya açık olmayan ticari bilgiler de dahil olmak üzere, Noam tarafından gizli olarak belirlenen veya duruma göre makul bir şekilde gizli olarak ele alınması gereken, kamuya açık olmayan bilgilerdir. Gizli bilgiler aşağıdaki bilgileri içermez: (1) sizin tarafınızdan herhangi bir kusur olmadan genel olarak kamuya açık hale gelen; (2) bu koşullar uyarınca teslim almadan önce herhangi bir gizlilik yükümlülüğü olmaksızın elinizde bulunan; (3) herhangi bir gizlilik yükümlülüğü olmaksızın üçüncü bir tarafça size yasal olarak ifşa edilen; veya (4) gizli bilgiler kullanılmadan tarafınızca bağımsız olarak geliştirilmişse. Yasa, mahkeme veya başka bir resmi emir gerektirdiği takdirde gizli bilgileri ifşa edebilirsiniz ancak Noam'a makul bir şekilde önceden yazılı bildirimde bulunmalı ve bize yardımcı olmak da dahil olmak üzere mümkün olduğu ölçüde ifşa kapsamını sınırlamak için makul çaba göstermelisiniz. ifşa taleplerine karşı çıkıyor.",
    block16:
      '(b) Güvenlik. Hizmete erişiminizi ve Hizmeti kullanımınızı korumaya yardımcı olmak için makul ve uygun önlemleri uygulamanız gerekir. Hizmeti kullanımınızla ilgili herhangi bir güvenlik açığı veya ihlal keşfederseniz derhal Noam ile iletişime geçmeli ve güvenlik açığı veya ihlalin ayrıntılarını vermelisiniz.',
    block17:
      '((c) Kişisel Verilerin İşlenmesi. Hizmeti kişisel verileri işlemek için kullanırsanız, yasal olarak yeterli gizlilik bildirimlerini sağlamalı ve bu tür verilerin işlenmesi için gerekli izinleri almalısınız ve bu tür verileri yürürlükteki yasalara uygun olarak kullandığınızı bize garanti edersiniz. .',
    block18:
      "(a) Fesih; Süspansiyon. Bu şartlar, Hizmeti ilk kullanımınızdan itibaren geçerli olacak ve sonlandırılana kadar yürürlükte kalacaktır. Hizmet ve İçeriği kullanmayı bırakarak bu koşulları istediğiniz zaman herhangi bir nedenle feshedebilirsiniz. Bu koşulları herhangi bir nedenle önceden bildirimde bulunarak feshedebiliriz. Bölüm 2 (Kullanım Gereksinimleri), Bölüm 5 (Gizlilik, Güvenlik ve Veri Koruma), Bölüm 8 (Anlaşmazlık Çözümü) veya Bölüm 9'u (Genel Şartlar) önemli ölçüde ihlal etmeniz durumunda veya kontrolümüz dışında herhangi bir üçüncü taraf teknoloji sağlayıcısıyla ilişki değişiklikleri veya yasalara veya devlet taleplerine uyum amacıyla. Bu şartlara uymamanız veya kullanımınızın bizim veya herhangi bir üçüncü taraf için güvenlik riski oluşturması veya kullanımınızın hileli olduğundan şüphelenmemiz veya bizi veya herhangi bir üçüncü tarafı yükümlülük altına sokması durumunda Hizmete erişiminizi askıya alabiliriz. .",
    block19:
      '(b) Feshin Etkileri. Feshin ardından, Hizmeti kullanmayı bırakacak ve gizli bilgileri tarafımızca belirtilen şekilde derhal iade edecek veya yok edeceksiniz. Bölüm 3 ve 5-9 dahil ancak bunlarla sınırlı olmamak üzere, doğası gereği fesih veya sona erme tarihinden sonra da geçerli olması gereken bu şartların hükümleri geçerliliğini sürdürecektir.',
    block2:
      'Bu Kullanım Koşulları, uygulama programlama arayüzlerimiz, yazılımımız, araçlarımız, geliştirici hizmetlerimiz, verilerimiz, belgelerimiz ve web sitemiz (toplu olarak "Hizmetler" olarak anılacaktır) dahil olmak üzere Noam hizmetlerini kullanımınız için geçerlidir. Hizmetlerimizi kullanarak bu şartlara uymayı kabul etmiş olursunuz. Gizlilik Politikamız kişisel bilgileri nasıl topladığımızı ve kullandığımızı açıklamaktadır.',
    block20:
      '(a) Tazminat. İçeriğiniz de dahil olmak üzere Hizmeti kullanımınızdan kaynaklanan veya bununla ilgili olarak ortaya çıkan her türlü talep, kayıp ve masrafa (avukatlık ücretleri dahil) karşı bizi, bağlı kuruluşlarımızı ve personelimizi savunacak, tazmin edecek ve masun tutacaksınız. Hizmetle ilgili ürün veya hizmetlerin kullanımı ve bu koşulları veya geçerli yasaları ihlal etmeniz.',
    block21:
      '(b) Sorumluluk reddi beyanları. Hizmet "olduğu gibi" sağlanır. Yasaların izin verdiği ölçüde, biz, bağlı kuruluşlarımız ve lisans verenlerimiz Hizmetle ilgili hiçbir beyanda bulunmaz veya garanti vermez ve ticarete elverişlilik, belirli bir amaca uygunluk, tatmin edici kalite, ihlal etmeme garantileri dahil ancak bunlarla sınırlı olmamak üzere hiçbir garanti vermez. ve sessiz kullanım ve ticaretten veya ticaretin kullanımından kaynaklanan her türlü garanti. Hizmetin kesintisiz, doğru veya hatasız olacağını veya herhangi bir İçeriğin güvenli olacağını, kaybolmayacağını veya değiştirilmeyeceğini garanti etmiyoruz.',
    block22:
      '(c) Sorumluluğun Sınırlandırılması. Ne biz, ne de bağlı kuruluşlarımız veya lisans verenlerimiz, kar kaybı, itibar kaybı, kullanım kaybı, veri kaybı veya diğer maddi olmayan kayıplar da dahil olmak üzere dolaylı, arızi, özel, sonuç olarak ortaya çıkan veya cezai zararlardan sorumlu olmayacağız. Bu tür zararların meydana gelebileceği konusunda bilgilendirildik. Bu şartlar uyarınca toplam sorumluluğumuz, talepten önceki on iki (12) ay içinde Hizmete ödediğiniz Ücretleri veya yüz ABD dolarını (100 ABD Doları) (hangisi daha büyükse) aşmayacaktır. Bu bölümdeki sınırlamalar yürürlükteki yasaların izin verdiği azami ölçüde geçerlidir.',
    block23:
      'Şartlar ve Hizmetleri kullanımınız, ABD kanunlar ihtilafı kuralları haricinde Amerika Birleşik Devletleri kanunlarına tabidir. Uygulamayı kullanımınız ayrıca diğer yerel, eyalet, ulusal veya uluslararası yasalara da tabi olabilir. Bu Koşullara ilişkin herhangi bir yasal işlem veya dava yalnızca Amerika Birleşik Devletleri mahkemelerinde görülecektir ve bu tür mahkemelerin kişisel yargı yetkisine tabi olmayı kabul edersiniz.',
    block24:
      '(a) Tarafların İlişkisi. Noam ve siz bağımsız yüklenicilersiniz ve taraflardan hiçbiri, diğer tarafın önceden yazılı izni olmadan diğer tarafı temsil veya ilzam etme veya diğer taraf adına yükümlülük üstlenme yetkisine sahip değildir.',
    block25:
      "(b) Marka Kullanımı. Noam'ın veya bağlı şirketlerinin adını, logolarını veya ticari markalarını önceden yazılı iznimiz olmadan kullanamazsınız.",
    block26:
      'Bu şartların herhangi bir hükmünün geçersiz, yasa dışı veya uygulanamaz olduğu tespit edilirse geri kalan hükümler tam olarak yürürlükte kalacaktır.',
    block3:
      '18 yaşın altındaysanız, Hizmeti kullanmak için ebeveynlerinizden veya yasal vasilerinizden izin almanız gerekir. Hizmeti başka bir kişi veya kuruluş adına kullanıyorsanız, bu şartları onlar adına kabul etmeye yetkili olmanız gerekir. Bir hesaba kaydolmak için doğru ve eksiksiz bilgi vermeniz gerekir. Erişim bilgilerinizi veya hesabınızı kuruluşunuz dışından hiç kimseye veremezsiniz ve kimlik bilgileriniz kullanılarak gerçekleştirilen tüm faaliyetlerden siz sorumlusunuz.',
    block4:
      '(a) Hizmetin Kullanımı. Hizmete bu şartlar kapsamında erişebilirsiniz ve size Hizmeti kullanma konusunda münhasır olmayan bir hak veriyoruz. Hizmeti kullanırken bu şartlara ve geçerli tüm yasalara uyacaksınız. Biz ve bağlı kuruluşlarımız, Hizmete ilişkin tüm haklara, unvanlara ve menfaatlere sahibiz.',
    block5:
      '(b) Geribildirim. Geri bildirimleri, yorumları, fikirleri, önerileri ve iyileştirmeleri memnuniyetle karşılıyoruz. Bu tür bir içerik sağlamanız durumunda, bunu herhangi bir kısıtlama olmaksızın ve size tazminat ödemeden kullanabiliriz.',
    block6:
      '(c) Kısıtlamalar. Şunları yapamazsınız: (1) Hizmeti başkalarının herhangi bir hakkını ihlal edecek, kötüye kullanacak veya ihlal edecek şekilde kullanamazsınız; (2) Hizmet modellerinin, algoritmalarının ve sistemlerinin kaynak kodunu veya temel bileşenlerini tersine mühendislik yapmak, kaynak koda dönüştürmek, parçalara ayırmak, tercüme etmek veya başka bir şekilde keşfetme girişiminde bulunmak (bu tür kısıtlamalar geçerli yasalarca yasaklanmadıkça); (3) Hizmetin çıktılarını Noam ile rekabet eden modeller geliştirmek için kullanmak; (4) API aracılığıyla izin verilmediği sürece, kazıma, web toplama veya web veri çıkarma dahil olmak üzere herhangi bir otomatik veya programlama yöntemini kullanarak Hizmetten veri veya çıktı çıkarmak; (5) Hizmetin çıktısını yapay olarak üretilmiş gibi göstermek veya kullanım politikalarımızı başka bir şekilde ihlal etmek; (6) API anahtarlarını önceden iznimiz olmadan satın almak, satmak veya aktarmak; veya (7) 13 yaşın veya geçerli dijital izin yaşının altındaki çocuklara ilişkin kişisel bilgileri bize sağlamak. Belgelerimizdeki her türlü oran sınırına ve diğer gereksinimlere uyacaksınız.',
    block7:
      '(d) Üçüncü Taraf Hizmetleri. Hizmetle ilişkili tüm üçüncü taraf yazılımları, hizmetleri veya diğer ürünleri, kendi şartlarına tabidir ve üçüncü taraf ürünlerinden biz sorumlu değiliz.',
    block8:
      '(a) İçeriğiniz. Hizmete girdi ("Giriş") sağlayabilir ve Girdiye dayalı olarak oluşturulan ve döndürülen çıktıyı ("Çıktı") alabilirsiniz (topluca "İçerik" olarak anılacaktır). Taraflar arasında, yürürlükteki yasaların izin verdiği ölçüde, tüm Girdilerin sahibi sizsiniz. Noam, İçeriği Hizmeti sağlamak ve sürdürmek, geçerli yasalara uymak ve politikalarımızı uygulamak için kullanabilir. Geçerli yasaları veya bu şartları ihlal etmemesini sağlamak da dahil olmak üzere İçerikten siz sorumlusunuz.',
    block9:
      '(b) Doğruluk. Yapay zeka ve makine öğrenimi hızla gelişen araştırma alanlarıdır. Hizmetimizi daha doğru, güvenilir, güvenli ve faydalı hale getirmek için sürekli olarak iyileştirmeye çalışıyoruz. Makine öğreniminin olasılıksal doğası göz önüne alındığında, hizmetimizi kullanmak bazı durumlarda gerçek kişileri, yerleri veya gerçekleri doğru şekilde yansıtmayabilecek hatalı çıktılara yol açabilir. Çıktıları manuel olarak gözden geçirmek de dahil olmak üzere, kullanım durumunuza göre herhangi bir çıktının doğruluğunu uygun şekilde değerlendirmelisiniz.',
    subTitle1: 'Kayıt ve Erişim',
    subTitle10: 'Bölünebilirlik',
    subTitle2: 'Kullanım Gereksinimleri',
    subTitle3: 'İçerik',
    subTitle4: 'Ücretler ve Ödeme',
    subTitle5: 'Gizlilik, Güvenlik ve Veri Koruma',
    subTitle6: 'Süre ve Fesih',
    subTitle7: 'Tazminat; Sorumluluk reddi beyanları; Sorumluluğun Sınırlandırılması',
    subTitle8: 'Geçerli Kanun ve Yargı Yetkisi',
    subTitle9: 'Genel Şartlar',
    title: 'Hizmet Şartları',
    updateTime: 'Güncelleme: 15 Mart 2024',
  },
  trialModal: {
    btn: 'Şimdi Başlayın（Yalnızca 0,99$）',
    chat: 'SohbetPDF',
    desc1: 'Deneme süresinden sonra sizden yıllık 180$ ücret alınacaktır.',
    desc2: 'İstediğiniz Zaman İptal Edin',
    focus: 'Odaklanma',
    hover: 'Fareyle üzerine gelindiğinde çeviri',
    image: 'Resim çevirisi',
    installBtn: 'Hediyenizi almak için hemen yükleyin',
    memo: 'Sık kullanılanlar yönetimi',
    pdf: 'PDF paralel çeviri',
    realtime: 'Anında çeviri',
    select: 'Kelime seçimi çevirisi',
    title: 'Hoş geldin hediyesi',
    trialText: '7 Günlük Deneme',
    video: 'Video çevirisi',
    videoSummary: 'Video özeti',
    web: 'Paralel Çeviri',
    webSummary: 'Web özeti',
  },
  upgradeModal: {
    btn: 'Şimdi Yükselt',
    chat: { free: 'ChatPDF sınırına ulaştınız', pro: 'Bu ay için ChatPDF sınırına ulaştınız' },
    contactStr:
      'Lütfen bir sonraki sıfırlamayı bekleyin veya contact@noam.tools adresinden bizimle iletişime geçin.',
    limitAlertStr: 'Ücretsiz plan sınırınıza ulaştınız. Devam etmek için yükseltin.',
    more: 'Planları Görüntüle',
    pageLimit: { free: 'PDF sayfa sınırına ulaştınız' },
    title: 'Hatırlatma',
    upgradeAlertStr: 'Ücretsiz plan sınırınıza ulaştınız. Devam etmek için yükseltin.',
    upload: {
      free: 'PDF çeviri sınırınıza ulaştınız',
      pro: 'Bu ayki PDF çeviri sınırınıza ulaştınız',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Şimdi Katılın',
      label: 'Yeni Kullanıcı Avantajları:',
      remain: 'Sadece 30 tane kaldı',
      text: 'Sadece 100 Yer Mevcut! Şimdi Sahip Olun!',
    },
  },
}

export default TRANSLATION
