const TRANSLATION = {
  buyModal: {
    afterFree: 'Nach Ihrer kostenlosen Testversion',
    btn: 'Probieren Sie es kostenlos aus',
    freeTrial: '3-tägige kostenlose Testversion',
    monthLabel: 'Monat',
    selectLabel: 'Wählen Sie einen Plan aus',
    title: 'Abonnementplan',
    yearLabel: 'Jahr',
  },
  contact: {
    block1:
      'Für Anpassungen, Fragen oder Vorschläge kontaktieren Sie uns bitte unter contact@noam.tools. Wir werden umgehend antworten.',
    block2:
      'Unterstützen Sie uns, indem Sie uns im Google Apps Extension Store unter ★★★★★ bewerten.',
    block3: 'Helfen Sie anderen, indem Sie diese Erweiterung teilen:',
    title: 'Kontaktieren Sie uns',
  },
  help: {
    AquickOverviewOfNoam: 'Ein kurzer Überblick über Noam',
    ChatPDF: 'Chat-PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Favoritenverwaltung',
    Fixedplugin: 'Festes Plugin',
    Focusing: 'Fokussieren',
    Free: 'Frei',
    FreeTrialBenefits: 'Vorteile der kostenlosen Testversion',
    GoWithWikipedia: 'Gehen Sie mit Wikipedia',
    GoWithYouTube: 'Gehen Sie mit YouTube',
    HoverTranslation: 'Hover-Übersetzung',
    Howtouse: 'Verwendung',
    ImageTranslation: 'Bildübersetzung',
    InstantTranslation: 'Sofortige Übersetzung',
    Membership: 'Mitgliedschaft',
    NoamHelp: 'Noam hilft Ihnen beim Übersetzen und Zusammenfassen',
    PDFparallelTranslation: 'PDF-Parallelübersetzung',
    ParallelTranslation: 'Parallele Übersetzung',
    SkipAllSteps: 'Überspringen Sie alle Schritte',
    Summary: 'Zusammenfassung',
    Translation: 'Übersetzung',
    TryItForFree: 'Probieren Sie es kostenlos aus',
    Video: 'Video',
    VideoSummary: 'Videozusammenfassung',
    VideoTranslation: 'Videoübersetzung',
    WebSummary: 'Web-Zusammenfassung',
    WordSelectionTranslation: 'Wortauswahlübersetzung',
    benefits: 'Wir halten Vorteile für Sie bereit!',
    dayFree: '3-tägige kostenlose Testversion',
    done: 'Erledigt',
    next: 'Nächste',
    pin: 'Pin Noam für einfacheren Zugriff～～',
    planIsNotExist: 'Plan ist nicht verfügbar',
    startWiki: 'Beginnen wir mit Wikimedia!',
    startYoutube: 'Beginnen wir mit YouTube!',
    try: 'Probieren Sie es aus',
    tryNow: 'Probieren Sie es jetzt aus!',
  },
  home: {
    PDFTranslate: {
      desc: 'Zweisprachige Übersetzung + Chat PDF',
      sub1: {
        desc: 'Beim zweisprachigen Lesen können Sie den Originaltext und die Übersetzung nebeneinander vergleichen und so die Leseeffizienz verbessern.',
        title: 'Zweisprachiges Lesen',
      },
      sub2: {
        desc: 'KI analysiert und fasst zusammen und kann Ihre Fragen anhand des PDF-Inhalts beantworten.',
        title: 'Chat-PDF',
      },
      sub3: {
        desc: 'KI-gestützte PDF-Übersetzung für mehr Genauigkeit und Effizienz.',
        title: 'Genauigkeit und Effizienz',
      },
      title: 'PDF-Übersetzung',
    },
    aiAssistant: {
      desc: 'Machen Sie die Webübersetzung intelligenter und bequemer',
      panel1: {
        desc: 'Zweisprachige Übersetzungen mit Querverweisen helfen Ihnen, den Originaltext mit der Übersetzung zu vergleichen, damit Sie ihn besser verstehen und Ihre Leseeffizienz verbessern können.',
        title: 'Zweisprachige Übersetzung',
      },
      panel2: {
        left: {
          desc: 'Bewegen Sie beim Durchsuchen einer Webseite den Mauszeiger über den Inhalt, den Sie übersetzen müssen, um die Übersetzung anzuzeigen.',
          title: 'Hover-Übersetzung',
        },
        right: {
          desc: 'Wählen Sie beim Durchsuchen einer Webseite den Text aus, den Sie übersetzen möchten, um die Übersetzung anzuzeigen.',
          title: 'Übersetzung der Textauswahl',
        },
      },
      panel3: {
        one: {
          desc: 'Übersetzen und fassen Sie Webseiten und Videoinhalte jederzeit und überall zusammen, für ein reibungsloseres Erlebnis!',
          title: 'Zeigen und klicken',
        },
        three: {
          desc: 'Das KI-gesteuerte große Sprachmodell gewährleistet Übersetzungsgenauigkeit und -konsistenz für ein verbessertes Leseerlebnis.',
          title: 'KI-gesteuert',
        },
        two: {
          desc: 'Mehrsprachige Vollabdeckung, zweisprachiges barrierefreies Lesen, One-Click-Übersetzung – erleben Sie den Unterschied!',
          title: 'Umfassender und bequemer',
        },
      },
      panel4: {
        tab1: {
          desc: 'Ihr Effizienzexperte – mit ChatGPT übersetzt und fasst Noam YouTube-Videoinhalte zusammen, um Ihnen Zeit zu sparen!',
          key: 'Video',
          title: 'Videoübersetzung und Zusammenfassung',
        },
        tab2: {
          desc: 'Klicken Sie hier, um Bilder beim Surfen im Internet zu übersetzen.',
          key: 'Bild',
          title: 'Bild übersetzen',
        },
        tab3: {
          desc: 'Extrahiert sofort wichtige Informationen aus Webseiten und stellt eine Zusammenfassung bereit, um Ihre Leseeffizienz zu verbessern.',
          key: 'Web',
          title: 'Web-Übersetzung und Zusammenfassung',
        },
        tag1: 'Einfach',
        tag2: 'Effizient',
        tag3: 'Leicht zu verstehen',
      },
      title: 'KI-Assistent',
    },
    getStart: 'Legen Sie los',
    getStartedForFree: 'Starten Sie kostenlos durch',
    howItWork: {
      desc: 'Noam integriert KI-Modelle, um Benutzern ein intelligentes Erlebnis für die sprachübergreifende Übersetzung und Zusammenfassung von Webseiten, PDF- und Bildübersetzungen sowie Videoübersetzungen und -zusammenfassungen zu bieten',
      title: 'Wie es funktioniert',
    },
    lastBlock: { desc: 'Bessere KI-Übersetzung für ein reibungsloseres Leseerlebnis' },
    top: {
      desc: 'Noam ist eine kostenlose KI-Browsererweiterung, die Ihnen hilft, Webseiten, Videos und PDF-Inhalte zu übersetzen und zusammenzufassen.\\n Treten Sie Noam bei und erleben Sie die Magie!',
      title1: 'Eine Magie',
      title2: 'Ein Klick',
    },
    userReviews: {
      desc: 'Fünf-Sterne-Bewertungen im Chrome Web Store',
      title: 'Benutzerbewertungen',
    },
  },
  installTips: {
    btn: 'Probieren Sie es jetzt aus',
    text: 'Installieren Sie „Noam Browser Plugin“, um weitere Funktionen freizuschalten! Web-Querverweise,\\n Web-Video-Zusammenfassung und mehr!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amharisch',
    ar: 'Arabisch',
    as: 'Assamesisch',
    auto: 'Automatische Erkennung',
    az: 'Aserbaidschanisch',
    ba: 'Baschkirisch',
    bg: 'bulgarisch',
    bn: 'Bangla',
    bo: 'Tibetisch',
    bs: 'bosnisch',
    ca: 'katalanisch',
    cs: 'tschechisch',
    cy: 'Walisisch',
    da: 'dänisch',
    de: 'Deutsch',
    dsb: 'Niedersorbisch',
    dv: 'Tauchgang',
    el: 'griechisch',
    en: 'Englisch',
    es: 'Spanisch',
    et: 'estnisch',
    eu: 'baskisch',
    fa: 'persisch',
    fi: 'finnisch',
    fil: 'Philippinisch',
    fj: 'Fidschianisch',
    fr: 'Französisch',
    frCA: 'Französisch (Kanada)',
    ga: 'irisch',
    gl: 'galizisch',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebräisch',
    hi: 'Hindi',
    hr: 'kroatisch',
    hsb: 'Obersorbisch',
    ht: 'Haitianisches Kreol',
    hu: 'ungarisch',
    hy: 'Armenisch',
    id: 'Indonesisch',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'isländisch',
    it: 'Italienisch',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latein)',
    ja: 'japanisch',
    ka: 'georgisch',
    kk: 'Kasachisch',
    km: 'Khmer',
    kmr: 'Kurdisch (Nordisch)',
    kn: 'Kannada',
    ko: 'Koreanisch',
    ku: 'Kurdisch (Zentral)',
    ky: 'Kirgisisch',
    ln: 'Lingala',
    lo: 'Laotisch',
    lt: 'litauisch',
    lug: 'Ganda',
    lv: 'lettisch',
    lzh: 'Chinesisch (Literatur)',
    mai: 'Maithili',
    mg: 'Madagassisch',
    mi: 'Maori',
    mk: 'mazedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongolisch (Kyrillisch)',
    mnMong: 'Mongolisch (traditionell)',
    mr: 'Marathi',
    ms: 'malaiisch',
    mt: 'maltesisch',
    mww: 'Hmong Daw',
    my: 'Myanmar (burmesisch)',
    nb: 'norwegisch',
    ne: 'Nepali',
    nl: 'Niederländisch',
    noSearchLang: 'Sprache wird nicht unterstützt',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Polieren',
    placeholder: 'Suchen',
    prs: 'Dari',
    ps: 'Paschtu',
    pt: 'Portugiesisch (Brasilien)',
    ptPT: 'Portugiesisch (Portugal)',
    ro: 'rumänisch',
    ru: 'Russisch',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singhalesisch',
    sk: 'slowakisch',
    sl: 'Slowenisch',
    sm: 'Samoaner',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanisch',
    srCyrl: 'Serbisch (Kyrillisch)',
    srLatn: 'Serbisch (Latein)',
    st: 'Sesotho',
    sv: 'Schwedisch',
    sw: 'Suaheli',
    ta: 'Tamilisch',
    te: 'Telugu',
    th: 'Thailändisch',
    ti: 'Tigrinya',
    tk: 'Turkmenisch',
    tlhLatn: 'Klingonisch (Latein)',
    tlhPiqd: 'Klingonisch (pIqaD)',
    tn: 'Setswana',
    to: 'Tonganisch',
    tr: 'Türkisch',
    tt: 'Tatarisch',
    ty: 'Tahitianisch',
    ug: 'Uigur',
    uk: 'ukrainisch',
    ur: 'Urdu',
    uz: 'Usbekisch (Latein)',
    vi: 'Vietnamesisch',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonesisch (traditionell)',
    zh: 'chinesisch',
    zhHans: 'Chinesisch vereinfacht',
    zhHant: 'Chinesisch traditionell',
    zu: 'Zulu',
  },
  login: {
    and: 'Und',
    btn: 'Melden Sie sich mit Google an',
    btn1: 'Melden Sie sich jetzt an',
    desc: 'Melden Sie sich an, um weitere Erlebnisse freizuschalten',
    policy: 'Datenschutzrichtlinie',
    terms: 'Nutzungsbedingungen',
    tips: 'Mit der Anmeldung erkläre ich mich damit einverstanden',
    title: 'Willkommen bei Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Folgen', twitter: 'Twitter' },
    legal: { policy: 'Datenschutzrichtlinie', terms: 'Nutzungsbedingungen', title: 'Legal' },
    product: {
      image: 'Bildübersetzung',
      pdf: 'PDF-Übersetzung',
      title: 'Produkte',
      video: 'Videoübersetzung und Zusammenfassung',
      web: 'Webübersetzung und Zusammenfassung',
    },
    resources: { title: 'Ressourcen' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Helfen',
    logout: 'Abmelden',
    logoutSucc: 'Abgemeldet.',
    pricing: 'Preise',
    settings: 'ERROR: [Settings]',
    signUp: 'Einloggen',
  },
  pdf: {
    autoDetect: 'Automatische Erkennung',
    cancelBtn: 'Stornieren',
    cancelUpload: 'Stornieren',
    chatPdf: {
      Search: 'Suchen',
      cancel: 'Stornieren',
      chatTitle: 'Willkommen bei Noam! Hier ist eine Zusammenfassung Ihres PDFs:',
      clear: 'Klar',
      clearContent:
        'Dadurch wird ein neues Gespräch gestartet. Die aktuelle Konversation wird nicht gespeichert. Bist du sicher?',
      clearDialogTitle: 'Bitte bestätigen',
      clearSucc: 'Gelöscht',
      copy: 'Kopie',
      copySucc: 'Kopiert',
      delete: 'Löschen',
      deleteCancel: 'Stornieren',
      deleteContent:
        'Dieses PDF kann nach dem Löschen nicht wiederhergestellt werden. Bist du sicher?',
      deleteOk: 'Löschen',
      deleteSuccess: 'Gelöscht',
      deleteTitle: 'Bestätigen Sie Löschen',
      expand: 'Zusammenbruch',
      historyTitle: 'Geschichte',
      listError:
        'Leseliste konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
      loading: 'Laden...',
      noDataDesc: 'Noch keine Geschichte.',
      noMore: 'Kein Inhalt mehr.',
      placeholder: 'Fragen Sie alles zu diesem PDF',
      summaryTitle: 'Diese Fragen könnten Sie interessieren:',
    },
    collectionEmpty: 'Ihre Sammlung ist leer.',
    collectionTab: 'Notizen',
    dataLoading: 'Laden...',
    dialogContent: 'Sind Sie sicher, dass Sie dies löschen möchten?',
    dialogTitle: 'Bitte bestätigen',
    download: {
      both: 'Zweisprachig',
      btn: 'Speichern',
      cancel:
        'Durch Schließen dieses Fensters wird der PDF-Download abgebrochen. Sind Sie sicher, dass Sie stornieren möchten?',
      desc: 'Auf Gerät speichern: Lädt Übersetzungen als Bilder herunter; Speichern und drucken: Lädt Übersetzungen im Originalformat herunter, mit Kopierunterstützung.',
      download: 'Herunterladen',
      downloadMsg1: 'PDF-Übersetzungsfortschritt:',
      downloadMsg2: 'Geschätzte verbleibende Zeit:',
      downloadSucc: 'PDF ist fertig! Klicken Sie zum Speichern.',
      downloadTip:
        'PDF herunterladen. Schließen Sie dieses Fenster nicht, um Downloadfehler zu vermeiden.',
      error: 'Speichern fehlgeschlagen. Versuchen Sie stattdessen zu drucken.',
      fileName: 'Dateiname:',
      fileType: 'Dateityp:',
      loading: 'Sparen...',
      loading2: 'Laden...',
      minute: 'Minuten...',
      msg1: 'Einige Übersetzungen in diesem Dokument sind länger als der Originaltext und können sich in der heruntergeladenen Datei überschneiden. Wir empfehlen die Lektüre auf der Webseite.',
      msg2: '1. Die Übersetzung ist noch nicht abgeschlossen. Bitte laden Sie es herunter, nachdem die Übersetzung abgeschlossen ist.',
      msg3: '2. Einige Übersetzungen sind zu lang. Lesen Sie auf der Webseite, um Textüberschneidungen zu vermeiden.',
      msg4: 'Übersetzungsaufgaben sind in Bearbeitung. Bitte versuchen Sie es erneut, nachdem sie abgeschlossen sind.',
      progress:
        'PDF übersetzen. Fortschritt: {{progress}}, geschätzte verbleibende Zeit: {{minutes}} Minuten ...',
      save: 'Auf Gerät speichern',
      savePrint: 'Speichern und drucken',
      success: 'Heruntergeladen',
      trans: 'Nur Übersetzung',
      transContinue: 'Weiterübersetzen',
    },
    downloadBtn: 'Herunterladen',
    dragTipModal: {
      black: 'Raum',
      leftKey: 'linke Taste',
      ok: 'Habe es',
      press: 'Drücken',
      tips: 'zum Ziehen und Anzeigen.',
    },
    entry: 'PDFs',
    fileErrMsg:
      'Das Lesen der Datei ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    fileMsg: 'Aktuelle Dateigröße:',
    fileMsg2: 'Bitte laden Sie eine Datei hoch, die kleiner als 300 MB ist.',
    freeBannerBtn: 'Upgrade',
    freeBannerTips: 'Limit für kostenlose PDF-Übersetzungen erreicht',
    guide: {
      placeholder: 'Fügen Sie den PDF-Link hier ein',
      start: 'Beginnen Sie mit dem Übersetzen',
      text1: 'Zweisprachiges Lesen',
      text2:
        'Lesen Sie zweisprachig, um den Originaltext und die Übersetzung nebeneinander zu vergleichen und so Ihre Effizienz zu steigern.',
      text3: 'Chatten Sie mit Ihrem PDF',
      text4:
        'KI-gestützte Analyse und Zusammenfassung. Stellen Sie Fragen zu Ihrem PDF und erhalten Sie sofort Antworten.',
      text5: 'Präzise und effizient',
      text6: 'KI-gestützte PDF-Übersetzung für mehr Genauigkeit und Effizienz.',
      title1: 'Zweisprachige Übersetzung',
      title2: 'Chatten Sie mit Ihrem PDF',
      toast: 'Bitte geben Sie einen gültigen Online-PDF-Link mit der Endung .pdf ein',
    },
    hasTransTask: 'Eine Übersetzungsaufgabe ist in Bearbeitung. Bitte warten.',
    hoverTrans: 'Bewegen Sie den Mauszeiger zum Übersetzen von Absätzen',
    initial: 'PDF übersetzen',
    installText: 'Ihre Komplettlösung für alltägliche Übersetzungen.',
    installText2: 'Bitte installieren Sie zuerst die Erweiterung',
    installTips:
      'Installieren Sie die Erweiterung für noch mehr PDF-Übersetzungen und reibungslosere Website-Übersetzungen:',
    kw: 'Klammer',
    link: 'Link',
    listError:
      'PDFs konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    memoTotalCount: 'Insgesamt 1 Artikel',
    new: 'neue Datei',
    noId: 'Datei-ID nicht gefunden.',
    notPdf: 'Nur PDF-Dateien.',
    okBtn: 'Löschen',
    okBtn2: 'Habe es',
    original: 'Original',
    parallel: 'Parallel',
    parseErrDialogContent:
      'Dieser PDF-Link kann nicht direkt übersetzt werden. Bitte laden Sie das PDF herunter und laden Sie es zur Übersetzung hoch.',
    parseErrTips: 'Datei konnte nicht analysiert werden',
    parseFileToast:
      'Datei konnte nicht analysiert werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    pdfLoading: 'Parsing...',
    searchPlaceholder: 'Suchen',
    selectTrans: 'Wählen Sie Text zum Übersetzen aus',
    shareBtn: 'Aktie',
    shareBtnText1: 'Teilen-Link generieren',
    shareBtnText2: 'Geteilte Inhalte aktualisieren',
    shareModal: {
      cancel: 'Stornieren',
      copy: 'Kopie',
      copySucc: 'Link kopiert',
      custom: 'Anpassen:',
      errMsg:
        'Der ausgewählte Inhalt wurde noch nicht vollständig übersetzt. Bitte übersetzen Sie vor dem Teilen.',
      gen: 'Aktie',
      modify: 'Bearbeiten',
      notSupport: 'Diese Sprache wird derzeit nicht unterstützt.',
      page: 'Seiten',
      pageNuErr: 'Bitte geben Sie eine gültige Seitenzahl ein.',
      range: 'Umfang:',
      remain: 'übrig',
      remain1: 'Geschätzte Gesamtzeit:',
      search: 'Suchen',
      settings: 'Freigabeeinstellungen:',
      shareSetting: 'Freigabeeinstellungen',
      shareText: 'Aktie',
      shareTips: 'Teilen Sie den übersetzten PDF-Link mit Ihren Freunden:',
      sourceLang: 'Aus:',
      target: 'Zu:',
      text1: 'Freigabeeinstellungen',
      text2: 'Teilen-Link generieren',
      totalPage: 'Insgesamt 1 Seite',
      trans: 'Übersetzen',
      translateTips:
        'Tipp: Wenn die Übersetzung eine Weile dauert, können Sie dieses Fenster schließen und später teilen.',
      translating: 'PDF übersetzen...',
      update: 'Teilen-Link aktualisieren',
    },
    shareMsg1: 'Teilen-Link generiert.',
    shareMsg2: 'Geteilte Inhalte aktualisiert.',
    shareMsg3:
      'Der Freigabelink konnte nicht generiert werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    shareMsg4:
      'Geteilte Inhalte konnten nicht aktualisiert werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    shareTips: 'Ihre PDF-Übersetzung ist fertig! Teilen Sie es mit Ihren Freunden.',
    start: 'Starten Sie Ihre kostenlose Testversion',
    thumbnail: 'Inhaltsverzeichnis',
    toast1: 'PDF wird geladen. Bitte versuchen Sie es später noch einmal.',
    toast2: 'Der PDF-Inhalt ist zu groß. Dialog wird derzeit nicht unterstützt.',
    toast3: 'Herunterladen. Bitte versuchen Sie es erneut, nachdem der Download abgeschlossen ist.',
    toolbar: { Adjust: 'Anpassen', autoFit: 'Automatische Anpassung' },
    trans: 'Übersetzung',
    transSucc: 'Übersetzung gelungen!',
    unLoginToast: 'Bitte melden Sie sich an, um diese Funktion zu nutzen.',
    uploadBtn: 'Hochladen',
    uploadErr:
      'Das Hochladen der Datei ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    uploadMsg1: 'Ziehen Sie Ihre Datei per Drag & Drop hierher.',
    uploadMsg2: 'Unterstützte Dateitypen: PDF |  Maximale Dateigröße: 100 MB',
    uploadMsg3: 'Klicken Sie hier, um Dateien auszuwählen oder per Drag & Drop hierher zu ziehen.',
    uploading: 'Hochladen',
    uploadingMsg: 'Hochladen. Bitte warten.',
    uploadingMsg1: 'Datei wird hochgeladen. Bitte versuchen Sie es später noch einmal.',
    uploadingMsg2: 'Eine Datei wird gerade konvertiert. Bitte versuchen Sie es später noch einmal.',
    uploadingMsg3:
      'Die Anfrage zum Konvertierungsfortschritt ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    uploadingMsg4:
      'Noam kann nicht auf lokale Dateien zugreifen. Bitte laden Sie manuell ein PDF zur Übersetzung hoch.',
    uploadingMsg5:
      'Datei-URL konnte nicht abgerufen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    video: 'Video',
    vipBannerBtn: 'Upgrade',
    vipBannerTips: 'Sie haben Ihr PDF-Übersetzungslimit für diesen Monat erreicht.',
    webTrans: 'Vollständiger Querverweis',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ja, Noam bietet einen kostenlosen Plan an, den Sie sofort nutzen können.',
      a2: 'Wir bieten drei Mitgliedschaftspläne an: Pro, Pro+ und Enterprise. Sie können wählen, ob Sie monatlich oder jährlich zahlen möchten. Die jährliche Abrechnung bietet erhebliche Einsparungen.',
      a3: 'Alle Funktionen sind in beiden Plänen verfügbar. Der Hauptunterschied liegt in den Kosten: Jahresabonnements kosten insgesamt weniger. Monatsabonnements werden jeden Monat in Rechnung gestellt, während Jahrespläne einmal pro Jahr in Rechnung gestellt werden.',
      a4: 'Sie können Ihr Abonnement jederzeit in Ihren Kontoeinstellungen kündigen.',
      q1: 'Gibt es einen kostenlosen Plan?',
      q2: 'Welche Preispläne und Zahlungsoptionen sind verfügbar?',
      q3: 'Was ist der Unterschied zwischen einem monatlichen und einem jährlichen Abonnement?',
      q4: 'Wie kann ich mein Abonnement kündigen?',
    },
    around: 'Um',
    billedMonthly: 'Monatlich abgerechnet',
    billedYearly: 'Jährliche Abrechnung',
    day: 'Tag',
    desc: 'Vergleichen Sie Pläne und wählen Sie das Beste aus',
    detailedCompare: 'Tarife vergleichen',
    discount: '20 % Rabatt',
    enterprise: {
      benefit1: 'Vorrangiger Kundensupport',
      benefit2: 'Mehrere Benutzer',
      benefit3: 'Maßgeschneiderte Lösungen',
      benefit5: 'Dedizierter Account Manager',
      btn: 'Kontaktieren Sie uns',
      recommendText: 'Benutzerdefinierte Pläne（≥3人）',
      talkToUs: 'Nehmen Sie Kontakt auf',
    },
    free: {
      benefit1: 'Sofortige Übersetzung 100 Mal pro Tag',
      benefit2: 'Website-Übersetzung und Zusammenfassung',
      benefit3: '30 Bildübersetzungen',
      benefit4: 'Videoübersetzung und Zusammenfassung',
      benefit5: '10 PDF-Übersetzungen',
      benefit6: '5.000 Token',
      btn: 'Probieren Sie es kostenlos aus',
      recommendText: 'Kostenlose Testversion',
    },
    imageTranslation: 'Bildübersetzung',
    month: 'Monat',
    monthly: 'Monatlich',
    pageTitle: 'Preise',
    pdf: {
      chat: 'PDF-Chat-Anfragen',
      maxPage: 'Max. Seiten pro PDF',
      translation: 'PDF-Übersetzung',
    },
    preMonth: 'Pro Monat',
    pro: {
      benefit1: 'Vorrangiger Kundensupport',
      benefit2: '1.000.000 Token/Monat',
      benefit3: 'Website-Übersetzung und Zusammenfassung',
      benefit4: '200 Bildübersetzungen',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '200 PDF-Übersetzungen',
      btn: 'Legen Sie los',
      recommendText: 'Am beliebtesten',
    },
    proPlus: {
      benefit1: 'Vorrangiger Kundensupport',
      benefit2: '3.000.000 Token/Monat',
      benefit3: 'Website-Übersetzung und Zusammenfassung',
      benefit4: '500 Bildübersetzungen',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '500 PDF-Übersetzungen',
      recommendText: 'Bestes Preis-Leistungs-Verhältnis',
    },
    video: { summary: 'Videozusammenfassung', translation: 'Videoübersetzung' },
    web: {
      contrastiveTranslation: 'Zweisprachige Übersetzung',
      dictionaryTranslation: 'Auswahlübersetzung',
      hoverTranslation: 'Hover-Übersetzung',
      instantTranslation: 'Sofortige Übersetzung',
      summary: 'Zusammenfassung der Webseite',
    },
    yearly: 'Jährlich',
  },
  privacy: {
    block1:
      'Noam („Noam“, „wir“, „unser“ oder „uns“) respektiert Ihre Privatsphäre und verpflichtet sich zum Schutz aller Informationen, die wir von Ihnen oder über Sie erhalten. Diese Datenschutzrichtlinie beschreibt unsere Praktiken hinsichtlich der Erfassung oder Verwendung personenbezogener Daten aus Ihrer Nutzung unserer Website, Anwendungen und Dienste (zusammen die „Dienste“). Diese Datenschutzrichtlinie gilt nicht für die Inhalte, die wir im Namen unserer Geschäftsproduktkunden verarbeiten, wie z. B. unsere API. Die Nutzung dieser Daten unterliegt unserer Kundenvereinbarung, die den Zugang zu und die Nutzung dieser Produkte regelt.',
    block10:
      'Wir ergreifen wirtschaftlich angemessene technische, administrative und organisatorische Maßnahmen, um personenbezogene Daten online und offline vor Verlust, Missbrauch und unbefugtem Zugriff, Offenlegung, Änderung oder feindseligen Handlungen zu schützen. Allerdings kann keine Internet- oder E-Mail-Übertragung vollständige Sicherheit oder Genauigkeit garantieren. Insbesondere an uns oder von uns gesendete E-Mails sind möglicherweise nicht sicher. Daher sollten Sie bei der Entscheidung, welche Informationen Sie uns über den Dienst oder per E-Mail senden, besonders vorsichtig sein. Darüber hinaus sind wir nicht verantwortlich für die Beeinträchtigung der Datenschutzeinstellungen oder Sicherheitsmaßnahmen im Dienst oder der Sicherheitseinstellungen oder Sicherheitsmaßnahmen von Websites Dritter. Wir bewahren Ihre personenbezogenen Daten so lange auf, wie es für die Erbringung von Dienstleistungen oder für andere legitime Geschäftszwecke erforderlich ist, z. B. zur Beilegung von Streitigkeiten, aus Sicherheitsgründen oder zur Einhaltung unserer gesetzlichen Verpflichtungen. Die Dauer der Aufbewahrung personenbezogener Daten hängt von vielen Faktoren ab, wie z. B. der Menge, der Art, der Sensibilität der Informationen, den potenziellen Risiken einer unbefugten Nutzung oder Offenlegung, den Zwecken, für die wir die Informationen verarbeiten, und anderen rechtsverbindlichen Erwägungen.',
    block11:
      'Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wenn wir dies tun, werden wir eine aktualisierte Version auf dieser Seite veröffentlichen, sofern das geltende Recht nichts anderes vorschreibt.',
    block12:
      'Wenn Sie unbeantwortete Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, wenden Sie sich bitte an unseren Kundenservice. E-Mail:',
    block2:
      'Wir erfassen die folgenden personenbezogenen Daten („personenbezogene Daten“) über Sie:',
    block3:
      'Von Ihnen bereitgestellte Informationen: Wenn Sie ein Konto erstellen, um unsere Dienste zu nutzen oder mit uns zu kommunizieren, erfassen wir personenbezogene Daten wie folgt:',
    block4:
      'Persönliche Informationen, die wir automatisch durch Ihre Nutzung der Dienste erhalten: Wenn Sie auf die Dienste zugreifen, sie nutzen oder mit ihnen interagieren, erhalten wir die folgenden Informationen über Ihren Zugriff, Ihre Nutzung oder Ihre Interaktion („technische Informationen“):',
    block5: 'Wir können personenbezogene Daten für die folgenden Zwecke verwenden:',
    block6:
      'Zusammenfassende oder anonymisierte Informationen: Wir können personenbezogene Daten aggregieren oder anonymisieren, um ihre Wiederverwendung zu Identifizierungszwecken zu verhindern, und diese Informationen verwenden, um die Wirksamkeit unserer Dienste zu analysieren, unsere Dienste zu verbessern und Funktionen hinzuzufügen, Forschungsarbeiten durchzuführen und für ähnliche Zwecke . Darüber hinaus können wir das allgemeine Verhalten und die Merkmale unserer Servicenutzer analysieren und aggregierte Informationen an Dritte weitergeben, diese aggregierten Informationen veröffentlichen oder sie allgemein verfügbar machen. Wir können aggregierte Informationen über die Dienste, über Cookies und über andere in dieser Datenschutzrichtlinie beschriebene Mittel sammeln. Wir werden nicht identifizierte Informationen in anonymisierter oder nicht identifizierter Form aufbewahren und verwenden und wir werden nicht versuchen, diese Informationen erneut zu identifizieren, es sei denn, dies ist gesetzlich vorgeschrieben.',
    block7:
      'In bestimmten Fällen können wir Ihre personenbezogenen Daten ohne weitere Mitteilung an Dritte weitergeben, sofern dies nicht gesetzlich vorgeschrieben ist:',
    block8:
      'Abhängig von Ihrem geografischen Standort haben Personen im Europäischen Wirtschaftsraum, im Vereinigten Königreich und weltweit möglicherweise bestimmte gesetzliche Rechte in Bezug auf ihre personenbezogenen Daten. Beispielsweise haben Sie möglicherweise das Recht:',
    block9:
      'ERROR: [The service may contain links to other websites not operated or controlled by Noam, including social media services ("Third Party Websites"). The information you share with Third Party Websites will be governed by the specific privacy policies and terms of service of the Third Party Websites, not by this Privacy Policy. We provide these links for convenience and do not imply our endorsement or review of these websites. Please contact Third Party Websites directly for information about their privacy policies and terms.]',
    subBlock1:
      'Kontoinformationen: Wenn Sie auf unserer Plattform ein Konto erstellen, erfassen wir Informationen zu Ihrem Konto, einschließlich Ihres Namens, Kontaktinformationen, Kontoanmeldeinformationen, Zahlungskarteninformationen und Transaktionsverlauf („Kontoinformationen“).',
    subBlock10: 'Bereitstellung, Verwaltung, Wartung und/oder Analyse von Dienstleistungen;',
    subBlock11: 'Mit Ihnen kommunizieren;',
    subBlock12: 'Entwicklung neuer Produkte und Dienstleistungen;',
    subBlock13:
      'Verhinderung von Betrug, kriminellen Aktivitäten oder Missbrauch unserer Dienste und Schutz der Sicherheit unserer IT-Systeme, Architektur und Netzwerke;',
    subBlock14: 'Durchführung von Geschäftsübertragungen;',
    subBlock15:
      'Einhaltung gesetzlicher Verpflichtungen und Gerichtsverfahren, Schutz unserer Rechte, Privatsphäre, Sicherheit oder unseres Eigentums sowie der Rechte unserer verbundenen Unternehmen, von Ihnen oder anderen Dritten.',
    subBlock16:
      'Lieferanten und Dienstleister: Um uns bei der Erfüllung geschäftlicher Betriebsanforderungen und der Erbringung bestimmter Dienste und Funktionen zu unterstützen, können wir personenbezogene Daten an Lieferanten und Dienstleister weitergeben, einschließlich Hosting-Dienstleistern, Cloud-Dienstanbietern und anderen Anbietern von Informationstechnologiediensten und E-Mail-Kommunikationssoftware , und Webanalysedienstanbieter usw. Diese Partner werden im Rahmen der Erfüllung ihrer Aufgaben für uns nur auf unsere Anweisung hin auf personenbezogene Daten zugreifen, diese verarbeiten oder speichern.',
    subBlock17:
      'Geschäftsübertragungen: Wenn wir uns an strategischen Transaktionen, Umstrukturierungen, Insolvenzen, Insolvenzübernahmen oder der Übertragung von Dienstleistungen an einen anderen Anbieter beteiligen (gemeinsam „Transaktionen“), können Ihre personenbezogenen Daten und andere Informationen an Transaktionspartner und andere Parteien weitergegeben werden, die bei den Transaktionen behilflich sind während der Due-Diligence-Prüfung übertragen und im Rahmen der Transaktionen zusammen mit anderen Vermögenswerten an ein Nachfolge- oder verbundenes Unternehmen übertragen.',
    subBlock18:
      'Gesetzliche Anforderungen: Wir können Ihre personenbezogenen Daten an Regierungsbehörden, Branchenkollegen oder andere Dritte weitergeben, einschließlich Ihrer Interaktionsdaten mit unseren Diensten, (1) wenn dies gesetzlich vorgeschrieben ist oder wenn wir glauben, dass eine solche Maßnahme zur Einhaltung gesetzlicher Verpflichtungen erforderlich ist; (2) zum Schutz und zur Verteidigung unserer Rechte oder unseres Eigentums; (3) wenn wir einseitig feststellen, dass ein Verstoß gegen unsere Bedingungen, Richtlinien oder Gesetze vorliegt; (4) um Betrug oder andere illegale Aktivitäten aufzudecken oder zu verhindern; (5) zum Schutz unserer Produkte, Mitarbeiter oder Benutzer oder der öffentlichen Sicherheit, Integrität; oder (vi) zur Abwehr rechtlicher Haftung.',
    subBlock19:
      'Zugriff auf Ihre persönlichen Daten und Informationen darüber, wie diese verarbeitet werden.',
    subBlock2:
      'Benutzerinhalte: Wenn Sie unsere Dienste nutzen, erfassen wir personenbezogene Daten aus Ihren Eingaben, Datei-Uploads oder Ihrem Feedback („Inhalte“).',
    subBlock20: 'Fordern Sie die Löschung Ihrer persönlichen Daten aus unseren Aufzeichnungen an.',
    subBlock21: 'Korrigieren oder aktualisieren Sie Ihre persönlichen Daten.',
    subBlock22: 'Übertragen Sie Ihre persönlichen Daten an Dritte (Datenportabilität).',
    subBlock23:
      'Beschränken Sie die Art und Weise, wie wir Ihre personenbezogenen Daten verarbeiten.',
    subBlock24:
      'Widerrufen Sie Ihre Einwilligung – wenn wir uns auf die Einwilligung als Rechtsgrundlage für die Verarbeitung stützen, können Sie Ihre Einwilligung jederzeit widerrufen.',
    subBlock25: 'Widersprechen Sie der Verarbeitung Ihrer personenbezogenen Daten durch uns.',
    subBlock26: 'Verbesserung unserer Dienstleistungen und Durchführung von Forschungsarbeiten;',
    subBlock3:
      'Kommunikationsinformationen: Wenn Sie uns Nachrichten senden, erfassen wir Ihren Namen, Ihre Kontaktinformationen und den Inhalt Ihrer Nachrichten („Kommunikationsinformationen“).',
    subBlock4:
      'Informationen zu sozialen Medien: Wir haben Seiten auf Social-Media-Websites wie Instagram, Facebook, Medium, Twitter, YouTube und LinkedIn. Wenn Sie mit unseren Social-Media-Seiten interagieren, erfassen wir die personenbezogenen Daten, die Sie uns zur Verfügung stellen, beispielsweise Ihre Kontaktdaten („Sozialdaten“). Darüber hinaus stellen uns die Unternehmen, die unsere Social-Media-Seiten hosten, möglicherweise aggregierte Informationen und Analysen über unsere Social-Media-Aktivitäten zur Verfügung.',
    subBlock5:
      'Protokolldaten: Informationen, die Ihr Browser automatisch sendet, wenn Sie unsere Dienste nutzen. Zu den Protokolldaten gehören Ihre Internet Protocol (IP)-Adresse, Browsertyp und -einstellungen, Datum und Uhrzeit Ihrer Anfragen sowie die Art und Weise, wie Sie mit unserer Website interagieren.',
    subBlock6:
      'Nutzungsdaten: Wir erfassen möglicherweise automatisch Informationen über Ihre Nutzung des Dienstes, z. B. die Arten von Inhalten, die Sie ansehen oder mit denen Sie interagieren, die von Ihnen genutzten Funktionen, die von Ihnen durchgeführten Aktionen sowie Ihre Zeitzone, Ihr Land, Datum und Uhrzeit Zugriff, Benutzeragent und Version, Computer- oder Mobilgerätetyp und Ihre Computerverbindung.',
    subBlock7:
      'Geräteinformationen: Dazu gehören der Name des Geräts, das Betriebssystem, die Gerätekennung und der von Ihnen verwendete Browser. Die gesammelten Informationen können von der Art des von Ihnen verwendeten Geräts und seinen Einstellungen abhängen.',
    subBlock8:
      'Cookies: Wir verwenden Cookies, um unsere Dienste zu betreiben und zu verwalten und Ihr Erlebnis zu verbessern. Ein „Cookie“ sind Informationen, die von einer Website, die Sie besuchen, an Ihren Browser gesendet werden. Sie können Ihren Browser so einstellen, dass er alle Cookies akzeptiert, alle Cookies ablehnt oder Sie benachrichtigt, wenn ein Cookie bereitgestellt wird, sodass Sie jedes Mal entscheiden können, ob Sie es akzeptieren möchten. Die Ablehnung von Cookies kann jedoch dazu führen, dass Sie bestimmte Bereiche oder Funktionen der Website nicht nutzen können oder die Anzeige oder Funktionalität beeinträchtigt wird. Weitere Informationen zu Cookies finden Sie unter „Alles über Cookies“.',
    subBlock9:
      'Analytik: Wir verwenden möglicherweise verschiedene Online-Analyseprodukte, die Cookies verwenden, um uns dabei zu helfen, zu analysieren, wie Benutzer unsere Dienste nutzen, und um Ihr Erlebnis bei der Nutzung der Dienste zu verbessern.',
    subTitle1: 'Persönliche Informationen, die wir sammeln',
    subTitle2: 'Wie wir personenbezogene Daten verwenden',
    subTitle3: 'Offenlegung personenbezogener Daten',
    subTitle4: 'Ihre Rechte',
    subTitle5: 'Links zu anderen Websites',
    subTitle6: 'Sicherheit und Aufbewahrung von Informationen',
    subTitle7: 'Änderung der Datenschutzrichtlinie',
    subTitle8: 'So erreichen Sie uns',
    title: 'Datenschutzrichtlinie',
    updateTime: 'Aktualisiert: 15. März 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Alle akzeptieren',
    text: 'Um Ihr Erlebnis zu verbessern, verwenden wir Cookies, um Inhalte und Dienste zu personalisieren.',
    title: 'Diese Website verwendet Cookies',
  },
  slogen: 'Der einfachste KI-Assistent',
  stripe: {
    contact: 'Kontaktieren Sie uns bei Fragen unter contact@noam.tools!',
    fail: {
      btn: 'Wiederholen',
      text1: 'Wir konnten Ihre Zahlung nicht verarbeiten.',
      text2: 'Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
      title: 'Die Zahlung ist fehlgeschlagen',
    },
    succ: {
      btn: 'Lass uns gehen',
      text1: 'Willkommen bei Noam,',
      text2: 'Lasst uns anfangen!',
      title: 'Glückwunsch',
    },
  },
  terms: {
    block1: 'Vielen Dank, dass Sie Noam nutzen!',
    block10:
      '(a) Gebühren und Abrechnung. Sie zahlen alle Gebühren („Gebühren“) gemäß den Preisen und Bedingungen auf der entsprechenden Preisseite oder wie schriftlich zwischen uns vereinbart. Wir behalten uns das Recht vor, Preisfehler oder Irrtümer zu korrigieren, auch wenn wir Rechnungen ausgestellt oder Zahlungen erhalten haben. Sie stellen vollständige und korrekte Rechnungsinformationen bereit, einschließlich einer gültigen und autorisierten Zahlungsmethode. Wir belasten Ihre Zahlungsmethode in regelmäßigen Abständen wie vereinbart, können das Abrechnungsdatum jedoch angemessen ändern. Sie ermächtigen Noam, seine verbundenen Unternehmen und Drittanbieter, Ihre Zahlungsmethode zu belasten. Wenn Ihre Zahlung fehlschlägt, benachrichtigen wir Sie schriftlich und können den Zugriff auf den Dienst sperren, bis die Zahlung eingegangen ist. Sofern in dieser Vereinbarung nicht anders angegeben, sind Zahlungen nicht erstattungsfähig.',
    block11:
      '(b) Steuern. Sofern nicht anders angegeben, beinhalten die Gebühren keine bundesstaatlichen, staatlichen, lokalen und ausländischen Steuern, Zölle und ähnliche Abgaben („Steuern“). Sie sind für alle mit Ihren Einkäufen verbundenen Steuern verantwortlich, mit Ausnahme der Steuern, die auf unserem Nettoeinkommen basieren und die wir Ihnen in Rechnung stellen. Sie erklären sich damit einverstanden, diese Steuern umgehend zu zahlen, uns rechtsgültige Datenschutzerklärungen zur Verfügung zu stellen und die erforderlichen Einwilligungen für den Umgang mit diesen Daten einzuholen, und Sie garantieren uns, dass Sie diese Daten in Übereinstimmung mit geltendem Recht behandeln.',
    block12:
      '(c) Preisänderungen. Wir können unsere Preise ändern, indem wir Sie über Ihr Konto und/oder unsere Website benachrichtigen. Preiserhöhungen werden sofort nach Veröffentlichung wirksam. Alle Preisänderungen gelten ab dem Datum des Inkrafttretens der Änderung für die Gebühren für Ihr Konto.',
    block13:
      '(d) Streitigkeiten und überfällige Zahlungen. Wenn Sie Gebühren oder Steuern anfechten möchten, wenden Sie sich bitte innerhalb von dreißig (30) Tagen nach Rechnungsdatum an contact@noam.tools.',
    block14:
      '(e) Kostenlose Nutzung. Sie dürfen nicht mehrere Konten erstellen, um Gratisguthaben zu erhalten. Wenn wir feststellen, dass Sie das kostenlose Guthaben nicht in gutem Glauben nutzen, können wir Ihnen Standardgebühren in Rechnung stellen oder den Zugriff auf den Dienst einstellen.',
    block15:
      '(a) Vertraulichkeit. Möglicherweise haben Sie Zugriff auf vertrauliche Informationen von Noam. Sie dürfen vertrauliche Informationen nur im Rahmen dieser Bedingungen zum Zwecke der Nutzung des Dienstes verwenden. Sie dürfen vertrauliche Informationen nicht an Dritte weitergeben und werden vertrauliche Informationen auf eine Weise schützen, die nicht weniger schützend ist als Ihre eigenen ähnlichen vertraulichen Informationen, zumindest mit angemessener Sorgfalt. Vertrauliche Informationen sind nicht öffentliche Informationen, die von Noam als vertraulich eingestuft werden oder die unter den gegebenen Umständen vernünftigerweise als vertraulich behandelt werden sollten, einschließlich Software, Spezifikationen und anderen nicht öffentlichen Geschäftsinformationen. Zu den vertraulichen Informationen zählen keine Informationen, die: (1) ohne Ihr Verschulden der Öffentlichkeit allgemein zugänglich werden; (2) Sie befanden sich ohne Verpflichtung zur Vertraulichkeit in Ihrem Besitz, bevor Sie sie gemäß diesen Bedingungen erhalten haben; (3) Ihnen von einem Dritten rechtmäßig und ohne Geheimhaltungsverpflichtung offengelegt werden; oder (4) von Ihnen unabhängig entwickelt wurde, ohne die vertraulichen Informationen zu nutzen. Sie können vertrauliche Informationen offenlegen, wenn dies gesetzlich, gerichtlich oder durch eine andere behördliche Anordnung erforderlich ist. Sie müssen Noam jedoch zuvor in angemessener Weise schriftlich benachrichtigen und, soweit möglich, angemessene Anstrengungen unternehmen, um den Umfang der Offenlegung einzuschränken, einschließlich unserer Unterstützung bei der Ablehnung von Offenlegungsanträgen.',
    block16:
      '(b) Sicherheit. Sie müssen angemessene und angemessene Maßnahmen ergreifen, um Ihren Zugriff auf den Dienst und Ihre Nutzung des Dienstes zu schützen. Wenn Sie Schwachstellen oder Verstöße im Zusammenhang mit Ihrer Nutzung des Dienstes entdecken, müssen Sie sich umgehend an Noam wenden und Einzelheiten zu der Schwachstelle oder dem Verstoß angeben.',
    block17:
      '((c) Verarbeitung personenbezogener Daten. Wenn Sie den Dienst zur Verarbeitung personenbezogener Daten nutzen, müssen Sie rechtlich ausreichende Datenschutzerklärungen bereitstellen und die erforderlichen Einwilligungen für die Verarbeitung dieser Daten einholen, und Sie gewährleisten uns gegenüber, dass Sie diese Daten in Übereinstimmung mit geltendem Recht behandeln .',
    block18:
      '(a) Kündigung; Suspension. Diese Bedingungen treten ab Ihrer ersten Nutzung des Dienstes in Kraft und bleiben bis zu ihrer Kündigung in Kraft. Sie können diese Bedingungen jederzeit und aus beliebigem Grund kündigen, indem Sie die Nutzung des Dienstes und der Inhalte einstellen. Wir können diese Bedingungen aus beliebigem Grund mit vorheriger Ankündigung kündigen. Wir können diese Bedingungen sofort kündigen, indem wir Sie benachrichtigen, wenn Sie erheblich gegen Abschnitt 2 (Nutzungsanforderungen), Abschnitt 5 (Vertraulichkeit, Sicherheit und Datenschutz), Abschnitt 8 (Streitbeilegung) oder Abschnitt 9 (Allgemeine Geschäftsbedingungen) verstoßen oder wenn unsere sich die Beziehung zu einem Drittanbieter von Technologie, der außerhalb unserer Kontrolle liegt, ändert, oder zur Einhaltung von Gesetzen oder behördlichen Anforderungen. Wir können Ihren Zugriff auf den Dienst sperren, wenn Sie diese Bedingungen nicht einhalten oder wenn Ihre Nutzung ein Sicherheitsrisiko für uns oder Dritte darstellt oder wenn wir den Verdacht haben, dass Ihre Nutzung betrügerisch ist oder uns oder Dritte einer Haftung aussetzen könnte .',
    block19:
      '(b) Auswirkungen der Kündigung. Nach der Kündigung werden Sie die Nutzung des Dienstes einstellen und alle vertraulichen Informationen gemäß unseren Anweisungen unverzüglich zurückgeben oder vernichten. Die Bestimmungen dieser Bedingungen, die ihrer Natur nach über die Kündigung oder den Ablauf hinaus bestehen sollten, einschließlich, aber nicht beschränkt auf die Abschnitte 3 und 5–9, bleiben bestehen.',
    block2:
      'Diese Nutzungsbedingungen gelten für Ihre Nutzung der Dienste von Noam, einschließlich unserer Anwendungsprogrammierschnittstellen, Software, Tools, Entwicklerdienste, Daten, Dokumentation und Website (gemeinsam als „Dienste“ bezeichnet). Durch die Nutzung unserer Dienste erklären Sie sich mit der Einhaltung dieser Bedingungen einverstanden. Unsere Datenschutzrichtlinie erläutert, wie wir personenbezogene Daten erfassen und verwenden.',
    block20:
      '(a) Entschädigung. Sie werden uns, unsere verbundenen Unternehmen und unser Personal von jeglichen Ansprüchen, Verlusten und Kosten (einschließlich Anwaltskosten) freistellen, die sich aus oder im Zusammenhang mit Ihrer Nutzung des Dienstes ergeben, einschließlich Ihrer Inhalte, Ihrer Nutzung von Produkten oder Dienstleistungen im Zusammenhang mit dem Service und Ihr Verstoß gegen diese Bedingungen oder geltendes Recht.',
    block21:
      '(b) Haftungsausschluss. Der Dienst wird „wie besehen“ bereitgestellt. Soweit gesetzlich zulässig, geben wir und unsere verbundenen Unternehmen und Lizenzgeber keine Zusicherungen oder Gewährleistungen jeglicher Art in Bezug auf den Dienst ab und übernehmen keine Gewährleistungen, einschließlich, aber nicht beschränkt auf Gewährleistungen der Marktgängigkeit, der Eignung für einen bestimmten Zweck, der zufriedenstellenden Qualität und der Nichtverletzung von Rechten Dritter und stiller Genuss sowie alle Garantien, die sich aus dem Geschäftsverlauf oder der Handelsbrauchbarkeit ergeben. Wir garantieren nicht, dass der Dienst ununterbrochen, genau oder fehlerfrei ist oder dass Inhalte sicher sind, nicht verloren gehen oder nicht verändert werden.',
    block22:
      '(c) Haftungsbeschränkung. Weder wir, unsere verbundenen Unternehmen noch unsere Lizenzgeber haften für indirekte, zufällige, besondere, Folge- oder Strafschäden, einschließlich entgangenen Gewinns, Reputationsverlust, Nutzungsausfall, Datenverlust oder anderen immateriellen Verlusten, selbst wenn Wir wurden auf die Möglichkeit solcher Schäden hingewiesen. Gemäß diesen Bedingungen übersteigt unsere Gesamthaftung nicht die Gebühren, die Sie in den zwölf (12) Monaten vor dem Anspruch an den Dienst gezahlt haben, oder einhundert US-Dollar (100 US-Dollar), je nachdem, welcher Betrag höher ist. Die Einschränkungen in diesem Abschnitt gelten im größtmöglichen gesetzlich zulässigen Umfang.',
    block23:
      'Die Bedingungen und Ihre Nutzung der Dienste unterliegen den Gesetzen der Vereinigten Staaten, mit Ausnahme der US-amerikanischen Kollisionsnormen. Ihre Nutzung der Anwendung kann auch anderen lokalen, staatlichen, nationalen oder internationalen Gesetzen unterliegen. Alle rechtlichen Schritte oder Verfahren im Zusammenhang mit diesen Bedingungen werden ausschließlich vor den Gerichten der Vereinigten Staaten verhandelt, und Sie erklären sich damit einverstanden, sich der persönlichen Zuständigkeit dieser Gerichte zu unterwerfen.',
    block24:
      '(a) Verhältnis der Parteien. Noam und Sie sind unabhängige Vertragspartner und keine der Parteien ist ohne die vorherige schriftliche Zustimmung der anderen Partei befugt, die andere Partei zu vertreten oder zu binden oder Verpflichtungen für die andere Partei einzugehen.',
    block25:
      '(b) Markennutzung. Ohne unsere vorherige schriftliche Zustimmung dürfen Sie den Namen, die Logos oder Marken von Noam oder einem seiner verbundenen Unternehmen nicht verwenden.',
    block26:
      'Sollte sich herausstellen, dass eine Bestimmung dieser Bedingungen ungültig, rechtswidrig oder nicht durchsetzbar ist, bleiben die übrigen Bestimmungen in vollem Umfang in Kraft und wirksam.',
    block3:
      'Wenn Sie unter 18 Jahre alt sind, müssen Sie zur Nutzung des Dienstes die Erlaubnis Ihrer Eltern oder Erziehungsberechtigten einholen. Wenn Sie den Dienst im Namen einer anderen natürlichen oder juristischen Person nutzen, müssen Sie berechtigt sein, diese Bedingungen in deren Namen zu akzeptieren. Sie müssen genaue und vollständige Informationen angeben, um sich für ein Konto zu registrieren. Sie dürfen Ihre Zugangsdaten oder Ihr Konto nicht an Dritte außerhalb Ihrer Organisation weitergeben und sind für alle Aktivitäten verantwortlich, die mit Ihren Zugangsdaten durchgeführt werden.',
    block4:
      '(a) Nutzung des Dienstes. Sie können im Rahmen dieser Bedingungen auf den Dienst zugreifen und wir gewähren Ihnen ein nicht ausschließliches Recht zur Nutzung des Dienstes. Bei der Nutzung des Dienstes werden Sie diese Bedingungen und alle geltenden Gesetze einhalten. Wir und unsere verbundenen Unternehmen besitzen alle Rechte, Titel und Interessen an dem Dienst.',
    block5:
      '(b) Feedback. Wir freuen uns über Feedback, Kommentare, Ideen, Vorschläge und Verbesserungen. Wenn Sie solche Inhalte bereitstellen, dürfen wir diese ohne Einschränkungen und ohne Vergütung an Sie nutzen.',
    block6:
      '(c) Einschränkungen. Es ist Ihnen nicht gestattet: (1) den Dienst in einer Weise zu nutzen, die die Rechte anderer verletzt, missbraucht oder verletzt; (2) den Quellcode oder die zugrunde liegenden Komponenten der Servicemodelle, Algorithmen und Systeme zurückzuentwickeln, zu dekompilieren, zu disassemblieren, zu übersetzen oder auf andere Weise zu versuchen, sie zu entdecken (es sei denn, solche Einschränkungen sind durch geltendes Recht verboten); (3) die Ergebnisse des Dienstes nutzen, um Modelle zu entwickeln, die mit Noam konkurrieren; (4) Extrahieren von Daten oder Ausgaben aus dem Dienst mithilfe automatisierter Methoden oder Programmiermethoden, sofern dies nicht durch die API gestattet ist, einschließlich Scraping, Web-Harvesting oder Web-Datenextraktion; (5) die Ausgabe des Dienstes als künstlich erzeugt darstellen, wenn dies nicht der Fall ist, oder anderweitig gegen unsere Nutzungsrichtlinien verstoßen; (6) API-Schlüssel ohne unsere vorherige Zustimmung kaufen, verkaufen oder übertragen; oder (7) uns personenbezogene Daten von Kindern unter 13 Jahren bzw. dem geltenden Mindestalter für die digitale Einwilligung zur Verfügung stellen. Sie werden etwaige Tarifbegrenzungen und andere Anforderungen in unserer Dokumentation einhalten.',
    block7:
      '(d) Dienste Dritter. Jegliche Software, Dienste oder andere Produkte Dritter, die mit dem Dienst verbunden sind, unterliegen ihren eigenen Bedingungen und wir sind nicht für Produkte Dritter verantwortlich.',
    block8:
      '(a) Ihre Inhalte. Sie können Eingaben („Eingaben“) für den Dienst bereitstellen und Ausgaben („Ausgaben“) erhalten, die auf der Grundlage der Eingaben generiert und zurückgegeben werden (gemeinsam als „Inhalte“ bezeichnet). Zwischen den Parteien und im gesetzlich zulässigen Umfang sind Sie Eigentümer sämtlicher Eingaben. Noam kann Inhalte verwenden, um den Dienst bereitzustellen und aufrechtzuerhalten, geltende Gesetze einzuhalten und unsere Richtlinien durchzusetzen. Sie sind für den Inhalt verantwortlich und stellen unter anderem sicher, dass dieser nicht gegen geltende Gesetze oder diese Bedingungen verstößt.',
    block9:
      '(b) Genauigkeit. Künstliche Intelligenz und maschinelles Lernen sind sich schnell entwickelnde Forschungsfelder. Wir sind ständig bestrebt, unseren Service zu verbessern, um ihn genauer, zuverlässiger, sicherer und nützlicher zu machen. Aufgrund der probabilistischen Natur des maschinellen Lernens kann die Nutzung unseres Dienstes in bestimmten Fällen zu falschen Ergebnissen führen, die möglicherweise nicht genau reale Personen, Orte oder Fakten widerspiegeln. Sie sollten die Genauigkeit aller Ausgaben entsprechend Ihrem Anwendungsfall angemessen beurteilen, unter anderem durch manuelle Überprüfung der Ausgaben.',
    subTitle1: 'Registrierung und Zugang',
    subTitle10: 'Salvatorische Klausel',
    subTitle2: 'Nutzungsanforderungen',
    subTitle3: 'Inhalt',
    subTitle4: 'Gebühren und Zahlung',
    subTitle5: 'Vertraulichkeit, Sicherheit und Datenschutz',
    subTitle6: 'Laufzeit und Kündigung',
    subTitle7: 'Entschädigung; Haftungsausschluss; Haftungsbeschränkung',
    subTitle8: 'Anwendbares Recht und Gerichtsstand',
    subTitle9: 'Allgemeine Geschäftsbedingungen',
    title: 'Nutzungsbedingungen',
    updateTime: 'Aktualisiert: 15. März 2024',
  },
  trialModal: {
    btn: 'Jetzt loslegen (nur 0,99 $)',
    chat: 'ChatPDF',
    desc1: 'Nach der Testversion wird Ihnen eine jährliche Gebühr von 180 US-Dollar berechnet',
    desc2: 'Jederzeit kündbar',
    focus: 'Fokussieren',
    hover: 'Hover-Übersetzung',
    image: 'Bildübersetzung',
    installBtn: 'Jetzt installieren, um Ihr Geschenk zu erhalten',
    memo: 'Favoritenverwaltung',
    pdf: 'PDF-Parallelübersetzung',
    realtime: 'Sofortige Übersetzung',
    select: 'Wortauswahlübersetzung',
    title: 'Willkommensgeschenk',
    trialText: '7-Tage-Testversion',
    video: 'Videoübersetzung',
    videoSummary: 'Videozusammenfassung',
    web: 'Parallele Übersetzung',
    webSummary: 'Web-Zusammenfassung',
  },
  upgradeModal: {
    btn: 'Jetzt upgraden',
    chat: {
      free: 'Sie haben das ChatPDF-Limit erreicht',
      pro: 'Sie haben das ChatPDF-Limit für diesen Monat erreicht',
    },
    contactStr:
      'Bitte warten Sie auf den nächsten Reset oder kontaktieren Sie uns unter contact@noam.tools.',
    limitAlertStr:
      'Sie haben Ihr Limit für den kostenlosen Plan erreicht. Aktualisieren Sie, um fortzufahren.',
    more: 'Pläne anzeigen',
    pageLimit: { free: 'Sie haben das PDF-Seitenlimit erreicht' },
    title: 'Erinnerung',
    upgradeAlertStr:
      'Sie haben Ihr Limit für den kostenlosen Plan erreicht. Aktualisieren Sie, um fortzufahren.',
    upload: {
      free: 'Sie haben Ihr PDF-Übersetzungslimit erreicht',
      pro: 'Sie haben Ihr PDF-Übersetzungslimit für diesen Monat erreicht',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Jetzt beitreten',
      label: 'Vorteile für neue Benutzer:',
      remain: 'Nur noch 30 übrig',
      text: 'Nur 100 Plätze verfügbar! Schnappen Sie sich jetzt Ihr Exemplar!',
    },
  },
}

export default TRANSLATION
