const TRANSLATION = {
  buyModal: {
    afterFree: 'Po bezplatné zkušební verzi',
    btn: 'Vyzkoušejte zdarma',
    freeTrial: '3denní bezplatná zkušební verze',
    monthLabel: 'měsíc',
    selectLabel: 'Vyberte plán',
    title: 'Plán předplatného',
    yearLabel: 'rok',
  },
  contact: {
    block1:
      'Pro přizpůsobení, dotazy nebo návrhy nás prosím kontaktujte na contact@noam.tools. Odpovíme obratem.',
    block2: 'Podpořte nás hodnocením v obchodě s rozšířeními Google Apps na ★★★★★.',
    block3: 'Pomozte ostatním sdílením tohoto rozšíření:',
    title: 'Kontaktujte nás',
  },
  help: {
    AquickOverviewOfNoam: 'Rychlý přehled Noama',
    ChatPDF: 'Chat PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Správa oblíbených',
    Fixedplugin: 'Opravený plugin',
    Focusing: 'Zaostřování',
    Free: 'Uvolnit',
    FreeTrialBenefits: 'Výhody bezplatné zkušební verze',
    GoWithWikipedia: 'Přejděte na Wikipedii',
    GoWithYouTube: 'Přejděte na YouTube',
    HoverTranslation: 'Překlad po najetí myší',
    Howtouse: 'Jak používat',
    ImageTranslation: 'Překlad obrázků',
    InstantTranslation: 'Okamžitý překlad',
    Membership: 'Členství',
    NoamHelp: 'Noam vám pomůže přeložit a shrnout',
    PDFparallelTranslation: 'Paralelní překlad PDF',
    ParallelTranslation: 'Paralelní překlad',
    SkipAllSteps: 'Přeskočit všechny kroky',
    Summary: 'Shrnutí',
    Translation: 'Překlad',
    TryItForFree: 'Vyzkoušejte zdarma',
    Video: 'Video',
    VideoSummary: 'Video shrnutí',
    VideoTranslation: 'Překlad videa',
    WebSummary: 'Souhrn webu',
    WordSelectionTranslation: 'Překlad výběru slov',
    benefits: 'Čekají na vás výhody!',
    dayFree: '3denní bezplatná zkušební verze',
    done: 'Hotovo',
    next: 'Další',
    pin: 'Pin Noam pro snadnější přístup～～',
    planIsNotExist: 'Plán není k dispozici',
    startWiki: 'Začněme s Wikimedia!',
    startYoutube: 'Začněme s YouTube!',
    try: 'Vyzkoušejte to',
    tryNow: 'Vyzkoušejte to hned!',
  },
  home: {
    PDFTranslate: {
      desc: 'Dvojjazyčný překlad + chat PDF',
      sub1: {
        desc: 'Dvojjazyčné čtení vám umožňuje porovnávat původní text a překlad vedle sebe, což zlepšuje efektivitu čtení.',
        title: 'Dvojjazyčné čtení',
      },
      sub2: {
        desc: 'Umělá inteligence analyzuje a shrnuje a může odpovídat na vaše otázky na základě obsahu PDF.',
        title: 'Chat PDF',
      },
      sub3: {
        desc: 'Překlad PDF využívající umělou inteligenci pro větší přesnost a efektivitu.',
        title: 'Přesnost a účinnost',
      },
      title: 'Překlad PDF',
    },
    aiAssistant: {
      desc: 'Webový překlad bude chytřejší a pohodlnější',
      panel1: {
        desc: 'Dvojjazyčné překlady s křížovými odkazy vám pomohou porovnat původní text s překladem, abyste lépe porozuměli a zlepšili efektivitu čtení.',
        title: 'Dvojjazyčný překlad',
      },
      panel2: {
        left: {
          desc: 'Při procházení webové stránky umístěte ukazatel myši na obsah, který potřebujete přeložit, aby se překlad zobrazil.',
          title: 'Překlad po najetí myší',
        },
        right: {
          desc: 'Při procházení webové stránky vyberte text, který chcete přeložit, aby se překlad zobrazil.',
          title: 'Překlad výběru textu',
        },
      },
      panel3: {
        one: {
          desc: 'Překládejte a sumarizujte webové stránky a videoobsah kdykoli a kdekoli, abyste měli hladší zážitek!',
          title: 'Ukaž a klikni',
        },
        three: {
          desc: 'Velký jazykový model řízený umělou inteligencí zajišťuje přesnost a konzistenci překladu pro lepší zážitek ze čtení.',
          title: 'řízený umělou inteligencí',
        },
        two: {
          desc: 'Vícejazyčné plné pokrytí, dvojjazyčné bezbariérové ​​čtení, překlad jedním kliknutím – zažijte ten rozdíl!',
          title: 'Komplexnější a pohodlnější',
        },
      },
      panel4: {
        tab1: {
          desc: 'Váš odborník na efektivitu – pomocí ChatGPT Noam překládá a shrnuje videoobsah YouTube, aby vám ušetřil čas!',
          key: 'Video',
          title: 'Překlad a shrnutí videa',
        },
        tab2: {
          desc: 'Kliknutím přeložíte obrázky při procházení webu.',
          key: 'Obraz',
          title: 'Překladač obrázků',
        },
        tab3: {
          desc: 'Okamžitě získává klíčové informace z webových stránek a poskytuje souhrn, čímž zvyšuje efektivitu vašeho čtení.',
          key: 'Web',
          title: 'Webový překlad a shrnutí',
        },
        tag1: 'Jednoduchý',
        tag2: 'Účinný',
        tag3: 'Snadno pochopitelné',
      },
      title: 'Asistent AI',
    },
    getStart: 'Začněte',
    getStartedForFree: 'Začněte zdarma',
    howItWork: {
      desc: 'Noam integruje modely umělé inteligence, aby uživatelům poskytl inteligentní prostředí pro překlad a sumarizaci webových stránek mezi jazyky, překlady PDF a obrázků, překlady videa a shrnutí',
      title: 'Jak to funguje',
    },
    lastBlock: { desc: 'Lepší překlad AI pro hladší zážitek ze čtení' },
    top: {
      desc: 'Noam je bezplatné rozšíření prohlížeče AI, které vám pomůže přeložit a shrnout webové stránky, videa a obsah PDF.\\n Připojte se k Noamovi a zažijte kouzlo!',
      title1: 'Jedno kouzlo',
      title2: 'Jedno kliknutí',
    },
    userReviews: {
      desc: 'Pětihvězdičkové recenze v Internetovém obchodě Chrome',
      title: 'Uživatelské recenze',
    },
  },
  installTips: {
    btn: 'Zkuste to hned',
    text: 'Nainstalujte si "Noam Browser Plugin" a odemkněte další funkce! Křížové odkazy na webu,\\n shrnutí webových videí a další!',
  },
  locale: {
    af: 'afrikánština',
    am: 'amharština',
    ar: 'arabština',
    as: 'ásámština',
    auto: 'Automatická detekce',
    az: 'ázerbájdžánský',
    ba: 'Baškir',
    bg: 'bulharský',
    bn: 'Bangla',
    bo: 'tibetský',
    bs: 'bosenský',
    ca: 'katalánština',
    cs: 'čeština',
    cy: 'velština',
    da: 'dánština',
    de: 'Němec',
    dsb: 'Dolní lužická srbština',
    dv: 'Divehi',
    el: 'řecký',
    en: 'angličtina',
    es: 'španělština',
    et: 'estonština',
    eu: 'baskický',
    fa: 'Peršan',
    fi: 'finština',
    fil: 'filipínský',
    fj: 'fidžijský',
    fr: 'francouzština',
    frCA: 'francouzština (Kanada)',
    ga: 'irština',
    gl: 'galicijský',
    gom: 'Konkani',
    gu: 'Gudžarátština',
    ha: 'Hausa',
    he: 'hebrejština',
    hi: 'hindština',
    hr: 'chorvatský',
    hsb: 'hornolužická srbština',
    ht: 'haitská kreolština',
    hu: 'maďarský',
    hy: 'arménský',
    id: 'indonéština',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandský',
    it: 'italština',
    iu: 'Inuktitut',
    iuLatn: 'inuktitut (latinka)',
    ja: 'japonský',
    ka: 'gruzínský',
    kk: 'kazašský',
    km: 'khmerské',
    kmr: 'kurdština (severní)',
    kn: 'Kannada',
    ko: 'korejština',
    ku: 'kurdština (střední)',
    ky: 'kyrgyzština',
    ln: 'lingala',
    lo: 'Lao',
    lt: 'litevský',
    lug: 'Ganda',
    lv: 'lotyšský',
    lzh: 'čínština (literární)',
    mai: 'Maithili',
    mg: 'malgašský',
    mi: 'maorští',
    mk: 'makedonský',
    ml: 'malajálamština',
    mnCyrl: 'mongolština (cyrilice)',
    mnMong: 'mongolština (tradiční)',
    mr: 'maráthština',
    ms: 'malajština',
    mt: 'maltština',
    mww: 'Hmong Daw',
    my: 'Myanmar (barmština)',
    nb: 'norský',
    ne: 'nepálský',
    nl: 'holandský',
    noSearchLang: 'Jazyk není podporován',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'pandžábština',
    pl: 'polština',
    placeholder: 'Vyhledávání',
    prs: 'Dari',
    ps: 'paštština',
    pt: 'portugalština (Brazílie)',
    ptPT: 'portugalština (Portugalsko)',
    ro: 'rumunština',
    ru: 'ruština',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'sinhálština',
    sk: 'Slovák',
    sl: 'slovinský',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'somálský',
    sq: 'albánský',
    srCyrl: 'srbština (cyrilice)',
    srLatn: 'srbština (latinka)',
    st: 'sesotho',
    sv: 'švédský',
    sw: 'svahilština',
    ta: 'tamilština',
    te: 'telugština',
    th: 'thajština',
    ti: 'Tigrinya',
    tk: 'turkmenský',
    tlhLatn: 'klingonština (latinka)',
    tlhPiqd: 'klingonština (pIqaD)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'turečtina',
    tt: 'Tatar',
    ty: 'tahitský',
    ug: 'ujgurské',
    uk: 'ukrajinština',
    ur: 'Urdu',
    uz: 'uzbečtina (latinka)',
    vi: 'vietnamština',
    xh: 'Xhosa',
    yo: 'jorubština',
    yua: 'Yucatec Maya',
    yue: 'kantonština (tradiční)',
    zh: 'čínština',
    zhHans: 'Zjednodušená čínština',
    zhHant: 'Tradiční čínská',
    zu: 'Zulu',
  },
  login: {
    and: 'a',
    btn: 'Přihlaste se pomocí Google',
    btn1: 'Přihlaste se nyní',
    desc: 'Chcete-li odemknout další zážitky, přihlaste se',
    policy: 'Zásady ochrany osobních údajů',
    terms: 'Podmínky použití',
    tips: 'Přihlášením souhlasím s',
    title: 'Vítejte v destinaci Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Následovat', twitter: 'Cvrlikání' },
    legal: { policy: 'Zásady ochrany osobních údajů', terms: 'Podmínky služby', title: 'Právní' },
    product: {
      image: 'Překlad obrázků',
      pdf: 'Překlad PDF',
      title: 'Produkty',
      video: 'Překlad a shrnutí videa',
      web: 'Webový překlad a shrnutí',
    },
    resources: { title: 'Zdroje' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Pomoc',
    logout: 'Odhlásit se',
    logoutSucc: 'Odhlášen.',
    pricing: 'Ceny',
    settings: 'Nastavení',
    signUp: 'Přihlaste se',
  },
  pdf: {
    autoDetect: 'Auto-Detect',
    cancelBtn: 'Zrušit',
    cancelUpload: 'Zrušit',
    chatPdf: {
      Search: 'Vyhledávání',
      cancel: 'Zrušit',
      chatTitle: 'Vítejte v Noam! Zde je shrnutí vašeho PDF:',
      clear: 'Jasný',
      clearContent:
        'Tím zahájíte novou konverzaci. Aktuální konverzace nebude uložena. jsi si jistý?',
      clearDialogTitle: 'Prosím potvrďte',
      clearSucc: 'Vymazáno',
      copy: 'Kopie',
      copySucc: 'Zkopírováno',
      delete: 'Vymazat',
      deleteCancel: 'Zrušit',
      deleteContent: 'Tento PDF nelze po smazání obnovit. jsi si jistý?',
      deleteOk: 'Vymazat',
      deleteSuccess: 'Smazáno',
      deleteTitle: 'Potvrďte Smazat',
      expand: 'Kolaps',
      historyTitle: 'Dějiny',
      listError:
        'Načtení seznamu četby se nezdařilo. Zkuste to znovu později nebo kontaktujte podporu.',
      loading: 'Načítání...',
      noDataDesc: 'Zatím bez historie.',
      noMore: 'Žádný další obsah.',
      placeholder: 'Zeptejte se na cokoliv ohledně tohoto PDF',
      summaryTitle: 'Mohly by vás zajímat tyto otázky:',
    },
    collectionEmpty: 'Vaše sbírka je prázdná.',
    collectionTab: 'Poznámky',
    dataLoading: 'Načítání...',
    dialogContent: 'Opravdu to chcete smazat?',
    dialogTitle: 'Prosím potvrďte',
    download: {
      both: 'Bilingvní',
      btn: 'Uložit',
      cancel: 'Zavřením tohoto okna zrušíte stahování PDF. Opravdu chcete zrušit?',
      desc: 'Uložit do zařízení: Stáhne překlady jako obrázky; Uložit a vytisknout: Stáhne překlady v původním formátu s podporou kopírování.',
      download: 'Stáhnout',
      downloadMsg1: 'Průběh překladu PDF:',
      downloadMsg2: 'Odhadovaný zbývající čas:',
      downloadSucc: 'PDF je připraveno! Kliknutím uložíte.',
      downloadTip: 'Stahování PDF. Nezavírejte toto okno, abyste předešli chybám při stahování.',
      error: 'Uložení se nezdařilo. Zkuste místo toho tisknout.',
      fileName: 'Název souboru:',
      fileType: 'Typ souboru:',
      loading: 'Ukládání...',
      loading2: 'Načítání...',
      minute: 'Zápis...',
      msg1: 'Některé překlady v tomto dokumentu jsou delší než původní text a mohou se ve staženém souboru překrývat. Doporučujeme číst na webu.',
      msg2: '1. Překlad ještě není dokončen. Stáhněte si prosím po dokončení překladu.',
      msg3: '2. Některé překlady jsou příliš dlouhé. Čtěte na webové stránce, abyste se vyhnuli překrývání textu.',
      msg4: 'Probíhají úlohy překladu. Zkuste to znovu po jejich dokončení.',
      progress: 'Překlad PDF. Průběh: {{progress}}, odhadovaný zbývající čas: {{minutes}} minut...',
      save: 'Uložit do zařízení',
      savePrint: 'Uložit a vytisknout',
      success: 'Staženo',
      trans: 'Pouze překlad',
      transContinue: 'Pokračovat v překladu',
    },
    downloadBtn: 'Stáhnout',
    dragTipModal: {
      black: 'plocha',
      leftKey: 'levé tlačítko',
      ok: 'Rozumím',
      press: 'Stiskněte',
      tips: 'přetáhnout a zobrazit.',
    },
    entry: 'PDF',
    fileErrMsg: 'Čtení souboru se nezdařilo. Zkuste to znovu později nebo kontaktujte podporu.',
    fileMsg: 'Aktuální velikost souboru:',
    fileMsg2: 'Nahrajte prosím soubor menší než 300 MB.',
    freeBannerBtn: 'Upgrade',
    freeBannerTips: 'Dosažen limit bezplatných překladů PDF',
    guide: {
      placeholder: 'Sem vložte odkaz na PDF',
      start: 'Začněte překládat',
      text1: 'Dvojjazyčné čtení',
      text2:
        'Čtěte dvojjazyčně a porovnejte původní text a překlad vedle sebe, čímž zvýšíte svou efektivitu.',
      text3: 'Chat s vaším PDF',
      text4:
        'Analýza a sumarizace využívající umělou inteligenci. Zeptejte se na své PDF a získejte okamžité odpovědi.',
      text5: 'Přesné a efektivní',
      text6: 'Překlad PDF s podporou umělé inteligence pro větší přesnost a efektivitu.',
      title1: 'Dvojjazyčný překlad',
      title2: 'Chat s vaším PDF',
      toast: 'Zadejte prosím platný online odkaz PDF končící na .pdf',
    },
    hasTransTask: 'Probíhá úloha překladu. Čekejte prosím.',
    hoverTrans: 'Odstavce pro přeložení kurzorem',
    initial: 'Přeložit PDF',
    installText: 'Vaše komplexní řešení pro každodenní překlady.',
    installText2: 'Nejprve nainstalujte rozšíření',
    installTips:
      'Nainstalujte si rozšíření pro ještě více překladů PDF a hladší překlady webových stránek:',
    kw: 'sešívačka',
    link: 'odkaz',
    listError:
      'Soubory PDF se nepodařilo načíst. Zkuste to znovu později nebo kontaktujte podporu.',
    memoTotalCount: 'Celkem 1 položka',
    new: 'nový soubor',
    noId: 'ID souboru nenalezeno.',
    notPdf: 'Pouze soubory PDF.',
    okBtn: 'Vymazat',
    okBtn2: 'Rozumím',
    original: 'Originál',
    parallel: 'Paralelní',
    parseErrDialogContent:
      'Tento odkaz PDF nelze přeložit přímo. Stáhněte si prosím PDF a nahrajte jej k překladu.',
    parseErrTips: 'Soubor se nepodařilo analyzovat',
    parseFileToast:
      'Soubor se nepodařilo analyzovat. Zkuste to znovu později nebo kontaktujte podporu.',
    pdfLoading: 'Analýza...',
    searchPlaceholder: 'Vyhledávání',
    selectTrans: 'Vyberte Text k překladu',
    shareBtn: 'Podíl',
    shareBtnText1: 'Vygenerovat odkaz na sdílení',
    shareBtnText2: 'Aktualizujte sdílený obsah',
    shareModal: {
      cancel: 'Zrušit',
      copy: 'Kopie',
      copySucc: 'Odkaz zkopírován',
      custom: 'Přizpůsobit:',
      errMsg: 'Vybraný obsah ještě nebyl plně přeložen. Před sdílením prosím přeložte.',
      gen: 'Podíl',
      modify: 'Upravit',
      notSupport: 'Tento jazyk není aktuálně podporován.',
      page: 'Stránky',
      pageNuErr: 'Zadejte prosím platné číslo stránky.',
      range: 'Rozsah:',
      remain: 'zbývající',
      remain1: 'Celkový odhadovaný čas:',
      search: 'Vyhledávání',
      settings: 'Nastavení sdílení:',
      shareSetting: 'Nastavení sdílení',
      shareText: 'Podíl',
      shareTips: 'Sdílejte přeložený odkaz PDF se svými přáteli:',
      sourceLang: 'Z:',
      target: 'Na:',
      text1: 'Nastavení sdílení',
      text2: 'Vygenerovat odkaz na sdílení',
      totalPage: 'celkem 1 stránka',
      trans: 'Přeložit',
      translateTips: 'Tip: Pokud překlad chvíli trvá, můžete toto okno zavřít a sdílet později.',
      translating: 'Překlad PDF...',
      update: 'Aktualizovat odkaz na sdílení',
    },
    shareMsg1: 'Sdílet odkaz vygenerován.',
    shareMsg2: 'Sdílený obsah byl aktualizován.',
    shareMsg3:
      'Vygenerování odkazu pro sdílení se nezdařilo. Zkuste to znovu později nebo kontaktujte podporu.',
    shareMsg4:
      'Aktualizace sdíleného obsahu se nezdařila. Zkuste to znovu později nebo kontaktujte podporu.',
    shareTips: 'Váš překlad PDF je připraven! Sdílejte to se svými přáteli.',
    start: 'Spusťte bezplatnou zkušební verzi',
    thumbnail: 'Obsah',
    toast1: 'Načítání PDF. Zkuste to znovu později.',
    toast2: 'Obsah PDF je příliš velký. Dialogové okno momentálně není podporováno.',
    toast3: 'Stahování. Zkuste to prosím znovu po dokončení stahování.',
    toolbar: { Adjust: 'ERROR: [Adjust]', autoFit: 'Automatické přizpůsobení' },
    trans: 'Překlad',
    transSucc: 'Překlad úspěšný!',
    unLoginToast: 'Pro použití této funkce se prosím přihlaste.',
    uploadBtn: 'Nahrát',
    uploadErr: 'Nahrání souboru se nezdařilo. Zkuste to znovu později nebo kontaktujte podporu.',
    uploadMsg1: 'Přetáhněte sem svůj soubor.',
    uploadMsg2: 'Podporované typy souborů: PDF |  Maximální velikost souboru: 100 MB',
    uploadMsg3: 'Kliknutím sem vyberte nebo přetáhněte soubory.',
    uploading: 'Nahrávání',
    uploadingMsg: 'Nahrávání. Čekejte prosím.',
    uploadingMsg1: 'Soubor se nahrává. Zkuste to znovu později.',
    uploadingMsg2: 'Soubor se právě převádí. Zkuste to znovu později.',
    uploadingMsg3:
      'Požadavek na průběh převodu se nezdařil. Zkuste to znovu později nebo kontaktujte podporu.',
    uploadingMsg4: 'Noam nemá přístup k místním souborům. Nahrajte prosím ručně PDF k překladu.',
    uploadingMsg5:
      'Nepodařilo se načíst adresu URL souboru. Zkuste to znovu později nebo kontaktujte podporu.',
    video: 'video',
    vipBannerBtn: 'Upgrade',
    vipBannerTips: 'Dosáhli jste limitu překladů PDF pro tento měsíc.',
    webTrans: 'Úplný křížový odkaz',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ano, Noam nabízí bezplatný plán, který můžete okamžitě použít.',
      a2: 'Nabízíme tři členské plány: Pro, Pro+ a Enterprise. Můžete si vybrat, zda budete platit měsíčně nebo ročně. Roční vyúčtování přináší značné úspory.',
      a3: 'Všechny funkce jsou dostupné u obou plánů. Hlavním rozdílem jsou náklady: roční předplatné stojí celkově méně. Měsíční předplatné se účtuje každý měsíc, zatímco roční plány jsou účtovány jednou ročně.',
      a4: 'Předplatné můžete kdykoli zrušit v Nastavení účtu.',
      q1: 'Existuje bezplatný plán?',
      q2: 'Jaké cenové plány a možnosti platby jsou k dispozici?',
      q3: 'Jaký je rozdíl mezi předplatným měsíčně a ročně?',
      q4: 'Jak zruším své předplatné?',
    },
    around: 'Kolem',
    billedMonthly: 'Účtováno měsíčně',
    billedYearly: 'Účtováno ročně',
    day: 'den',
    desc: 'Porovnejte plány a vyberte si to nejlepší',
    detailedCompare: 'Porovnejte plány',
    discount: '20% sleva',
    enterprise: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: 'Více uživatelů',
      benefit3: 'Řešení na míru',
      benefit5: 'Dedikovaný Account Manager',
      btn: 'Kontaktujte nás',
      recommendText: 'Vlastní plány (≥3人)',
      talkToUs: 'Kontaktujte nás',
    },
    free: {
      benefit1: 'Okamžitý překlad 100x denně',
      benefit2: 'Překlad a shrnutí webových stránek',
      benefit3: '30 Překlady obrázků',
      benefit4: 'Překlad a shrnutí videa',
      benefit5: '10 překladů PDF',
      benefit6: '5 000 žetonů',
      btn: 'Vyzkoušejte zdarma',
      recommendText: 'Bezplatná zkušební verze',
    },
    imageTranslation: 'Překlad obrázků',
    month: 'měsíc',
    monthly: 'Měsíční',
    pageTitle: 'Ceny',
    pdf: {
      chat: 'Dotazy na chat ve formátu PDF',
      maxPage: 'Max. Stránky v PDF',
      translation: 'Překlad PDF',
    },
    preMonth: 'Za měsíc',
    pro: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: '1 000 000 tokenů/měsíc',
      benefit3: 'Překlad a shrnutí webových stránek',
      benefit4: '200 obrazových překladů',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '200 PDF překladů',
      btn: 'Začněte',
      recommendText: 'Nejoblíbenější',
    },
    proPlus: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: '3 000 000 tokenů/měsíc',
      benefit3: 'Překlad a shrnutí webových stránek',
      benefit4: '500 obrazových překladů',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '500 PDF překladů',
      recommendText: 'Nejlepší hodnota',
    },
    video: { summary: 'Video shrnutí', translation: 'Překlad videa' },
    web: {
      contrastiveTranslation: 'Dvojjazyčný překlad',
      dictionaryTranslation: 'Výběr Překlad',
      hoverTranslation: 'Překlad po najetí myší',
      instantTranslation: 'Okamžitý překlad',
      summary: 'Shrnutí webové stránky',
    },
    yearly: 'Roční',
  },
  privacy: {
    block1:
      'Společnost Noam („Noam“, „my“, „naše“ nebo „nás“) respektuje vaše soukromí a zavazuje se chránit veškeré informace, které od vás nebo o vás získáme. Tyto zásady ochrany osobních údajů popisují naše postupy týkající se shromažďování nebo používání osobních údajů z vašeho používání našich webových stránek, aplikací a služeb (souhrnně „služby“). Tyto zásady ochrany osobních údajů se nevztahují na obsah, který zpracováváme jménem zákazníků našich obchodních produktů, jako je naše API. Použití těchto údajů podléhá naší zákaznické smlouvě, která se vztahuje na přístup k těmto produktům a jejich používání.',
    block10:
      'Zavádíme obchodně přiměřená technická, administrativní a organizační opatření k ochraně online i offline osobních údajů před ztrátou, zneužitím a neoprávněným přístupem, zveřejněním, pozměněním nebo nepřátelskými činy. Žádný internetový nebo e-mailový přenos však nemůže zaručit úplnou bezpečnost nebo přesnost. Zejména e-maily zaslané nám nebo od nás nemusí být bezpečné. Měli byste být tedy obzvláště opatrní při rozhodování, jaké informace nám prostřednictvím služby nebo e-mailu zasílat. Kromě toho neneseme odpovědnost za jakékoli narušení nastavení ochrany osobních údajů nebo bezpečnostních opatření ve službě nebo nastavení zabezpečení nebo bezpečnostních opatření webových stránek třetích stran. Vaše osobní údaje budeme uchovávat tak dlouho, jak to bude nutné k tomu, abychom vám mohli poskytovat služby nebo pro jiné legitimní obchodní účely, jako je řešení sporů, z důvodů bezpečnosti a ochrany nebo plnění našich zákonných povinností. Doba, po kterou osobní údaje uchováváme, bude záviset na mnoha faktorech, jako je množství, povaha, citlivost informací, potenciální rizika neoprávněného použití nebo zveřejnění, účely, pro které údaje zpracováváme, a další právně závazné úvahy.',
    block11:
      'Tyto Zásady ochrany osobních údajů můžeme čas od času aktualizovat. Když tak učiníme, zveřejníme aktualizovanou verzi na této stránce, pokud to nebude vyžadovat jinak platnými zákony.',
    block12:
      'Pokud máte nějaké nezodpovězené otázky nebo obavy týkající se těchto Zásad ochrany osobních údajů, kontaktujte prosím náš zákaznický servis. E-mail:',
    block2: 'Shromažďujeme následující osobní údaje („Osobní údaje“), které se vás týkají:',
    block3:
      'Informace, které poskytujete: Když si vytvoříte účet, abyste mohli používat naše služby nebo s námi komunikovat, shromažďujeme osobní údaje následovně:',
    block4:
      'Osobní údaje automaticky získané z vašeho používání služeb: Když přistupujete ke službám, používáte je nebo s nimi komunikujete, dostáváme následující informace o vašem přístupu, používání nebo interakci („Technické informace“):',
    block5: 'Osobní údaje můžeme použít pro následující účely:',
    block6:
      'Souhrnné informace nebo informace o zrušení identifikace: Můžeme agregovat nebo zrušit identifikaci osobních údajů, abychom zabránili jejich opětovnému použití pro účely identifikace, a použít tyto informace k analýze účinnosti našich služeb, vylepšování a přidávání funkcí do našich služeb, provádění výzkumu a pro podobné účely. . Kromě toho můžeme analyzovat obecné chování a charakteristiky uživatelů našich služeb a sdílet souhrnné informace s třetími stranami, zveřejňovat takové souhrnné informace nebo je obecně zpřístupňovat. Můžeme shromažďovat souhrnné informace prostřednictvím služeb, souborů cookie a jinými prostředky popsanými v těchto Zásadách ochrany osobních údajů. Budeme udržovat a používat deidentifikované informace v anonymní nebo deidentifikované formě a nebudeme se pokoušet takové informace znovu identifikovat, pokud to nevyžaduje zákon.',
    block7:
      'V určitých případech můžeme vaše osobní údaje poskytnout třetím stranám bez dalšího upozornění, pokud to nevyžaduje zákon:',
    block8:
      'V závislosti na vaší geografické poloze mohou mít jednotlivci v Evropském hospodářském prostoru, Spojeném království a na celém světě určitá zákonná práva týkající se jejich osobních údajů. Můžete mít například právo:',
    block9:
      'Služba může obsahovat odkazy na jiné webové stránky, které společnost Noam neprovozuje ani neřídí, včetně služeb sociálních médií ("Webové stránky třetích stran"). Informace, které sdílíte s webovými stránkami třetích stran, se budou řídit konkrétními zásadami ochrany osobních údajů a podmínkami služeb webových stránek třetích stran, nikoli těmito zásadami ochrany osobních údajů. Tyto odkazy poskytujeme pro pohodlí a neimplikují naši podporu nebo kontrolu těchto webových stránek. Kontaktujte prosím webové stránky třetích stran přímo pro informace o jejich zásadách ochrany osobních údajů a podmínkách.',
    subBlock1:
      'Informace o účtu: Když si vytvoříte účet na naší platformě, shromažďujeme informace související s vaším účtem, včetně vašeho jména, kontaktních údajů, přihlašovacích údajů k účtu, informací o platební kartě a historie transakcí ("Informace o účtu").',
    subBlock10: 'Poskytování, správa, údržba a/nebo analyzování služeb;',
    subBlock11: 'Komunikace s vámi;',
    subBlock12: 'Vývoj nových produktů a služeb;',
    subBlock13:
      'Prevence podvodů, trestné činnosti nebo zneužití našich služeb a ochrana bezpečnosti našich IT systémů, architektury a sítí;',
    subBlock14: 'Provádění převodů podniků;',
    subBlock15:
      'Dodržování zákonných povinností a soudních řízení, ochrana našich práv, soukromí, bezpečnosti nebo majetku, jakož i práv našich přidružených společností, vás nebo jiných třetích stran.',
    subBlock16:
      'Dodavatelé a poskytovatelé služeb: Abychom nám pomohli při plnění obchodních provozních potřeb a provádění určitých služeb a funkcí, můžeme poskytovat osobní údaje dodavatelům a poskytovatelům služeb, včetně poskytovatelů hostingových služeb, poskytovatelů cloudových služeb a dalších poskytovatelů služeb informačních technologií, softwaru pro e-mailovou komunikaci a poskytovatelé služeb webové analýzy atd. Tito partneři budou při plnění svých povinností pro nás přistupovat, zpracovávat nebo ukládat osobní údaje pouze na náš pokyn.',
    subBlock17:
      'Převody podniků: Pokud se zapojíme do strategických transakcí, reorganizací, bankrotů, převzetí bankrotu nebo převodu služeb k jinému poskytovateli (souhrnně „Transakce“), mohou být vaše osobní údaje a další informace sdíleny s protějšky transakce a dalšími stranami, které při Transakcích pomáhají. při due diligence a převedeny v rámci Transakcí spolu s dalším majetkem na nástupnickou nebo sesterskou společnost.',
    subBlock18:
      'Právní požadavky: Můžeme sdílet vaše osobní údaje s vládními úřady, partnery v oboru nebo jinými třetími stranami, včetně vašich informací o interakci s našimi službami, (1) pokud to vyžaduje zákon nebo pokud se domníváme, že taková akce je nezbytná pro splnění zákonných povinností; (2) k ochraně a obraně našich práv nebo majetku; (3) pokud jednostranně zjistíme, že došlo k porušení našich podmínek, zásad nebo zákonů; (4) odhalovat podvody nebo jiné nezákonné činnosti nebo jim předcházet; (5) chránit naše produkty, zaměstnance nebo uživatele nebo veřejnou bezpečnost, zabezpečení, integritu; nebo (vi) bránit se právní odpovědnosti.',
    subBlock19:
      'Získejte přístup k vašim osobním údajům a informacím o tom, jak jsou zpracovávány.',
    subBlock2:
      'Uživatelský obsah: Když používáte naše služby, shromažďujeme osobní údaje z vašich vstupů, nahraných souborů nebo zpětné vazby, kterou nám poskytnete („Obsah“).',
    subBlock20: 'Požádejte o vymazání vašich osobních údajů z našich záznamů.',
    subBlock21: 'Opravte nebo aktualizujte své osobní údaje.',
    subBlock22: 'Předání vašich osobních údajů třetí straně (přenositelnost údajů).',
    subBlock23: 'Omezte způsob, jakým zpracováváme vaše osobní údaje.',
    subBlock24:
      'Odvolání souhlasu – tam, kde se spoléháme na souhlas jako na právní základ pro zpracování, můžete svůj souhlas kdykoli odvolat.',
    subBlock25: 'Vznést námitku proti tomu, jak zpracováváme vaše osobní údaje.',
    subBlock26: 'zlepšování našich služeb a provádění výzkumu;',
    subBlock3:
      'Komunikační informace: Pokud nám pošlete zprávy, budeme shromažďovat vaše jméno, kontaktní údaje a obsah vašich zpráv („informace o komunikaci“).',
    subBlock4:
      'Informace o sociálních médiích: Máme stránky na webech sociálních médií, jako je Instagram, Facebook, Medium, Twitter, YouTube a LinkedIn. Když komunikujete s našimi stránkami sociálních médií, shromažďujeme osobní údaje, které se nám rozhodnete poskytnout, jako jsou vaše kontaktní údaje („Sociální informace“). Společnosti hostující naše stránky na sociálních sítích nám navíc mohou poskytovat souhrnné informace a analýzy o našich aktivitách na sociálních sítích.',
    subBlock5:
      'Log Data: Informace automaticky zasílané vaším prohlížečem při používání našich služeb. Data protokolu zahrnují vaši adresu internetového protokolu (IP), typ a nastavení prohlížeče, datum a čas vašich požadavků a způsob, jakým komunikujete s našimi webovými stránkami.',
    subBlock6:
      'Údaje o používání: Můžeme automaticky shromažďovat informace o vašem používání služby, jako jsou typy obsahu, který si prohlížíte nebo se kterým pracujete, funkce, které používáte, akce, které provádíte, a také vaše časové pásmo, zemi, datum a čas přístup, uživatelský agent a verze, typ počítače nebo mobilního zařízení a připojení k počítači.',
    subBlock7:
      'Informace o zařízení: Patří sem název zařízení, operační systém, identifikátor zařízení a prohlížeč, který používáte. Shromážděné informace mohou záviset na typu zařízení, které používáte, a jeho nastavení.',
    subBlock8:
      'Cookies: Cookies používáme k provozování a správě našich služeb a zlepšování vašeho zážitku. „Cookie“ je informace odeslaná webovou stránkou, kterou navštívíte, do vašeho prohlížeče. Svůj prohlížeč můžete nastavit tak, aby přijímal všechny soubory cookie, odmítal všechny soubory cookie nebo vás upozornil, když je soubor cookie poskytnut, abyste se mohli rozhodnout, zda jej pokaždé přijmete. Odmítnutí souborů cookie vám však může zabránit v používání nebo negativně ovlivnit zobrazení nebo funkčnost určitých oblastí nebo funkcí webových stránek. Další informace o souborech cookie naleznete na stránce Vše o souborech cookie.',
    subBlock9:
      'Analytika: Můžeme používat různé online analytické produkty, které používají soubory cookie, aby nám pomohly analyzovat, jak uživatelé používají naše služby, a zlepšit váš zážitek při používání služeb.',
    subTitle1: 'Osobní údaje, které shromažďujeme',
    subTitle2: 'Jak používáme osobní údaje',
    subTitle3: 'Zpřístupnění osobních údajů',
    subTitle4: 'Vaše práva',
    subTitle5: 'Odkazy na jiné webové stránky',
    subTitle6: 'Bezpečnost a uchovávání informací',
    subTitle7: 'Úprava Zásad ochrany osobních údajů',
    subTitle8: 'Jak nás kontaktovat',
    title: 'Zásady ochrany osobních údajů',
    updateTime: 'Aktualizováno: 15. března 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Přijmout vše',
    text: 'Pro zlepšení vašeho zážitku používáme soubory cookie k personalizaci obsahu a služeb.',
    title: 'Tento web používá soubory cookie',
  },
  slogen: 'Nejjednodušší asistent AI',
  stripe: {
    contact: 'V případě jakýchkoliv dotazů nás kontaktujte na contact@noam.tools!',
    fail: {
      btn: 'Zkuste to znovu',
      text1: 'Vaši platbu jsme nemohli zpracovat.',
      text2: 'Zkontrolujte připojení a zkuste to znovu.',
      title: 'Platba se nezdařila',
    },
    succ: { btn: 'Pojďme', text1: 'Vítejte v Noam,', text2: 'pojďme začít!', title: 'Gratuluji' },
  },
  terms: {
    block1: 'Děkujeme, že používáte Noam!',
    block10:
      '(a) Poplatky a fakturace. Všechny poplatky ("Poplatky") zaplatíte podle cen a podmínek na příslušné cenové stránce nebo podle písemné dohody mezi námi. Vyhrazujeme si právo opravit chyby nebo chyby v cenách, i když jsme vystavili faktury nebo přijali platbu. Poskytnete úplné a přesné fakturační údaje, včetně platné a autorizované platební metody. Budeme účtovat vaši platební metodu pravidelně, jak bylo dohodnuto, ale můžeme přiměřeně změnit datum fakturace. Udělujete oprávnění společnosti Noam, jejím přidruženým společnostem a externím zpracovatelům plateb účtovat vaši platební metodu. Pokud se vaše platba nezdaří, zašleme vám písemné oznámení a můžeme pozastavit přístup ke Službě, dokud platbu neobdržíme. Pokud není v této smlouvě uvedeno jinak, platby jsou nevratné.',
    block11:
      '(b) Daně. Pokud není uvedeno jinak, poplatky nezahrnují žádné federální, státní, místní a zahraniční daně, cla a podobné výměry („daně“). Jste odpovědní za všechny daně spojené s vašimi nákupy, s výjimkou daní založených na našem čistém příjmu, který vám budeme fakturovat. Souhlasíte s tím, že neprodleně zaplatíte takové daně a poskytnete nám právně platná prohlášení o ochraně osobních údajů a získáte potřebné souhlasy pro nakládání s takovými údaji, a zaručujete nám, že s takovými údaji nakládáte v souladu s platnými zákony.',
    block12:
      '(c) Změny cen. Můžeme změnit naše ceny tím, že vás o tom budeme informovat prostřednictvím vašeho účtu a/nebo našich webových stránek. Zvýšení cen bude účinné ihned po zveřejnění. Jakékoli změny cen se budou vztahovat na poplatky za váš účet po datu účinnosti změny.',
    block13:
      '(d) Spory a platby po splatnosti. Pokud chcete zpochybnit jakékoli poplatky nebo daně, kontaktujte nás do třiceti (30) dnů od data faktury na contact@noam.tools.',
    block14:
      '(e) Bezplatné použití. Pro získání bezplatných kreditů nemůžete vytvořit více účtů. Pokud zjistíme, že nevyužíváte bezplatné kredity v dobré víře, můžeme vám účtovat standardní poplatky nebo přestat poskytovat přístup ke Službě.',
    block15:
      '(a) Důvěrnost. Můžete mít přístup k důvěrným informacím společnosti Noam. Důvěrné informace můžete používat pouze v souladu s těmito podmínkami pro účely používání Služby. Důvěrné informace nesmíte sdělovat žádné třetí straně a důvěrné informace budete chránit způsobem, který není méně ochranný, než chráníte své vlastní podobné důvěrné informace, alespoň s přiměřenou péčí. Důvěrné informace jsou neveřejné informace, které společnost Noam označila za důvěrné nebo s nimiž by se za daných okolností mělo zacházet jako s důvěrnými, včetně softwaru, specifikací a dalších neveřejných obchodních informací. Důvěrné informace nezahrnují informace, které: (1) se stanou obecně dostupnými veřejnosti bez vašeho zavinění; (2) jste měli v držení bez jakékoli povinnosti zachovávat důvěrnost, než jste jej obdrželi podle těchto podmínek; (3) je vám zákonně sdělena třetí stranou bez jakékoli povinnosti důvěrnosti; nebo (4) jste nezávisle vyvinuli bez použití důvěrných informací. Důvěrné informace můžete prozradit, pokud to vyžaduje zákon, soud nebo jiný vládní příkaz, ale musíte Noam předem písemně upozornit přiměřeným způsobem a v rámci možností vyvinout přiměřené úsilí k omezení rozsahu zveřejnění, včetně pomoci nám. v oponování žádostí o zveřejnění.',
    block16:
      '(b) Zabezpečení. Musíte zavést přiměřená a vhodná opatření, která pomohou zabezpečit váš přístup ke Službě a její používání. Pokud zjistíte jakékoli zranitelnosti nebo porušení související s vaším používáním služby, musíte neprodleně kontaktovat společnost Noam a poskytnout podrobnosti o zranitelnosti nebo porušení.',
    block17:
      '((c) Zpracování osobních údajů. Pokud používáte Službu ke zpracování osobních údajů, musíte poskytnout ze zákona dostatečná prohlášení o ochraně osobních údajů a získat potřebné souhlasy se zpracováním těchto údajů a zaručujete nám, že s takovými údaji nakládáte v souladu s platnými právními předpisy .',
    block18:
      'a) ukončení; Suspenze. Tyto podmínky vstoupí v platnost od vašeho prvního použití služby a zůstanou v platnosti, dokud nebudou ukončeny. Tyto podmínky můžete kdykoli z jakéhokoli důvodu ukončit tím, že přestanete používat službu a obsah. Tyto podmínky můžeme z jakéhokoli důvodu ukončit s předchozím upozorněním. Tyto podmínky můžeme okamžitě ukončit tím, že vás upozorníme, pokud závažným způsobem porušíte oddíl 2 (Požadavky na používání), oddíl 5 (Důvěrnost, bezpečnost a ochrana dat), oddíl 8 (Řešení sporů) nebo oddíl 9 (Všeobecné podmínky), nebo pokud naše vztah s jakýmkoliv poskytovatelem technologií třetí strany mimo naši kontrolu, změny nebo pro soulad se zákonem nebo vládními požadavky. Můžeme pozastavit váš přístup ke Službě, pokud nedodržíte tyto podmínky nebo pokud vaše používání představuje bezpečnostní riziko pro nás nebo jakoukoli třetí stranu, nebo pokud máme podezření, že vaše používání je podvodné nebo může vystavit nás nebo jakoukoli třetí stranu odpovědnosti. .',
    block19:
      '(b) Účinky ukončení. Po ukončení přestanete používat Službu a neprodleně vrátíte nebo zničíte jakékoli důvěrné informace podle našich pokynů. Ustanovení těchto podmínek, která by svou povahou měla přetrvat ukončení nebo vypršení platnosti, včetně, ale bez omezení na oddíly 3 a 5-9, zůstanou v platnosti.',
    block2:
      'Tyto podmínky použití se vztahují na vaše používání služeb společnosti Noam, včetně našich aplikačních programovacích rozhraní, softwaru, nástrojů, vývojářských služeb, dat, dokumentace a webových stránek (souhrnně označovaných jako „služby“). Používáním našich Služeb souhlasíte s dodržováním těchto podmínek. Naše zásady ochrany osobních údajů vysvětlují, jak shromažďujeme a používáme osobní údaje.',
    block20:
      '(a) Odškodnění. Budete nás, naše přidružené společnosti a naše zaměstnance bránit, odškodníte a ochráníte před a proti jakýmkoli nárokům, ztrátám a výdajům (včetně poplatků za právní zastoupení) vyplývajících z vašeho používání služby nebo souvisejících s vaším používáním služby, včetně vašeho obsahu, vašeho používání produktů nebo služeb souvisejících se službou a vaše porušení těchto podmínek nebo platných zákonů.',
    block21:
      '(b) Vyloučení odpovědnosti. Služba je poskytována „tak jak je“. V rozsahu povoleném zákonem my a naše přidružené společnosti a poskytovatelé licencí neposkytujeme žádná prohlášení ani záruky jakéhokoli druhu týkající se služby a neposkytujeme žádné záruky, včetně, ale nejen, záruk prodejnosti, vhodnosti pro konkrétní účel, uspokojivé kvality, neporušení a tiché užívání a jakékoli záruky vyplývající z obchodu nebo používání obchodu. Nezaručujeme, že Služba bude nepřerušovaná, přesná nebo bezchybná, nebo že jakýkoli Obsah bude zabezpečený, neztratí se nebo nezmění.',
    block22:
      '(c) Omezení odpovědnosti. Ani my, naše přidružené společnosti ani naši poskytovatelé licencí neneseme odpovědnost za žádné nepřímé, náhodné, zvláštní, následné nebo represivní škody, včetně ušlého zisku, ztráty pověsti, ztráty použití, ztráty dat nebo jiných nehmotných ztrát, a to ani v případě, že byli jsme upozorněni na možnost takových škod. Podle těchto podmínek naše celková odpovědnost nepřesáhne poplatky, které jste zaplatili za službu během dvanácti (12) měsíců předcházejících nároku, nebo sto amerických dolarů (100 USD), podle toho, která hodnota je vyšší. Omezení v této části platí v maximálním rozsahu povoleném příslušnými zákony.',
    block23:
      'Podmínky a vaše používání Služeb se řídí zákony Spojených států amerických, s výjimkou kolizních norem USA. Vaše používání Aplikace může také podléhat dalším místním, státním, národním nebo mezinárodním zákonům. Jakékoli právní kroky nebo řízení týkající se těchto Podmínek budou vedeny výhradně u soudů Spojených států amerických a souhlasíte s tím, že se podřídíte osobní jurisdikci těchto soudů.',
    block24:
      'a) Vztah smluvních stran. Noam a vy jste nezávislí dodavatelé a žádná ze stran nemá pravomoc zastupovat nebo zavazovat druhou stranu nebo přebírat závazky za druhou stranu bez předchozího písemného souhlasu druhé strany.',
    block25:
      '(b) Používání značky. Bez našeho předchozího písemného souhlasu nesmíte používat jméno, loga nebo ochranné známky společnosti Noam ani žádné z jejích přidružených společností.',
    block26:
      'Pokud bude jakékoli ustanovení těchto podmínek shledáno jako neplatné, nezákonné nebo nevymahatelné, zbývající ustanovení zůstanou v plné platnosti a účinnosti.',
    block3:
      'Pokud je vám méně než 18 let, musíte k používání služby získat povolení od svých rodičů nebo zákonných zástupců. Pokud Službu používáte jménem jiné osoby nebo subjektu, musíte být oprávněni přijmout tyto podmínky jejich jménem. Pro registraci účtu musíte poskytnout přesné a úplné informace. Nesmíte poskytnout své přístupové údaje ani účet nikomu mimo vaši organizaci a nesete odpovědnost za všechny aktivity prováděné pomocí vašich přihlašovacích údajů.',
    block4:
      '(a) Použití služby. Ke Službě můžete přistupovat za těchto podmínek a my vám poskytujeme nevýhradní právo Službu používat. Při používání Služby budete dodržovat tyto podmínky a všechny příslušné zákony. My a naše přidružené společnosti vlastníme všechna práva, tituly a zájmy ve Službě.',
    block5:
      '(b) Zpětná vazba. Uvítáme zpětnou vazbu, připomínky, nápady, návrhy a vylepšení. Pokud nám takový obsah poskytnete, můžeme jej používat bez jakýchkoli omezení a bez kompenzace vůči vám.',
    block6:
      '(c) Omezení. Nesmíte: (1) používat Službu způsobem, který porušuje, zpronevěřuje nebo porušuje jakákoli práva ostatních; (2) zpětně analyzovat, dekompilovat, rozebírat, překládat nebo se jinak pokoušet odhalit zdrojový kód nebo základní součásti modelů, algoritmů a systémů služeb (pokud taková omezení nezakazují platné zákony); (3) využívat výstupy Služby k vývoji modelů konkurujících Noam; (4) extrahovat data nebo výstup ze Služby pomocí jakýchkoli automatizovaných nebo programovacích metod, pokud to není povoleno prostřednictvím API, včetně scrapingu, web harvestingu nebo extrakce webových dat; (5) představovat výstup služby jako uměle vytvořený, když tomu tak není, nebo jinak porušovat naše zásady používání; (6) nakupovat, prodávat nebo převádět API klíče bez našeho předchozího souhlasu; nebo (7) poskytnout nám jakékoli osobní údaje dětí mladších 13 let nebo příslušného věku digitálního souhlasu. Budete dodržovat všechny limity sazeb a další požadavky v naší dokumentaci.',
    block7:
      '(d) Služby třetích stran. Jakýkoli software, služby nebo jiné produkty třetích stran spojené se Službou se řídí jejich vlastními podmínkami a nejsme odpovědní za produkty třetích stran.',
    block8:
      '(a) Váš obsah. Službě můžete poskytnout vstup („Vstup“) a přijímat výstup („Výstup“) generovaný a vracený na základě Vstupu (souhrnně označovaný jako „Obsah“). Mezi stranami a v rozsahu povoleném příslušnými zákony vlastníte veškerý Vstup. Společnost Noam může používat obsah k poskytování a udržování služby, dodržování platných zákonů a prosazování našich zásad. Jste odpovědní za obsah, včetně zajištění toho, že neporušuje žádné platné zákony nebo tyto podmínky.',
    block9:
      '(b) Přesnost. Umělá inteligence a strojové učení jsou rychle se rozvíjející oblasti výzkumu. Neustále se snažíme zlepšovat naše služby, aby byly přesnější, spolehlivější, bezpečnější a přínosnější. Vzhledem k pravděpodobnostní povaze strojového učení může používání naší služby v určitých případech vést k nesprávným výstupům, které nemusí přesně odrážet skutečné osoby, místa nebo fakta. Měli byste náležitě posoudit přesnost jakéhokoli výstupu na základě vašeho případu použití, včetně ruční kontroly výstupů.',
    subTitle1: 'Registrace a přístup',
    subTitle10: 'Oddělitelnost',
    subTitle2: 'Požadavky na použití',
    subTitle3: 'Obsah',
    subTitle4: 'Poplatky a platby',
    subTitle5: 'Důvěrnost, bezpečnost a ochrana dat',
    subTitle6: 'Termín a ukončení',
    subTitle7: 'Odškodnění; Vyloučení odpovědnosti; Omezení odpovědnosti',
    subTitle8: 'Rozhodné právo a jurisdikce',
    subTitle9: 'Všeobecné podmínky',
    title: 'Podmínky služby',
    updateTime: 'Aktualizováno: 15. března 2024',
  },
  trialModal: {
    btn: 'Začněte hned (pouze 0,99 $)',
    chat: 'ChatPDF',
    desc1: 'Po skončení zkušebního období vám bude účtován roční poplatek 180 USD',
    desc2: 'Zrušit kdykoli',
    focus: 'Zaostřování',
    hover: 'Překlad po najetí myší',
    image: 'Překlad obrázků',
    installBtn: 'Nainstalujte nyní a získejte dárek',
    memo: 'Správa oblíbených',
    pdf: 'Paralelní překlad PDF',
    realtime: 'Okamžitý překlad',
    select: 'Překlad výběru slov',
    title: 'Dárek na uvítanou',
    trialText: '7denní zkušební verze',
    video: 'Překlad videa',
    videoSummary: 'Video shrnutí',
    web: 'Paralelní překlad',
    webSummary: 'Webové shrnutí',
  },
  upgradeModal: {
    btn: 'Upgradujte nyní',
    chat: {
      free: 'Dosáhli jste limitu ChatPDF',
      pro: 'Dosáhli jste limitu ChatPDF pro tento měsíc',
    },
    contactStr: 'Počkejte prosím na další reset nebo nás kontaktujte na contact@noam.tools.',
    limitAlertStr: 'Dosáhli jste limitu bezplatného tarifu. Chcete-li pokračovat, upgradujte.',
    more: 'Zobrazit plány',
    pageLimit: { free: 'Dosáhli jste limitu stránek PDF' },
    title: 'Připomínka',
    upgradeAlertStr: 'Dosáhli jste limitu bezplatného tarifu. Chcete-li pokračovat, upgradujte.',
    upload: {
      free: 'Dosáhli jste limitu překladů PDF',
      pro: 'Dosáhli jste limitu překladů PDF pro tento měsíc',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Připojte se nyní',
      label: 'Výhody nového uživatele:',
      remain: 'Zbývá pouze 30',
      text: 'K dispozici pouze 100 míst! Popadněte své hned teď!',
    },
  },
}

export default TRANSLATION
