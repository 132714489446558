const TRANSLATION = {
  buyModal: {
    afterFree: 'Após seu teste gratuito',
    btn: 'Experimente grátis',
    freeTrial: 'Avaliação gratuita de 3 dias',
    monthLabel: 'mês',
    selectLabel: 'Selecione um plano',
    title: 'Plano de assinatura',
    yearLabel: 'ano',
  },
  contact: {
    block1:
      'Para customização, dúvidas ou sugestões, entre em contato conosco pelo e-mail contact@noam.tools. Responderemos prontamente.',
    block2: 'Apoie-nos classificando-nos na loja de extensões do Google Apps em ★★★★★.',
    block3: 'Ajude outras pessoas compartilhando esta extensão:',
    title: 'Contate-nos',
  },
  help: {
    AquickOverviewOfNoam: 'Uma rápida visão geral do Noam',
    ChatPDF: 'Bate-papo em PDF',
    ChatPDF2: 'Bate-papo PDF',
    FavoritesManagement: 'Gerenciamento de Favoritos',
    Fixedplugin: 'Plug-in fixo',
    Focusing: 'Foco',
    Free: 'Livre',
    FreeTrialBenefits: 'Benefícios de avaliação gratuita',
    GoWithWikipedia: 'Vá com a Wikipédia',
    GoWithYouTube: 'Vá com o YouTube',
    HoverTranslation: 'Tradução flutuante',
    Howtouse: 'Como usar',
    ImageTranslation: 'Tradução de imagens',
    InstantTranslation: 'Tradução Instantânea',
    Membership: 'Associação',
    NoamHelp: 'Noam ajuda você a traduzir e resumir',
    PDFparallelTranslation: 'Tradução Paralela de PDF',
    ParallelTranslation: 'Tradução Paralela',
    SkipAllSteps: 'Pular todas as etapas',
    Summary: 'Resumo',
    Translation: 'Tradução',
    TryItForFree: 'Experimente gratuitamente',
    Video: 'Vídeo',
    VideoSummary: 'Resumo do vídeo',
    VideoTranslation: 'Tradução de vídeo',
    WebSummary: 'Resumo da Web',
    WordSelectionTranslation: 'Tradução de seleção de palavras',
    benefits: 'Temos benefícios esperando por você!',
    dayFree: 'Avaliação gratuita de 3 dias',
    done: 'Feito',
    next: 'Próximo',
    pin: 'Pin Noam para acesso mais fácil ～～',
    planIsNotExist: 'O plano não está disponível',
    startWiki: 'Vamos começar com a Wikimedia!',
    startYoutube: 'Vamos começar com o YouTube!',
    try: 'Experimente',
    tryNow: 'Experimente agora!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tradução Bilíngue + Chat PDF',
      sub1: {
        desc: 'A leitura bilíngue permite comparar o texto original e a tradução lado a lado, melhorando a eficiência da leitura.',
        title: 'Leitura Bilíngue',
      },
      sub2: {
        desc: 'A IA analisa e resume e pode responder às suas perguntas com base no conteúdo do PDF.',
        title: 'Bate-papo em PDF',
      },
      sub3: {
        desc: 'Tradução de PDF com tecnologia de IA para maior precisão e eficiência.',
        title: 'Precisão e eficiência',
      },
      title: 'Tradução de PDF',
    },
    aiAssistant: {
      desc: 'Tornando a tradução da Web mais inteligente e conveniente',
      panel1: {
        desc: 'As traduções bilíngues com referências cruzadas ajudam você a comparar o texto original com a tradução para que você possa entender melhor e melhorar sua eficiência de leitura.',
        title: 'Tradução Bilíngue',
      },
      panel2: {
        left: {
          desc: 'Ao navegar em uma página da web, passe o mouse sobre o conteúdo que você precisa traduzir para ver a tradução.',
          title: 'Tradução flutuante',
        },
        right: {
          desc: 'Ao navegar em uma página da web, selecione o texto que deseja traduzir para ver a tradução.',
          title: 'Tradução de seleção de texto',
        },
      },
      panel3: {
        one: {
          desc: 'Traduza e resuma páginas da web e conteúdo de vídeo a qualquer hora, em qualquer lugar, para uma experiência mais tranquila!',
          title: 'Aponte e clique',
        },
        three: {
          desc: 'O modelo de linguagem grande orientado por IA garante precisão e consistência da tradução para uma experiência de leitura aprimorada.',
          title: 'Orientado por IA',
        },
        two: {
          desc: 'Cobertura completa em vários idiomas, leitura bilíngue sem barreiras, tradução com um clique - experimente a diferença!',
          title: 'Mais abrangente e conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Seu especialista em eficiência - usando o ChatGPT, Noam traduz e resume o conteúdo de vídeo do YouTube para economizar seu tempo!',
          key: 'Vídeo',
          title: 'Tradução e resumo de vídeo',
        },
        tab2: {
          desc: 'Clique para traduzir imagens enquanto navega na web.',
          key: 'Imagem',
          title: 'Tradução de imagens',
        },
        tab3: {
          desc: 'Extrai instantaneamente informações importantes de páginas da web e fornece um resumo, melhorando sua eficiência de leitura.',
          key: 'Rede',
          title: 'Tradução e Resumo da Web',
        },
        tag1: 'Simples',
        tag2: 'Eficiente',
        tag3: 'Fácil de entender',
      },
      title: 'Assistente de IA',
    },
    getStart: 'Comece',
    getStartedForFree: 'Comece gratuitamente',
    howItWork: {
      desc: 'Noam integra modelos de IA para fornecer aos usuários uma experiência inteligente para tradução e resumo de páginas da web em vários idiomas, tradução de PDF e imagens, tradução e resumo de vídeos',
      title: 'Como funciona',
    },
    lastBlock: { desc: 'Melhor tradução de IA para uma experiência de leitura mais tranquila' },
    top: {
      desc: 'Noam é uma extensão gratuita de IA para navegador que ajuda você a traduzir e resumir páginas da web, vídeos e conteúdo em PDF.\\n Junte-se ao Noam e experimente a magia!',
      title1: 'Uma magia',
      title2: 'Um clique',
    },
    userReviews: {
      desc: 'Avaliações cinco estrelas na Chrome Web Store',
      title: 'Avaliações de usuários',
    },
  },
  installTips: {
    btn: 'Experimente agora',
    text: 'Instale o "Noam Browser Plugin" para desbloquear mais recursos! Referência cruzada na Web,\\n resumo de vídeos na Web e muito mais!',
  },
  locale: {
    af: 'afrikaans',
    am: 'amárico',
    ar: 'árabe',
    as: 'Assamês',
    auto: 'Detecção automática',
    az: 'Azerbaijano',
    ba: 'Basquir',
    bg: 'búlgaro',
    bn: 'Bangla',
    bo: 'tibetano',
    bs: 'Bósnio',
    ca: 'catalão',
    cs: 'Tcheco',
    cy: 'galês',
    da: 'dinamarquês',
    de: 'Alemão',
    dsb: 'Baixo Sórábio',
    dv: 'Divehi',
    el: 'grego',
    en: 'Inglês',
    es: 'Espanhol',
    et: 'estoniano',
    eu: 'Basco',
    fa: 'persa',
    fi: 'finlandês',
    fil: 'filipino',
    fj: 'Fijiano',
    fr: 'Francês',
    frCA: 'Francês (Canadá)',
    ga: 'irlandês',
    gl: 'Galego',
    gom: 'concani',
    gu: 'Guzerate',
    ha: 'Hauçá',
    he: 'hebraico',
    hi: 'hindi',
    hr: 'croata',
    hsb: 'Alto Sorbio',
    ht: 'crioulo haitiano',
    hu: 'húngaro',
    hy: 'Armênio',
    id: 'indonésio',
    ig: 'Ibo',
    ikt: 'Inuinnaqtun',
    is: 'islandês',
    it: 'italiano',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latim)',
    ja: 'japonês',
    ka: 'Georgiano',
    kk: 'Cazaque',
    km: 'Khmer',
    kmr: 'Curdo (Norte)',
    kn: 'Kannada',
    ko: 'coreano',
    ku: 'Curdo (Central)',
    ky: 'Quirguistão',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letão',
    lzh: 'Chinês (Literário)',
    mai: 'Maithili',
    mg: 'malgaxe',
    mi: 'Maori',
    mk: 'Macedônio',
    ml: 'Malaiala',
    mnCyrl: 'Mongol (cirílico)',
    mnMong: 'Mongol (tradicional)',
    mr: 'Marathi',
    ms: 'malaio',
    mt: 'maltês',
    mww: 'Hmong Daw',
    my: 'Mianmar (birmanês)',
    nb: 'norueguês',
    ne: 'nepalês',
    nl: 'Holandês',
    noSearchLang: 'Idioma não suportado',
    nso: 'Sesoto em Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'punjabi',
    pl: 'polonês',
    placeholder: 'Procurar',
    prs: 'Dari',
    ps: 'pashto',
    pt: 'Português (Brasil)',
    ptPT: 'Português (Portugal)',
    ro: 'romeno',
    ru: 'russo',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindi',
    si: 'Cingalês',
    sk: 'Eslovaco',
    sl: 'esloveno',
    sm: 'samoano',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanês',
    srCyrl: 'Sérvio (cirílico)',
    srLatn: 'Sérvio (latim)',
    st: 'Sesoto',
    sv: 'sueco',
    sw: 'suaíli',
    ta: 'tâmil',
    te: 'Telugu',
    th: 'Tailandês',
    ti: 'Tigrínia',
    tk: 'turcomano',
    tlhLatn: 'Klingon (latim)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setsuana',
    to: 'tonganês',
    tr: 'turco',
    tt: 'Tártaro',
    ty: 'taitiano',
    ug: 'Uigur',
    uk: 'ucraniano',
    ur: 'urdu',
    uz: 'Uzbeque (latim)',
    vi: 'vietnamita',
    xh: 'Xhosa',
    yo: 'Iorubá',
    yua: 'Yucateca maia',
    yue: 'Cantonês (tradicional)',
    zh: 'chinês',
    zhHans: 'Chinês Simplificado',
    zhHant: 'Chinês Tradicional',
    zu: 'zulu',
  },
  login: {
    and: 'e',
    btn: 'Faça login com o Google',
    btn1: 'Faça login agora',
    desc: 'Faça login para desbloquear mais experiências',
    policy: 'política de Privacidade',
    terms: 'Termos de Uso',
    tips: 'Ao me inscrever, concordo com os',
    title: 'Bem vindo ao Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguir', twitter: 'Twitter' },
    legal: { policy: 'política de Privacidade', terms: 'Termos de Serviço', title: 'Jurídico' },
    product: {
      image: 'Tradução de imagens',
      pdf: 'Tradução de PDF',
      title: 'Produtos',
      video: 'Tradução e resumo de vídeo',
      web: 'Tradução e Resumo da Web',
    },
    resources: { title: 'Recursos' },
  },
  pageHeader: {
    contact: 'Contato',
    help: 'Ajuda',
    logout: 'Sair',
    logoutSucc: 'Desconectado.',
    pricing: 'Preços',
    settings: 'Configurações',
    signUp: 'Conecte-se',
  },
  pdf: {
    autoDetect: 'Detecção automática',
    cancelBtn: 'Cancelar',
    cancelUpload: 'Cancelar',
    chatPdf: {
      Search: 'Procurar',
      cancel: 'Cancelar',
      chatTitle: 'Bem-vindo ao Noam! Aqui está um resumo do seu PDF:',
      clear: 'Claro',
      clearContent:
        'Isso iniciará uma nova conversa. A conversa atual não será salva. Tem certeza?',
      clearDialogTitle: 'Por favor confirme',
      clearSucc: 'Desmarcado',
      copy: 'Cópia',
      copySucc: 'Copiado',
      delete: 'Excluir',
      deleteCancel: 'Cancelar',
      deleteContent: 'Este PDF não pode ser recuperado depois de excluído. Tem certeza?',
      deleteOk: 'Excluir',
      deleteSuccess: 'Excluído',
      deleteTitle: 'Confirmar exclusão',
      expand: 'Colapso',
      historyTitle: 'História',
      listError:
        'Falha ao carregar a lista de leitura. Tente novamente mais tarde ou entre em contato com o suporte.',
      loading: 'Carregando...',
      noDataDesc: 'Ainda não há história.',
      noMore: 'Não há mais conteúdo.',
      placeholder: 'Pergunte qualquer coisa sobre este PDF',
      summaryTitle: 'Você pode estar interessado nestas questões:',
    },
    collectionEmpty: 'Sua coleção está vazia.',
    collectionTab: 'Notas',
    dataLoading: 'Carregando...',
    dialogContent: 'Tem certeza de que deseja excluir isto?',
    dialogTitle: 'Por favor confirme',
    download: {
      both: 'Bilíngue',
      btn: 'Salvar',
      cancel: 'Fechar esta janela cancelará o download do PDF. Tem certeza de que deseja cancelar?',
      desc: 'Salvar no dispositivo: baixa traduções como imagens; Salvar e Imprimir: Baixa traduções no formato original, com suporte para cópia.',
      download: 'Download',
      downloadMsg1: 'Progresso da tradução de PDF:',
      downloadMsg2: 'Tempo restante estimado:',
      downloadSucc: 'O PDF está pronto! Clique para salvar.',
      downloadTip: 'Baixando PDF. Não feche esta janela para evitar erros de download.',
      error: 'Falha ao salvar. Tente imprimir.',
      fileName: 'Nome do arquivo:',
      fileType: 'Tipo de arquivo:',
      loading: 'Salvando...',
      loading2: 'Carregando...',
      minute: 'Minutos...',
      msg1: 'Algumas traduções neste documento são mais longas que o texto original e podem se sobrepor no arquivo baixado. Recomendamos a leitura na página web.',
      msg2: '1. A tradução ainda não foi concluída. Faça o download após a conclusão da tradução.',
      msg3: '2. Algumas traduções são muito longas. Leia na página da web para evitar sobreposição de texto.',
      msg4: 'As tarefas de tradução estão em andamento. Por favor, tente novamente depois que eles forem concluídos.',
      progress:
        'Traduzindo PDF. Progresso: {{progress}}, Tempo restante estimado: {{minutos}} minutos...',
      save: 'Salvar no dispositivo',
      savePrint: 'Salvar e imprimir',
      success: 'Baixado',
      trans: 'Somente tradução',
      transContinue: 'Continuar traduzindo',
    },
    downloadBtn: 'Download',
    dragTipModal: {
      black: 'espaço',
      leftKey: 'botão esquerdo',
      ok: 'Entendi',
      press: 'Imprensa',
      tips: 'para arrastar e visualizar.',
    },
    entry: 'PDFs',
    fileErrMsg:
      'Falha na leitura do arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    fileMsg: 'Tamanho atual do arquivo:',
    fileMsg2: 'Faça upload de um arquivo menor que 300 MB.',
    freeBannerBtn: 'Atualizar',
    freeBannerTips: 'Limite de traduções gratuitas de PDF atingido',
    guide: {
      placeholder: 'Cole o link do PDF aqui',
      start: 'Comece a traduzir',
      text1: 'Leitura Bilíngue',
      text2:
        'Leia bilíngue para comparar o texto original e a tradução lado a lado, melhorando sua eficiência.',
      text3: 'Converse com seu PDF',
      text4:
        'Análise e resumo alimentados por IA. Faça perguntas sobre o seu PDF e obtenha respostas instantâneas.',
      text5: 'Preciso e Eficiente',
      text6: 'Tradução de PDF com tecnologia de IA para maior precisão e eficiência.',
      title1: 'Tradução Bilíngue',
      title2: 'Converse com seu PDF',
      toast: 'Insira um link de PDF on-line válido que termine em .pdf',
    },
    hasTransTask: 'Uma tarefa de tradução está em andamento. Por favor, aguarde.',
    hoverTrans: 'Passe o mouse para traduzir parágrafos',
    initial: 'Traduzir PDF',
    installText: 'Sua solução completa para traduções do dia a dia.',
    installText2: 'Instale a extensão primeiro',
    installTips:
      'Instale a extensão para obter ainda mais traduções de PDF e traduções de sites mais fáceis:',
    kw: 'grampo',
    link: 'link',
    listError:
      'Falha ao carregar PDFs. Tente novamente mais tarde ou entre em contato com o suporte.',
    memoTotalCount: '1 item no total',
    new: 'novo arquivo',
    noId: 'ID do arquivo não encontrado.',
    notPdf: 'Somente arquivos PDF.',
    okBtn: 'Excluir',
    okBtn2: 'Entendi',
    original: 'Original',
    parallel: 'Paralelo',
    parseErrDialogContent:
      'Este link do PDF não pode ser traduzido diretamente. Baixe o PDF e carregue-o para tradução.',
    parseErrTips: 'Falha ao analisar o arquivo',
    parseFileToast:
      'Falha ao analisar o arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    pdfLoading: 'Análise...',
    searchPlaceholder: 'Procurar',
    selectTrans: 'Selecione o texto para traduzir',
    shareBtn: 'Compartilhar',
    shareBtnText1: 'Gerar link de compartilhamento',
    shareBtnText2: 'Atualizar conteúdo compartilhado',
    shareModal: {
      cancel: 'Cancelar',
      copy: 'Cópia',
      copySucc: 'Link copiado',
      custom: 'Personalizar:',
      errMsg:
        'O conteúdo selecionado ainda não foi totalmente traduzido. Por favor traduza antes de compartilhar.',
      gen: 'Compartilhar',
      modify: 'Editar',
      notSupport: 'Este idioma não é suportado atualmente.',
      page: 'Páginas',
      pageNuErr: 'Insira um número de página válido.',
      range: 'Escopo:',
      remain: 'restante',
      remain1: 'Tempo total estimado:',
      search: 'Procurar',
      settings: 'Configurações de compartilhamento:',
      shareSetting: 'Configurações de compartilhamento',
      shareText: 'Compartilhar',
      shareTips: 'Compartilhe o link do PDF traduzido com seus amigos:',
      sourceLang: 'De:',
      target: 'Para:',
      text1: 'Configurações de compartilhamento',
      text2: 'ERROR: [Generate Share Link]',
      totalPage: '1 página no total',
      trans: 'Traduzir',
      translateTips:
        'Dica: Se a tradução estiver demorando, você pode fechar esta janela e compartilhar mais tarde.',
      translating: 'Traduzindo PDF...',
      update: 'Atualizar link de compartilhamento',
    },
    shareMsg1: 'Link de compartilhamento gerado.',
    shareMsg2: 'Conteúdo compartilhado atualizado.',
    shareMsg3:
      'Falha ao gerar link de compartilhamento. Tente novamente mais tarde ou entre em contato com o suporte.',
    shareMsg4:
      'Falha ao atualizar o conteúdo compartilhado. Tente novamente mais tarde ou entre em contato com o suporte.',
    shareTips: 'Sua tradução de PDF está pronta! Compartilhe com seus amigos.',
    start: 'Comece seu teste gratuito',
    thumbnail: 'Índice',
    toast1: 'Carregando PDF. Por favor, tente novamente mais tarde.',
    toast2: 'O conteúdo do PDF é muito grande. A caixa de diálogo não é suportada no momento.',
    toast3: 'Baixando. Por favor, tente novamente após a conclusão do download.',
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    trans: 'Tradução',
    transSucc: 'Tradução bem-sucedida!',
    unLoginToast: 'Faça login para usar este recurso.',
    uploadBtn: 'Carregar',
    uploadErr:
      'Falha no upload do arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    uploadMsg1: 'Arraste e solte seu arquivo aqui.',
    uploadMsg2: 'Tipos de arquivos suportados: PDF |  Tamanho máximo do arquivo: 100 MB',
    uploadMsg3: 'Clique para selecionar ou arrastar e soltar arquivos aqui.',
    uploading: 'Enviando',
    uploadingMsg: 'Carregando. Por favor, aguarde.',
    uploadingMsg1: 'O arquivo está sendo carregado. Por favor, tente novamente mais tarde.',
    uploadingMsg2: 'Um arquivo está sendo convertido. Por favor, tente novamente mais tarde.',
    uploadingMsg3:
      'Falha na solicitação de andamento da conversão. Tente novamente mais tarde ou entre em contato com o suporte.',
    uploadingMsg4:
      'Noam não consegue acessar arquivos locais. Carregue manualmente um PDF para tradução.',
    uploadingMsg5:
      'Falha ao recuperar o URL do arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    video: 'vídeo',
    vipBannerBtn: 'Atualizar',
    vipBannerTips: 'Você atingiu seu limite de tradução de PDF para este mês.',
    webTrans: 'Referência Cruzada Completa',
  },
  pricing: {
    FAQ: 'Perguntas frequentes',
    FQAObj: {
      a1: 'Sim, Noam oferece um plano gratuito que você pode usar imediatamente.',
      a2: 'Oferecemos três planos de associação: Pro, Pro+ e Enterprise. Você pode optar por pagar mensalmente ou anualmente. O faturamento anual oferece economias significativas.',
      a3: 'Todos os recursos estão disponíveis em ambos os planos. A principal diferença é o custo: as assinaturas anuais custam menos no geral. As assinaturas mensais são cobradas mensalmente, enquanto os planos anuais são cobrados uma vez por ano.',
      a4: 'Você pode cancelar sua assinatura a qualquer momento nas configurações da sua conta.',
      q1: 'Existe um plano gratuito?',
      q2: 'Quais planos de preços e opções de pagamento estão disponíveis?',
      q3: 'Qual é a diferença entre assinatura mensal e anual?',
      q4: 'Como cancelo minha assinatura?',
    },
    around: 'Em volta',
    billedMonthly: 'Faturado Mensalmente',
    billedYearly: 'Faturado anualmente',
    day: 'dia',
    desc: 'Compare planos e escolha o melhor',
    detailedCompare: 'Comparar planos',
    discount: '20% de desconto',
    enterprise: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: 'Vários usuários',
      benefit3: 'Soluções sob medida',
      benefit5: 'Gerente de contas dedicado',
      btn: 'Contate-nos',
      recommendText: 'Planos personalizados（≥3人）',
      talkToUs: 'Entre em contato',
    },
    free: {
      benefit1: 'Tradução instantânea 100 vezes/dia',
      benefit2: 'Tradução e resumo do site',
      benefit3: '30 traduções de imagens',
      benefit4: 'Tradução e resumo de vídeo',
      benefit5: '10 traduções de PDF',
      benefit6: '5.000 fichas',
      btn: 'Experimente grátis',
      recommendText: 'Avaliação Gratuita',
    },
    imageTranslation: 'Tradução de imagens',
    month: 'mês',
    monthly: 'Mensal',
    pageTitle: 'Preços',
    pdf: {
      chat: 'Consultas de bate-papo em PDF',
      maxPage: 'Máx. Páginas por PDF',
      translation: 'Tradução de PDF',
    },
    preMonth: 'Por mês',
    pro: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: '1.000.000 tokens/mês',
      benefit3: 'Tradução e resumo do site',
      benefit4: '200 traduções de imagens',
      benefit5: 'Tradução e resumo de vídeo',
      benefit6: '200 traduções de PDF',
      btn: 'Comece',
      recommendText: 'Mais populares',
    },
    proPlus: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: '3.000.000 tokens/mês',
      benefit3: 'Tradução e resumo do site',
      benefit4: '500 traduções de imagens',
      benefit5: 'Tradução e resumo de vídeo',
      benefit6: '500 traduções de PDF',
      recommendText: 'Melhor valor',
    },
    video: { summary: 'Resumo do vídeo', translation: 'Tradução de vídeo' },
    web: {
      contrastiveTranslation: 'Tradução Bilíngue',
      dictionaryTranslation: 'Tradução de Seleção',
      hoverTranslation: 'Tradução flutuante',
      instantTranslation: 'Tradução Instantânea',
      summary: 'Resumo da página da web',
    },
    yearly: 'Anual',
  },
  privacy: {
    block1:
      'Noam ("Noam", "nós", "nosso" ou "nos") respeita sua privacidade e está comprometido em proteger qualquer informação que obtivermos de você ou sobre você. Esta Política de Privacidade descreve nossas práticas em relação à coleta ou uso de informações pessoais provenientes do uso de nosso site, aplicativos e serviços (coletivamente, os “Serviços”). Esta Política de Privacidade não se aplica ao conteúdo que tratamos em nome de nossos clientes de produtos empresariais, como nossa API. A utilização destes dados está sujeita ao nosso contrato de cliente, que abrange o acesso e utilização destes produtos.',
    block10:
      'Implementamos medidas técnicas, administrativas e organizacionais comercialmente razoáveis ​​para proteger informações pessoais online e offline contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou atos hostis. No entanto, nenhuma transmissão pela Internet ou por e-mail pode garantir total segurança ou precisão. Em particular, os e-mails enviados para nós ou por nós podem não ser seguros. Portanto, você deve ter especial cuidado ao decidir quais informações nos enviar através do serviço ou e-mail. Além disso, não somos responsáveis ​​por qualquer obstrução das configurações de privacidade ou medidas de segurança no serviço ou nas configurações ou medidas de segurança de sites de terceiros. Reteremos suas informações pessoais pelo tempo necessário para lhe fornecermos serviços ou para outros fins comerciais legítimos, como resolução de disputas, motivos de segurança e proteção ou cumprimento de nossas obrigações legais. O período de tempo que retemos as informações pessoais dependerá de muitos fatores, como a quantidade, a natureza, a sensibilidade das informações, os riscos potenciais de uso ou divulgação não autorizada, as finalidades para as quais processamos as informações e outras considerações juridicamente vinculativas.',
    block11:
      'Poderemos atualizar esta Política de Privacidade de tempos em tempos. Quando o fizermos, publicaremos uma versão atualizada nesta página, salvo exigência em contrário pela lei aplicável.',
    block12:
      'Se você tiver alguma dúvida ou preocupação não respondida sobre esta Política de Privacidade, entre em contato com nosso atendimento ao cliente. E-mail:',
    block2:
      'Coletamos as seguintes informações pessoais (“Informações Pessoais”) relacionadas a você:',
    block3:
      'Informações fornecidas por você: Quando você cria uma conta para usar nossos serviços ou se comunicar conosco, coletamos informações pessoais da seguinte forma:',
    block4:
      'Informações Pessoais Recebidas Automaticamente pelo Seu Uso dos Serviços: Quando você acessa, usa ou interage com os serviços, recebemos as seguintes informações sobre seu acesso, uso ou interação ("Informações Técnicas"):',
    block5: 'Podemos usar informações pessoais para os seguintes fins:',
    block6:
      'Informações resumidas ou anonimizadas: podemos agregar ou desidentificar informações pessoais para evitar sua reutilização para fins de identificação e usar essas informações para analisar a eficácia de nossos serviços, melhorar e adicionar recursos a nossos serviços, realizar pesquisas e para fins semelhantes. . Além disso, podemos analisar o comportamento geral e as características dos usuários de nossos serviços e compartilhar informações agregadas com terceiros, publicar essas informações agregadas ou disponibilizá-las ao público geral. Poderemos coletar informações agregadas por meio dos serviços, de cookies e de outros meios descritos nesta Política de Privacidade. Manteremos e usaremos informações anonimizadas de forma anônima ou não identificada e não tentaremos reidentificar tais informações, a menos que exigido por lei.',
    block7:
      'Em certos casos, poderemos fornecer as suas informações pessoais a terceiros sem aviso prévio, a menos que exigido por lei:',
    block8:
      'Dependendo da sua localização geográfica, os indivíduos no Espaço Económico Europeu, no Reino Unido e em todo o mundo podem ter determinados direitos legais relativamente às suas informações pessoais. Por exemplo, você pode ter o direito de:',
    block9:
      'O serviço pode conter links para outros sites não operados ou controlados pela Noam, incluindo serviços de mídia social (“Sites de Terceiros”). As informações que você compartilha com sites de terceiros serão regidas pelas políticas de privacidade e termos de serviço específicos dos sites de terceiros, e não por esta Política de Privacidade. Fornecemos esses links por conveniência e não implicam nosso endosso ou análise desses sites. Entre em contato diretamente com sites de terceiros para obter informações sobre suas políticas e termos de privacidade.',
    subBlock1:
      'Informações da conta: Quando você cria uma conta em nossa plataforma, coletamos informações relacionadas à sua conta, incluindo seu nome, informações de contato, credenciais da conta, informações do cartão de pagamento e histórico de transações (“Informações da conta”).',
    subBlock10: 'Fornecer, gerenciar, manter e/ou analisar serviços;',
    subBlock11: 'Comunicando com você;',
    subBlock12: 'Desenvolvimento de novos produtos e serviços;',
    subBlock13:
      'Prevenir fraudes, atividades criminosas ou uso indevido de nossos serviços e proteger a segurança de nossos sistemas de TI, arquitetura e redes;',
    subBlock14: 'Realização de transferências comerciais;',
    subBlock15:
      'Cumprir obrigações legais e procedimentos legais, protegendo nossos direitos, privacidade, segurança ou propriedade, bem como os de nossas afiliadas, de você ou de outros terceiros.',
    subBlock16:
      'Fornecedores e prestadores de serviços: Para nos ajudar a atender às necessidades operacionais de negócios e a executar determinados serviços e funções, podemos fornecer informações pessoais a fornecedores e prestadores de serviços, incluindo prestadores de serviços de hospedagem, prestadores de serviços em nuvem e outros prestadores de serviços de tecnologia da informação, software de comunicação por e-mail e provedores de serviços de análise da web, etc. Esses parceiros acessarão, processarão ou armazenarão informações pessoais somente sob nossa orientação enquanto desempenham suas funções para nós.',
    subBlock17:
      'Transferências de Negócios: Se nos envolvermos em transações estratégicas, reorganizações, falências, aquisições de falência ou transição de serviços para outro fornecedor (coletivamente, "Transações"), suas informações pessoais e outras informações poderão ser compartilhadas com contrapartes da transação e outras partes que auxiliam nas Transações durante a devida diligência e transferido como parte das Transações juntamente com outros ativos para um sucessor ou empresa afiliada.',
    subBlock18:
      'Requisitos legais: Podemos compartilhar suas informações pessoais com autoridades governamentais, pares do setor ou outros terceiros, incluindo suas informações de interação com nossos serviços, (1) se exigido por lei ou se acreditarmos que tal ação é necessária para cumprir obrigações legais; (2) para proteger e defender nossos direitos ou propriedade; (3) se determinarmos unilateralmente que há uma violação dos nossos termos, políticas ou leis; (4) para detectar ou prevenir fraudes ou outras atividades ilegais; (5) para proteger nossos produtos, funcionários ou usuários, ou segurança pública, proteção e integridade; ou (vi) para se defender contra responsabilidade legal.',
    subBlock19: 'Acesse suas informações pessoais e informações sobre como elas são processadas.',
    subBlock2:
      'Conteúdo do usuário: quando você usa nossos serviços, coletamos informações pessoais de suas entradas, uploads de arquivos ou feedback que nos é fornecido ("Conteúdo").',
    subBlock20: 'Solicite a exclusão de suas informações pessoais de nossos registros.',
    subBlock21: 'Corrija ou atualize suas informações pessoais.',
    subBlock22: 'Transferir suas informações pessoais para terceiros (portabilidade de dados).',
    subBlock23: 'Limite a forma como processamos suas informações pessoais.',
    subBlock24:
      'Retirar o seu consentimento – quando basearmos o consentimento como base legal para o processamento, você poderá retirar o seu consentimento a qualquer momento.',
    subBlock25: 'Opor-se à forma como processamos suas informações pessoais.',
    subBlock26: 'Melhorar nossos serviços e realizar pesquisas;',
    subBlock3:
      'Informações de comunicação: Se você nos enviar mensagens, coletaremos seu nome, informações de contato e o conteúdo de suas mensagens (“Informações de comunicação”).',
    subBlock4:
      'Informações de mídia social: temos páginas em sites de mídia social como Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando você interage com nossas páginas de mídia social, coletamos as informações pessoais que você escolhe nos fornecer, como seus dados de contato (“Informações Sociais”). Além disso, as empresas que hospedam as nossas páginas nas redes sociais podem nos fornecer informações agregadas e análises sobre as nossas atividades nas redes sociais.',
    subBlock5:
      'Dados de registro: Informações enviadas automaticamente pelo seu navegador ao utilizar nossos serviços. Os dados de registro incluem seu endereço de protocolo de Internet (IP), tipo e configurações do navegador, a data e hora de suas solicitações e como você interage com nosso site.',
    subBlock6:
      'Dados de uso: Podemos coletar automaticamente informações sobre seu uso do serviço, como os tipos de conteúdo que você visualiza ou interage, os recursos que você usa, as ações que você realiza, bem como seu fuso horário, país, data e hora de acesso, agente e versão do usuário, tipo de computador ou dispositivo móvel e conexão do computador.',
    subBlock7:
      'Informações do dispositivo: incluem o nome do dispositivo, o sistema operacional, o identificador do dispositivo e o navegador que você está usando. As informações coletadas podem depender do tipo de dispositivo que você está usando e de suas configurações.',
    subBlock8:
      'Cookies: Utilizamos cookies para operar e gerenciar nossos serviços e melhorar sua experiência. Um “cookie” é uma informação enviada por um site que você visita para o seu navegador. Você pode configurar seu navegador para aceitar todos os cookies, rejeitar todos os cookies ou notificá-lo quando um cookie for fornecido, para que você possa decidir se deseja aceitá-lo sempre. No entanto, recusar cookies pode impedir a utilização ou afetar negativamente a visualização ou funcionalidade de determinadas áreas ou funcionalidades do website. Para obter mais informações sobre cookies, visite Tudo sobre Cookies.',
    subBlock9:
      'Análise: Podemos utilizar vários produtos de análise online que utilizam cookies para nos ajudar a analisar como os utilizadores utilizam os nossos serviços e melhorar a sua experiência ao utilizar os serviços.',
    subTitle1: 'Informações pessoais que coletamos',
    subTitle2: 'Como usamos informações pessoais',
    subTitle3: 'Divulgação de informações pessoais',
    subTitle4: 'Seus direitos',
    subTitle5: 'Links para outros sites',
    subTitle6: 'Segurança e retenção de informações',
    subTitle7: 'Modificação da Política de Privacidade',
    subTitle8: 'Como entrar em contato conosco',
    title: 'política de Privacidade',
    updateTime: 'Atualizado: 15 de março de 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Aceitar tudo',
    text: 'Para melhorar a sua experiência, utilizamos cookies para personalizar conteúdos e serviços.',
    title: 'Este site usa cookies',
  },
  slogen: 'O assistente de IA mais fácil',
  stripe: {
    contact: 'Contate-nos em contact@noam.tools com qualquer dúvida!',
    fail: {
      btn: 'Tentar novamente',
      text1: 'Não foi possível processar seu pagamento.',
      text2: 'Verifique sua conexão e tente novamente.',
      title: 'Falha no pagamento',
    },
    succ: { btn: 'Vamos', text1: 'Bem-vindo ao Noam,', text2: 'vamos começar!', title: 'Parabéns' },
  },
  terms: {
    block1: 'Obrigado por usar Noam!',
    block10:
      '(a) Taxas e cobrança. Você pagará todas as taxas ("Taxas") de acordo com os preços e termos na página de preços aplicável ou conforme acordado por escrito entre nós. Reservamo-nos o direito de corrigir erros ou erros de preços, mesmo que tenhamos emitido faturas ou recebido pagamentos. Você fornecerá informações de cobrança completas e precisas, incluindo um método de pagamento válido e autorizado. Cobraremos sua forma de pagamento periodicamente conforme acordado, mas poderemos alterar razoavelmente a data de cobrança. Você autoriza a Noam, suas afiliadas e processadores de pagamento terceirizados a cobrar sua forma de pagamento. Se o seu pagamento falhar, iremos notificá-lo por escrito e poderemos suspender o acesso ao Serviço até que o pagamento seja recebido. Exceto quando especificado de outra forma neste contrato, os pagamentos não são reembolsáveis.',
    block11:
      '(b) Impostos. Salvo indicação em contrário, as Taxas não incluem quaisquer impostos, taxas e avaliações semelhantes federais, estaduais, locais e estrangeiras ("Impostos"). Você é responsável por todos os Impostos associados às suas compras, excluindo os Impostos baseados em nosso lucro líquido, pelos quais lhe faturaremos. Você concorda em pagar prontamente tais Impostos e nos fornecer declarações de privacidade legalmente válidas e obter os consentimentos necessários para o tratamento de tais dados, e você nos garante que trata tais dados de acordo com a lei aplicável.',
    block12:
      '(c) Mudanças de Preço. Podemos alterar nossos preços notificando você através de sua conta e/ou nosso site. Os aumentos de preços entrarão em vigor imediatamente após a publicação. Quaisquer alterações de preço serão aplicadas às taxas da sua conta após a data efetiva da alteração.',
    block13:
      '(d) Disputas e Pagamentos Atrasados. Se desejar contestar quaisquer Taxas ou Impostos, entre em contato com contact@noam.tools dentro de trinta (30) dias a partir da data da fatura.',
    block14:
      '(e) Uso Gratuito. Você não pode criar várias contas para obter créditos gratuitos. Se determinarmos que você não está usando os créditos gratuitos de boa fé, poderemos cobrar taxas padrão ou parar de fornecer acesso ao Serviço.',
    block15:
      '(a) Confidencialidade. Você pode ter acesso a informações confidenciais da Noam. Você só poderá usar informações confidenciais conforme permitido por estes termos para fins de uso do Serviço. Você não poderá divulgar informações confidenciais a terceiros e protegerá as informações confidenciais de maneira não menos protetora do que protege suas próprias informações confidenciais semelhantes, pelo menos com cuidado razoável. Informações confidenciais são informações não públicas designadas como confidenciais pela Noam ou que deveriam ser razoavelmente tratadas como confidenciais sob as circunstâncias, incluindo software, especificações e outras informações comerciais não públicas. As informações confidenciais não incluem informações que: (1) se tornem geralmente disponíveis ao público sem culpa de sua parte; (2) você o tinha em sua posse sem qualquer obrigação de confidencialidade antes de recebê-lo sob estes termos; (3) for legalmente divulgado a você por terceiros, sem quaisquer obrigações de confidencialidade; ou (4) for desenvolvido de forma independente por você, sem usar informações confidenciais. Você pode divulgar informações confidenciais se exigido por lei, tribunal ou outra ordem governamental, mas deve fornecer notificação prévia por escrito à Noam de maneira razoável e, na medida do possível, fazer esforços razoáveis ​​para limitar o escopo da divulgação, inclusive ajudando-nos na oposição a pedidos de divulgação.',
    block16:
      '(b) Segurança. Você deve implementar medidas razoáveis ​​e apropriadas para ajudar a proteger seu acesso e uso do Serviço. Se você descobrir quaisquer vulnerabilidades ou violações relacionadas ao uso do Serviço, deverá entrar em contato imediatamente com a Noam e fornecer detalhes da vulnerabilidade ou violação.',
    block17:
      '((c) Processamento de dados pessoais. Se você usar o Serviço para processar dados pessoais, deverá fornecer declarações de privacidade legalmente suficientes e obter os consentimentos necessários para o processamento de tais dados, e você nos garante que trata esses dados de acordo com a lei aplicável .',
    block18:
      '(a) Rescisão; Suspensão. Estes termos entrarão em vigor a partir da primeira utilização do Serviço e permanecerão em vigor até serem rescindidos. Você pode rescindir estes termos a qualquer momento, por qualquer motivo, descontinuando o uso do Serviço e do Conteúdo. Poderemos rescindir estes termos com aviso prévio por qualquer motivo. Poderemos rescindir imediatamente estes termos, notificando você se você violar materialmente a Seção 2 (Requisitos de Uso), Seção 5 (Confidencialidade, Segurança e Proteção de Dados), Seção 8 (Resolução de Disputas) ou Seção 9 (Termos Gerais), ou se nosso relacionamento com qualquer fornecedor de tecnologia terceirizado fora de nosso controle, ou para conformidade com a lei ou solicitações governamentais. Poderemos suspender o seu acesso ao Serviço se você não cumprir estes termos, ou se seu uso representar um risco de segurança para nós ou para terceiros, ou se suspeitarmos que seu uso é fraudulento ou pode expor a nós ou a terceiros a responsabilidades. .',
    block19:
      '(b) Efeitos da Rescisão. Após a rescisão, você deixará de usar o Serviço e devolverá ou destruirá imediatamente qualquer informação confidencial conforme orientado por nós. As disposições destes termos que, por sua natureza, devem sobreviver à rescisão ou expiração, incluindo, mas não se limitando às Seções 3 e 5-9, sobreviverão.',
    block2:
      'Estes Termos de Uso se aplicam ao uso dos serviços da Noam, incluindo nossas interfaces de programação de aplicativos, software, ferramentas, serviços de desenvolvedor, dados, documentação e site (coletivamente denominados "Serviços"). Ao utilizar nossos Serviços, você concorda em cumprir estes termos. Nossa Política de Privacidade explica como coletamos e usamos informações pessoais.',
    block20:
      '(a) Indenização. Você defenderá, indenizará e isentará a nós, nossas afiliadas e nosso pessoal de e contra quaisquer reivindicações, perdas e despesas (incluindo honorários advocatícios) decorrentes ou relacionadas ao seu uso do Serviço, incluindo seu Conteúdo, seu uso de produtos ou serviços relacionados ao Serviço e sua violação destes termos ou da lei aplicável.',
    block21:
      '(b) Isenções de responsabilidade. O Serviço é fornecido "como está". Na medida permitida por lei, nós e nossas afiliadas e licenciadores não fazemos representações ou garantias de qualquer tipo em relação ao Serviço e não oferecemos garantias, incluindo, entre outras, garantias de comercialização, adequação a uma finalidade específica, qualidade satisfatória, não violação e diversão tranquila, e quaisquer garantias decorrentes da negociação ou uso do comércio. Não garantimos que o Serviço será ininterrupto, preciso ou livre de erros, ou que qualquer Conteúdo será seguro, não será perdido ou não será alterado.',
    block22:
      '(c) Limitação de Responsabilidade. Nem nós, nem nossas afiliadas, nem nossos licenciadores seremos responsáveis ​​por quaisquer danos indiretos, incidentais, especiais, consequenciais ou punitivos, incluindo perda de lucros, perda de reputação, perda de uso, perda de dados ou outras perdas intangíveis, mesmo que fomos avisados ​​da possibilidade de tais danos. Sob estes termos, nossa responsabilidade total não excederá as Taxas que você pagou ao Serviço nos doze (12) meses anteriores à reclamação ou cem dólares americanos (US$ 100), o que for maior. As limitações nesta seção se aplicam na extensão máxima permitida pela lei aplicável.',
    block23:
      'Os Termos e o uso dos Serviços são regidos pelas leis dos Estados Unidos, exceto pelas regras de conflito de leis dos EUA. O uso do Aplicativo também pode estar sujeito a outras leis locais, estaduais, nacionais ou internacionais. Qualquer ação ou processo legal relacionado a estes Termos será instaurado exclusivamente nos tribunais dos Estados Unidos, e você concorda em submeter-se à jurisdição pessoal de tais tribunais.',
    block24:
      '(a) Relacionamento das Partes. Noam e você são contratantes independentes e nenhuma das partes tem o poder de representar ou vincular a outra parte ou assumir obrigações para a outra parte sem o consentimento prévio por escrito da outra parte.',
    block25:
      '(b) Uso da Marca. Você não pode usar o nome, logotipos ou marcas registradas da Noam ou de qualquer uma de suas afiliadas sem nosso consentimento prévio por escrito.',
    block26:
      'Se qualquer disposição destes termos for considerada inválida, ilegal ou inexequível, as disposições restantes permanecerão em pleno vigor e efeito.',
    block3:
      'Se você tiver menos de 18 anos, deverá obter permissão de seus pais ou responsáveis ​​legais para usar o Serviço. Se estiver usando o Serviço em nome de outra pessoa ou entidade, você deverá estar autorizado a aceitar estes termos em nome dela. Você deve fornecer informações precisas e completas para se registrar em uma conta. Você não pode fornecer suas credenciais de acesso ou conta a ninguém fora de sua organização e é responsável por todas as atividades realizadas usando suas credenciais.',
    block4:
      '(a) Uso do Serviço. Você pode acessar o Serviço sob estes termos e nós lhe concedemos o direito não exclusivo de usar o Serviço. Ao utilizar o Serviço, você cumprirá estes termos e todas as leis aplicáveis. Nós e nossas afiliadas possuímos todos os direitos, títulos e interesses no Serviço.',
    block5:
      '(b) Feedback. Agradecemos comentários, comentários, ideias, sugestões e melhorias. Se você fornecer tal conteúdo, poderemos usá-lo sem quaisquer restrições e sem compensação para você.',
    block6:
      '(c) Restrições. Você não pode: (1) usar o Serviço de maneira que infrinja, se aproprie indevidamente ou viole quaisquer direitos de terceiros; (2) fazer engenharia reversa, descompilar, desmontar, traduzir ou de outra forma tentar descobrir o código-fonte ou componentes subjacentes dos modelos, algoritmos e sistemas do Serviço (a menos que tais restrições sejam proibidas pela lei aplicável); (3) usar o resultado do Serviço para desenvolver modelos concorrentes do Noam; (4) extrair dados ou resultados do Serviço usando quaisquer métodos automatizados ou de programação, a menos que permitido pela API, incluindo scraping, coleta da web ou extração de dados da web; (5) representar a saída do Serviço como gerada artificialmente quando não o é, ou de outra forma violar nossas políticas de uso; (6) comprar, vender ou transferir chaves de API sem nosso consentimento prévio; ou (7) nos fornecer quaisquer informações pessoais de crianças menores de 13 anos ou idade aplicável de consentimento digital. Você cumprirá quaisquer limites de taxas e outros requisitos em nossa documentação.',
    block7:
      '(d) Serviços de Terceiros. Quaisquer software, serviços ou outros produtos de terceiros associados ao Serviço são regidos pelos seus próprios termos e não somos responsáveis ​​por produtos de terceiros.',
    block8:
      '(a) Seu Conteúdo. Você pode fornecer dados ("Entrada") ao Serviço e receber resultados ("Saída") gerados e retornados com base na Entrada (coletivamente referidos como "Conteúdo"). Entre as partes, e na medida permitida pela lei aplicável, você é o proprietário de todas as Informações. Noam poderá usar o Conteúdo para fornecer e manter o Serviço, cumprir as leis aplicáveis ​​e fazer cumprir nossas políticas. Você é responsável pelo Conteúdo, inclusive por garantir que ele não viole nenhuma lei aplicável ou estes termos.',
    block9:
      '(b) Precisão. A inteligência artificial e o aprendizado de máquina são campos de pesquisa em rápida evolução. Nós nos esforçamos continuamente para melhorar nosso serviço para torná-lo mais preciso, confiável, seguro e benéfico. Dada a natureza probabilística do aprendizado de máquina, o uso do nosso serviço pode resultar em resultados incorretos em certos casos, que podem não refletir com precisão indivíduos, lugares ou fatos reais. Você deve avaliar adequadamente a precisão de qualquer saída com base em seu caso de uso, inclusive revisando manualmente as saídas.',
    subTitle1: 'Cadastro e Acesso',
    subTitle10: 'Divisibilidade',
    subTitle2: 'Requisitos de uso',
    subTitle3: 'Contente',
    subTitle4: 'Taxas e Pagamento',
    subTitle5: 'Confidencialidade, Segurança e Proteção de Dados',
    subTitle6: 'Prazo e Rescisão',
    subTitle7: 'Indenização; Isenções de responsabilidade; Limitação de responsabilidade',
    subTitle8: 'Lei Aplicável e Jurisdição',
    subTitle9: 'Termos Gerais',
    title: 'Termos de Serviço',
    updateTime: 'Atualizado: 15 de março de 2024',
  },
  trialModal: {
    btn: 'Comece agora (apenas $ 0,99)',
    chat: 'Bate-papo PDF',
    desc1: 'Após o teste, será cobrada uma taxa anual de US$ 180',
    desc2: 'Cancelar a qualquer momento',
    focus: 'Foco',
    hover: 'Tradução instantânea',
    image: 'Tradução de imagem',
    installBtn: 'Instale agora para receber seu presente',
    memo: 'Gerenciamento de favoritos',
    pdf: 'Tradução paralela de PDF',
    realtime: 'Tradução instantânea',
    select: 'Tradução de seleção de palavras',
    title: 'Presente de boas-vindas',
    trialText: 'Teste de 7 dias',
    video: 'Tradução de vídeo',
    videoSummary: 'Resumo do vídeo',
    web: 'Tradução Paralela',
    webSummary: 'Resumo da web',
  },
  upgradeModal: {
    btn: 'Atualize agora',
    chat: {
      free: 'Você atingiu o limite do ChatPDF',
      pro: 'Você atingiu o limite do ChatPDF para este mês',
    },
    contactStr: 'Aguarde a próxima redefinição ou entre em contato conosco em contact@noam.tools.',
    limitAlertStr: 'Você atingiu o limite do seu plano gratuito. Atualize para continuar.',
    more: 'Ver planos',
    pageLimit: { free: 'Você atingiu o limite de páginas do PDF' },
    title: 'Lembrete',
    upgradeAlertStr: 'Você atingiu o limite do seu plano gratuito. Atualize para continuar.',
    upload: {
      free: 'Você atingiu seu limite de tradução de PDF',
      pro: 'Você atingiu seu limite de tradução de PDF para este mês',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Cadastre-se agora',
      label: 'Novos benefícios para usuários:',
      remain: 'Restam apenas 30',
      text: 'Apenas 100 vagas disponíveis! Pegue o seu agora!',
    },
  },
}

export default TRANSLATION
