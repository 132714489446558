const TRANSLATION = {
  buyModal: {
    afterFree: 'Dopo la prova gratuita',
    btn: 'Provalo gratuitamente',
    freeTrial: 'Prova gratuita di 3 giorni',
    monthLabel: 'mese',
    selectLabel: 'Seleziona un piano',
    title: 'Piano di abbonamento',
    yearLabel: 'anno',
  },
  contact: {
    block1:
      "Per personalizzazioni, domande o suggerimenti, contattaci all'indirizzo contact@noam.tools. Risponderemo tempestivamente.",
    block2:
      "Sostienici valutandoci nello store delle estensioni di Google Apps all'indirizzo ★★★★★.",
    block3: 'Aiuta gli altri condividendo questa estensione:',
    title: 'Contattaci',
  },
  help: {
    AquickOverviewOfNoam: 'Una rapida panoramica di Noam',
    ChatPDF: 'Chatta PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Gestione dei preferiti',
    Fixedplugin: 'Plugin fisso',
    Focusing: 'Messa a fuoco',
    Free: 'Gratuito',
    FreeTrialBenefits: 'Vantaggi della prova gratuita',
    GoWithWikipedia: 'Vai con Wikipedia',
    GoWithYouTube: 'Vai con YouTube',
    HoverTranslation: 'Traduzione al passaggio del mouse',
    Howtouse: 'Come usare',
    ImageTranslation: 'Traduzione di immagini',
    InstantTranslation: 'Traduzione istantanea',
    Membership: 'Appartenenza',
    NoamHelp: 'Noam ti aiuta a tradurre e riassumere',
    PDFparallelTranslation: 'PDF Traduzione parallela',
    ParallelTranslation: 'Traduzione parallela',
    SkipAllSteps: 'Salta tutti i passaggi',
    Summary: 'Riepilogo',
    Translation: 'Traduzione',
    TryItForFree: 'Provalo gratuitamente',
    Video: 'Video',
    VideoSummary: 'Riepilogo video',
    VideoTranslation: 'Traduzione video',
    WebSummary: 'Riepilogo Web',
    WordSelectionTranslation: 'Traduzione di selezione di parole',
    benefits: 'Abbiamo vantaggi che ti aspettano!',
    dayFree: 'Prova gratuita di 3 giorni',
    done: 'Fatto',
    next: 'Prossimo',
    pin: 'Pin Noam per un accesso più semplice～～',
    planIsNotExist: 'Il piano non è disponibile',
    startWiki: 'Cominciamo con Wikimedia!',
    startYoutube: 'Cominciamo con YouTube!',
    try: 'Provalo',
    tryNow: 'Provatelo adesso!',
  },
  home: {
    PDFTranslate: {
      desc: 'Traduzione bilingue + Chat PDF',
      sub1: {
        desc: "La lettura bilingue ti consente di confrontare il testo originale e la traduzione fianco a fianco, migliorando l'efficienza della lettura.",
        title: 'Lettura bilingue',
      },
      sub2: {
        desc: "L'intelligenza artificiale analizza e riassume e può rispondere alle tue domande in base al contenuto del PDF.",
        title: 'Chatta PDF',
      },
      sub3: {
        desc: "Traduzione PDF basata sull'intelligenza artificiale per maggiore precisione ed efficienza.",
        title: 'Precisione ed efficienza',
      },
      title: 'PDF Traduci',
    },
    aiAssistant: {
      desc: 'Rendere la traduzione web più intelligente e conveniente',
      panel1: {
        desc: 'Le traduzioni bilingue con riferimenti incrociati ti aiutano a confrontare il testo originale con la traduzione in modo da poter capire meglio e migliorare la tua efficienza di lettura.',
        title: 'Traduzione bilingue',
      },
      panel2: {
        left: {
          desc: 'Durante la navigazione in una pagina web, passa il mouse sopra il contenuto che devi tradurre per vedere la traduzione.',
          title: 'Traduzione al passaggio del mouse',
        },
        right: {
          desc: 'Durante la navigazione in una pagina web, seleziona il testo che desideri tradurre per vedere la traduzione.',
          title: 'Traduzione della selezione del testo',
        },
      },
      panel3: {
        one: {
          desc: "Traduci e riassumi pagine web e contenuti video sempre e ovunque, per un'esperienza più fluida!",
          title: 'Punta e clicca',
        },
        three: {
          desc: "Il modello linguistico di grandi dimensioni basato sull'intelligenza artificiale garantisce accuratezza e coerenza della traduzione per un'esperienza di lettura migliorata.",
          title: "Guidato dall'intelligenza artificiale",
        },
        two: {
          desc: 'Copertura completa multilingue, lettura bilingue senza barriere, traduzione con un clic: sperimenta la differenza!',
          title: 'Più completo e conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Il tuo esperto di efficienza: utilizzando ChatGPT, Noam traduce e riassume i contenuti video di YouTube per farti risparmiare tempo!',
          key: 'Video',
          title: 'Traduzione video e riepilogo',
        },
        tab2: {
          desc: 'Fare clic per tradurre le immagini durante la navigazione sul Web.',
          key: 'Immagine',
          title: 'Traduzione di immagini',
        },
        tab3: {
          desc: "Estrae istantaneamente le informazioni chiave dalle pagine Web e fornisce un riepilogo, migliorando l'efficienza della lettura.",
          key: 'Rete',
          title: 'Traduzione e riepilogo Web',
        },
        tag1: 'Semplice',
        tag2: 'Efficiente',
        tag3: 'Facile da capire',
      },
      title: 'Assistente AI',
    },
    getStart: 'Inizia',
    getStartedForFree: 'Inizia gratuitamente',
    howItWork: {
      desc: "Noam integra modelli di intelligenza artificiale per fornire agli utenti un'esperienza intelligente per la traduzione e il riepilogo di pagine Web in più lingue, la traduzione di PDF e immagini, la traduzione e il riepilogo di video",
      title: 'Come funziona',
    },
    lastBlock: { desc: "Migliore traduzione AI per un'esperienza di lettura più fluida" },
    top: {
      desc: "Noam è un'estensione gratuita del browser AI che ti aiuta a tradurre e riepilogare pagine Web, video e contenuti PDF.\\n Unisciti a Noam e sperimenta la magia!",
      title1: 'Una magia',
      title2: 'Un clic',
    },
    userReviews: {
      desc: 'Recensioni a cinque stelle sul Chrome Web Store',
      title: 'Recensioni degli utenti',
    },
  },
  installTips: {
    btn: 'Provalo adesso',
    text: 'Installa "Noam Browser Plugin" per sbloccare più funzionalità! Riferimenti incrociati sul Web,\\n riepilogo di video Web e altro ancora!',
  },
  locale: {
    af: 'afrikaans',
    am: 'Amarico',
    ar: 'arabo',
    as: 'Assamese',
    auto: 'Rilevamento automatico',
    az: 'Azero',
    ba: 'Baschiro',
    bg: 'bulgaro',
    bn: 'bengalese',
    bo: 'tibetano',
    bs: 'Bosniaco',
    ca: 'catalano',
    cs: 'ceco',
    cy: 'gallese',
    da: 'danese',
    de: 'tedesco',
    dsb: 'Sorabo inferiore',
    dv: 'Divehi',
    el: 'greco',
    en: 'Inglese',
    es: 'spagnolo',
    et: 'Estone',
    eu: 'Basco',
    fa: 'persiano',
    fi: 'finlandese',
    fil: 'filippino',
    fj: 'Fijiano',
    fr: 'francese',
    frCA: 'Francese (Canada)',
    ga: 'irlandese',
    gl: 'galiziano',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'ebraico',
    hi: 'hindi',
    hr: 'croato',
    hsb: 'Alto sorabo',
    ht: 'Creolo haitiano',
    hu: 'ungherese',
    hy: 'armeno',
    id: 'indonesiano',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandese',
    it: 'Italiano',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latino)',
    ja: 'giapponese',
    ka: 'georgiano',
    kk: 'Kazakistan',
    km: 'Khmer',
    kmr: 'Curdo (settentrionale)',
    kn: 'Kannada',
    ko: 'coreano',
    ku: 'Curdo (centrale)',
    ky: 'Kirghizistan',
    ln: 'Lingala',
    lo: 'Laotiano',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'lettone',
    lzh: 'Cinese (letterario)',
    mai: 'Maithili',
    mg: 'malgascio',
    mi: 'Maori',
    mk: 'macedone',
    ml: 'Malayalam',
    mnCyrl: 'Mongolo (cirillico)',
    mnMong: 'Mongolo (tradizionale)',
    mr: 'Marathi',
    ms: 'malese',
    mt: 'maltese',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmano)',
    nb: 'norvegese',
    ne: 'nepalese',
    nl: 'Olandese',
    noSearchLang: 'Lingua non supportata',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Polacco',
    placeholder: 'Ricerca',
    prs: 'Dari',
    ps: 'Pashtu',
    pt: 'Portoghese (Brasile)',
    ptPT: 'Portoghese (Portogallo)',
    ro: 'rumeno',
    ru: 'russo',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singalese',
    sk: 'slovacco',
    sl: 'sloveno',
    sm: 'Samoano',
    sn: 'Shona',
    so: 'Somalo',
    sq: 'albanese',
    srCyrl: 'Serbo (cirillico)',
    srLatn: 'Serbo (latino)',
    st: 'Sesotho',
    sv: 'svedese',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'tailandese',
    ti: 'Tigrino',
    tk: 'turkmeno',
    tlhLatn: 'Klingon (latino)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongano',
    tr: 'turco',
    tt: 'Tartaro',
    ty: 'Tahitiano',
    ug: 'Uiguro',
    uk: 'ucraino',
    ur: 'Urdu',
    uz: 'Uzbeco (latino)',
    vi: 'vietnamita',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Maya yucatechi',
    yue: 'Cantonese (tradizionale)',
    zh: 'cinese',
    zhHans: 'Cinese semplificato',
    zhHant: 'Cinese tradizionale',
    zu: 'Zulù',
  },
  login: {
    and: 'E',
    btn: 'Accedi con Google',
    btn1: 'Accedi adesso',
    desc: 'Accedi per sbloccare più esperienze',
    policy: 'politica sulla riservatezza',
    terms: 'Termini di utilizzo',
    tips: 'Iscrivendomi, accetto il',
    title: 'Benvenuto a Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguire', twitter: 'Twitter' },
    legal: { policy: 'politica sulla riservatezza', terms: 'Termini di servizio', title: 'Legale' },
    product: {
      image: 'Traduzione di immagini',
      pdf: 'Traduzione PDF',
      title: 'Prodotti',
      video: 'Traduzione video e riepilogo',
      web: 'Traduzione Web e riepilogo',
    },
    resources: { title: 'Risorse' },
  },
  pageHeader: {
    contact: 'Contatto',
    help: 'Aiuto',
    logout: 'Esci',
    logoutSucc: 'Disconnesso.',
    pricing: 'Prezzi',
    settings: 'Impostazioni',
    signUp: 'Login',
  },
  pdf: {
    autoDetect: 'Rilevamento automatico',
    cancelBtn: 'Cancellare',
    cancelUpload: 'Cancellare',
    chatPdf: {
      Search: 'Ricerca',
      cancel: 'Cancellare',
      chatTitle: 'Benvenuto a Noam! Ecco un riepilogo del tuo PDF:',
      clear: 'Chiaro',
      clearContent:
        'Questo avvierà una nuova conversazione. La conversazione corrente non verrà salvata. Sei sicuro?',
      clearDialogTitle: 'Per favore conferma',
      clearSucc: 'Cancellato',
      copy: 'Copia',
      copySucc: 'Copiato',
      delete: 'Eliminare',
      deleteCancel: 'Cancellare',
      deleteContent: 'Questo PDF non può essere recuperato una volta eliminato. Sei sicuro?',
      deleteOk: 'Eliminare',
      deleteSuccess: 'Eliminato',
      deleteTitle: 'Conferma eliminazione',
      expand: 'Crollo',
      historyTitle: 'Storia',
      listError:
        "Impossibile caricare l'elenco di lettura. Riprova più tardi o contatta l'assistenza.",
      loading: 'Caricamento...',
      noDataDesc: 'Nessuna storia ancora.',
      noMore: 'Niente più contenuti.',
      placeholder: 'Chiedi qualsiasi cosa su questo PDF',
      summaryTitle: 'Potrebbero interessarti queste domande:',
    },
    collectionEmpty: 'La tua raccolta è vuota.',
    collectionTab: 'Note',
    dataLoading: 'Caricamento...',
    dialogContent: 'Sei sicuro di voler eliminare questo?',
    dialogTitle: 'Per favore conferma',
    download: {
      both: 'Bilingue',
      btn: 'Salva',
      cancel:
        'La chiusura di questa finestra annullerà il download del PDF. Sei sicuro di voler annullare?',
      desc: 'Salva sul dispositivo: scarica le traduzioni come immagini; Salva e stampa: scarica le traduzioni nel formato originale, con supporto per la copia.',
      download: 'Scaricamento',
      downloadMsg1: 'Avanzamento della traduzione del PDF:',
      downloadMsg2: 'Tempo rimanente stimato:',
      downloadSucc: 'Il PDF è pronto! Fare clic per salvare.',
      downloadTip: 'Download del PDF. Non chiudere questa finestra per evitare errori di download.',
      error: 'Impossibile salvare. Prova invece a stampare.',
      fileName: 'Nome del file:',
      fileType: 'Tipo di file:',
      loading: 'Risparmio...',
      loading2: 'Caricamento...',
      minute: 'Minuti...',
      msg1: 'Alcune traduzioni in questo documento sono più lunghe del testo originale e potrebbero sovrapporsi nel file scaricato. Si consiglia la lettura sulla pagina web.',
      msg2: '1. La traduzione non è ancora completa. Si prega di scaricarlo al termine della traduzione.',
      msg3: '2. Alcune traduzioni sono troppo lunghe. Leggi sulla pagina web per evitare sovrapposizioni di testo.',
      msg4: 'Le attività di traduzione sono in corso. Riprova una volta completati.',
      progress:
        'Traduzione di PDF. Avanzamento: {{progress}}, tempo rimanente stimato: {{minutes}} minuti...',
      save: 'Salva sul dispositivo',
      savePrint: 'Salva e stampa',
      success: 'Scaricato',
      trans: 'Solo traduzione',
      transContinue: 'Continua a tradurre',
    },
    downloadBtn: 'Scaricamento',
    dragTipModal: {
      black: 'spazio',
      leftKey: 'pulsante sinistro',
      ok: 'Fatto',
      press: 'Premere',
      tips: 'per trascinare e visualizzare.',
    },
    entry: 'PDF',
    fileErrMsg: "La lettura del file non è riuscita. Riprova più tardi o contatta l'assistenza.",
    fileMsg: 'Dimensione file corrente:',
    fileMsg2: 'Carica un file di dimensioni inferiori a 300 MB.',
    freeBannerBtn: 'Aggiornamento',
    freeBannerTips: 'È stato raggiunto il limite delle traduzioni PDF gratuite',
    guide: {
      placeholder: 'Incolla qui il collegamento al PDF',
      start: 'Inizia a tradurre',
      text1: 'Lettura bilingue',
      text2:
        'Leggi bilingue per confrontare il testo originale e la traduzione fianco a fianco, migliorando la tua efficienza.',
      text3: 'Chatta con il tuo PDF',
      text4:
        "Analisi e riepilogo basati sull'intelligenza artificiale. Fai domande sul tuo PDF e ottieni risposte immediate.",
      text5: 'Preciso ed efficiente',
      text6:
        "Traduzione PDF basata sull'intelligenza artificiale per maggiore precisione ed efficienza.",
      title1: 'Traduzione bilingue',
      title2: 'Chatta con il tuo PDF',
      toast: 'Inserisci un collegamento PDF online valido che termina con .pdf',
    },
    hasTransTask: "È in corso un'attività di traduzione. Attendere prego.",
    hoverTrans: 'Passa il mouse per tradurre i paragrafi',
    initial: 'Traduci PDF',
    installText: 'La tua soluzione unica per le traduzioni di tutti i giorni.',
    installText2: "Installa prima l'estensione",
    installTips:
      "Installa l'estensione per avere ancora più traduzioni PDF e traduzioni di siti web più fluide:",
    kw: 'di base',
    link: 'collegamento',
    listError: "Impossibile caricare i PDF. Riprova più tardi o contatta l'assistenza.",
    memoTotalCount: '1 articolo in totale',
    new: 'nuovo file',
    noId: 'ID file non trovato.',
    notPdf: 'Solo file PDF.',
    okBtn: 'Eliminare',
    okBtn2: 'Fatto',
    original: 'Originale',
    parallel: 'Parallelo',
    parseErrDialogContent:
      'Questo collegamento PDF non può essere tradotto direttamente. Si prega di scaricare il PDF e caricarlo per la traduzione.',
    parseErrTips: 'Impossibile analizzare il file',
    parseFileToast: "Impossibile analizzare il file. Riprova più tardi o contatta l'assistenza.",
    pdfLoading: 'Analisi in corso...',
    searchPlaceholder: 'Ricerca',
    selectTrans: 'Seleziona Testo da tradurre',
    shareBtn: 'Condividere',
    shareBtnText1: 'Genera collegamento di condivisione',
    shareBtnText2: 'Aggiorna contenuto condiviso',
    shareModal: {
      cancel: 'Cancellare',
      copy: 'Copia',
      copySucc: 'Collegamento copiato',
      custom: 'Personalizza:',
      errMsg:
        'Il contenuto selezionato non è stato ancora completamente tradotto. Si prega di tradurre prima di condividere.',
      gen: 'Condividere',
      modify: 'Modificare',
      notSupport: 'Questa lingua non è attualmente supportata.',
      page: 'Pagine',
      pageNuErr: 'Inserisci un numero di pagina valido.',
      range: 'Ambito:',
      remain: 'rimanente',
      remain1: 'Tempo totale stimato:',
      search: 'Ricerca',
      settings: 'Condividi impostazioni:',
      shareSetting: 'Condividi impostazioni',
      shareText: 'Condividere',
      shareTips: 'Condividi il collegamento PDF tradotto con i tuoi amici:',
      sourceLang: 'Da:',
      target: 'A:',
      text1: 'Condividi impostazioni',
      text2: 'Genera collegamento di condivisione',
      totalPage: '1 pagina totale',
      trans: 'Tradurre',
      translateTips:
        'Suggerimento: se la traduzione richiede del tempo, puoi chiudere questa finestra e condividere in seguito.',
      translating: 'Traduzione del PDF in corso...',
      update: 'Aggiorna collegamento di condivisione',
    },
    shareMsg1: 'Collegamento di condivisione generato.',
    shareMsg2: 'Contenuti condivisi aggiornati.',
    shareMsg3:
      "Impossibile generare il collegamento di condivisione. Riprova più tardi o contatta l'assistenza.",
    shareMsg4:
      "Impossibile aggiornare il contenuto condiviso. Riprova più tardi o contatta l'assistenza.",
    shareTips: 'La tua traduzione PDF è pronta! Condividilo con i tuoi amici.',
    start: 'Inizia la tua prova gratuita',
    thumbnail: 'Sommario',
    toast1: 'Caricamento PDF. Per favore riprova più tardi.',
    toast2:
      'Il contenuto del PDF è troppo grande. La finestra di dialogo al momento non è supportata.',
    toast3: 'Download in corso. Riprova al termine del download.',
    toolbar: { Adjust: 'Regolare', autoFit: 'Adattamento automatico' },
    trans: 'Traduzione',
    transSucc: 'Traduzione riuscita!',
    unLoginToast: 'Effettua il login per utilizzare questa funzione.',
    uploadBtn: 'Caricamento',
    uploadErr: "Caricamento file non riuscito. Riprova più tardi o contatta l'assistenza.",
    uploadMsg1: 'Trascina e rilascia il tuo file qui.',
    uploadMsg2: 'Tipi di file supportati: PDF |  Dimensione massima del file: 100 MB',
    uploadMsg3: 'Fare clic per selezionare o trascinare e rilasciare i file qui.',
    uploading: 'Caricamento in corso',
    uploadingMsg: 'Caricamento in corso. Attendere prego.',
    uploadingMsg1: 'Il file è in caricamento. Per favore riprova più tardi.',
    uploadingMsg2: 'Un file è attualmente in fase di conversione. Per favore riprova più tardi.',
    uploadingMsg3:
      "La richiesta di avanzamento della conversione non è riuscita. Riprova più tardi o contatta l'assistenza.",
    uploadingMsg4:
      'Noam non può accedere ai file locali. Carica manualmente un PDF per la traduzione.',
    uploadingMsg5:
      "Impossibile recuperare l'URL del file. Riprova più tardi o contatta l'assistenza.",
    video: 'video',
    vipBannerBtn: 'Aggiornamento',
    vipBannerTips: 'Hai raggiunto il limite di traduzioni PDF per questo mese.',
    webTrans: 'Riferimento incrociato completo',
  },
  pricing: {
    FAQ: 'Domande frequenti',
    FQAObj: {
      a1: 'Sì, Noam offre un piano gratuito che puoi utilizzare subito.',
      a2: 'Offriamo tre piani di abbonamento: Pro, Pro+ ed Enterprise. Puoi scegliere di pagare mensilmente o annualmente. La fatturazione annuale offre risparmi significativi.',
      a3: "Tutte le funzionalità sono disponibili su entrambi i piani. La differenza principale è il costo: gli abbonamenti annuali costano complessivamente meno. Gli abbonamenti mensili vengono fatturati ogni mese, mentre i piani annuali vengono fatturati una volta all'anno.",
      a4: "Puoi annullare l'abbonamento in qualsiasi momento nelle Impostazioni account.",
      q1: 'Esiste un piano gratuito?',
      q2: 'Quali piani tariffari e opzioni di pagamento sono disponibili?',
      q3: "Qual è la differenza tra l'abbonamento mensile e quello annuale?",
      q4: 'Come posso cancellare il mio abbonamento?',
    },
    around: 'In giro',
    billedMonthly: 'Fatturazione mensile',
    billedYearly: 'Fatturato annualmente',
    day: 'giorno',
    desc: 'Confronta i piani e scegli il migliore',
    detailedCompare: 'Confronta i piani',
    discount: 'Sconto del 20%.',
    enterprise: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: 'Utenti multipli',
      benefit3: 'Soluzioni su misura',
      benefit5: 'Account manager dedicato',
      btn: 'Contattaci',
      recommendText: 'Piani personalizzati（≥3人）',
      talkToUs: 'Mettiti in contatto',
    },
    free: {
      benefit1: 'Traduzione istantanea 100 volte al giorno',
      benefit2: 'Traduzione e riepilogo del sito web',
      benefit3: '30 traduzioni di immagini',
      benefit4: 'Traduzione video e riepilogo',
      benefit5: '10 traduzioni PDF',
      benefit6: '5.000 gettoni',
      btn: 'Provalo gratuitamente',
      recommendText: 'Prova gratuita',
    },
    imageTranslation: 'Traduzione di immagini',
    month: 'mese',
    monthly: 'Mensile',
    pageTitle: 'Prezzi',
    pdf: {
      chat: 'Domande sulla chat PDF',
      maxPage: 'Massimo. Pagine per PDF',
      translation: 'Traduzione PDF',
    },
    preMonth: 'Al mese',
    pro: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: '1.000.000 di token/mese',
      benefit3: 'Traduzione e riepilogo del sito web',
      benefit4: '200 traduzioni di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '200 traduzioni PDF',
      btn: 'Inizia',
      recommendText: 'Il più popolare',
    },
    proPlus: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: '3.000.000 di token/mese',
      benefit3: 'Traduzione e riepilogo del sito web',
      benefit4: '500 traduzioni di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '500 traduzioni PDF',
      recommendText: 'Miglior rapporto qualità/prezzo',
    },
    video: { summary: 'Riepilogo video', translation: 'Traduzione video' },
    web: {
      contrastiveTranslation: 'Traduzione bilingue',
      dictionaryTranslation: 'Traduzione di selezione',
      hoverTranslation: 'Traduzione al passaggio del mouse',
      instantTranslation: 'Traduzione istantanea',
      summary: 'Riepilogo della pagina Web',
    },
    yearly: 'Annuale',
  },
  privacy: {
    block1:
      'Noam ("Noam", "noi", "nostro" o "ci") rispetta la tua privacy e si impegna a proteggere qualsiasi informazione che otteniamo da te o su di te. La presente Informativa sulla privacy descrive le nostre pratiche relative alla raccolta o all\'utilizzo delle informazioni personali derivanti dall\'utilizzo del nostro sito Web, delle applicazioni e dei servizi (collettivamente, i "Servizi"). La presente Informativa sulla privacy non si applica ai contenuti che gestiamo per conto dei nostri clienti di prodotti aziendali, come la nostra API. L\'utilizzo di questi dati è soggetto al nostro contratto con i clienti, che copre l\'accesso e l\'utilizzo di questi prodotti.',
    block10:
      "Implementiamo misure tecniche, amministrative e organizzative commercialmente ragionevoli per proteggere le informazioni personali online e offline da perdita, uso improprio e accesso non autorizzato, divulgazione, alterazione o atti ostili. Tuttavia, nessuna trasmissione via Internet o e-mail può garantire la completa sicurezza o accuratezza. In particolare, le e-mail inviate a noi o da noi potrebbero non essere sicure. Pertanto, dovresti prestare particolare attenzione nel decidere quali informazioni inviarci tramite il servizio o l'e-mail. Inoltre, non siamo responsabili per qualsiasi ostruzione delle impostazioni sulla privacy o delle misure di sicurezza nel servizio o nelle impostazioni di sicurezza o nelle misure di sicurezza dei siti Web di terze parti. Conserveremo le tue informazioni personali per tutto il tempo necessario per fornirti servizi o per altri scopi commerciali legittimi, come la risoluzione di controversie, motivi di sicurezza e protezione o il rispetto dei nostri obblighi legali. Il periodo di tempo in cui conserviamo le informazioni personali dipenderà da molti fattori, come la quantità, la natura, la sensibilità delle informazioni, i potenziali rischi di utilizzo o divulgazione non autorizzati, gli scopi per i quali trattiamo le informazioni e altre considerazioni giuridicamente vincolanti.",
    block11:
      'Potremmo aggiornare la presente Informativa sulla privacy di tanto in tanto. Quando lo faremo, pubblicheremo una versione aggiornata su questa pagina, se non diversamente richiesto dalla legge applicabile.',
    block12:
      'In caso di domande o dubbi senza risposta sulla presente Informativa sulla privacy, contattare il nostro servizio clienti. E-mail:',
    block2:
      'Raccogliamo le seguenti informazioni personali ("Informazioni personali") relative all\'utente:',
    block3:
      'Informazioni fornite da te: quando crei un account per utilizzare i nostri servizi o comunicare con noi, raccogliamo informazioni personali come segue:',
    block4:
      'Informazioni personali ricevute automaticamente dal tuo utilizzo dei servizi: quando accedi, utilizzi o interagisci con i servizi, riceviamo le seguenti informazioni sul tuo accesso, utilizzo o interazione ("Informazioni tecniche"):',
    block5: 'Potremmo utilizzare le informazioni personali per i seguenti scopi:',
    block6:
      "Informazioni riepilogative o anonimizzate: possiamo aggregare o anonimizzare le informazioni personali per impedirne il riutilizzo a fini di identificazione e utilizzare tali informazioni per analizzare l'efficacia dei nostri servizi, migliorare e aggiungere funzionalità ai nostri servizi, condurre ricerche e per scopi simili . Inoltre, potremmo analizzare il comportamento generale e le caratteristiche degli utenti dei nostri servizi e condividere informazioni aggregate con terze parti, pubblicare tali informazioni aggregate o renderle generalmente disponibili. Possiamo raccogliere informazioni aggregate attraverso i servizi, attraverso i cookie e attraverso altri mezzi descritti nella presente Informativa sulla privacy. Manterremo e utilizzeremo le informazioni deidentificate in forma anonima o deidentificata e non tenteremo di identificare nuovamente tali informazioni se non richiesto dalla legge.",
    block7:
      'In alcuni casi, potremmo fornire le tue informazioni personali a terzi senza ulteriore avviso, a meno che non sia richiesto dalla legge:',
    block8:
      'A seconda della posizione geografica, gli individui nello Spazio Economico Europeo, nel Regno Unito e nel mondo potrebbero avere determinati diritti legali in merito alle proprie informazioni personali. Ad esempio, potresti avere il diritto di:',
    block9:
      'Il servizio può contenere collegamenti ad altri siti Web non gestiti o controllati da Noam, inclusi servizi di social media ("Siti Web di terze parti"). Le informazioni condivise con i siti Web di terze parti saranno regolate dalle politiche sulla privacy e dai termini di servizio specifici dei siti Web di terze parti, non dalla presente Informativa sulla privacy. Forniamo questi collegamenti per comodità e non implicano la nostra approvazione o revisione di questi siti Web. Si prega di contattare direttamente i siti Web di terze parti per informazioni sulle loro politiche e termini sulla privacy.',
    subBlock1:
      "Informazioni sull'account: quando crei un account sulla nostra piattaforma, raccogliamo informazioni relative al tuo account, inclusi nome, informazioni di contatto, credenziali dell'account, informazioni sulla carta di pagamento e cronologia delle transazioni (\"Informazioni sull'account\").",
    subBlock10: 'Fornire, gestire, mantenere e/o analizzare servizi;',
    subBlock11: 'Comunicare con te;',
    subBlock12: 'Sviluppo di nuovi prodotti e servizi;',
    subBlock13:
      'Prevenire frodi, attività criminali o uso improprio dei nostri servizi e proteggere la sicurezza dei nostri sistemi IT, architettura e reti;',
    subBlock14: "Conduzione di trasferimenti d'impresa;",
    subBlock15:
      "Rispettare obblighi legali e procedimenti legali, proteggere i nostri diritti, la privacy, la sicurezza o la proprietà, nonché quelli dei nostri affiliati, dell'utente o di altre terze parti.",
    subBlock16:
      'Fornitori e prestatori di servizi: per aiutarci a soddisfare le esigenze operative aziendali e a eseguire determinati servizi e funzioni, potremmo fornire informazioni personali a fornitori e prestatori di servizi, inclusi fornitori di servizi di hosting, fornitori di servizi cloud e altri fornitori di servizi informatici, software di comunicazione e-mail e fornitori di servizi di analisi web, ecc. Questi partner accederanno, elaboreranno o memorizzeranno le informazioni personali solo su nostra indicazione mentre svolgono i loro compiti per noi.',
    subBlock17:
      'Trasferimenti aziendali: se ci impegniamo in transazioni strategiche, riorganizzazioni, fallimenti, acquisizioni fallimentari o transizione di servizi a un altro fornitore (collettivamente, "Transazioni"), le tue informazioni personali e altre informazioni potrebbero essere condivise con le controparti delle transazioni e altre parti che assistono nelle Transazioni durante la due diligence e trasferito come parte delle Transazioni insieme ad altri beni a una società successore o affiliata.',
    subBlock18:
      'Requisiti legali: potremmo condividere le tue informazioni personali con autorità governative, colleghi del settore o altre terze parti, comprese le informazioni sulla tua interazione con i nostri servizi, (1) se richiesto dalla legge o se riteniamo che tale azione sia necessaria per rispettare gli obblighi legali; (2) per proteggere e difendere i nostri diritti o proprietà; (3) se determiniamo unilateralmente che esiste una violazione dei nostri termini, politiche o leggi; (4) per individuare o prevenire frodi o altre attività illegali; (5) per proteggere i nostri prodotti, dipendenti o utenti o la pubblica sicurezza, protezione e integrità; o (vi) per difendersi da responsabilità legali.',
    subBlock19:
      'Accedi alle tue informazioni personali e alle informazioni su come vengono elaborate.',
    subBlock2:
      'Contenuti dell\'utente: quando utilizzi i nostri servizi, raccogliamo informazioni personali dai tuoi input, caricamenti di file o feedback forniti a noi ("Contenuti").',
    subBlock20: 'Richiedi la cancellazione dei tuoi dati personali dai nostri archivi.',
    subBlock21: 'Correggere o aggiornare le tue informazioni personali.',
    subBlock22: 'Trasferire le tue informazioni personali a terzi (portabilità dei dati).',
    subBlock23: 'Limita il modo in cui trattiamo le tue informazioni personali.',
    subBlock24:
      'Revocare il tuo consenso: laddove facciamo affidamento sul consenso come base giuridica per il trattamento, puoi revocare il tuo consenso in qualsiasi momento.',
    subBlock25: 'Opporsi al modo in cui trattiamo le tue informazioni personali.',
    subBlock26: 'Migliorare i nostri servizi e condurre ricerche;',
    subBlock3:
      'Informazioni sulla comunicazione: se ci invii messaggi, raccoglieremo il tuo nome, le informazioni di contatto e il contenuto dei tuoi messaggi ("Informazioni sulla comunicazione").',
    subBlock4:
      'Informazioni sui social media: abbiamo pagine sui siti Web di social media come Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando interagisci con le nostre pagine sui social media, raccogliamo le informazioni personali che scegli di fornirci, come i tuoi dettagli di contatto ("Informazioni social"). Inoltre, le società che ospitano le nostre pagine sui social media possono fornirci informazioni aggregate e analisi sulle nostre attività sui social media.',
    subBlock5:
      "Dati di registro: informazioni inviate automaticamente dal tuo browser quando utilizzi i nostri servizi. I dati di registro includono il tuo indirizzo IP (protocollo Internet), il tipo e le impostazioni del browser, la data e l'ora delle tue richieste e il modo in cui interagisci con il nostro sito web.",
    subBlock6:
      "Dati di utilizzo: potremmo raccogliere automaticamente informazioni sul tuo utilizzo del servizio, come i tipi di contenuti che visualizzi o con cui interagisci, le funzionalità che utilizzi, le azioni che intraprendi, nonché il fuso orario, il Paese, la data e l'ora del servizio. accesso, agente utente e versione, tipo di computer o dispositivo mobile e connessione al computer.",
    subBlock7:
      "Informazioni sul dispositivo: includono il nome del dispositivo, il sistema operativo, l'identificatore del dispositivo e il browser che stai utilizzando. Le informazioni raccolte possono dipendere dal tipo di dispositivo che stai utilizzando e dalle sue impostazioni.",
    subBlock8:
      'Cookie: utilizziamo i cookie per operare e gestire i nostri servizi e migliorare la tua esperienza. Un "cookie" è un\'informazione inviata da un sito web visitato al tuo browser. Puoi impostare il tuo browser per accettare tutti i cookie, rifiutare tutti i cookie o avvisarti quando viene fornito un cookie in modo che tu possa decidere se accettarlo ogni volta. Tuttavia, rifiutare i cookie potrebbe impedirti di utilizzare o influenzare negativamente la visualizzazione o la funzionalità di determinate aree o funzionalità del sito web. Per ulteriori informazioni sui cookie, visitare Tutto sui cookie.',
    subBlock9:
      "Analisi: potremmo utilizzare vari prodotti di analisi online che utilizzano cookie per aiutarci ad analizzare il modo in cui gli utenti utilizzano i nostri servizi e migliorare la tua esperienza durante l'utilizzo dei servizi.",
    subTitle1: 'Informazioni personali che raccogliamo',
    subTitle2: 'Come utilizziamo le informazioni personali',
    subTitle3: 'Divulgazione di informazioni personali',
    subTitle4: 'I tuoi diritti',
    subTitle5: 'Collegamenti ad altri siti web',
    subTitle6: 'Sicurezza e conservazione delle informazioni',
    subTitle7: 'Modifica della politica sulla privacy',
    subTitle8: 'Come contattarci',
    title: 'politica sulla riservatezza',
    updateTime: 'Aggiornato: 15 marzo 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accetta tutto',
    text: 'Per migliorare la tua esperienza, utilizziamo i cookie per personalizzare contenuti e servizi.',
    title: 'Questo sito web utilizza i cookie',
  },
  slogen: "L'assistente AI più semplice",
  stripe: {
    contact: 'Contattaci a contact@noam.tools per qualsiasi domanda!',
    fail: {
      btn: 'Riprova',
      text1: 'Non è stato possibile elaborare il tuo pagamento.',
      text2: 'Controlla la connessione e riprova.',
      title: 'Pagamento non riuscito',
    },
    succ: {
      btn: 'Andiamo',
      text1: 'Benvenuto a Noam,',
      text2: 'cominciamo!',
      title: 'Congratulazioni',
    },
  },
  terms: {
    block1: 'Grazie per aver utilizzato Noam!',
    block10:
      '(a) Commissioni e fatturazione. Pagherai tutte le tariffe ("Commissioni") in base ai prezzi e ai termini indicati nella pagina dei prezzi applicabile o come concordato per iscritto tra di noi. Ci riserviamo il diritto di correggere errori o errori nei prezzi anche se abbiamo emesso fatture o ricevuto pagamenti. Fornirai informazioni di fatturazione complete e accurate, incluso un metodo di pagamento valido e autorizzato. Addebiteremo periodicamente il tuo metodo di pagamento come concordato, ma potremmo ragionevolmente modificare la data di fatturazione. Autorizzi Noam, le sue affiliate e i processori di pagamento di terze parti ad addebitare il tuo metodo di pagamento. Se il pagamento non va a buon fine, ti forniremo un avviso scritto e potremmo sospendere l\'accesso al Servizio fino al ricevimento del pagamento. Salvo quanto diversamente specificato nel presente accordo, i pagamenti non sono rimborsabili.',
    block11:
      '(b) Imposte. Salvo diversa indicazione, le Tariffe non includono tasse, dazi e imposte federali, statali, locali ed estere ("Tasse"). Sei responsabile di tutte le tasse associate ai tuoi acquisti, escluse le tasse basate sul nostro reddito netto per il quale ti fattureremo. Accetti di pagare tempestivamente tali tasse e di fornirci informative sulla privacy legalmente valide e di ottenere i consensi necessari per il trattamento di tali dati, e ci garantisci di gestire tali dati in conformità con la legge applicabile.',
    block12:
      '(c) Variazioni di prezzo. Potremmo modificare i nostri prezzi avvisandoti tramite il tuo account e/o il nostro sito web. Gli aumenti di prezzo entreranno in vigore immediatamente dopo la pubblicazione. Eventuali modifiche di prezzo verranno applicate alle Commissioni per il tuo account dopo la data di entrata in vigore della modifica.',
    block13:
      '(d) Controversie e pagamenti scaduti. Se desideri contestare eventuali commissioni o tasse, contatta contact@noam.tools entro trenta (30) giorni dalla data della fattura.',
    block14:
      "(e) Utilizzo gratuito. Non è possibile creare più account per ottenere crediti gratuiti. Se stabiliamo che non stai utilizzando i crediti gratuiti in buona fede, potremmo addebitarti tariffe standard o interrompere l'accesso al Servizio.",
    block15:
      "(a) Riservatezza. Potresti avere accesso a informazioni riservate di Noam. È possibile utilizzare solo le informazioni riservate consentite dai presenti termini allo scopo di utilizzare il Servizio. Non puoi divulgare informazioni riservate a terzi e proteggerai le informazioni riservate in un modo non meno protettivo di quello con cui proteggi le tue informazioni riservate simili, almeno con ragionevole cura. Le informazioni riservate sono informazioni non pubbliche designate come riservate da Noam o che dovrebbero ragionevolmente essere trattate come riservate date le circostanze, inclusi software, specifiche e altre informazioni commerciali non pubbliche. Le informazioni riservate non includono informazioni che: (1) diventano generalmente disponibili al pubblico senza colpa da parte tua; (2) eri in tuo possesso senza alcun obbligo di riservatezza prima di riceverlo ai sensi dei presenti termini; (3) ti vengono legalmente divulgati da terzi senza alcun obbligo di riservatezza; o (4) è sviluppato in modo indipendente dall'utente senza utilizzare le informazioni riservate. Puoi divulgare informazioni riservate se richiesto dalla legge, da un tribunale o da un altro ordine governativo, ma devi fornire previa comunicazione scritta a Noam in modo ragionevole e, per quanto possibile, compiere sforzi ragionevoli per limitare l'ambito della divulgazione, inclusa l'assistenza nell'opporsi alle richieste di divulgazione.",
    block16:
      "(b) Sicurezza. È necessario implementare misure ragionevoli e appropriate per contribuire a salvaguardare l'accesso e l'utilizzo del Servizio. Se scopri eventuali vulnerabilità o violazioni relative al tuo utilizzo del Servizio, devi contattare tempestivamente Noam e fornire i dettagli della vulnerabilità o violazione.",
    block17:
      '((c) Trattamento dei dati personali. Se utilizzi il Servizio per trattare dati personali, devi fornire informative sulla privacy legalmente sufficienti e ottenere i consensi necessari per il trattamento di tali dati, e ci garantisci di gestire tali dati in conformità con la legge applicabile .',
    block18:
      "(a) Risoluzione; Sospensione. Questi termini entreranno in vigore dal primo utilizzo del Servizio e rimarranno in vigore fino alla risoluzione. L'utente può risolvere questi termini in qualsiasi momento e per qualsiasi motivo interrompendo l'uso del Servizio e dei Contenuti. Possiamo risolvere questi termini con preavviso per qualsiasi motivo. Potremo risolvere immediatamente i presenti termini avvisandoti in caso di violazione materiale della Sezione 2 (Requisiti di utilizzo), Sezione 5 (Riservatezza, sicurezza e protezione dei dati), Sezione 8 (Risoluzione delle controversie) o Sezione 9 (Condizioni generali), o se il nostro rapporto con qualsiasi fornitore di tecnologia di terze parti al di fuori del nostro controllo, modifiche o per conformità con la legge o richieste governative. Potremo sospendere il tuo accesso al Servizio se non rispetti questi termini, o se il tuo utilizzo rappresenta un rischio per la sicurezza nostra o di terze parti, o se sospettiamo che il tuo utilizzo sia fraudolento o possa esporre noi o terze parti a responsabilità .",
    block19:
      '(b) Effetti della risoluzione. Al momento della risoluzione, cesserai di utilizzare il Servizio e restituirai o distruggerai tempestivamente qualsiasi informazione riservata come da noi indicato. Le disposizioni di questi termini che per loro natura dovrebbero sopravvivere alla risoluzione o alla scadenza, incluse ma non limitate alle Sezioni 3 e 5-9, sopravvivranno.',
    block2:
      'Le presenti Condizioni d\'uso si applicano all\'utilizzo dei servizi Noam, comprese le nostre interfacce di programmazione delle applicazioni, software, strumenti, servizi per sviluppatori, dati, documentazione e sito Web (collettivamente denominati "Servizi"). Utilizzando i nostri Servizi, accetti di rispettare questi termini. La nostra Informativa sulla privacy spiega come raccogliamo e utilizziamo le informazioni personali.',
    block20:
      "(a) Indennizzo. Difenderai, indennizzerai e manterrai noi, i nostri affiliati e il nostro personale indenni da e contro qualsiasi reclamo, perdita e spesa (comprese le spese legali) derivanti da o correlate al tuo utilizzo del Servizio, inclusi i tuoi Contenuti, i tuoi utilizzo di prodotti o servizi relativi al Servizio e violazione da parte dell'utente di questi termini o della legge applicabile.",
    block21:
      '(b) Esclusioni di responsabilità. Il Servizio viene fornito "così com\'è". Nella misura consentita dalla legge, noi e i nostri affiliati e licenziatari non rilasciamo dichiarazioni o garanzie di alcun tipo in merito al Servizio e non forniamo alcuna garanzia, incluse ma non limitate a garanzie di commerciabilità, idoneità per uno scopo particolare, qualità soddisfacente, non violazione e il pacifico godimento e qualsiasi garanzia derivante dal corso delle trattative o dall\'uso commerciale. Non garantiamo che il Servizio sarà ininterrotto, accurato o privo di errori, o che qualsiasi Contenuto sarà sicuro, non perso o non alterato.',
    block22:
      '(c) Limitazione di responsabilità. Né noi, né i nostri affiliati, né i nostri licenziatari saremo responsabili per eventuali danni indiretti, incidentali, speciali, consequenziali o punitivi, inclusa la perdita di profitti, perdita di reputazione, perdita di utilizzo, perdita di dati o altre perdite immateriali, anche se siamo stati informati della possibilità di tali danni. Secondo questi termini, la nostra responsabilità totale non supererà le Commissioni pagate per il Servizio nei dodici (12) mesi precedenti il ​​reclamo o cento dollari statunitensi ($ 100), a seconda di quale valore sia maggiore. Le limitazioni in questa sezione si applicano nella misura massima consentita dalla legge applicabile.',
    block23:
      "I Termini e l'utilizzo dei Servizi sono regolati dalle leggi degli Stati Uniti, ad eccezione delle norme sui conflitti di leggi statunitensi. L'utilizzo dell'Applicazione può essere soggetto anche ad altre leggi locali, statali, nazionali o internazionali. Qualsiasi azione legale o procedimento relativo ai presenti Termini sarà portato esclusivamente nei tribunali degli Stati Uniti e l'utente accetta di sottoporsi alla giurisdizione personale di tali tribunali.",
    block24:
      "(a) Rapporto tra le parti. Noam e tu siete contraenti indipendenti e nessuna delle parti ha il potere di rappresentare o vincolare l'altra parte o assumere obblighi per l'altra parte senza il previo consenso scritto dell'altra parte.",
    block25:
      '(b) Utilizzo del marchio. Non è possibile utilizzare il nome, i loghi o i marchi di Noam o di una delle sue affiliate senza il nostro previo consenso scritto.',
    block26:
      'Se una qualsiasi disposizione di questi termini viene ritenuta non valida, illegale o inapplicabile, le restanti disposizioni rimarranno in vigore a tutti gli effetti.',
    block3:
      "Se hai meno di 18 anni, devi ottenere il permesso dai tuoi genitori o tutori legali per utilizzare il Servizio. Se utilizzi il Servizio per conto di un'altra persona o entità, devi essere autorizzato ad accettare questi termini per loro conto. È necessario fornire informazioni accurate e complete per registrare un account. Non puoi fornire le tue credenziali di accesso o il tuo account a nessuno al di fuori della tua organizzazione e sei responsabile di tutte le attività condotte utilizzando le tue credenziali.",
    block4:
      '(a) Utilizzo del Servizio. Puoi accedere al Servizio secondo questi termini e ti concediamo un diritto non esclusivo di utilizzare il Servizio. Quando utilizzi il Servizio, rispetterai questi termini e tutte le leggi applicabili. Noi e i nostri affiliati possediamo tutti i diritti, titoli e interessi nel Servizio.',
    block5:
      '(b) Feedback. Accogliamo con favore feedback, commenti, idee, suggerimenti e miglioramenti. Se fornisci tali contenuti, potremmo utilizzarli senza alcuna restrizione e senza alcun compenso per te.',
    block6:
      "(c) Restrizioni. Non è possibile: (1) utilizzare il Servizio in un modo che violi, si appropri indebitamente o violi i diritti di altri; (2) decodificare, decompilare, disassemblare, tradurre o tentare in altro modo di scoprire il codice sorgente o i componenti sottostanti dei modelli, degli algoritmi e dei sistemi del Servizio (a meno che tali restrizioni non siano vietate dalla legge applicabile); (3) utilizzare i risultati del Servizio per sviluppare modelli concorrenti di Noam; (4) estrarre dati o output dal Servizio utilizzando qualsiasi metodo automatizzato o di programmazione se non consentito tramite l'API, inclusi scraping, raccolta web o estrazione di dati web; (5) rappresentare l'output del Servizio come generato artificialmente quando non lo è, o violare in altro modo le nostre politiche di utilizzo; (6) acquistare, vendere o trasferire chiavi API senza il nostro previo consenso; o (7) fornirci informazioni personali di bambini di età inferiore a 13 anni o all'età applicabile del consenso digitale. Rispetterai eventuali limiti tariffari e altri requisiti indicati nella nostra documentazione.",
    block7:
      '(d) Servizi di terze parti. Eventuali software, servizi o altri prodotti di terze parti associati al Servizio sono regolati dai rispettivi termini e non siamo responsabili per i prodotti di terze parti.',
    block8:
      '(a) I tuoi contenuti. L\'utente può fornire input ("Input") al Servizio e ricevere output ("Output") generato e restituito in base all\'Input (collettivamente denominato "Contenuto"). Tra le parti, e nella misura consentita dalla legge applicabile, sei proprietario di tutti gli Input. Noam può utilizzare i Contenuti per fornire e mantenere il Servizio, rispettare le leggi applicabili e far rispettare le nostre politiche. L\'utente è responsabile del Contenuto, inclusa la garanzia che non violi alcuna legge applicabile o questi termini.',
    block9:
      "(b) Precisione. L’intelligenza artificiale e l’apprendimento automatico sono campi di ricerca in rapida evoluzione. Ci impegniamo costantemente a migliorare il nostro servizio per renderlo più accurato, affidabile, sicuro e vantaggioso. Data la natura probabilistica dell'apprendimento automatico, in alcuni casi l'utilizzo del nostro servizio potrebbe comportare risultati errati, che potrebbero non riflettere accuratamente persone, luoghi o fatti reali. Dovresti valutare adeguatamente l'accuratezza di qualsiasi output in base al tuo caso d'uso, anche rivedendo manualmente gli output.",
    subTitle1: 'Registrazione e accesso',
    subTitle10: 'Separabilità',
    subTitle2: 'Requisiti di utilizzo',
    subTitle3: 'Contenuto',
    subTitle4: 'Commissioni e pagamenti',
    subTitle5: 'Riservatezza, sicurezza e protezione dei dati',
    subTitle6: 'Durata e risoluzione',
    subTitle7: 'Indennizzo; dichiarazioni di non responsabilità; Limitazione di responsabilità',
    subTitle8: 'Legge applicabile e giurisdizione',
    subTitle9: 'Condizioni generali',
    title: 'Termini di servizio',
    updateTime: 'Aggiornato: 15 marzo 2024',
  },
  trialModal: {
    btn: 'Inizia ora（Solo $ 0,99）',
    chat: 'ChatPDF',
    desc1: 'Al termine del periodo di prova ti verrà addebitata una tariffa annuale di $ 180',
    desc2: 'Annulla in qualsiasi momento',
    focus: 'Messa a fuoco',
    hover: 'Traduzione al passaggio del mouse',
    image: 'Traduzione di immagini',
    installBtn: 'Installalo ora per ricevere il tuo regalo',
    memo: 'Gestione dei preferiti',
    pdf: 'Traduzione parallela PDF',
    realtime: 'Traduzione istantanea',
    select: 'Traduzione della selezione di parole',
    title: 'Regalo di benvenuto',
    trialText: 'Prova di 7 giorni',
    video: 'Traduzione video',
    videoSummary: 'Riepilogo video',
    web: 'Traduzione parallela',
    webSummary: 'Riepilogo web',
  },
  upgradeModal: {
    btn: 'Aggiorna ora',
    chat: {
      free: 'Hai raggiunto il limite di ChatPDF',
      pro: 'Hai raggiunto il limite di ChatPDF per questo mese',
    },
    contactStr: "Attendi il prossimo ripristino o contattaci all'indirizzo contact@noam.tools.",
    limitAlertStr: 'Hai raggiunto il limite del tuo piano gratuito. Aggiorna per continuare.',
    more: 'Visualizza i piani',
    pageLimit: { free: 'Hai raggiunto il limite di pagine PDF' },
    title: 'Promemoria',
    upgradeAlertStr: 'Hai raggiunto il limite del tuo piano gratuito. Aggiorna per continuare.',
    upload: {
      free: 'Hai raggiunto il limite di traduzione PDF',
      pro: 'Hai raggiunto il limite di traduzioni PDF per questo mese',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Iscriviti ora',
      label: 'Nuovi vantaggi per gli utenti:',
      remain: 'Ne sono rimasti solo 30',
      text: 'Solo 100 posti disponibili! Prendi il tuo adesso!',
    },
  },
}

export default TRANSLATION
