const TRANSLATION = {
  buyModal: {
    afterFree: 'Po bezpłatnym okresie próbnym',
    btn: 'Wypróbuj za darmo',
    freeTrial: '3-dniowy bezpłatny okres próbny',
    monthLabel: 'miesiąc',
    selectLabel: 'Wybierz plan',
    title: 'Abonament',
    yearLabel: 'rok',
  },
  contact: {
    block1:
      'W przypadku dostosowań, pytań lub sugestii prosimy o kontakt pod adresem contact@noam.tools. Odpowiemy niezwłocznie.',
    block2: 'Wesprzyj nas, oceniając nas w sklepie z rozszerzeniami Google Apps pod adresem ★★★★★.',
    block3: 'Pomóż innym, udostępniając to rozszerzenie:',
    title: 'Skontaktuj się z nami',
  },
  help: {
    AquickOverviewOfNoam: 'Szybki przegląd Noama',
    ChatPDF: 'Czat w formacie PDF',
    ChatPDF2: 'CzatPDF',
    FavoritesManagement: 'Zarządzanie ulubionymi',
    Fixedplugin: 'Naprawiono wtyczkę',
    Focusing: 'Skupienie',
    Free: 'Bezpłatny',
    FreeTrialBenefits: 'Korzyści z bezpłatnego okresu próbnego',
    GoWithWikipedia: 'Idź z Wikipedią',
    GoWithYouTube: "Korzystaj z YouTube'a",
    HoverTranslation: 'Tłumaczenie kursora',
    Howtouse: 'Jak używać',
    ImageTranslation: 'Tłumaczenie obrazu',
    InstantTranslation: 'Natychmiastowe tłumaczenie',
    Membership: 'Członkostwo',
    NoamHelp: 'Noam pomaga w tłumaczeniu i podsumowywaniu',
    PDFparallelTranslation: 'Tłumaczenie równoległe PDF',
    ParallelTranslation: 'Tłumaczenie równoległe',
    SkipAllSteps: 'Pomiń wszystkie kroki',
    Summary: 'Streszczenie',
    Translation: 'Tłumaczenie',
    TryItForFree: 'Wypróbuj za darmo',
    Video: 'Wideo',
    VideoSummary: 'Podsumowanie wideo',
    VideoTranslation: 'Tłumaczenie wideo',
    WebSummary: 'Podsumowanie internetowe',
    WordSelectionTranslation: 'Tłumaczenie wyboru słów',
    benefits: 'Czekają na Ciebie korzyści!',
    dayFree: '3-dniowy bezpłatny okres próbny',
    done: 'Zrobione',
    next: 'Następny',
    pin: 'Pin Noam dla łatwiejszego dostępu～～',
    planIsNotExist: 'Plan nie jest dostępny',
    startWiki: 'Zacznijmy od Wikimediów!',
    startYoutube: 'Zacznijmy od YouTube!',
    try: 'Wypróbuj',
    tryNow: 'Spróbuj teraz!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tłumaczenie dwujęzyczne + Czat PDF',
      sub1: {
        desc: 'Czytanie dwujęzyczne umożliwia porównanie tekstu oryginalnego i tłumaczenia obok siebie, co poprawia efektywność czytania.',
        title: 'Czytanie dwujęzyczne',
      },
      sub2: {
        desc: 'AI analizuje i podsumowuje oraz może odpowiedzieć na Twoje pytania w oparciu o zawartość pliku PDF.',
        title: 'Czat w formacie PDF',
      },
      sub3: {
        desc: 'Tłumaczenie plików PDF wspomagane przez sztuczną inteligencję zapewnia większą dokładność i wydajność.',
        title: 'Dokładność i wydajność',
      },
      title: 'Tłumacz PDF',
    },
    aiAssistant: {
      desc: 'Dzięki czemu tłumaczenie internetowe jest mądrzejsze i wygodniejsze',
      panel1: {
        desc: 'Dwujęzyczne tłumaczenia porównawcze pomagają porównać tekst oryginalny z tłumaczeniem, dzięki czemu możesz lepiej zrozumieć i poprawić efektywność czytania.',
        title: 'Tłumaczenie dwujęzyczne',
      },
      panel2: {
        left: {
          desc: 'Przeglądając stronę internetową, najedź kursorem na treść, którą chcesz przetłumaczyć, aby zobaczyć tłumaczenie.',
          title: 'Tłumaczenie kursora',
        },
        right: {
          desc: 'Przeglądając stronę internetową, wybierz tekst, który chcesz przetłumaczyć, aby zobaczyć tłumaczenie.',
          title: 'Tłumaczenie wyboru tekstu',
        },
      },
      panel3: {
        one: {
          desc: 'Tłumacz i podsumowuj strony internetowe oraz treści wideo w dowolnym miejscu i czasie, aby zapewnić płynniejszą obsługę!',
          title: 'Wskaż i kliknij',
        },
        three: {
          desc: 'Model dużego języka oparty na sztucznej inteligencji zapewnia dokładność i spójność tłumaczenia, co przekłada się na lepsze wrażenia z czytania.',
          title: 'Oparte na sztucznej inteligencji',
        },
        two: {
          desc: 'Pełna obsługa wielu języków, dwujęzyczna lektura bez barier, tłumaczenie jednym kliknięciem – poczuj różnicę!',
          title: 'Bardziej wszechstronne i wygodne',
        },
      },
      panel4: {
        tab1: {
          desc: 'Twój ekspert w zakresie wydajności — korzystając z ChatGPT, Noam tłumaczy i podsumowuje treści wideo YouTube, aby zaoszczędzić Twój czas!',
          key: 'Wideo',
          title: 'Tłumaczenie i podsumowanie wideo',
        },
        tab2: {
          desc: 'Kliknij, aby przetłumaczyć obrazy podczas przeglądania Internetu.',
          key: 'Obraz',
          title: 'Tłumaczenie obrazu',
        },
        tab3: {
          desc: 'Natychmiast wyodrębnia kluczowe informacje ze stron internetowych i przedstawia podsumowanie, zwiększając efektywność czytania.',
          key: 'Sieć',
          title: 'Tłumaczenie internetowe i podsumowanie',
        },
        tag1: 'Prosty',
        tag2: 'Wydajny',
        tag3: 'Łatwe do zrozumienia',
      },
      title: 'Asystent AI',
    },
    getStart: 'Rozpocznij',
    getStartedForFree: 'Rozpocznij bezpłatnie',
    howItWork: {
      desc: 'Noam integruje modele sztucznej inteligencji, aby zapewnić użytkownikom inteligentne doświadczenie w zakresie tłumaczenia i podsumowań stron internetowych w wielu językach, tłumaczenia plików PDF i obrazów, tłumaczenia i podsumowań wideo',
      title: 'Jak to działa',
    },
    lastBlock: { desc: 'Lepsze tłumaczenie AI dla płynniejszego czytania' },
    top: {
      desc: 'Noam to bezpłatne rozszerzenie przeglądarki AI, które pomaga tłumaczyć i podsumowywać strony internetowe, filmy i treści PDF.\\n Dołącz do Noam i poczuj magię!',
      title1: 'Jedna magia',
      title2: 'Jedno kliknięcie',
    },
    userReviews: {
      desc: 'Pięciogwiazdkowe recenzje w sklepie Chrome Web Store',
      title: 'Recenzje użytkowników',
    },
  },
  installTips: {
    btn: 'Wypróbuj teraz',
    text: 'Zainstaluj „Wtyczkę do przeglądarki Noam”, aby odblokować więcej funkcji! Odsyłacze internetowe,\\n podsumowania wideo w Internecie i wiele więcej!',
  },
  locale: {
    af: 'Afrykanerski',
    am: 'amharski',
    ar: 'arabski',
    as: 'asamski',
    auto: 'Automatyczne wykrywanie',
    az: 'azerbejdżański',
    ba: 'Baszkir',
    bg: 'bułgarski',
    bn: 'Bangla',
    bo: 'tybetański',
    bs: 'bośniacki',
    ca: 'kataloński',
    cs: 'czeski',
    cy: 'walijski',
    da: 'duński',
    de: 'niemiecki',
    dsb: 'dolnołużycki',
    dv: 'Divehi',
    el: 'grecki',
    en: 'angielski',
    es: 'hiszpański',
    et: 'estoński',
    eu: 'baskijski',
    fa: 'perski',
    fi: 'fiński',
    fil: 'Filipiński',
    fj: 'Fidżi',
    fr: 'francuski',
    frCA: 'Francuski (Kanada)',
    ga: 'irlandzki',
    gl: 'galicyjski',
    gom: 'Konkani',
    gu: 'gudżarati',
    ha: 'Hausa',
    he: 'hebrajski',
    hi: 'hinduski',
    hr: 'chorwacki',
    hsb: 'Górnołużycki',
    ht: 'Kreolski haitański',
    hu: 'węgierski',
    hy: 'ormiański',
    id: 'indonezyjski',
    ig: 'Ibo',
    ikt: 'Inuinnaqtun',
    is: 'islandzki',
    it: 'włoski',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (łac.)',
    ja: 'japoński',
    ka: 'gruziński',
    kk: 'kazachski',
    km: 'Khmer',
    kmr: 'kurdyjski (północny)',
    kn: 'Kannada',
    ko: 'koreański',
    ku: 'Kurdyjski (centralny)',
    ky: 'Kirgiski',
    ln: 'Lingala',
    lo: 'Laotański',
    lt: 'litewski',
    lug: 'Ganda',
    lv: 'łotewski',
    lzh: 'Chiński (literacki)',
    mai: 'Maithili',
    mg: 'malgaski',
    mi: 'Maorysi',
    mk: 'Macedoński',
    ml: 'malajalam',
    mnCyrl: 'mongolski (cyrylica)',
    mnMong: 'mongolski (tradycyjny)',
    mr: 'Marathi',
    ms: 'malajski',
    mt: 'maltański',
    mww: 'Hmong Daw',
    my: 'Birma (birmański)',
    nb: 'norweski',
    ne: 'Nepalski',
    nl: 'Holenderski',
    noSearchLang: 'Język nie jest obsługiwany',
    nso: 'Sesotho na Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pendżabski',
    pl: 'Polski',
    placeholder: 'Szukaj',
    prs: 'Dari',
    ps: 'paszto',
    pt: 'portugalski (Brazylia)',
    ptPT: 'portugalski (Portugalia)',
    ro: 'rumuński',
    ru: 'rosyjski',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'syngaleski',
    sk: 'słowacki',
    sl: 'słoweński',
    sm: 'Samoański',
    sn: 'Shona',
    so: 'somalijski',
    sq: 'albański',
    srCyrl: 'serbski (cyrylica)',
    srLatn: 'serbski (łaciński)',
    st: 'Sesoto',
    sv: 'szwedzki',
    sw: 'Suahili',
    ta: 'Tamil',
    te: 'telugu',
    th: 'tajski',
    ti: 'Tigrinia',
    tk: 'Turkmenów',
    tlhLatn: 'klingoński (łac.)',
    tlhPiqd: 'klingoński (pIqaD)',
    tn: 'Setswany',
    to: 'Tongijski',
    tr: 'turecki',
    tt: 'Tatar',
    ty: 'tahitański',
    ug: 'Ujgur',
    uk: 'ukraiński',
    ur: 'urdu',
    uz: 'uzbecki (łac.)',
    vi: 'wietnamski',
    xh: 'Xhosa',
    yo: 'Joruba',
    yua: 'Maja Yucatec',
    yue: 'Kantoński (tradycyjny)',
    zh: 'chiński',
    zhHans: 'Chiński uproszczony',
    zhHant: 'Chiński tradycyjny',
    zu: 'Zulus',
  },
  login: {
    and: 'I',
    btn: 'Zaloguj się za pomocą Google',
    btn1: 'Zaloguj się teraz',
    desc: 'Zaloguj się, aby odblokować więcej wrażeń',
    policy: 'Polityka prywatności',
    terms: 'Warunki użytkowania',
    tips: 'Rejestrując się, wyrażam zgodę na',
    title: 'Witamy w Noamie',
  },
  pageFooter: {
    follow: { facebook: 'Facebooku', title: 'Podążać', twitter: 'Świergot' },
    legal: { policy: 'Polityka prywatności', terms: 'Warunki świadczenia usług', title: 'Prawny' },
    product: {
      image: 'Tłumaczenie obrazu',
      pdf: 'Tłumaczenie PDF',
      title: 'Produkty',
      video: 'Tłumaczenie i podsumowanie wideo',
      web: 'Tłumaczenie internetowe i podsumowanie',
    },
    resources: { title: 'Zasoby' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Pomoc',
    logout: 'Wyloguj się',
    logoutSucc: 'Wylogowałem się.',
    pricing: 'Wycena',
    settings: 'Ustawienia',
    signUp: 'Zaloguj się',
  },
  pdf: {
    autoDetect: 'Automatyczne wykrywanie',
    cancelBtn: 'Anulować',
    cancelUpload: 'Anulować',
    chatPdf: {
      Search: 'Szukaj',
      cancel: 'Anulować',
      chatTitle: 'Witamy w Noamie! Oto podsumowanie Twojego pliku PDF:',
      clear: 'Jasne',
      clearContent:
        'To rozpocznie nową rozmowę. Bieżąca rozmowa nie zostanie zapisana. Czy jesteś pewien?',
      clearDialogTitle: 'Proszę potwierdzić',
      clearSucc: 'Wyczyszczone',
      copy: 'Kopia',
      copySucc: 'Skopiowano',
      delete: 'Usuwać',
      deleteCancel: 'Anulować',
      deleteContent: 'Usuniętego pliku PDF nie można odzyskać. Czy jesteś pewien?',
      deleteOk: 'Usuwać',
      deleteSuccess: 'Usunięto',
      deleteTitle: 'Potwierdź usunięcie',
      expand: 'Zawalić się',
      historyTitle: 'Historia',
      listError:
        'Nie udało się załadować listy do przeczytania. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
      loading: 'Załadunek...',
      noDataDesc: 'Nie ma jeszcze historii.',
      noMore: 'Żadnych więcej treści.',
      placeholder: 'Zapytaj o cokolwiek na temat tego pliku PDF',
      summaryTitle: 'Być może zainteresują Cię te pytania:',
    },
    collectionEmpty: 'Twoja kolekcja jest pusta.',
    collectionTab: 'Notatki',
    dataLoading: 'Załadunek...',
    dialogContent: 'Czy na pewno chcesz to usunąć?',
    dialogTitle: 'Proszę potwierdzić',
    download: {
      both: 'Dwujęzyczny',
      btn: 'Ratować',
      cancel: 'Zamknięcie tego okna anuluje pobieranie pliku PDF. Czy na pewno chcesz anulować?',
      desc: 'Zapisz na urządzeniu: pobiera tłumaczenia w postaci obrazów; Zapisz i drukuj: pobiera tłumaczenia w oryginalnym formacie, z możliwością kopiowania.',
      download: 'Pobierać',
      downloadMsg1: 'Postęp tłumaczenia PDF:',
      downloadMsg2: 'Szacowany pozostały czas:',
      downloadSucc: 'PDF jest gotowy! Kliknij, aby zapisać.',
      downloadTip:
        'Pobieranie pliku PDF. Nie zamykaj tego okna, aby uniknąć błędów podczas pobierania.',
      error: 'Nie udało się zapisać. Zamiast tego spróbuj wydrukować.',
      fileName: 'Nazwa pliku:',
      fileType: 'Typ pliku:',
      loading: 'Oszczędność...',
      loading2: 'Załadunek...',
      minute: 'Protokół...',
      msg1: 'Niektóre tłumaczenia w tym dokumencie są dłuższe niż tekst oryginalny i mogą nakładać się na siebie w pobranym pliku. Polecamy lekturę na stronie.',
      msg2: '1. Tłumaczenie nie jest jeszcze ukończone. Proszę pobrać po zakończeniu tłumaczenia.',
      msg3: '2. Niektóre tłumaczenia są za długie. Przeczytaj na stronie internetowej, aby uniknąć nakładania się tekstu.',
      msg4: 'Zadania tłumaczeniowe są w toku. Spróbuj ponownie po ich zakończeniu.',
      progress:
        'Tłumaczenie PDF-a. Postęp: {{postęp}}, szacowany pozostały czas: {{minuty}} minut...',
      save: 'Zapisz na urządzeniu',
      savePrint: 'Zapisz i wydrukuj',
      success: 'Pobrano',
      trans: 'Tylko tłumaczenie',
      transContinue: 'Kontynuuj tłumaczenie',
    },
    downloadBtn: 'Pobierać',
    dragTipModal: {
      black: 'przestrzeń',
      leftKey: 'lewy przycisk',
      ok: 'Rozumiem',
      press: 'Naciskać',
      tips: 'przeciągnąć i wyświetlić.',
    },
    entry: 'ERROR: [PDFs]',
    fileErrMsg:
      'Odczyt pliku nie powiódł się. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    fileMsg: 'Aktualny rozmiar pliku:',
    fileMsg2: 'Prześlij plik mniejszy niż 300MB.',
    freeBannerBtn: 'Aktualizacja',
    freeBannerTips: 'Osiągnięto limit bezpłatnych tłumaczeń plików PDF',
    guide: {
      placeholder: 'Wklej tutaj link do pliku PDF',
      start: 'Rozpocznij tłumaczenie',
      text1: 'Czytanie dwujęzyczne',
      text2:
        'Czytaj dwujęzycznie, aby porównać tekst oryginalny i tłumaczenie obok siebie, zwiększając w ten sposób swoją efektywność.',
      text3: 'Czatuj ze swoim plikiem PDF',
      text4:
        'Analiza i podsumowanie oparte na sztucznej inteligencji. Zadawaj pytania dotyczące plików PDF i otrzymuj natychmiastowe odpowiedzi.',
      text5: 'Dokładny i wydajny',
      text6:
        'Tłumaczenie plików PDF wspomagane przez sztuczną inteligencję zapewnia większą dokładność i wydajność.',
      title1: 'Tłumaczenie dwujęzyczne',
      title2: 'Czatuj ze swoim plikiem PDF',
      toast: 'Wprowadź prawidłowy link do pliku PDF online kończący się na .pdf',
    },
    hasTransTask: 'Zadanie tłumaczeniowe jest w toku. Proszę czekać.',
    hoverTrans: 'Najedź kursorem, aby przetłumaczyć akapity',
    initial: 'Przetłumacz PDF',
    installText: 'Twoje kompleksowe rozwiązanie do codziennych tłumaczeń.',
    installText2: 'Najpierw zainstaluj rozszerzenie',
    installTips:
      'Zainstaluj rozszerzenie, aby uzyskać jeszcze więcej tłumaczeń plików PDF i płynniejsze tłumaczenia stron internetowych:',
    kw: 'zszywka',
    link: 'połączyć',
    listError:
      'Nie udało się załadować plików PDF. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    memoTotalCount: 'Łącznie 1 pozycja',
    new: 'nowy plik',
    noId: 'Nie znaleziono identyfikatora pliku.',
    notPdf: 'Tylko pliki PDF.',
    okBtn: 'Usuwać',
    okBtn2: 'Rozumiem',
    original: 'Oryginalny',
    parallel: 'Równoległy',
    parseErrDialogContent:
      'Tego łącza do pliku PDF nie można bezpośrednio przetłumaczyć. Proszę pobrać plik PDF i przesłać go do tłumaczenia.',
    parseErrTips: 'Nie udało się przeanalizować pliku',
    parseFileToast:
      'Nie udało się przeanalizować pliku. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    pdfLoading: 'Rozbiór gramatyczny zdania...',
    searchPlaceholder: 'Szukaj',
    selectTrans: 'Wybierz Tekst do przetłumaczenia',
    shareBtn: 'Udział',
    shareBtnText1: 'Wygeneruj link do udostępniania',
    shareBtnText2: 'Aktualizuj udostępnioną zawartość',
    shareModal: {
      cancel: 'Anulować',
      copy: 'Kopia',
      copySucc: 'Link skopiowany',
      custom: 'Dostosuj:',
      errMsg:
        'Wybrana treść nie została jeszcze w pełni przetłumaczona. Proszę o przetłumaczenie przed udostępnieniem.',
      gen: 'Udział',
      modify: 'Redagować',
      notSupport: 'Ten język nie jest obecnie obsługiwany.',
      page: 'Strony',
      pageNuErr: 'Proszę wprowadzić prawidłowy numer strony.',
      range: 'Zakres:',
      remain: 'pozostały',
      remain1: 'Całkowity szacowany czas:',
      search: 'Szukaj',
      settings: 'Udostępnij ustawienia:',
      shareSetting: 'Udostępnij ustawienia',
      shareText: 'Udział',
      shareTips: 'Udostępnij link do przetłumaczonego pliku PDF swoim znajomym:',
      sourceLang: 'Z:',
      target: 'Do:',
      text1: 'Udostępnij ustawienia',
      text2: 'Wygeneruj link do udostępniania',
      totalPage: 'Łącznie 1 strona',
      trans: 'Tłumaczyć',
      translateTips:
        'Wskazówka: jeśli tłumaczenie zajmuje trochę czasu, możesz zamknąć to okno i udostępnić później.',
      translating: 'Tłumaczenie PDF...',
      update: 'Zaktualizuj łącze udostępniania',
    },
    shareMsg1: 'Udostępnij wygenerowany link.',
    shareMsg2: 'Zaktualizowano udostępnione treści.',
    shareMsg3:
      'Nie udało się wygenerować linku do udostępniania. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    shareMsg4:
      'Nie udało się zaktualizować udostępnionej zawartości. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    shareTips: 'Twoje tłumaczenie PDF jest gotowe! Podziel się nim ze swoimi przyjaciółmi.',
    start: 'Rozpocznij bezpłatny okres próbny',
    thumbnail: 'Spis treści',
    toast1: 'Ładowanie pliku PDF. Spróbuj ponownie później.',
    toast2: 'Zawartość pliku PDF jest zbyt duża. Okno dialogowe nie jest obecnie obsługiwane.',
    toast3: 'Ściąganie. Spróbuj ponownie po zakończeniu pobierania.',
    toolbar: { Adjust: 'Regulować', autoFit: 'Automatyczne dopasowanie' },
    trans: 'Tłumaczenie',
    transSucc: 'Tłumaczenie udane!',
    unLoginToast: 'Aby skorzystać z tej funkcji, zaloguj się.',
    uploadBtn: 'Wgrywać',
    uploadErr:
      'Przesyłanie pliku nie powiodło się. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    uploadMsg1: 'Przeciągnij i upuść tutaj swój plik.',
    uploadMsg2: 'Obsługiwane typy plików: PDF |  Maksymalny rozmiar pliku: 100 MB',
    uploadMsg3: 'Kliknij, aby wybrać lub przeciągnij i upuść pliki tutaj.',
    uploading: 'Przesyłanie',
    uploadingMsg: 'Przesyłanie. Proszę czekać.',
    uploadingMsg1: 'Trwa przesyłanie pliku. Spróbuj ponownie później.',
    uploadingMsg2: 'Plik jest aktualnie konwertowany. Spróbuj ponownie później.',
    uploadingMsg3:
      'Żądanie postępu konwersji nie powiodło się. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    uploadingMsg4:
      'Noam nie ma dostępu do plików lokalnych. Proszę ręcznie przesłać plik PDF do tłumaczenia.',
    uploadingMsg5:
      'Nie udało się pobrać adresu URL pliku. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    video: 'wideo',
    vipBannerBtn: 'Aktualizacja',
    vipBannerTips: 'Osiągnąłeś limit tłumaczeń plików PDF na ten miesiąc.',
    webTrans: 'Pełne odsyłacze',
  },
  pricing: {
    FAQ: 'Często zadawane pytania',
    FQAObj: {
      a1: 'Tak, Noam oferuje darmowy plan, z którego możesz skorzystać od razu.',
      a2: 'Oferujemy trzy plany członkostwa: Pro, Pro+ i Enterprise. Możesz wybrać płatność miesięczną lub roczną. Rozliczenie roczne zapewnia znaczne oszczędności.',
      a3: 'Wszystkie funkcje są dostępne w obu planach. Główną różnicą jest koszt: roczne subskrypcje kosztują ogólnie mniej. Abonamenty miesięczne są rozliczane co miesiąc, natomiast abonamenty roczne są rozliczane raz w roku.',
      a4: 'Możesz anulować subskrypcję w dowolnym momencie w Ustawieniach konta.',
      q1: 'Czy istnieje darmowy plan?',
      q2: 'Jakie plany cenowe i opcje płatności są dostępne?',
      q3: 'Jaka jest różnica pomiędzy subskrypcją miesięczną a roczną?',
      q4: 'Jak anulować subskrypcję?',
    },
    around: 'Wokół',
    billedMonthly: 'Rozliczane miesięcznie',
    billedYearly: 'Rozliczane co roku',
    day: 'dzień',
    desc: 'Porównaj plany i wybierz najlepszy',
    detailedCompare: 'Porównaj plany',
    discount: '20% zniżki',
    enterprise: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: 'Wielu użytkowników',
      benefit3: 'Rozwiązania szyte na miarę',
      benefit5: 'Dedykowany Account Manager',
      btn: 'Skontaktuj się z nami',
      recommendText: 'Plany niestandardowe (≥3人)',
      talkToUs: 'Skontaktuj się',
    },
    free: {
      benefit1: 'Natychmiastowe tłumaczenie 100 razy dziennie',
      benefit2: 'Tłumaczenie i podsumowanie witryny internetowej',
      benefit3: '30 tłumaczeń obrazów',
      benefit4: 'Tłumaczenie i podsumowanie wideo',
      benefit5: '10 tłumaczeń PDF',
      benefit6: '5000 tokenów',
      btn: 'Wypróbuj za darmo',
      recommendText: 'Bezpłatny okres próbny',
    },
    imageTranslation: 'Tłumaczenie obrazu',
    month: 'miesiąc',
    monthly: 'Miesięczny',
    pageTitle: 'Wycena',
    pdf: {
      chat: 'Zapytania na czacie w formacie PDF',
      maxPage: 'Maks. Strony w formacie PDF',
      translation: 'Tłumaczenie PDF',
    },
    preMonth: 'Na miesiąc',
    pro: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: '1 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie i podsumowanie witryny internetowej',
      benefit4: '200 tłumaczeń obrazów',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: '200 tłumaczeń PDF',
      btn: 'Rozpocznij',
      recommendText: 'Najpopularniejsze',
    },
    proPlus: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: '3 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie i podsumowanie witryny internetowej',
      benefit4: '500 tłumaczeń obrazów',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: '500 tłumaczeń PDF',
      recommendText: 'Najlepsza wartość',
    },
    video: { summary: 'Podsumowanie wideo', translation: 'Tłumaczenie wideo' },
    web: {
      contrastiveTranslation: 'Tłumaczenie dwujęzyczne',
      dictionaryTranslation: 'Tłumaczenie wyboru',
      hoverTranslation: 'Najedź na tłumaczenie',
      instantTranslation: 'Natychmiastowe tłumaczenie',
      summary: 'Podsumowanie strony internetowej',
    },
    yearly: 'Rocznie',
  },
  privacy: {
    block1:
      'Noam („Noam”, „my”, „nasz” lub „nas”) szanuje Twoją prywatność i zobowiązuje się chronić wszelkie informacje, które uzyskujemy od Ciebie lub o Tobie. Niniejsza Polityka prywatności opisuje nasze praktyki dotyczące gromadzenia lub wykorzystywania danych osobowych podczas korzystania z naszej witryny internetowej, aplikacji i usług (zwanych łącznie „Usługami”). Niniejsza Polityka prywatności nie ma zastosowania do treści, którymi zarządzamy w imieniu naszych klientów korzystających z produktów biznesowych, takich jak nasze API. Wykorzystanie tych danych podlega naszej umowie z klientem, która obejmuje dostęp do tych produktów i korzystanie z nich.',
    block10:
      'Wdrażamy uzasadnione z handlowego punktu widzenia środki techniczne, administracyjne i organizacyjne, aby chronić dane osobowe w Internecie i poza nim przed utratą, niewłaściwym wykorzystaniem i nieuprawnionym dostępem, ujawnieniem, zmianą lub wrogimi działaniami. Żadna transmisja internetowa ani e-mailowa nie gwarantuje jednak całkowitego bezpieczeństwa ani dokładności. W szczególności wiadomości e-mail wysyłane do nas lub od nas mogą nie być bezpieczne. Dlatego też należy zachować szczególną ostrożność przy podejmowaniu decyzji, jakie informacje przesłać nam za pośrednictwem serwisu lub poczty elektronicznej. Ponadto nie ponosimy odpowiedzialności za jakiekolwiek utrudnianie ustawień prywatności lub środków bezpieczeństwa w serwisie lub ustawieniach bezpieczeństwa lub środkach bezpieczeństwa Stron Trzecich. Będziemy przechowywać Twoje dane osobowe tak długo, jak będzie to konieczne, abyśmy mogli świadczyć Ci usługi lub w innych uzasadnionych celach biznesowych, takich jak rozwiązywanie sporów, względy bezpieczeństwa lub wywiązywanie się z naszych zobowiązań prawnych. Długość czasu, przez jaki przechowujemy dane osobowe, będzie zależeć od wielu czynników, takich jak ilość, charakter, wrażliwość informacji, potencjalne ryzyko nieuprawnionego wykorzystania lub ujawnienia, cele, dla których przetwarzamy informacje, oraz inne prawnie wiążące względy.',
    block11:
      'Od czasu do czasu możemy aktualizować niniejszą Politykę prywatności. Gdy to zrobimy, opublikujemy zaktualizowaną wersję na tej stronie, chyba że obowiązujące prawo stanowi inaczej.',
    block12:
      'Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące niniejszej Polityki prywatności, na które nie odpowiedziałeś, skontaktuj się z naszym działem obsługi klienta. E-mail:',
    block2: 'Gromadzimy następujące dane osobowe („Dane osobowe”) dotyczące Ciebie:',
    block3:
      'Informacje podawane przez Ciebie: Kiedy tworzysz konto, aby korzystać z naszych usług lub komunikować się z nami, zbieramy dane osobowe w następujący sposób:',
    block4:
      'Dane osobowe otrzymywane automatycznie w wyniku korzystania przez Ciebie z usług: Kiedy uzyskujesz dostęp do usług, korzystasz z nich lub wchodzisz z nimi w interakcję, otrzymujemy następujące informacje o Twoim dostępie, użytkowaniu lub interakcji („Informacje techniczne”):',
    block5: 'Możemy wykorzystywać dane osobowe w następujących celach:',
    block6:
      'Informacje podsumowujące lub pozbawione danych identyfikacyjnych: Możemy agregować lub usuwać dane osobowe, aby zapobiec ich ponownemu wykorzystaniu do celów identyfikacyjnych i wykorzystywać takie informacje do analizy skuteczności naszych usług, ulepszania i dodawania funkcji do naszych usług, prowadzenia badań i do podobnych celów . Ponadto możemy analizować ogólne zachowanie i cechy użytkowników naszych usług oraz udostępniać zbiorcze informacje stronom trzecim, publikować takie zbiorcze informacje lub udostępniać je powszechnie. Możemy gromadzić informacje zbiorcze za pośrednictwem usług, plików cookie i innych środków opisanych w niniejszej Polityce prywatności. Będziemy przechowywać i wykorzystywać informacje pozbawione danych identyfikacyjnych w formie anonimowej lub pozbawionej elementów umożliwiających identyfikację i nie będziemy podejmować prób ponownej identyfikacji takich informacji, chyba że będzie to wymagane przez prawo.',
    block7:
      'W niektórych przypadkach możemy udostępniać Twoje dane osobowe stronom trzecim bez dodatkowego powiadomienia, chyba że jest to wymagane przez prawo:',
    block8:
      'W zależności od Twojej lokalizacji geograficznej osobom w Europejskim Obszarze Gospodarczym, Wielkiej Brytanii i na całym świecie mogą przysługiwać pewne ustawowe prawa dotyczące ich danych osobowych. Na przykład możesz mieć prawo do:',
    block9:
      'Usługa może zawierać łącza do innych stron internetowych, które nie są obsługiwane ani kontrolowane przez firmę Noam, w tym do serwisów społecznościowych („Strony internetowe osób trzecich”). Informacje, które udostępniasz witrynom stron trzecich, będą podlegać szczegółowym zasadom ochrony prywatności i warunkom korzystania z witryn stron trzecich, a nie niniejszej Polityce prywatności. Linki te udostępniamy dla wygody i nie oznacza to naszego poparcia ani recenzji tych witryn. Aby uzyskać informacje na temat ich polityki prywatności i warunków, należy skontaktować się bezpośrednio z witrynami stron trzecich.',
    subBlock1:
      'Informacje o koncie: Kiedy tworzysz konto na naszej platformie, zbieramy informacje związane z Twoim kontem, w tym Twoje imię i nazwisko, dane kontaktowe, dane uwierzytelniające do konta, informacje o karcie płatniczej i historię transakcji („Informacje o koncie”).',
    subBlock10: 'Świadczenie, zarządzanie, utrzymywanie i/lub analizowanie usług;',
    subBlock11: 'Komunikowanie się z Tobą;',
    subBlock12: 'Opracowywanie nowych produktów i usług;',
    subBlock13:
      'Zapobieganie oszustwom, działalności przestępczej lub niewłaściwemu korzystaniu z naszych usług oraz ochrona bezpieczeństwa naszych systemów informatycznych, architektury i sieci;',
    subBlock14: 'Przeprowadzanie transferów biznesowych;',
    subBlock15:
      'Przestrzeganie obowiązków prawnych i postępowań prawnych, ochrona naszych praw, prywatności, bezpieczeństwa lub własności, a także praw naszych podmiotów stowarzyszonych, Ciebie lub innych stron trzecich.',
    subBlock16:
      'Dostawcy i usługodawcy: Aby pomóc nam w zaspokajaniu potrzeb operacyjnych firmy oraz wykonywaniu określonych usług i funkcji, możemy udostępniać dane osobowe dostawcom i usługodawcom, w tym dostawcom usług hostingowych, dostawcom usług w chmurze i innym dostawcom usług technologii informatycznych, oprogramowaniu do komunikacji e-mailowej oraz dostawcy usług analityki internetowej itp. Partnerzy ci będą uzyskiwać dostęp do danych osobowych, je przetwarzać i przechowywać wyłącznie na nasze polecenie podczas wykonywania swoich obowiązków dla nas.',
    subBlock17:
      'Przeniesienie działalności: Jeśli angażujemy się w strategiczne transakcje, reorganizacje, bankructwa, przejęcia upadłościowe lub przeniesienie usług do innego dostawcy (łącznie zwane „Transakcjami”), Twoje dane osobowe i inne informacje mogą zostać udostępnione kontrahentom transakcji i innym stronom pomagającym w Transakcjach w trakcie badania due diligence i przekazane w ramach Transakcji wraz z innymi aktywami następcy lub spółce stowarzyszonej.',
    subBlock18:
      'Wymogi prawne: Możemy udostępniać Twoje dane osobowe organom rządowym, partnerom z branży lub innym stronom trzecim, w tym informacje o Twoich interakcjach z naszymi usługami, (1) jeśli jest to wymagane przez prawo lub jeśli uważamy, że takie działanie jest konieczne w celu wywiązania się z obowiązków prawnych; (2) w celu ochrony i obrony naszych praw lub własności; (3) jeśli jednostronnie stwierdzimy, że nastąpiło naruszenie naszych warunków, zasad lub przepisów; (4) w celu wykrywania lub zapobiegania oszustwom lub innym nielegalnym działaniom; (5) w celu ochrony naszych produktów, pracowników lub użytkowników lub bezpieczeństwa publicznego, ochrony i integralności; lub (vi) w celu obrony przed odpowiedzialnością prawną.',
    subBlock19:
      'Uzyskaj dostęp do swoich danych osobowych i informacji o sposobie ich przetwarzania.',
    subBlock2:
      'Treści użytkownika: Kiedy korzystasz z naszych usług, zbieramy dane osobowe na podstawie Twoich danych wejściowych, przesłanych plików lub przekazanych nam opinii („Treść”).',
    subBlock20: 'Poproś o usunięcie swoich danych osobowych z naszych rejestrów.',
    subBlock21: 'Popraw lub zaktualizuj swoje dane osobowe.',
    subBlock22: 'Prześlij swoje dane osobowe stronie trzeciej (przenoszenie danych).',
    subBlock23: 'Ogranicz sposób, w jaki przetwarzamy Twoje dane osobowe.',
    subBlock24:
      'Wycofać swoją zgodę – jeżeli podstawą prawną przetwarzania jest zgoda, możesz ją wycofać w dowolnym momencie.',
    subBlock25: 'Sprzeciw wobec sposobu, w jaki przetwarzamy Twoje dane osobowe.',
    subBlock26: 'Udoskonalanie naszych usług i prowadzenie badań;',
    subBlock3:
      'Informacje komunikacyjne: Jeśli przesyłasz nam wiadomości, zbieramy Twoje imię i nazwisko, dane kontaktowe oraz treść Twoich wiadomości („Informacje komunikacyjne”).',
    subBlock4:
      'Informacje o mediach społecznościowych: Mamy strony w serwisach społecznościowych, takich jak Instagram, Facebook, Medium, Twitter, YouTube i LinkedIn. Kiedy wchodzisz w interakcję z naszymi stronami w mediach społecznościowych, zbieramy dane osobowe, które nam przekazujesz, takie jak dane kontaktowe („Dane społecznościowe”). Ponadto firmy hostujące nasze strony w mediach społecznościowych mogą dostarczać nam zbiorcze informacje i analizy dotyczące naszych działań w mediach społecznościowych.',
    subBlock5:
      'Dane dziennika: Informacje automatycznie wysyłane przez Twoją przeglądarkę podczas korzystania z naszych usług. Dane dziennika obejmują adres protokołu internetowego (IP), typ i ustawienia przeglądarki, datę i godzinę Twoich żądań oraz sposób interakcji z naszą witryną.',
    subBlock6:
      'Dane o użytkowaniu: Możemy automatycznie zbierać informacje na temat Twojego korzystania z usługi, takie jak rodzaje treści, które przeglądasz lub z którymi wchodzisz w interakcję, funkcje, z których korzystasz, podejmowane przez Ciebie działania, a także Twoja strefa czasowa, kraj, data i godzina dostęp, agent użytkownika i wersja, typ komputera lub urządzenia mobilnego oraz połączenie z komputerem.',
    subBlock7:
      'Informacje o urządzeniu: obejmują nazwę urządzenia, system operacyjny, identyfikator urządzenia i używaną przeglądarkę. Gromadzone informacje mogą zależeć od rodzaju urządzenia, z którego korzystasz i jego ustawień.',
    subBlock8:
      'Pliki cookie: Używamy plików cookie do obsługi i zarządzania naszymi usługami oraz poprawy komfortu korzystania z nich. „Cookie” to informacja wysyłana przez odwiedzaną przez Ciebie stronę internetową do Twojej przeglądarki. Możesz ustawić swoją przeglądarkę tak, aby akceptowała wszystkie pliki cookie, odrzucała wszystkie pliki cookie lub powiadamiała Cię o udostępnieniu pliku cookie, abyś mógł za każdym razem zdecydować, czy go zaakceptować. Jednakże odrzucenie plików cookie może uniemożliwić korzystanie z witryny lub negatywnie wpłynąć na wyświetlanie lub funkcjonalność niektórych obszarów lub funkcji witryny. Więcej informacji na temat plików cookie można znaleźć na stronie Wszystko o plikach cookie.',
    subBlock9:
      'Analityka: Możemy korzystać z różnych produktów do analityki internetowej, które wykorzystują pliki cookie, aby pomóc nam analizować, w jaki sposób użytkownicy korzystają z naszych usług i poprawiać komfort korzystania z usług.',
    subTitle1: 'Dane osobowe, które gromadzimy',
    subTitle2: 'Jak wykorzystujemy dane osobowe',
    subTitle3: 'Ujawnianie danych osobowych',
    subTitle4: 'Twoje prawa',
    subTitle5: 'Linki do innych stron internetowych',
    subTitle6: 'Bezpieczeństwo i przechowywanie informacji',
    subTitle7: 'Modyfikacja Polityki Prywatności',
    subTitle8: 'Jak się z nami skontaktować',
    title: 'Polityka prywatności',
    updateTime: 'Aktualizacja: 15 marca 2024 r',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Zaakceptuj wszystko',
    text: 'Aby poprawić Twoje doświadczenia, używamy plików cookie w celu personalizacji treści i usług.',
    title: 'Ta strona korzysta z plików cookies',
  },
  slogen: 'Najłatwiejszy asystent AI',
  stripe: {
    contact:
      'W przypadku jakichkolwiek pytań skontaktuj się z nami pod adresem contact@noam.tools!',
    fail: {
      btn: 'Spróbować ponownie',
      text1: 'Nie mogliśmy przetworzyć Twojej płatności.',
      text2: 'Sprawdź połączenie i spróbuj ponownie.',
      title: 'Płatność nie powiodła się',
    },
    succ: { btn: 'Chodźmy', text1: 'Witamy w Noamie,', text2: 'zaczynajmy!', title: 'Gratulacje' },
  },
  terms: {
    block1: 'Dziękujemy za korzystanie z Noama!',
    block10:
      '(a) Opłaty i rozliczenia. Zapłacisz wszystkie opłaty („Opłaty”) zgodnie z cenami i warunkami podanymi na odpowiedniej stronie z cenami lub zgodnie z pisemnymi ustaleniami pomiędzy nami. Zastrzegamy sobie prawo do poprawiania błędów lub pomyłek cenowych, nawet jeśli wystawiliśmy faktury lub otrzymaliśmy płatność. Podasz pełne i dokładne informacje rozliczeniowe, w tym ważną i autoryzowaną metodę płatności. Będziemy okresowo obciążać Twoją metodę płatności, zgodnie z ustaleniami, ale możemy zasadnie zmienić datę rozliczenia. Upoważniasz firmę Noam, jej podmioty stowarzyszone i zewnętrzne podmioty przetwarzające płatności do obciążania Twojej metody płatności. Jeśli Twoja płatność nie powiedzie się, przekażemy Ci pisemne powiadomienie i możemy zawiesić dostęp do Usługi do czasu otrzymania płatności. O ile w niniejszej umowie nie określono inaczej, płatności nie podlegają zwrotowi.',
    block11:
      '(b) Podatki. O ile nie określono inaczej, Opłaty nie obejmują żadnych federalnych, stanowych, lokalnych i zagranicznych podatków, ceł ani podobnych naliczeń („Podatki”). Jesteś odpowiedzialny za uiszczenie wszystkich podatków związanych z Twoimi zakupami, z wyłączeniem podatków opartych na naszym dochodzie netto, za które będziemy Ci wystawiać faktury. Zgadzasz się niezwłocznie zapłacić takie Podatki i przekazać nam prawnie obowiązujące oświadczenia o ochronie prywatności oraz uzyskać niezbędne zgody na przetwarzanie takich danych, a także gwarantujesz nam, że obchodzisz się z tymi danymi zgodnie z obowiązującym prawem.',
    block12:
      '(c) Zmiany cen. Możemy zmienić nasze ceny, powiadamiając Cię o tym za pośrednictwem Twojego konta i/lub naszej strony internetowej. Podwyżki cen będą obowiązywać natychmiast po ich zaksięgowaniu. Wszelkie zmiany cen będą miały zastosowanie do Opłat za Twoje konto po dacie wejścia zmiany w życie.',
    block13:
      '(d) Spory i zaległe płatności. Jeżeli chcesz zakwestionować jakiekolwiek opłaty lub podatki, skontaktuj się z nami pod adresem contact@noam.tools w ciągu trzydziestu (30) dni od daty wystawienia faktury.',
    block14:
      '(e) Bezpłatne korzystanie. Nie możesz tworzyć wielu kont w celu uzyskania darmowych kredytów. Jeśli ustalimy, że nie korzystasz z bezpłatnych kredytów w dobrej wierze, możemy naliczyć Ci standardowe opłaty lub zaprzestać zapewniania dostępu do Usługi.',
    block15:
      '(a) Poufność. Możesz mieć dostęp do poufnych informacji firmy Noam. Do celów korzystania z Usługi możesz wykorzystywać informacje poufne wyłącznie w sposób dozwolony na mocy niniejszych warunków. Nie możesz ujawniać informacji poufnych żadnej stronie trzeciej i będziesz chronić informacje poufne w sposób nie mniej ochronny niż chronisz własne podobne informacje poufne, przynajmniej z należytą starannością. Informacje poufne to informacje niepubliczne określone przez firmę Noam jako poufne lub które w danych okolicznościach powinny być traktowane jako poufne, w tym oprogramowanie, specyfikacje i inne niepubliczne informacje biznesowe. Do informacji poufnych nie zalicza się informacji, które: (1) staną się powszechnie dostępne bez Twojej winy; (2) posiadałeś je bez obowiązku zachowania poufności przed otrzymaniem ich na niniejszych warunkach; (3) zostały zgodnie z prawem ujawnione Tobie przez osobę trzecią bez żadnych zobowiązań dotyczących poufności; lub (4) zostało przez Ciebie niezależnie opracowane bez wykorzystania informacji poufnych. Możesz ujawnić informacje poufne, jeśli jest to wymagane przez prawo, sąd lub inne nakazy rządowe, ale musisz uprzednio powiadomić firmę Noam na piśmie w rozsądny sposób i, w miarę możliwości, podjąć uzasadnione wysiłki, aby ograniczyć zakres ujawnienia, w tym pomóc nam sprzeciwiając się prośbom o ujawnienie informacji.',
    block16:
      '(b) Bezpieczeństwo. Musisz wdrożyć rozsądne i odpowiednie środki, aby zabezpieczyć swój dostęp do Usługi i korzystanie z niej. Jeśli odkryjesz jakiekolwiek luki w zabezpieczeniach lub naruszenia związane z korzystaniem z Usługi, musisz niezwłocznie skontaktować się z Noam i podać szczegóły dotyczące luki lub naruszenia.',
    block17:
      '((c) Przetwarzanie danych osobowych. Jeśli korzystasz z Usługi do przetwarzania danych osobowych, musisz przedstawić wystarczające prawnie oświadczenia o ochronie prywatności i uzyskać niezbędne zgody na przetwarzanie takich danych, a także gwarantujesz nam, że obchodzisz się z tymi danymi zgodnie z obowiązującym prawem .',
    block18:
      '(a) Zakończenie; Zawieszenie. Niniejsze warunki będą obowiązywać od pierwszego użycia Usługi i pozostaną w mocy aż do ich rozwiązania. Użytkownik może wypowiedzieć niniejsze warunki w dowolnym momencie i z dowolnego powodu, zaprzestając korzystania z Usługi i Treści. Możemy rozwiązać niniejsze warunki za uprzednim powiadomieniem z dowolnego powodu. Możemy natychmiast rozwiązać niniejsze warunki, powiadamiając Cię, jeśli w istotny sposób naruszysz sekcję 2 (Wymagania dotyczące użytkowania), sekcję 5 (Poufność, bezpieczeństwo i ochrona danych), sekcję 8 (Rozstrzyganie sporów) lub sekcję 9 (Warunki ogólne) lub jeśli nasze relacji z jakimkolwiek zewnętrznym dostawcą technologii będącym poza naszą kontrolą, zmiany lub w celu zapewnienia zgodności z prawem lub żądaniami rządowymi. Możemy zawiesić Twój dostęp do Usługi, jeśli nie zastosujesz się do niniejszych warunków lub jeśli Twoje korzystanie stwarza ryzyko bezpieczeństwa dla nas lub jakiejkolwiek strony trzeciej, lub jeśli podejrzewamy, że Twoje korzystanie jest oszukańcze lub może narazić nas lub jakąkolwiek osobę trzecią na odpowiedzialność .',
    block19:
      '(b) Skutki rozwiązania umowy. Po rozwiązaniu umowy zaprzestaniesz korzystania z Usługi i niezwłocznie zwrócisz lub zniszczysz wszelkie informacje poufne zgodnie z naszym poleceniem. Postanowienia niniejszych warunków, które ze względu na swój charakter powinny obowiązywać po rozwiązaniu lub wygaśnięciu, w tym między innymi sekcje 3 i 5-9, pozostają w mocy.',
    block2:
      'Niniejsze Warunki użytkowania mają zastosowanie do korzystania przez Ciebie z usług Noam, w tym z naszych interfejsów programowania aplikacji, oprogramowania, narzędzi, usług programistycznych, danych, dokumentacji i strony internetowej (zwanych łącznie „Usługami”). Korzystając z naszych Usług, wyrażasz zgodę na przestrzeganie niniejszych warunków. Nasza Polityka prywatności wyjaśnia, w jaki sposób zbieramy i wykorzystujemy dane osobowe.',
    block20:
      '(a) Odszkodowanie. Będziesz bronić, zabezpieczać i zabezpieczać nas, nasze podmioty stowarzyszone i nasz personel przed wszelkimi roszczeniami, stratami i wydatkami (w tym honorariami adwokackimi) wynikającymi lub związanymi z korzystaniem przez Ciebie z Usługi, w tym z Treściami, Twoimi korzystania z produktów lub usług związanych z Usługą oraz naruszenia przez Ciebie niniejszego regulaminu lub obowiązującego prawa.',
    block21:
      '(b) Zastrzeżenia. Usługa jest świadczona „tak jak jest”. W zakresie dozwolonym przez prawo my, nasi partnerzy i licencjodawcy nie składamy żadnych oświadczeń ani zapewnień jakiegokolwiek rodzaju w odniesieniu do Usługi i nie udzielamy żadnych gwarancji, w tym między innymi gwarancji wartości handlowej, przydatności do określonego celu, zadowalającej jakości, nienaruszania praw i spokojnej przyjemności oraz wszelkie gwarancje wynikające z przebiegu transakcji lub korzystania z handlu. Nie gwarantujemy, że Usługa będzie nieprzerwana, dokładna i wolna od błędów, ani że jakakolwiek Treść będzie bezpieczna, nieutracona ani niezmieniona.',
    block22:
      '(c) Ograniczenie odpowiedzialności. Ani my, ani nasi partnerzy, ani nasi licencjodawcy nie ponosimy odpowiedzialności za jakiekolwiek szkody pośrednie, przypadkowe, szczególne, wynikowe lub karne, w tym utratę zysków, utratę reputacji, utratę możliwości użytkowania, utratę danych lub inne straty niematerialne, nawet jeśli zostaliśmy poinformowani o możliwości wystąpienia takich szkód. Zgodnie z niniejszymi warunkami nasza całkowita odpowiedzialność nie przekroczy Opłat uiszczonych przez Państwa na rzecz Usługi w ciągu dwunastu (12) miesięcy poprzedzających roszczenie lub stu dolarów amerykańskich (100 dolarów), w zależności od tego, która kwota jest większa. Ograniczenia zawarte w tej sekcji mają zastosowanie w maksymalnym zakresie dozwolonym przez obowiązujące prawo.',
    block23:
      'Warunki i sposób korzystania z Usług podlegają prawu Stanów Zjednoczonych, z wyjątkiem przepisów kolizyjnych obowiązujących w USA. Korzystanie z Aplikacji może również podlegać innym przepisom prawa lokalnego, stanowego, krajowego lub międzynarodowego. Wszelkie działania prawne lub postępowania dotyczące niniejszych Warunków będą toczone wyłącznie przed sądami Stanów Zjednoczonych, a użytkownik wyraża zgodę na poddanie się osobistej jurysdykcji takich sądów.',
    block24:
      '(a) Stosunki Stron. Noam i Ty jesteście niezależnymi wykonawcami i żadna ze stron nie ma uprawnień do reprezentowania drugiej strony, wiązania drugiej strony ani podejmowania zobowiązań w jej imieniu bez uprzedniej pisemnej zgody drugiej strony.',
    block25:
      '(b) Wykorzystanie marki. Nie możesz używać nazwy, logo lub znaków towarowych firmy Noam ani żadnego z jej podmiotów stowarzyszonych bez naszej uprzedniej pisemnej zgody.',
    block26:
      'Jeżeli którekolwiek postanowienie niniejszych warunków zostanie uznane za nieważne, niezgodne z prawem lub niewykonalne, pozostałe postanowienia pozostaną w pełnej mocy.',
    block3:
      'Jeśli nie masz ukończonych 18 lat, musisz uzyskać zgodę rodziców lub opiekunów prawnych na korzystanie z Usługi. Jeśli korzystasz z Usługi w imieniu innej osoby lub podmiotu, musisz być upoważniony do zaakceptowania niniejszych warunków w jej imieniu. Aby zarejestrować konto, musisz podać dokładne i pełne dane. Nie możesz udostępniać swoich danych dostępu ani konta nikomu spoza swojej organizacji i jesteś odpowiedzialny za wszystkie działania prowadzone przy użyciu Twoich danych uwierzytelniających.',
    block4:
      '(a) Korzystanie z Usługi. Możesz uzyskać dostęp do Usługi na niniejszych warunkach, a my udzielamy Ci niewyłącznego prawa do korzystania z Usługi. Korzystając z Usługi, będziesz przestrzegać niniejszego Regulaminu oraz wszystkich obowiązujących przepisów. Zarówno my, jak i nasze podmioty stowarzyszone posiadamy wszelkie prawa, tytuły i udziały w Usłudze.',
    block5:
      '(b) Informacje zwrotne. Czekamy na opinie, komentarze, pomysły, sugestie i ulepszenia. Jeśli udostępnisz taką treść, możemy z niej korzystać bez żadnych ograniczeń i bez wynagrodzenia dla Ciebie.',
    block6:
      '(c) Ograniczenia. Użytkownikowi nie wolno: (1) korzystać z Usługi w sposób naruszający, przywłaszczający lub naruszający jakiekolwiek prawa innych osób; (2) odtwarzać kodu źródłowego, dekompilować, dezasemblować, tłumaczyć ani podejmować w inny sposób prób odkrycia kodu źródłowego lub podstawowych komponentów modeli, algorytmów i systemów Usługi (chyba że takie ograniczenia są zabronione przez obowiązujące prawo); (3) wykorzystywać wyniki Usługi do opracowywania modeli konkurencyjnych wobec Noam; (4) wyodrębniać dane lub dane wyjściowe z Usługi przy użyciu jakichkolwiek metod automatycznych lub programistycznych, chyba że jest to dozwolone przez interfejs API, w tym skrobanie, zbieranie danych z sieci lub ekstrakcja danych z sieci; (5) przedstawiać wyników Usługi jako sztucznie wygenerowanych, podczas gdy tak nie jest, lub w inny sposób naruszać nasze zasady użytkowania; (6) kupować, sprzedawać lub przekazywać klucze API bez naszej uprzedniej zgody; lub (7) przekazywać nam jakiekolwiek dane osobowe dzieci poniżej 13 roku życia lub obowiązującego wieku wyrażonego zgodą cyfrową. Będziesz przestrzegać wszelkich limitów stawek i innych wymagań zawartych w naszej dokumentacji.',
    block7:
      '(d) Usługi stron trzecich. Wszelkie oprogramowanie, usługi lub inne produkty stron trzecich powiązane z Usługą podlegają odrębnym warunkom i nie ponosimy odpowiedzialności za produkty stron trzecich.',
    block8:
      '(a) Twoje treści. Możesz przekazać dane wejściowe („Wejście”) do Usługi i otrzymać dane wyjściowe („Wyjście”) wygenerowane i zwrócone na podstawie Wkładów (zwanych łącznie „Treścią”). Między stronami, w zakresie dozwolonym przez obowiązujące prawo, jesteś właścicielem wszystkich Wkładów. Noam może wykorzystywać Treści w celu świadczenia i utrzymywania Usługi, przestrzegania obowiązujących przepisów i egzekwowania naszych zasad. Użytkownik ponosi odpowiedzialność za Treść, w tym za zapewnienie, że nie narusza ona żadnych obowiązujących przepisów prawa ani niniejszych warunków.',
    block9:
      '(b) Dokładność. Sztuczna inteligencja i uczenie maszynowe to szybko rozwijające się dziedziny badań. Nieustannie staramy się ulepszać nasze usługi, aby były dokładniejsze, niezawodne, bezpieczne i korzystne. Biorąc pod uwagę probabilistyczny charakter uczenia maszynowego, korzystanie z naszych usług może w niektórych przypadkach powodować nieprawidłowe wyniki, które mogą nie odzwierciedlać dokładnie rzeczywistych osób, miejsc lub faktów. Powinieneś odpowiednio ocenić dokładność dowolnego wyniku w oparciu o swój przypadek użycia, w tym poprzez ręczne przeglądanie wyników.',
    subTitle1: 'Rejestracja i dostęp',
    subTitle10: 'Rozdzielność',
    subTitle2: 'Wymagania dotyczące użytkowania',
    subTitle3: 'Treść',
    subTitle4: 'Opłaty i płatności',
    subTitle5: 'Poufność, bezpieczeństwo i ochrona danych',
    subTitle6: 'Termin i zakończenie',
    subTitle7: 'Odszkodowanie; Zastrzeżenia; Ograniczenie odpowiedzialności',
    subTitle8: 'Prawo właściwe i jurysdykcja',
    subTitle9: 'Warunki ogólne',
    title: 'Warunki świadczenia usług',
    updateTime: 'Aktualizacja: 15 marca 2024 r',
  },
  trialModal: {
    btn: 'Zacznij już teraz (tylko 0,99 USD)',
    chat: 'CzatPDF',
    desc1: 'Po zakończeniu okresu próbnego zostanie naliczona roczna opłata w wysokości 180 USD',
    desc2: 'Anuluj w dowolnym momencie',
    focus: 'Skupienie',
    hover: 'Tłumaczenie kursora',
    image: 'Tłumaczenie obrazu',
    installBtn: 'Zainstaluj teraz, aby otrzymać prezent',
    memo: 'Zarządzanie ulubionymi',
    pdf: 'Tłumaczenie równoległe PDF',
    realtime: 'Natychmiastowe tłumaczenie',
    select: 'Tłumaczenie wyboru słów',
    title: 'Prezent powitalny',
    trialText: '7-dniowy okres próbny',
    video: 'Tłumaczenie wideo',
    videoSummary: 'Podsumowanie wideo',
    web: 'Tłumaczenie równoległe',
    webSummary: 'Podsumowanie internetowe',
  },
  upgradeModal: {
    btn: 'Uaktualnij teraz',
    chat: { free: 'Osiągnąłeś limit ChatPDF', pro: 'Osiągnąłeś limit ChatPDF na ten miesiąc' },
    contactStr:
      'Poczekaj na następny reset lub skontaktuj się z nami pod adresem contact@noam.tools.',
    limitAlertStr: 'Osiągnąłeś limit bezpłatnego planu. Uaktualnij, aby kontynuować.',
    more: 'Zobacz plany',
    pageLimit: { free: 'Osiągnąłeś limit stron PDF' },
    title: 'Przypomnienie',
    upgradeAlertStr: 'Osiągnąłeś limit bezpłatnego planu. Uaktualnij, aby kontynuować.',
    upload: {
      free: 'Osiągnąłeś limit tłumaczeń PDF',
      pro: 'Osiągnąłeś limit tłumaczeń plików PDF na ten miesiąc',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Dołącz teraz',
      label: 'Nowe korzyści dla użytkownika:',
      remain: 'Zostało tylko 30',
      text: 'Dostępne tylko 100 miejsc! Złap swoje teraz!',
    },
  },
}

export default TRANSLATION
