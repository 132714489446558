const TRANSLATION = {
  buyModal: {
    afterFree: 'بعد تجربتك المجانية',
    btn: 'جربه مجانًا',
    freeTrial: 'تجربة مجانية لمدة 3 أيام',
    monthLabel: 'شهر',
    selectLabel: 'حدد خطة',
    title: 'خطة الاشتراك',
    yearLabel: 'سنة',
  },
  contact: {
    block1:
      'للتخصيص أو الأسئلة أو الاقتراحات، يرجى الاتصال بنا على contact@noam.tools. سوف نقوم بالرد على الفور.',
    block2: 'ادعمنا من خلال تقييمنا على متجر Google Apps Extension على ★★★★★.',
    block3: 'ساعد الآخرين من خلال مشاركة هذا الامتداد:',
    title: 'اتصل بنا',
  },
  help: {
    AquickOverviewOfNoam: 'لمحة سريعة عن نعوم',
    ChatPDF: 'الدردشة بصيغة PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'إدارة المفضلة',
    Fixedplugin: 'البرنامج المساعد الثابت',
    Focusing: 'التركيز',
    Free: 'حر',
    FreeTrialBenefits: 'فوائد التجربة المجانية',
    GoWithWikipedia: 'اذهب مع ويكيبيديا',
    GoWithYouTube: 'اذهب مع يوتيوب',
    HoverTranslation: 'تحوم الترجمة',
    Howtouse: 'كيفية الاستخدام',
    ImageTranslation: 'ترجمة الصور',
    InstantTranslation: 'الترجمة الفورية',
    Membership: 'عضوية',
    NoamHelp: 'نعوم يساعدك على الترجمة والتلخيص',
    PDFparallelTranslation: 'الترجمة الموازية لقوات الدفاع الشعبي',
    ParallelTranslation: 'الترجمة الموازية',
    SkipAllSteps: 'تخطي كافة الخطوات',
    Summary: 'ملخص',
    Translation: 'ترجمة',
    TryItForFree: 'جربه مجانا',
    Video: 'فيديو',
    VideoSummary: 'ملخص الفيديو',
    VideoTranslation: 'ترجمة الفيديو',
    WebSummary: 'ملخص الويب',
    WordSelectionTranslation: 'ترجمة اختيار الكلمات',
    benefits: 'لدينا فوائد في انتظاركم!',
    dayFree: 'تجربة مجانية لمدة 3 أيام',
    done: 'منتهي',
    next: 'التالي',
    pin: 'دبوس نعوم لتسهيل الوصول～～',
    planIsNotExist: 'الخطة غير متوفرة',
    startWiki: 'لنبدأ مع ويكيميديا!',
    startYoutube: 'لنبدأ مع يوتيوب!',
    try: 'جربه',
    tryNow: 'جربها الآن!',
  },
  home: {
    PDFTranslate: {
      desc: 'ترجمة ثنائية اللغة + دردشة PDF',
      sub1: {
        desc: 'تتيح لك القراءة ثنائية اللغة مقارنة النص الأصلي والترجمة جنبًا إلى جنب، مما يؤدي إلى تحسين كفاءة القراءة.',
        title: 'القراءة ثنائية اللغة',
      },
      sub2: {
        desc: 'يقوم الذكاء الاصطناعي بالتحليل والتلخيص، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF.',
        title: 'الدردشة بصيغة PDF',
      },
      sub3: {
        desc: 'ترجمة PDF مدعومة بالذكاء الاصطناعي لمزيد من الدقة والكفاءة.',
        title: 'الدقة والكفاءة',
      },
      title: 'ترجمة قوات الدفاع الشعبي',
    },
    aiAssistant: {
      desc: 'جعل ترجمة الويب أكثر ذكاءً وملاءمة',
      panel1: {
        desc: 'تساعدك الترجمات المرجعية ثنائية اللغة على مقارنة النص الأصلي بالترجمة حتى تتمكن من الفهم بشكل أفضل وتحسين كفاءة القراءة لديك.',
        title: 'الترجمة ثنائية اللغة',
      },
      panel2: {
        left: {
          desc: 'عند تصفح صفحة ويب، قم بالتمرير فوق المحتوى الذي تريد ترجمته لرؤية الترجمة.',
          title: 'تحوم الترجمة',
        },
        right: {
          desc: 'عند تصفح صفحة ويب، حدد النص الذي تريد ترجمته لرؤية الترجمة.',
          title: 'ترجمة اختيار النص',
        },
      },
      panel3: {
        one: {
          desc: 'قم بترجمة وتلخيص صفحات الويب ومحتوى الفيديو في أي وقت وفي أي مكان للحصول على تجربة أكثر سلاسة!',
          title: 'أشر وانقر',
        },
        three: {
          desc: 'يضمن نموذج اللغة الكبير المدعوم بالذكاء الاصطناعي دقة الترجمة واتساقها للحصول على تجربة قراءة محسنة.',
          title: 'يحركها الذكاء الاصطناعي',
        },
        two: {
          desc: 'تغطية كاملة متعددة اللغات، قراءة ثنائية اللغة خالية من العوائق، ترجمة بنقرة واحدة - اكتشف الفرق!',
          title: 'أكثر شمولاً وملاءمة',
        },
      },
      panel4: {
        tab1: {
          desc: 'خبير الكفاءة الخاص بك - باستخدام ChatGPT، يقوم Noam بترجمة وتلخيص محتوى فيديو YouTube لتوفير الوقت!',
          key: 'فيديو',
          title: 'ترجمة وملخص الفيديو',
        },
        tab2: { desc: 'انقر لترجمة الصور أثناء تصفح الويب.', key: 'صورة', title: 'ترجمة الصور' },
        tab3: {
          desc: 'يستخرج المعلومات الأساسية من صفحات الويب على الفور ويقدم ملخصًا، مما يعزز كفاءة القراءة لديك.',
          key: 'ويب',
          title: 'ترجمة الويب والملخص',
        },
        tag1: 'بسيط',
        tag2: 'فعال',
        tag3: 'من السهل أن نفهم',
      },
      title: 'مساعد الذكاء الاصطناعي',
    },
    getStart: 'ابدأ',
    getStartedForFree: 'ابدأ مجانًا',
    howItWork: {
      desc: 'يدمج Noam نماذج الذكاء الاصطناعي لتزويد المستخدمين بتجربة ذكية لترجمة صفحات الويب وتلخيصها عبر اللغات، وترجمة ملفات PDF والصور، وترجمة الفيديو وتلخيصه.',
      title: 'كيف يعمل',
    },
    lastBlock: { desc: 'ترجمة أفضل بالذكاء الاصطناعي لتجربة قراءة أكثر سلاسة' },
    top: {
      desc: 'Noam هو امتداد متصفح مجاني يعمل بتقنية الذكاء الاصطناعي يساعدك على ترجمة وتلخيص صفحات الويب ومقاطع الفيديو ومحتوى PDF.\\n انضم إلى Noam واستمتع بتجربة السحر!',
      title1: 'سحر واحد',
      title2: 'نقرة واحدة',
    },
    userReviews: { desc: 'تقييمات خمس نجوم على سوق Chrome الإلكتروني', title: 'مراجعات المستخدم' },
  },
  installTips: {
    btn: 'جربه الآن',
    text: 'قم بتثبيت "Noam Browser Plugin" لفتح المزيد من الميزات! الإسناد الترافقي على الويب،\\n تلخيص فيديو الويب، والمزيد!',
  },
  locale: {
    af: 'الأفريكانية',
    am: 'الأمهرية',
    ar: 'عربي',
    as: 'الأسامية',
    auto: 'الكشف التلقائي',
    az: 'أذربيجاني',
    ba: 'بشكير',
    bg: 'البلغارية',
    bn: 'البنغالية',
    bo: 'التبتية',
    bs: 'البوسنية',
    ca: 'الكاتالونية',
    cs: 'التشيكية',
    cy: 'الويلزية',
    da: 'دانماركي',
    de: 'الألمانية',
    dsb: 'الصوربية السفلى',
    dv: 'ديفيهي',
    el: 'اليونانية',
    en: 'إنجليزي',
    es: 'الأسبانية',
    et: 'الإستونية',
    eu: 'الباسك',
    fa: 'الفارسية',
    fi: 'الفنلندية',
    fil: 'فلبينية',
    fj: 'فيجي',
    fr: 'فرنسي',
    frCA: 'الفرنسية (كندا)',
    ga: 'الأيرلندية',
    gl: 'الجاليكية',
    gom: 'الكونكانية',
    gu: 'الغوجاراتية',
    ha: 'الهوسا',
    he: 'العبرية',
    hi: 'الهندية',
    hr: 'الكرواتية',
    hsb: 'الصوربية العليا',
    ht: 'الكريول الهايتية',
    hu: 'المجرية',
    hy: 'الأرمينية',
    id: 'الاندونيسية',
    ig: 'الإيغبو',
    ikt: 'إينويناكتون',
    is: 'الأيسلندية',
    it: 'ايطالي',
    iu: 'إنكتيتوت',
    iuLatn: 'إنكتيتوت (لاتينية)',
    ja: 'اليابانية',
    ka: 'الجورجية',
    kk: 'الكازاخستانية',
    km: 'الخمير',
    kmr: 'الكردية (الشمالية)',
    kn: 'الكانادا',
    ko: 'كوري',
    ku: 'الكردية (الوسطى)',
    ky: 'قيرغيزستان',
    ln: 'اللينجالا',
    lo: 'لاو',
    lt: 'الليتوانية',
    lug: 'غاندا',
    lv: 'لاتفيا',
    lzh: 'الصينية (الأدبية)',
    mai: 'مايثيلي',
    mg: 'مدغشقر',
    mi: 'الماوري',
    mk: 'المقدونية',
    ml: 'المالايالامية',
    mnCyrl: 'المنغولية (السيريلية)',
    mnMong: 'المنغولية (التقليدية)',
    mr: 'المهاراتية',
    ms: 'لغة الملايو',
    mt: 'المالطية',
    mww: 'همونغ داو',
    my: 'ميانمار (البورمية)',
    nb: 'النرويجية',
    ne: 'النيبالية',
    nl: 'هولندي',
    noSearchLang: 'اللغة غير مدعومة',
    nso: 'سيسوتو سا ليبوا',
    nya: 'نيانجا',
    or: 'أوديا',
    otq: 'كويريتارو أوتومي',
    pa: 'البنجابية',
    pl: 'بولندي',
    placeholder: 'يبحث',
    prs: 'داري',
    ps: 'الباشتو',
    pt: 'البرتغالية (البرازيل)',
    ptPT: 'البرتغالية (البرتغال)',
    ro: 'روماني',
    ru: 'الروسية',
    run: 'روندي',
    rw: 'الكينيارواندية',
    sd: 'السندية',
    si: 'السنهالية',
    sk: 'السلوفاكية',
    sl: 'السلوفينية',
    sm: 'ساموا',
    sn: 'شونا',
    so: 'الصومالية',
    sq: 'الألبانية',
    srCyrl: 'الصربية (السيريلية)',
    srLatn: 'الصربية (اللاتينية)',
    st: 'سيسوتو',
    sv: 'السويدية',
    sw: 'السواحلية',
    ta: 'التاميل',
    te: 'التيلجو',
    th: 'التايلاندية',
    ti: 'التغرينية',
    tk: 'التركمان',
    tlhLatn: 'كلينجون (لاتينية)',
    tlhPiqd: 'كلينجون (pIqaD)',
    tn: 'سيتسوانا',
    to: 'تونجا',
    tr: 'تركي',
    tt: 'التتار',
    ty: 'تاهيتي',
    ug: 'الأويغور',
    uk: 'الأوكرانية',
    ur: 'الأردية',
    uz: 'الأوزبكية (اللاتينية)',
    vi: 'الفيتنامية',
    xh: 'خوسا',
    yo: 'اليوروبا',
    yua: 'يوكاتيك مايا',
    yue: 'الكانتونية (التقليدية)',
    zh: 'الصينية',
    zhHans: 'الصينية المبسطة',
    zhHant: 'الصينية التقليدية',
    zu: 'الزولو',
  },
  login: {
    and: 'و',
    btn: 'تسجيل الدخول مع جوجل',
    btn1: 'تسجيل الدخول الآن',
    desc: 'قم بتسجيل الدخول لفتح المزيد من التجارب',
    policy: 'سياسة الخصوصية',
    terms: 'شروط الاستخدام',
    tips: 'بالتسجيل، أنا أوافق على',
    title: 'مرحبا بكم في نعوم',
  },
  pageFooter: {
    follow: { facebook: 'فيسبوك', title: 'يتبع', twitter: 'تغريد' },
    legal: { policy: 'سياسة الخصوصية', terms: 'شروط الخدمة', title: 'قانوني' },
    product: {
      image: 'ترجمة الصور',
      pdf: 'ترجمة قوات الدفاع الشعبي',
      title: 'منتجات',
      video: 'ترجمة وملخص الفيديو',
      web: 'ترجمة الويب والملخص',
    },
    resources: { title: 'موارد' },
  },
  pageHeader: {
    contact: 'اتصال',
    help: 'يساعد',
    logout: 'تسجيل الخروج',
    logoutSucc: 'تم تسجيل الخروج.',
    pricing: 'التسعير',
    settings: 'إعدادات',
    signUp: 'تسجيل الدخول',
  },
  pdf: {
    autoDetect: 'الكشف التلقائي',
    cancelBtn: 'يلغي',
    cancelUpload: 'يلغي',
    chatPdf: {
      Search: 'يبحث',
      cancel: 'يلغي',
      chatTitle: 'مرحبا بكم في نعوم! فيما يلي ملخص لملف PDF الخاص بك:',
      clear: 'واضح',
      clearContent: 'سيبدأ هذا محادثة جديدة. لن يتم حفظ المحادثة الحالية. هل أنت متأكد؟',
      clearDialogTitle: 'يرجى التأكيد',
      clearSucc: 'مسح',
      copy: 'ينسخ',
      copySucc: 'منقول',
      delete: 'يمسح',
      deleteCancel: 'يلغي',
      deleteContent: 'لا يمكن استرداد ملف PDF هذا بمجرد حذفه. هل أنت متأكد؟',
      deleteOk: 'يمسح',
      deleteSuccess: 'تم الحذف',
      deleteTitle: 'تأكيد الحذف',
      expand: 'ينهار',
      historyTitle: 'تاريخ',
      listError: 'فشل تحميل قائمة القراءة. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
      loading: 'تحميل...',
      noDataDesc: 'لا يوجد تاريخ بعد.',
      noMore: 'لا مزيد من المحتوى.',
      placeholder: 'اسأل أي شيء عن ملف PDF هذا',
      summaryTitle: 'قد تكون مهتمًا بهذه الأسئلة:',
    },
    collectionEmpty: 'مجموعتك فارغة.',
    collectionTab: 'ملحوظات',
    dataLoading: 'تحميل...',
    dialogContent: 'هل أنت متأكد أنك تريد حذف هذا؟',
    dialogTitle: 'يرجى التأكيد',
    download: {
      both: 'ثنائي اللغة',
      btn: 'يحفظ',
      cancel: 'سيؤدي إغلاق هذه النافذة إلى إلغاء تنزيل ملف PDF. هل أنت متأكد أنك تريد الإلغاء؟',
      desc: 'حفظ على الجهاز: تنزيل الترجمات كصور؛ الحفظ والطباعة: تنزيل الترجمات بالتنسيق الأصلي، مع دعم النسخ.',
      download: 'تحميل',
      downloadMsg1: 'تقدم ترجمة PDF:',
      downloadMsg2: 'الوقت المتبقي المقدر:',
      downloadSucc: 'PDF جاهز! انقر للحفظ.',
      downloadTip: 'تحميل قوات الدفاع الشعبي. لا تغلق هذه النافذة لتجنب أخطاء التنزيل.',
      error: 'فشل الحفظ. حاول الطباعة بدلاً من ذلك.',
      fileName: 'اسم الملف:',
      fileType: 'نوع الملف:',
      loading: 'توفير...',
      loading2: 'تحميل...',
      minute: 'دقائق...',
      msg1: 'بعض الترجمات في هذا المستند أطول من النص الأصلي، وقد تتداخل في الملف الذي تم تنزيله. نوصي بالقراءة على صفحة الويب.',
      msg2: '1. الترجمة لم تكتمل بعد. يرجى التحميل بعد الانتهاء من الترجمة.',
      msg3: '2. بعض الترجمات طويلة جدًا. اقرأ على صفحة الويب لتجنب تداخل النص.',
      msg4: 'مهام الترجمة قيد التقدم. يرجى المحاولة مرة أخرى بعد الانتهاء منها.',
      progress:
        'ترجمة قوات الدفاع الشعبي. التقدم: {{progress}}، الوقت المقدر المتبقي: {{دقائق}} دقيقة...',
      save: 'حفظ إلى الجهاز',
      savePrint: 'حفظ وطباعة',
      success: 'تم التنزيل',
      trans: 'الترجمة فقط',
      transContinue: 'متابعة الترجمة',
    },
    downloadBtn: 'تحميل',
    dragTipModal: {
      black: 'فضاء',
      leftKey: 'الزر الأيسر',
      ok: 'فهمتها',
      press: 'يضعط',
      tips: 'للسحب والعرض.',
    },
    entry: 'ملفات PDF',
    fileErrMsg: 'فشلت قراءة الملف. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    fileMsg: 'حجم الملف الحالي:',
    fileMsg2: 'يرجى تحميل ملف أصغر من 300 ميجابايت.',
    freeBannerBtn: 'يرقي',
    freeBannerTips: 'تم الوصول إلى الحد الأقصى لترجمات PDF المجانية',
    guide: {
      placeholder: 'الصق رابط PDF هنا',
      start: 'ابدأ الترجمة',
      text1: 'القراءة ثنائية اللغة',
      text2: 'اقرأ بلغتين لمقارنة النص الأصلي والترجمة جنبًا إلى جنب، مما يحسن كفاءتك.',
      text3: 'الدردشة مع ملف PDF الخاص بك',
      text4:
        'التحليل والتلخيص المدعوم بالذكاء الاصطناعي. اطرح أسئلة حول ملف PDF الخاص بك، واحصل على إجابات فورية.',
      text5: 'دقيقة وفعالة',
      text6: 'ترجمة PDF مدعومة بالذكاء الاصطناعي لمزيد من الدقة والكفاءة.',
      title1: 'الترجمة ثنائية اللغة',
      title2: 'الدردشة مع ملف PDF الخاص بك',
      toast: 'الرجاء إدخال رابط PDF صالح عبر الإنترنت وينتهي بـ .pdf',
    },
    hasTransTask: 'مهمة الترجمة قيد التقدم. انتظر من فضلك.',
    hoverTrans: 'تحوم لترجمة الفقرات',
    initial: 'ترجمة قوات الدفاع الشعبي',
    installText: 'الحل الشامل للترجمات اليومية.',
    installText2: 'الرجاء تثبيت الامتداد أولاً',
    installTips:
      'قم بتثبيت الامتداد للحصول على المزيد من ترجمات PDF، وترجمات أكثر سلاسة لمواقع الويب:',
    kw: 'التيلة',
    link: 'وصلة',
    listError: 'فشل في تحميل ملفات PDF. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    memoTotalCount: '1 عنصر المجموع',
    new: 'ملف جديد',
    noId: 'لم يتم العثور على معرف الملف.',
    notPdf: 'ملفات PDF فقط.',
    okBtn: 'يمسح',
    okBtn2: 'فهمتها',
    original: 'إبداعي',
    parallel: 'موازي',
    parseErrDialogContent: 'لا يمكن ترجمة رابط PDF هذا مباشرة. يرجى تنزيل ملف PDF وتحميله للترجمة.',
    parseErrTips: 'فشل في تحليل الملف',
    parseFileToast: 'فشل في تحليل الملف. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    pdfLoading: 'جارٍ التحليل...',
    searchPlaceholder: 'يبحث',
    selectTrans: 'حدد نصًا للترجمة',
    shareBtn: 'يشارك',
    shareBtnText1: 'إنشاء رابط المشاركة',
    shareBtnText2: 'تحديث المحتوى المشترك',
    shareModal: {
      cancel: 'يلغي',
      copy: 'ينسخ',
      copySucc: 'تم نسخ الرابط',
      custom: 'تخصيص:',
      errMsg: 'لم تتم ترجمة المحتوى المحدد بالكامل بعد. يرجى الترجمة قبل المشاركة.',
      gen: 'يشارك',
      modify: 'يحرر',
      notSupport: 'هذه اللغة غير مدعومة حاليًا.',
      page: 'الصفحات',
      pageNuErr: 'الرجاء إدخال رقم صفحة صالح.',
      range: 'نِطَاق:',
      remain: 'متبقي',
      remain1: 'إجمالي الوقت المقدر:',
      search: 'يبحث',
      settings: 'إعدادات المشاركة:',
      shareSetting: 'إعدادات المشاركة',
      shareText: 'يشارك',
      shareTips: 'شارك رابط PDF المترجم مع أصدقائك:',
      sourceLang: 'من:',
      target: 'ل:',
      text1: 'إعدادات المشاركة',
      text2: 'إنشاء رابط المشاركة',
      totalPage: '1 صفحة المجموع',
      trans: 'يترجم',
      translateTips:
        'نصيحة: إذا كانت الترجمة تستغرق بعض الوقت، فيمكنك إغلاق هذه النافذة ومشاركتها لاحقًا.',
      translating: 'ترجمة قوات الدفاع الشعبي...',
      update: 'تحديث رابط المشاركة',
    },
    shareMsg1: 'تم إنشاء رابط المشاركة.',
    shareMsg2: 'تم تحديث المحتوى المشترك.',
    shareMsg3: 'فشل في إنشاء رابط المشاركة. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    shareMsg4: 'فشل تحديث المحتوى المشترك. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    shareTips: 'ترجمة PDF الخاصة بك جاهزة! شاركها مع أصدقائك.',
    start: 'ابدأ تجربتك المجانية',
    thumbnail: 'جدول المحتويات',
    toast1: 'تحميل قوات الدفاع الشعبي. يرجى المحاولة مرة أخرى في وقت لاحق.',
    toast2: 'محتوى PDF كبير جدًا. الحوار غير مدعوم حاليًا.',
    toast3: 'جاري التحميل يرجى المحاولة مرة أخرى بعد انتهاء التنزيل.',
    toolbar: { Adjust: 'يُعدِّل', autoFit: 'احتواء تلقائي' },
    trans: 'ترجمة',
    transSucc: 'الترجمة ناجحة!',
    unLoginToast: 'الرجاء تسجيل الدخول لاستخدام هذه الميزة.',
    uploadBtn: 'رفع',
    uploadErr: 'فشل تحميل الملف. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    uploadMsg1: 'اسحب وأفلت ملفك هنا.',
    uploadMsg2: 'أنواع الملفات المدعومة: PDF |  الحد الأقصى لحجم الملف: 100 ميجابايت',
    uploadMsg3: 'انقر لتحديد الملفات أو سحبها وإفلاتها هنا.',
    uploading: 'جارٍ التحميل',
    uploadingMsg: 'جارٍ التحميل. انتظر من فضلك.',
    uploadingMsg1: 'جارٍ تحميل الملف. يرجى المحاولة مرة أخرى لاحقا.',
    uploadingMsg2: 'يتم حاليًا تحويل ملف. يرجى المحاولة مرة أخرى لاحقا.',
    uploadingMsg3: 'فشل طلب تقدم التحويل. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    uploadingMsg4: 'لا يستطيع نعوم الوصول إلى الملفات المحلية. يرجى تحميل ملف PDF يدويًا للترجمة.',
    uploadingMsg5:
      'فشل في استرداد عنوان URL للملف. يرجى المحاولة مرة أخرى لاحقًا، أو الاتصال بالدعم.',
    video: 'فيديو',
    vipBannerBtn: 'يرقي',
    vipBannerTips: 'لقد وصلت إلى حد ترجمة PDF الخاص بك لهذا الشهر.',
    webTrans: 'الإسناد الترافقي الكامل',
  },
  pricing: {
    FAQ: 'التعليمات',
    FQAObj: {
      a1: 'نعم، يقدم Noam خطة مجانية يمكنك استخدامها على الفور.',
      a2: 'نحن نقدم ثلاث خطط عضوية: Pro وPro+ وEnterprise. يمكنك اختيار الدفع شهريًا أو سنويًا. توفر الفواتير السنوية وفورات كبيرة.',
      a3: 'جميع الميزات متوفرة في كلا الخطتين. والفرق الرئيسي هو التكلفة: تكلفة الاشتراكات السنوية أقل بشكل عام. تتم محاسبة الاشتراكات الشهرية كل شهر، بينما تتم محاسبة الخطط السنوية مرة واحدة سنويًا.',
      a4: 'يمكنك إلغاء اشتراكك في أي وقت في إعدادات حسابك.',
      q1: 'هل هناك خطة مجانية؟',
      q2: 'ما هي خطط التسعير وخيارات الدفع المتاحة؟',
      q3: 'ما الفرق بين الاشتراك الشهري والسنوي؟',
      q4: 'كيف يمكنني إلغاء اشتراكي؟',
    },
    around: 'حول',
    billedMonthly: 'الفاتورة الشهرية',
    billedYearly: 'تتم الفاتورة سنويًا',
    day: 'يوم',
    desc: 'قارن الخطط واختر الأفضل',
    detailedCompare: 'قارن الخطط',
    discount: 'خصم 20%',
    enterprise: {
      benefit1: 'أولوية دعم العملاء',
      benefit2: 'مستخدمين متعددين',
      benefit3: 'حلول مخصصة',
      benefit5: 'مدير حساب مخصص',
      btn: 'اتصل بنا',
      recommendText: 'الخطط المخصصة (≥3人)',
      talkToUs: 'تواصل معنا',
    },
    free: {
      benefit1: 'ترجمة فورية 100 مرة في اليوم',
      benefit2: 'ترجمة وملخص الموقع',
      benefit3: '30 ترجمة للصور',
      benefit4: 'ترجمة وملخص الفيديو',
      benefit5: '10 ترجمات PDF',
      benefit6: '5000 رمز',
      btn: 'جربه مجانًا',
      recommendText: 'تجربة مجانية',
    },
    imageTranslation: 'ترجمة الصور',
    month: 'شهر',
    monthly: 'شهريا',
    pageTitle: 'التسعير',
    pdf: {
      chat: 'استعلامات الدردشة بصيغة PDF',
      maxPage: 'الأعلى. الصفحات لكل PDF',
      translation: 'ترجمة قوات الدفاع الشعبي',
    },
    preMonth: 'كل شهر',
    pro: {
      benefit1: 'أولوية دعم العملاء',
      benefit2: '1,000,000 توكن / شهر',
      benefit3: 'ترجمة وملخص الموقع',
      benefit4: '200 صورة ترجمة',
      benefit5: 'ترجمة وملخص الفيديو',
      benefit6: '200 ترجمة PDF',
      btn: 'ابدأ',
      recommendText: 'الأكثر شعبية',
    },
    proPlus: {
      benefit1: 'أولوية دعم العملاء',
      benefit2: '3,000,000 توكن / شهر',
      benefit3: 'ترجمة وملخص الموقع',
      benefit4: '500 صورة ترجمة',
      benefit5: 'ترجمة وملخص الفيديو',
      benefit6: '500 ترجمة PDF',
      recommendText: 'أفضل قيمة',
    },
    video: { summary: 'ملخص الفيديو', translation: 'ترجمة الفيديو' },
    web: {
      contrastiveTranslation: 'الترجمة ثنائية اللغة',
      dictionaryTranslation: 'ترجمة الاختيار',
      hoverTranslation: 'تحوم الترجمة',
      instantTranslation: 'الترجمة الفورية',
      summary: 'ملخص صفحة الويب',
    },
    yearly: 'سنوي',
  },
  privacy: {
    block1:
      'نعوم ("نعوم" أو "نحن" أو "خاصتنا") يحترم خصوصيتك ويلتزم بحماية أي معلومات نحصل عليها منك أو عنك. توضح سياسة الخصوصية هذه ممارساتنا فيما يتعلق بجمع أو استخدام المعلومات الشخصية من استخدامك لموقعنا الإلكتروني وتطبيقاتنا وخدماتنا (يُشار إليها إجمالاً بـ "الخدمات"). لا تنطبق سياسة الخصوصية هذه على المحتوى الذي نتعامل معه نيابة عن عملاء منتجات الأعمال لدينا، مثل واجهة برمجة التطبيقات (API) الخاصة بنا. يخضع استخدام هذه البيانات لاتفاقية العملاء الخاصة بنا، والتي تغطي الوصول إلى هذه المنتجات واستخدامها.',
    block10:
      'نحن ننفذ إجراءات فنية وإدارية وتنظيمية معقولة تجاريًا لحماية المعلومات الشخصية عبر الإنترنت وغير المتصلة بالإنترنت من الفقدان أو سوء الاستخدام أو الوصول غير المصرح به أو الكشف أو التغيير أو الأعمال العدائية. ومع ذلك، لا يمكن لأي نقل عبر الإنترنت أو البريد الإلكتروني أن يضمن الأمان أو الدقة الكاملة. وعلى وجه الخصوص، قد لا تكون رسائل البريد الإلكتروني المرسلة إلينا أو منا آمنة. لذا، يجب أن تكون حذرًا بشكل خاص عند تحديد المعلومات التي تريد إرسالها إلينا عبر الخدمة أو البريد الإلكتروني. بالإضافة إلى ذلك، نحن لسنا مسؤولين عن أي عرقلة لإعدادات الخصوصية أو التدابير الأمنية في الخدمة أو إعدادات الأمان أو التدابير الأمنية لمواقع الطرف الثالث. سنحتفظ بمعلوماتك الشخصية طالما كان ذلك ضروريًا لتزويدك بالخدمات أو لأغراض تجارية مشروعة أخرى، مثل حل النزاعات أو لأسباب الأمن والسلامة أو الامتثال لالتزاماتنا القانونية. يعتمد طول المدة التي نحتفظ فيها بالمعلومات الشخصية على العديد من العوامل، مثل مقدار المعلومات وطبيعتها وحساسيتها والمخاطر المحتملة للاستخدام أو الكشف غير المصرح به، والأغراض التي نعالج المعلومات من أجلها، وغيرها من الاعتبارات الملزمة قانونًا.',
    block11:
      'قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. عندما نقوم بذلك، سنقوم بنشر نسخة محدثة على هذه الصفحة، ما لم ينص القانون المعمول به على خلاف ذلك.',
    block12:
      'إذا كانت لديك أية أسئلة أو استفسارات لم تتم الإجابة عليها بشأن سياسة الخصوصية هذه، فيرجى الاتصال بخدمة العملاء لدينا. بريد إلكتروني:',
    block2: 'نقوم بجمع المعلومات الشخصية التالية ("المعلومات الشخصية") المتعلقة بك:',
    block3:
      'المعلومات التي تقدمها: عندما تقوم بإنشاء حساب لاستخدام خدماتنا أو التواصل معنا، فإننا نقوم بجمع المعلومات الشخصية على النحو التالي:',
    block4:
      'المعلومات الشخصية التي يتم تلقيها تلقائيًا من استخدامك للخدمات: عند وصولك إلى الخدمات أو استخدامها أو التفاعل معها، نتلقى المعلومات التالية حول وصولك أو استخدامك أو تفاعلك ("المعلومات الفنية"):',
    block5: 'قد نستخدم المعلومات الشخصية للأغراض التالية:',
    block6:
      'المعلومات الموجزة أو غير المحددة للهوية: يجوز لنا تجميع المعلومات الشخصية أو إلغاء تحديدها لمنع إعادة استخدامها لأغراض تحديد الهوية واستخدام هذه المعلومات لتحليل فعالية خدماتنا وتحسين وإضافة ميزات إلى خدماتنا وإجراء الأبحاث ولأغراض مماثلة. . بالإضافة إلى ذلك، قد نقوم بتحليل السلوك والخصائص العامة لمستخدمي خدمتنا ومشاركة المعلومات المجمعة مع أطراف ثالثة، أو نشر هذه المعلومات المجمعة، أو إتاحتها بشكل عام. يجوز لنا جمع معلومات مجمعة من خلال الخدمات، ومن خلال ملفات تعريف الارتباط، ومن خلال وسائل أخرى موضحة في سياسة الخصوصية هذه. سوف نحتفظ بالمعلومات غير محددة الهوية ونستخدمها في شكل مجهول أو غير محدد الهوية، ولن نحاول إعادة تحديد هذه المعلومات ما لم يكن ذلك مطلوبًا بموجب القانون.',
    block7:
      'في بعض الحالات، قد نقدم معلوماتك الشخصية إلى أطراف ثالثة دون إشعار آخر لك، ما لم يقتضي القانون ذلك:',
    block8:
      'اعتمادًا على موقعك الجغرافي، قد يتمتع الأفراد في المنطقة الاقتصادية الأوروبية والمملكة المتحدة والعالم بحقوق قانونية معينة فيما يتعلق بمعلوماتهم الشخصية. على سبيل المثال، قد يكون لك الحق في:',
    block9:
      'قد تحتوي الخدمة على روابط لمواقع إلكترونية أخرى لا تديرها أو تسيطر عليها شركة Noam، بما في ذلك خدمات الوسائط الاجتماعية ("مواقع الطرف الثالث"). ستخضع المعلومات التي تشاركها مع مواقع الطرف الثالث لسياسات الخصوصية وشروط الخدمة المحددة لمواقع الطرف الثالث، وليس لسياسة الخصوصية هذه. نحن نقدم هذه الروابط من أجل التسهيل ولا تعني تأييدنا أو مراجعتنا لهذه المواقع. يرجى الاتصال بمواقع الطرف الثالث مباشرة للحصول على معلومات حول سياسات وشروط الخصوصية الخاصة بها.',
    subBlock1:
      'معلومات الحساب: عندما تقوم بإنشاء حساب على منصتنا، نقوم بجمع المعلومات المتعلقة بحسابك، بما في ذلك اسمك ومعلومات الاتصال وبيانات اعتماد الحساب ومعلومات بطاقة الدفع وسجل المعاملات ("معلومات الحساب").',
    subBlock10: 'توفير الخدمات وإدارتها وصيانتها و/أو تحليلها؛',
    subBlock11: 'التواصل معك؛',
    subBlock12: 'تطوير منتجات وخدمات جديدة؛',
    subBlock13:
      'منع الاحتيال أو النشاط الإجرامي أو إساءة استخدام خدماتنا، وحماية أمن أنظمة تكنولوجيا المعلومات والهندسة المعمارية والشبكات لدينا؛',
    subBlock14: 'إجراء التحويلات التجارية؛',
    subBlock15:
      'الامتثال للالتزامات القانونية والإجراءات القانونية، وحماية حقوقنا أو خصوصيتنا أو أمننا أو ممتلكاتنا، بالإضافة إلى حقوق الشركات التابعة لنا أو أنت أو أطراف ثالثة أخرى.',
    subBlock16:
      'الموردون ومقدمو الخدمات: لمساعدتنا في تلبية الاحتياجات التشغيلية للأعمال وأداء خدمات ووظائف معينة، قد نقدم معلومات شخصية للموردين ومقدمي الخدمات، بما في ذلك مقدمو خدمات الاستضافة ومقدمو الخدمات السحابية ومقدمو خدمات تكنولوجيا المعلومات الآخرون وبرامج الاتصال عبر البريد الإلكتروني. ومقدمي خدمات تحليلات الويب، وما إلى ذلك. لن يتمكن هؤلاء الشركاء من الوصول إلى المعلومات الشخصية أو معالجتها أو تخزينها إلا بناءً على توجيهاتنا أثناء أداء واجباتهم لصالحنا.',
    subBlock17:
      'تحويلات الأعمال: إذا انخرطنا في معاملات استراتيجية، أو عمليات إعادة تنظيم، أو حالات إفلاس، أو عمليات استحواذ بسبب الإفلاس، أو نقل الخدمات إلى مزود آخر (يُشار إليها إجمالاً باسم "المعاملات")، فقد تتم مشاركة معلوماتك الشخصية والمعلومات الأخرى مع نظراء المعاملات والأطراف الأخرى التي تساعد في المعاملات. أثناء العناية الواجبة ونقلها كجزء من المعاملات إلى جانب الأصول الأخرى إلى شركة خلف أو شركة تابعة.',
    subBlock18:
      'المتطلبات القانونية: يجوز لنا مشاركة معلوماتك الشخصية مع السلطات الحكومية أو نظراء الصناعة أو أطراف ثالثة أخرى، بما في ذلك معلومات تفاعلك مع خدماتنا، (1) إذا كان ذلك مطلوبًا بموجب القانون أو إذا كنا نعتقد أن هذا الإجراء ضروري للامتثال للالتزامات القانونية؛ (2) لحماية حقوقنا أو ممتلكاتنا والدفاع عنها؛ (3) إذا قررنا من جانب واحد أن هناك انتهاكًا لشروطنا أو سياساتنا أو قوانيننا؛ (4) لاكتشاف أو منع الاحتيال أو الأنشطة غير القانونية الأخرى؛ (5) لحماية منتجاتنا أو موظفينا أو مستخدمينا أو السلامة العامة والأمن والنزاهة؛ أو (6) للدفاع ضد المسؤولية القانونية.',
    subBlock19: 'الوصول إلى معلوماتك الشخصية والمعلومات المتعلقة بكيفية معالجتها.',
    subBlock2:
      'محتوى المستخدم: عندما تستخدم خدماتنا، نقوم بجمع معلومات شخصية من مدخلاتك أو تحميلات الملفات أو التعليقات المقدمة إلينا ("المحتوى").',
    subBlock20: 'اطلب حذف معلوماتك الشخصية من سجلاتنا.',
    subBlock21: 'تصحيح أو تحديث معلوماتك الشخصية.',
    subBlock22: 'نقل معلوماتك الشخصية إلى طرف ثالث (إمكانية نقل البيانات).',
    subBlock23: 'الحد من كيفية معالجتنا لمعلوماتك الشخصية.',
    subBlock24:
      'سحب موافقتك - عندما نعتمد على الموافقة كأساس قانوني للمعالجة، يمكنك سحب موافقتك في أي وقت.',
    subBlock25: 'الاعتراض على كيفية معالجتنا لمعلوماتك الشخصية.',
    subBlock26: 'تحسين خدماتنا وإجراء البحوث؛',
    subBlock3:
      'معلومات الاتصال: إذا أرسلت لنا رسائل، فسنقوم بجمع اسمك ومعلومات الاتصال ومحتوى رسائلك ("معلومات الاتصال").',
    subBlock4:
      'معلومات وسائل التواصل الاجتماعي: لدينا صفحات على مواقع التواصل الاجتماعي مثل Instagram وFacebook وMedium وTwitter وYouTube وLinkedIn. عندما تتفاعل مع صفحات وسائل التواصل الاجتماعي الخاصة بنا، نقوم بجمع المعلومات الشخصية التي تختار تقديمها لنا، مثل تفاصيل الاتصال الخاصة بك ("المعلومات الاجتماعية"). بالإضافة إلى ذلك، قد تزودنا الشركات التي تستضيف صفحات وسائل التواصل الاجتماعي الخاصة بنا بمعلومات وتحليلات مجمعة حول أنشطتنا على وسائل التواصل الاجتماعي.',
    subBlock5:
      'بيانات السجل: المعلومات التي يرسلها متصفحك تلقائيًا عند استخدام خدماتنا. تتضمن بيانات السجل عنوان بروتوكول الإنترنت (IP) الخاص بك، ونوع المتصفح والإعدادات، وتاريخ ووقت طلباتك، وكيفية تفاعلك مع موقعنا.',
    subBlock6:
      'بيانات الاستخدام: قد نقوم تلقائيًا بجمع معلومات حول استخدامك للخدمة، مثل أنواع المحتوى الذي تشاهده أو تتفاعل معه، والميزات التي تستخدمها، والإجراءات التي تتخذها، بالإضافة إلى المنطقة الزمنية والبلد والتاريخ والوقت الخاص بك. الوصول ووكيل المستخدم والإصدار ونوع الكمبيوتر أو الجهاز المحمول واتصال الكمبيوتر الخاص بك.',
    subBlock7:
      'معلومات الجهاز: تتضمن اسم الجهاز ونظام التشغيل ومعرف الجهاز والمتصفح الذي تستخدمه. قد تعتمد المعلومات التي تم جمعها على نوع الجهاز الذي تستخدمه وإعداداته.',
    subBlock8:
      'ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لتشغيل خدماتنا وإدارتها وتحسين تجربتك. "ملف تعريف الارتباط" هو المعلومات التي يرسلها موقع الويب الذي تزوره إلى متصفحك. يمكنك ضبط المتصفح الخاص بك لقبول جميع ملفات تعريف الارتباط، أو رفض جميع ملفات تعريف الارتباط، أو إعلامك عند توفير ملف تعريف الارتباط حتى تتمكن من تحديد ما إذا كنت ستقبله في كل مرة. ومع ذلك، فإن رفض ملفات تعريف الارتباط قد يمنعك من استخدام أو يؤثر سلبًا على عرض أو وظائف مناطق أو ميزات معينة في موقع الويب. لمزيد من المعلومات حول ملفات تعريف الارتباط، يرجى زيارة كل شيء عن ملفات تعريف الارتباط.',
    subBlock9:
      'التحليلات: قد نستخدم العديد من منتجات التحليلات عبر الإنترنت التي تستخدم ملفات تعريف الارتباط لمساعدتنا في تحليل كيفية استخدام المستخدمين لخدماتنا وتحسين تجربتك عند استخدام الخدمات.',
    subTitle1: 'المعلومات الشخصية التي نجمعها',
    subTitle2: 'كيف نستخدم المعلومات الشخصية',
    subTitle3: 'الكشف عن المعلومات الشخصية',
    subTitle4: 'حقوقك',
    subTitle5: 'روابط لمواقع أخرى',
    subTitle6: 'الأمن والاحتفاظ بالمعلومات',
    subTitle7: 'تعديل سياسة الخصوصية',
    subTitle8: 'كيفية الاتصال بنا',
    title: 'سياسة الخصوصية',
    updateTime: 'تم التحديث: 15 مارس 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'قبول الكل',
    text: 'لتحسين تجربتك، نستخدم ملفات تعريف الارتباط لتخصيص المحتوى والخدمات.',
    title: 'يستخدم هذا الموقع ملفات تعريف الارتباط',
  },
  slogen: 'أسهل مساعد الذكاء الاصطناعي',
  stripe: {
    contact: 'اتصل بنا على contact@noam.tools إذا كانت لديك أية أسئلة!',
    fail: {
      btn: 'أعد المحاولة',
      text1: 'لم نتمكن من معالجة دفعتك.',
      text2: 'تحقق من اتصالك وحاول مرة أخرى.',
      title: 'فشل الدفع',
    },
    succ: { btn: 'دعنا نذهب', text1: 'مرحبا بكم في نعوم،', text2: 'دعونا نبدأ!', title: 'تهانينا' },
  },
  terms: {
    block1: 'شكرا لاستخدامك نعوم!',
    block10:
      '(أ) الرسوم والفواتير. ستدفع جميع الرسوم ("الرسوم") وفقًا للتسعير والشروط الواردة في صفحة التسعير المعمول بها أو كما هو متفق عليه كتابيًا بيننا. نحن نحتفظ بالحق في تصحيح أخطاء أو أخطاء التسعير حتى لو قمنا بإصدار فواتير أو تلقينا الدفع. ستقدم معلومات كاملة ودقيقة عن الفواتير، بما في ذلك طريقة دفع صالحة ومصرح بها. سنقوم بتحصيل الرسوم من طريقة الدفع الخاصة بك على أساس دوري كما هو متفق عليه، ولكن يجوز لنا تغيير تاريخ الفاتورة بشكل معقول. أنت تفوض شركة Noam والشركات التابعة لها ومعالجي الدفع التابعين لجهات خارجية لتحصيل الرسوم من طريقة الدفع الخاصة بك. إذا فشلت عملية الدفع، فسنقدم لك إشعارًا كتابيًا وقد نقوم بتعليق الوصول إلى الخدمة حتى يتم استلام الدفع. باستثناء ما هو منصوص عليه خلاف ذلك في هذه الاتفاقية، فإن المدفوعات غير قابلة للاسترداد.',
    block11:
      '(ب) الضرائب. ما لم يُنص على خلاف ذلك، لا تشمل الرسوم أي ضرائب ورسوم وتقديرات فيدرالية وحكومية ومحلية وأجنبية وتقييمات مماثلة ("الضرائب"). أنت مسؤول عن جميع الضرائب المرتبطة بمشترياتك، باستثناء الضرائب المستندة إلى صافي دخلنا والتي سنقوم بإصدار فاتورة لك بها. أنت توافق على دفع هذه الضرائب على الفور وتزويدنا ببيانات خصوصية صالحة قانونًا والحصول على الموافقات اللازمة للتعامل مع هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به.',
    block12:
      '(ج) تغيرات الأسعار. قد نقوم بتغيير أسعارنا عن طريق إخطارك عبر حسابك و/أو موقعنا الإلكتروني. ستكون الزيادات في الأسعار سارية فور نشرها. سيتم تطبيق أي تغييرات في الأسعار على الرسوم الخاصة بحسابك بعد تاريخ سريان التغيير.',
    block13:
      '(د) المنازعات والمدفوعات المتأخرة. إذا كنت ترغب في الاعتراض على أي رسوم أو ضرائب، فيرجى الاتصال بـ contact@noam.tools خلال ثلاثين (30) يومًا من تاريخ الفاتورة.',
    block14:
      '(هـ) الاستخدام المجاني. لا يجوز لك إنشاء حسابات متعددة للحصول على أرصدة مجانية. إذا قررنا أنك لا تستخدم الأرصدة المجانية بحسن نية، فقد نفرض عليك رسومًا قياسية أو نتوقف عن توفير الوصول إلى الخدمة.',
    block15:
      '(أ) السرية. قد يكون لديك حق الوصول إلى المعلومات السرية الخاصة بـ Noam. لا يجوز لك استخدام المعلومات السرية إلا على النحو المسموح به بموجب هذه الشروط لغرض استخدام الخدمة. لا يجوز لك الكشف عن معلومات سرية لأي طرف ثالث، وسوف تقوم بحماية المعلومات السرية بطريقة لا تقل حماية عن حماية معلوماتك السرية المماثلة، على الأقل مع العناية المعقولة. المعلومات السرية هي معلومات غير عامة تم تصنيفها على أنها سرية بواسطة Noam أو يجب التعامل معها بشكل معقول على أنها سرية في ظل الظروف، بما في ذلك البرامج والمواصفات وغيرها من المعلومات التجارية غير العامة. لا تتضمن المعلومات السرية المعلومات التي: (1) تصبح متاحة للجمهور بشكل عام دون أي خطأ من جانبك؛ (2) كانت بحوزتك دون أي التزام بالسرية قبل استلامها بموجب هذه الشروط؛ (3) تم الكشف عنها لك بشكل قانوني من قبل طرف ثالث دون أي التزامات بالسرية؛ أو (4) تم تطويره بشكل مستقل بواسطتك دون استخدام المعلومات السرية. يجوز لك الكشف عن المعلومات السرية إذا كان ذلك مطلوبًا بموجب القانون أو المحكمة أو أي أمر حكومي آخر، ولكن يجب عليك تقديم إشعار كتابي مسبق إلى Noam بطريقة معقولة، وإلى أقصى حد ممكن، بذل جهود معقولة للحد من نطاق الكشف، بما في ذلك مساعدتنا في معارضة طلبات الإفصاح.',
    block16:
      '(ب) الأمن. يجب عليك تنفيذ التدابير المعقولة والمناسبة للمساعدة في حماية وصولك إلى الخدمة واستخدامها. إذا اكتشفت أي نقاط ضعف أو انتهاكات تتعلق باستخدامك للخدمة، فيجب عليك الاتصال بـ Noam على الفور وتقديم تفاصيل حول الثغرة الأمنية أو الانتهاك.',
    block17:
      '((ج) معالجة البيانات الشخصية. إذا كنت تستخدم الخدمة لمعالجة البيانات الشخصية، فيجب عليك تقديم بيانات خصوصية كافية قانونًا والحصول على الموافقات اللازمة لمعالجة هذه البيانات، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به .',
    block18:
      '(أ) الإنهاء؛ تعليق. ستكون هذه الشروط سارية منذ أول استخدام لك للخدمة وستظل سارية حتى إنهائها. يجوز لك إنهاء هذه الشروط في أي وقت ولأي سبب عن طريق التوقف عن استخدام الخدمة والمحتوى. يجوز لنا إنهاء هذه الشروط بإشعار مسبق لأي سبب من الأسباب. يجوز لنا إنهاء هذه الشروط على الفور عن طريق إخطارك إذا قمت بانتهاك مادي للقسم 2 (متطلبات الاستخدام)، أو القسم 5 (السرية والأمن وحماية البيانات)، أو القسم 8 (حل النزاعات)، أو القسم 9 (الشروط العامة)، أو إذا كان لدينا العلاقة مع أي مزود تكنولوجيا تابع لجهة خارجية خارج نطاق سيطرتنا، أو للامتثال للقانون أو الطلبات الحكومية. يجوز لنا تعليق وصولك إلى الخدمة إذا فشلت في الالتزام بهذه الشروط، أو إذا كان استخدامك يشكل خطرًا أمنيًا علينا أو على أي طرف ثالث، أو إذا اشتبهنا في أن استخدامك احتيالي أو قد يعرضنا أو يعرض أي طرف ثالث للمسؤولية. .',
    block19:
      '(ب) آثار الإنهاء. عند الإنهاء، ستتوقف عن استخدام الخدمة وستقوم على الفور بإرجاع أو تدمير أي معلومات سرية وفقًا لتوجيهاتنا. تظل أحكام هذه الشروط التي يجب أن تظل سارية بطبيعتها بعد الإنهاء أو انتهاء الصلاحية، بما في ذلك على سبيل المثال لا الحصر الأقسام 3 و5-9، سارية المفعول.',
    block2:
      'تنطبق شروط الاستخدام هذه على استخدامك لخدمات Noam، بما في ذلك واجهات برمجة التطبيقات والبرامج والأدوات وخدمات المطورين والبيانات والوثائق والموقع الإلكتروني (يشار إليها مجتمعة باسم "الخدمات"). باستخدام خدماتنا، فإنك توافق على الالتزام بهذه الشروط. تشرح سياسة الخصوصية الخاصة بنا كيف نقوم بجمع واستخدام المعلومات الشخصية.',
    block20:
      '(أ) التعويض. سوف تدافع عنا وتعوضنا وتحمينا نحن والشركات التابعة لنا وموظفينا من وضد أي مطالبات وخسائر ونفقات (بما في ذلك أتعاب المحاماة) تنشأ عن أو تتعلق باستخدامك للخدمة، بما في ذلك المحتوى الخاص بك، استخدام المنتجات أو الخدمات المتعلقة بالخدمة، وانتهاكك لهذه الشروط أو القانون المعمول به.',
    block21:
      '(ب) إخلاء المسؤولية. يتم توفير الخدمة "كما هي". إلى الحد الذي يسمح به القانون، لا نقدم نحن والشركات التابعة لنا والمرخصون لنا أي تعهدات أو ضمانات من أي نوع فيما يتعلق بالخدمة ولا نقدم أي ضمانات، بما في ذلك على سبيل المثال لا الحصر ضمانات قابلية التسويق والملاءمة لغرض معين والجودة المرضية وعدم الانتهاك. والتمتع الهادئ وأي ضمانات تنشأ عن التعامل أو استخدام التجارة. نحن لا نضمن أن الخدمة ستكون متواصلة أو دقيقة أو خالية من الأخطاء، أو أن أي محتوى سيكون آمنًا أو غير مفقود أو متغير.',
    block22:
      '(ج) حدود المسؤولية. لن نتحمل نحن أو الشركات التابعة لنا أو الجهات المرخصة لدينا المسؤولية عن أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية، بما في ذلك خسارة الأرباح أو فقدان السمعة أو فقدان الاستخدام أو فقدان البيانات أو غيرها من الخسائر غير الملموسة، حتى لو لقد تم إعلامنا بإمكانية حدوث مثل هذه الأضرار. بموجب هذه الشروط، لن تتجاوز مسؤوليتنا الإجمالية الرسوم التي دفعتها للخدمة خلال الاثني عشر (12) شهرًا السابقة للمطالبة أو مائة دولار أمريكي (100 دولار)، أيهما أكبر. تنطبق القيود الواردة في هذا القسم إلى الحد الأقصى الذي يسمح به القانون المعمول به.',
    block23:
      'تخضع الشروط واستخدامك للخدمات لقوانين الولايات المتحدة، باستثناء قواعد تنازع القوانين الأمريكية. قد يخضع استخدامك للتطبيق أيضًا لقوانين محلية أو حكومية أو وطنية أو دولية أخرى. سيتم تقديم أي إجراء قانوني أو إجراء يتعلق بهذه الشروط حصريًا إلى محاكم الولايات المتحدة، وأنت توافق على الخضوع للسلطة القضائية الشخصية لهذه المحاكم.',
    block24:
      '(أ) العلاقة بين الأطراف. أنت ونعوم متعاقدان مستقلان، ولا يتمتع أي من الطرفين بسلطة تمثيل الطرف الآخر أو إلزامه أو تحمل التزامات الطرف الآخر دون موافقة كتابية مسبقة من الطرف الآخر.',
    block25:
      '(ب) استخدام العلامة التجارية. لا يجوز لك استخدام الاسم أو الشعارات أو العلامات التجارية الخاصة بشركة Noam أو أي من الشركات التابعة لها دون الحصول على موافقة كتابية مسبقة منا.',
    block26:
      'إذا تبين أن أي بند من هذه الشروط غير صالح أو غير قانوني أو غير قابل للتنفيذ، فستظل الأحكام المتبقية سارية المفعول والتأثير الكامل.',
    block3:
      'إذا كان عمرك أقل من 18 عامًا، فيجب عليك الحصول على إذن من والديك أو الأوصياء القانونيين لاستخدام الخدمة. إذا كنت تستخدم الخدمة نيابة عن شخص أو كيان آخر، فيجب أن تكون مرخصًا لقبول هذه الشروط نيابةً عنهم. يجب عليك تقديم معلومات دقيقة وكاملة للتسجيل للحصول على حساب. لا يجوز لك تقديم بيانات اعتماد الوصول أو الحساب الخاص بك إلى أي شخص خارج مؤسستك، وأنت مسؤول عن جميع الأنشطة التي يتم إجراؤها باستخدام بيانات الاعتماد الخاصة بك.',
    block4:
      '(أ) استخدام الخدمة. يمكنك الوصول إلى الخدمة بموجب هذه الشروط، ونمنحك حقًا غير حصري لاستخدام الخدمة. عند استخدام الخدمة، فإنك ستلتزم بهذه الشروط وجميع القوانين المعمول بها. نحن والشركات التابعة لنا نملك جميع الحقوق والملكيات والمصالح في الخدمة.',
    block5:
      '(ب) ردود الفعل. نحن نرحب بالتعليقات والتعليقات والأفكار والاقتراحات والتحسينات. إذا قمت بتقديم أي محتوى من هذا القبيل، فقد نستخدمه دون أي قيود ودون تعويض لك.',
    block6:
      '(ج) القيود. لا يجوز لك: (1) استخدام الخدمة بطريقة تنتهك أو تختلس أو تنتهك أي حقوق للآخرين؛ (2) إجراء هندسة عكسية أو تفكيك أو تفكيك أو ترجمة أو محاولة اكتشاف كود المصدر أو المكونات الأساسية لنماذج الخدمة والخوارزميات والأنظمة (ما لم تكن هذه القيود محظورة بموجب القانون المعمول به)؛ (3) استخدام مخرجات الخدمة لتطوير نماذج تنافس نعوم؛ (4) استخراج البيانات أو المخرجات من الخدمة باستخدام أي طرق آلية أو برمجية ما لم يسمح بذلك من خلال واجهة برمجة التطبيقات، بما في ذلك الاستخراج أو تجميع الويب أو استخراج بيانات الويب؛ (5) تمثيل مخرجات الخدمة على أنها تم إنشاؤها بشكل مصطنع عندما لا تكون كذلك، أو تنتهك سياسات الاستخدام الخاصة بنا بطريقة أخرى؛ (6) شراء أو بيع أو نقل مفاتيح API دون موافقتنا المسبقة؛ أو (7) تزويدنا بأي معلومات شخصية عن الأطفال الذين تقل أعمارهم عن 13 عامًا أو السن المعمول به للموافقة الرقمية. سوف تلتزم بأي حدود للأسعار ومتطلبات أخرى في وثائقنا.',
    block7:
      '(د) خدمات الطرف الثالث. تخضع أي برامج أو خدمات أو منتجات أخرى تابعة لجهة خارجية مرتبطة بالخدمة لشروطها الخاصة، ونحن لسنا مسؤولين عن منتجات الجهات الخارجية.',
    block8:
      '(أ) المحتوى الخاص بك. يجوز لك تقديم مدخلات ("المدخلات") إلى الخدمة وتلقي المخرجات ("المخرجات") التي يتم إنشاؤها وإرجاعها بناءً على المدخلات (يشار إليها إجمالاً باسم "المحتوى"). بين الطرفين، وإلى الحد الذي يسمح به القانون المعمول به، فإنك تمتلك جميع المدخلات. يجوز لـ Noam استخدام المحتوى لتوفير الخدمة والحفاظ عليها، والامتثال للقوانين المعمول بها، وإنفاذ سياساتنا. أنت مسؤول عن المحتوى، بما في ذلك التأكد من أنه لا ينتهك أي قوانين معمول بها أو هذه الشروط.',
    block9:
      '(ب) الدقة. الذكاء الاصطناعي والتعلم الآلي من مجالات البحث سريعة التطور. نحن نسعى باستمرار لتحسين خدماتنا لجعلها أكثر دقة وموثوقية وأمانًا وإفادة. نظرًا للطبيعة الاحتمالية للتعلم الآلي، فإن استخدام خدمتنا قد يؤدي إلى مخرجات غير صحيحة في بعض الحالات، والتي قد لا تعكس بدقة الأفراد أو الأماكن أو الحقائق الحقيقية. يجب عليك تقييم دقة أي مخرجات بشكل مناسب بناءً على حالة الاستخدام الخاصة بك، بما في ذلك عن طريق مراجعة المخرجات يدويًا.',
    subTitle1: 'التسجيل والوصول',
    subTitle10: 'قابلية الفصل',
    subTitle2: 'متطلبات الاستخدام',
    subTitle3: 'محتوى',
    subTitle4: 'الرسوم والدفع',
    subTitle5: 'السرية والأمن وحماية البيانات',
    subTitle6: 'المدة والإنهاء',
    subTitle7: 'التعويض؛ إخلاء المسؤولية؛ حدود المسؤولية',
    subTitle8: 'القانون الحاكم والاختصاص القضائي',
    subTitle9: 'الشروط العامة',
    title: 'شروط الخدمة',
    updateTime: 'تم التحديث: 15 مارس 2024',
  },
  trialModal: {
    btn: 'ابدأ الآن (فقط 0.99 دولارًا)',
    chat: 'ChatPDF',
    desc1: 'بعد انتهاء الفترة التجريبية، سيتم محاسبتك برسوم سنوية قدرها 180 دولارًا',
    desc2: 'إلغاء في أي وقت',
    focus: 'التركيز',
    hover: 'تحوم الترجمة',
    image: 'ترجمة الصور',
    installBtn: 'قم بالتثبيت الآن لتحصل على هديتك',
    memo: 'إدارة المفضلة',
    pdf: 'ترجمة موازية لقوات الدفاع الشعبي',
    realtime: 'ترجمة فورية',
    select: 'ترجمة اختيار الكلمات',
    title: 'هدية الترحيب',
    trialText: 'تجربة لمدة 7 أيام',
    video: 'ترجمة الفيديو',
    videoSummary: 'ملخص الفيديو',
    web: 'الترجمة الموازية',
    webSummary: 'ملخص الويب',
  },
  upgradeModal: {
    btn: 'الترقية الآن',
    chat: {
      free: 'لقد وصلت إلى الحد الأقصى لـ ChatPDF',
      pro: 'لقد وصلت إلى الحد الأقصى لـ ChatPDF لهذا الشهر',
    },
    contactStr:
      'يرجى الانتظار حتى تتم عملية إعادة التعيين التالية، أو اتصل بنا على contact@noam.tools.',
    limitAlertStr: 'لقد وصلت إلى الحد الأقصى للخطة المجانية. الترقية للمتابعة.',
    more: 'عرض الخطط',
    pageLimit: { free: 'لقد وصلت إلى الحد الأقصى لعدد صفحات PDF' },
    title: 'تذكير',
    upgradeAlertStr: 'لقد وصلت إلى الحد الأقصى للخطة المجانية. الترقية للمتابعة.',
    upload: {
      free: 'لقد وصلت إلى حد ترجمة PDF الخاص بك',
      pro: 'لقد وصلت إلى حد ترجمة PDF الخاص بك لهذا الشهر',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'انضم الآن',
      label: 'فوائد المستخدم الجديد:',
      remain: 'بقي 30 فقط',
      text: '100 مكان متاح فقط! احصل على منتجك الآن!',
    },
  },
}

export default TRANSLATION
