const TRANSLATION = {
  buyModal: {
    afterFree: 'Después de tu prueba gratuita',
    btn: 'Pruébalo gratis',
    freeTrial: 'Prueba gratuita de 3 días',
    monthLabel: 'mes',
    selectLabel: 'Selecciona un plan',
    title: 'Plan de suscripción',
    yearLabel: 'año',
  },
  contact: {
    block1:
      'Para personalización, preguntas o sugerencias, contáctenos en contact@noam.tools. Responderemos con prontitud.',
    block2: 'Apóyenos calificándonos en la tienda de extensiones de Google Apps en ★★★★★.',
    block3: 'Ayude a otros compartiendo esta extensión:',
    title: 'Contáctenos',
  },
  help: {
    AquickOverviewOfNoam: 'Una descripción general rápida de Noam',
    ChatPDF: 'Chat PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Gestión de favoritos',
    Fixedplugin: 'Complemento fijo',
    Focusing: 'Enfoque',
    Free: 'Gratis',
    FreeTrialBenefits: 'Beneficios de la prueba gratuita',
    GoWithWikipedia: 'Vaya con Wikipedia',
    GoWithYouTube: 'Ve con YouTube',
    HoverTranslation: 'Traductor de hover',
    Howtouse: 'Cómo utilizar',
    ImageTranslation: 'Traducción de imágenes',
    InstantTranslation: 'Traducción instantánea',
    Membership: 'Afiliación',
    NoamHelp: 'Noam te ayuda a traducir y resumir',
    PDFparallelTranslation: 'Traducción paralela de PDF',
    ParallelTranslation: 'Traducción paralela',
    SkipAllSteps: 'Saltar todos los pasos',
    Summary: 'Resumen',
    Translation: 'Traducción',
    TryItForFree: 'Pruébalo gratis',
    Video: 'Video',
    VideoSummary: 'Resumen de vídeo',
    VideoTranslation: 'Traducción de vídeos',
    WebSummary: 'Resumen Web',
    WordSelectionTranslation: 'Traducción de selección de palabras',
    benefits: '¡Tenemos beneficios esperándote!',
    dayFree: 'Prueba gratuita de 3 días',
    done: 'Hecho',
    next: 'Próximo',
    pin: 'Pin Noam para un acceso más fácil～～',
    planIsNotExist: 'El plan no está disponible',
    startWiki: '¡Empecemos con Wikimedia!',
    startYoutube: '¡Empecemos con YouTube!',
    try: 'Pruébalo',
    tryNow: '¡Pruébalo ahora!',
  },
  home: {
    PDFTranslate: {
      desc: 'Traducción Bilingüe + Chat PDF',
      sub1: {
        desc: 'La lectura bilingüe le permite comparar el texto original y la traducción uno al lado del otro, mejorando la eficiencia de la lectura.',
        title: 'Lectura bilingüe',
      },
      sub2: {
        desc: 'La IA analiza y resume, y puede responder sus preguntas en función del contenido del PDF.',
        title: 'Chat PDF',
      },
      sub3: {
        desc: 'Traducción de PDF basada en IA para mayor precisión y eficiencia.',
        title: 'Precisión y eficiencia',
      },
      title: 'Traducir PDF',
    },
    aiAssistant: {
      desc: 'Cómo hacer que la traducción web sea más inteligente y cómoda',
      panel1: {
        desc: 'Las traducciones bilingües con referencias cruzadas lo ayudan a comparar el texto original con la traducción para que pueda comprender mejor y mejorar su eficiencia de lectura.',
        title: 'Traducción bilingüe',
      },
      panel2: {
        left: {
          desc: 'Cuando navegue por una página web, coloque el cursor sobre el contenido que necesita traducir para ver la traducción.',
          title: 'Traductor de hover',
        },
        right: {
          desc: 'Cuando navegue por una página web, seleccione el texto que desea traducir para ver la traducción.',
          title: 'Traducción de selección de texto',
        },
      },
      panel3: {
        one: {
          desc: '¡Traduce y resume páginas web y contenido de video en cualquier momento y lugar para una experiencia más fluida!',
          title: 'Apuntar y hacer clic',
        },
        three: {
          desc: 'El modelo de lenguaje grande impulsado por IA garantiza precisión y coherencia en la traducción para una experiencia de lectura mejorada.',
          title: 'Impulsado por IA',
        },
        two: {
          desc: 'Cobertura completa en varios idiomas, lectura bilingüe sin barreras, traducción con un solo clic: ¡experimente la diferencia!',
          title: 'Más completo y conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Su experto en eficiencia: con ChatGPT, Noam traduce y resume el contenido de vídeo de YouTube para ahorrarle tiempo.',
          key: 'Video',
          title: 'Traducción y resumen de vídeo',
        },
        tab2: {
          desc: 'Haga clic para traducir imágenes mientras navega por la web.',
          key: 'Imagen',
          title: 'Traducir imagen',
        },
        tab3: {
          desc: 'Extrae instantáneamente información clave de páginas web y proporciona un resumen, mejorando su eficiencia de lectura.',
          key: 'Web',
          title: 'Traducción y resumen web',
        },
        tag1: 'Simple',
        tag2: 'Eficiente',
        tag3: 'Fácil de entender',
      },
      title: 'Asistente de IA',
    },
    getStart: 'Empezar',
    getStartedForFree: 'Comience gratis',
    howItWork: {
      desc: 'Noam integra modelos de IA para brindar a los usuarios una experiencia inteligente para traducción y resúmenes de páginas web en varios idiomas, traducción de PDF e imágenes, traducción y resúmenes de videos.',
      title: 'como funciona',
    },
    lastBlock: { desc: 'Mejor traducción con IA para una experiencia de lectura más fluida' },
    top: {
      desc: 'Noam es una extensión gratuita de navegador con IA que te ayuda a traducir y resumir páginas web, vídeos y contenido PDF.\\n ¡Únete a Noam y experimenta la magia!',
      title1: 'Una magia',
      title2: 'Un clic',
    },
    userReviews: {
      desc: 'Reseñas de cinco estrellas en Chrome Web Store',
      title: 'Reseñas de usuarios',
    },
  },
  installTips: {
    btn: 'Pruébalo ahora',
    text: '¡Instala el "Complemento del navegador Noam" para desbloquear más funciones! Referencias cruzadas web,\\n resúmenes de vídeos web y mucho más.',
  },
  locale: {
    af: 'africaans',
    am: 'amárico',
    ar: 'árabe',
    as: 'Assamese',
    auto: 'Detección automática',
    az: 'azerbaiyano',
    ba: 'bashkir',
    bg: 'búlgaro',
    bn: 'bengalí',
    bo: 'tibetano',
    bs: 'bosnio',
    ca: 'catalán',
    cs: 'checo',
    cy: 'galés',
    da: 'danés',
    de: 'Alemán',
    dsb: 'bajo sorabo',
    dv: 'Divehi',
    el: 'Griego',
    en: 'Inglés',
    es: 'Español',
    et: 'estonio',
    eu: 'vasco',
    fa: 'persa',
    fi: 'finlandés',
    fil: 'filipino',
    fj: 'fiyiano',
    fr: 'Francés',
    frCA: 'Francés (Canadá)',
    ga: 'irlandés',
    gl: 'gallego',
    gom: 'Konkani',
    gu: 'gujarati',
    ha: 'Hausa',
    he: 'hebreo',
    hi: 'hindi',
    hr: 'croata',
    hsb: 'alto sorabo',
    ht: 'criollo haitiano',
    hu: 'húngaro',
    hy: 'armenio',
    id: 'indonesio',
    ig: 'igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandés',
    it: 'italiano',
    iu: 'inuktitut',
    iuLatn: 'Inuktitut (latín)',
    ja: 'japonés',
    ka: 'georgiano',
    kk: 'kazajo',
    km: 'jemer',
    kmr: 'Kurdo (norte)',
    kn: 'canarés',
    ko: 'coreano',
    ku: 'Kurdo (Central)',
    ky: 'Kirguistán',
    ln: 'Lingala',
    lo: 'laosiano',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letón',
    lzh: 'Chino (literario)',
    mai: 'Maithili',
    mg: 'madagascarí',
    mi: 'Maorí',
    mk: 'macedónio',
    ml: 'malayalam',
    mnCyrl: 'mongol (cirílico)',
    mnMong: 'Mongol (tradicional)',
    mr: 'marathi',
    ms: 'malayo',
    mt: 'maltés',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmano)',
    nb: 'noruego',
    ne: 'nepalí',
    nl: 'Holandés',
    noSearchLang: 'Idioma no compatible',
    nso: 'Sesotho en Leboa',
    nya: 'nyanja',
    or: 'Odia',
    otq: 'Otomí de Querétaro',
    pa: 'punjabi',
    pl: 'Polaco',
    placeholder: 'Buscar',
    prs: 'Darí',
    ps: 'pastún',
    pt: 'Portugués (Brasil)',
    ptPT: 'Portugués (Portugal)',
    ro: 'rumano',
    ru: 'ruso',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'sindhi',
    si: 'cingalés',
    sk: 'eslovaco',
    sl: 'esloveno',
    sm: 'samoano',
    sn: 'Shona',
    so: 'somalí',
    sq: 'albanés',
    srCyrl: 'Serbio (cirílico)',
    srLatn: 'serbio (latín)',
    st: 'sesoto',
    sv: 'sueco',
    sw: 'swahili',
    ta: 'Tamil',
    te: 'telugu',
    th: 'tailandés',
    ti: 'Tigrinya',
    tk: 'turcomano',
    tlhLatn: 'Klingon (latín)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'setswana',
    to: 'tongano',
    tr: 'turco',
    tt: 'tártaro',
    ty: 'tahitiano',
    ug: 'uigur',
    uk: 'ucranio',
    ur: 'urdu',
    uz: 'Uzbeko (latín)',
    vi: 'vietnamita',
    xh: 'xhosa',
    yo: 'yoruba',
    yua: 'Maya yucateco',
    yue: 'Cantonés (tradicional)',
    zh: 'Chino',
    zhHans: 'chino simplificado',
    zhHant: 'chino tradicional',
    zu: 'zulú',
  },
  login: {
    and: 'y',
    btn: 'Iniciar sesión con Google',
    btn1: 'Iniciar sesión ahora',
    desc: 'Inicia sesión para desbloquear más experiencias',
    policy: 'política de privacidad',
    terms: 'Condiciones de uso',
    tips: 'Al registrarme, acepto las',
    title: 'Bienvenido a Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguir', twitter: 'Gorjeo' },
    legal: { policy: 'política de privacidad', terms: 'Términos de servicio', title: 'Legal' },
    product: {
      image: 'Traducción de imágenes',
      pdf: 'Traducción de PDF',
      title: 'Productos',
      video: 'Traducción y resumen de vídeo',
      web: 'Traducción web y resumen',
    },
    resources: { title: 'Recursos' },
  },
  pageHeader: {
    contact: 'Contacto',
    help: 'Ayuda',
    logout: 'Finalizar la sesión',
    logoutSucc: 'Cerrado la sesión.',
    pricing: 'Precios',
    settings: 'Ajustes',
    signUp: 'Acceso',
  },
  pdf: {
    autoDetect: 'Detección automática',
    cancelBtn: 'Cancelar',
    cancelUpload: 'Cancelar',
    chatPdf: {
      Search: 'Buscar',
      cancel: 'Cancelar',
      chatTitle: '¡Bienvenidos a Noam! Aquí hay un resumen de su PDF:',
      clear: 'Claro',
      clearContent:
        'Esto iniciará una nueva conversación. La conversación actual no se guardará. ¿Está seguro?',
      clearDialogTitle: 'Por favor confirme',
      clearSucc: 'Borrado',
      copy: 'Copiar',
      copySucc: 'copiado',
      delete: 'Borrar',
      deleteCancel: 'Cancelar',
      deleteContent: 'Este PDF no se puede recuperar una vez eliminado. ¿Está seguro?',
      deleteOk: 'Borrar',
      deleteSuccess: 'Eliminado',
      deleteTitle: 'Confirmar eliminación',
      expand: 'Colapsar',
      historyTitle: 'Historia',
      listError:
        'No se pudo cargar la lista de lectura. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
      loading: 'Cargando...',
      noDataDesc: 'Aún no hay antecedentes.',
      noMore: 'No más contenido.',
      placeholder: 'Pregunta cualquier cosa sobre este PDF',
      summaryTitle: 'Quizás te interesen estas preguntas:',
    },
    collectionEmpty: 'Tu colección está vacía.',
    collectionTab: 'Notas',
    dataLoading: 'Cargando...',
    dialogContent: '¿Estás seguro de que quieres eliminar esto?',
    dialogTitle: 'Por favor confirme',
    download: {
      both: 'Bilingüe',
      btn: 'Ahorrar',
      cancel:
        'Al cerrar esta ventana se cancelará la descarga del PDF. ¿Estás seguro de que quieres cancelar?',
      desc: 'Guardar en dispositivo: descarga traducciones como imágenes; Guardar e imprimir: descarga traducciones en el formato original, con soporte para copia.',
      download: 'Descargar',
      downloadMsg1: 'Progreso de la traducción de PDF:',
      downloadMsg2: 'Tiempo restante estimado:',
      downloadSucc: '¡El PDF está listo! Haga clic para guardar.',
      downloadTip: 'Descargando PDF. No cierres esta ventana para evitar errores de descarga.',
      error: 'No se pudo guardar. Intente imprimir en su lugar.',
      fileName: 'Nombre del archivo:',
      fileType: 'Tipo de archivo:',
      loading: 'Ahorro...',
      loading2: 'Cargando...',
      minute: 'Minutos...',
      msg1: 'Algunas traducciones de este documento son más largas que el texto original y pueden superponerse en el archivo descargado. Recomendamos leer en la página web.',
      msg2: '1. La traducción aún no está completa. Descárguelo una vez finalizada la traducción.',
      msg3: '2. Algunas traducciones son demasiado largas. Lea en la página web para evitar la superposición de texto.',
      msg4: 'Las tareas de traducción están en curso. Inténtelo de nuevo una vez completados.',
      progress:
        'Traduciendo PDF. Progreso: {{progress}}, Tiempo restante estimado: {{minutos}} minutos...',
      save: 'Guardar en dispositivo',
      savePrint: 'Guardar e imprimir',
      success: 'Descargado',
      trans: 'Sólo traducción',
      transContinue: 'Continuar traduciendo',
    },
    downloadBtn: 'Descargar',
    dragTipModal: {
      black: 'espacio',
      leftKey: 'botón izquierdo',
      ok: 'Entiendo',
      press: 'Prensa',
      tips: 'para arrastrar y ver.',
    },
    entry: 'PDF',
    fileErrMsg:
      'La lectura del archivo falló. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    fileMsg: 'Tamaño de archivo actual:',
    fileMsg2: 'Cargue un archivo de menos de 300 MB.',
    freeBannerBtn: 'Mejora',
    freeBannerTips: 'Se alcanzó el límite de traducciones gratuitas de PDF',
    guide: {
      placeholder: 'Pegue el enlace PDF aquí',
      start: 'Empezar a traducir',
      text1: 'Lectura bilingüe',
      text2:
        'Lea bilingüe para comparar el texto original y la traducción uno al lado del otro, mejorando su eficiencia.',
      text3: 'Chatea con tu PDF',
      text4:
        'Análisis y resúmenes impulsados ​​por IA. Haga preguntas sobre su PDF y obtenga respuestas instantáneas.',
      text5: 'Preciso y eficiente',
      text6: 'Traducción de PDF basada en IA para mayor precisión y eficiencia.',
      title1: 'Traducción bilingüe',
      title2: 'Chatea con tu PDF',
      toast: 'Ingrese un enlace PDF en línea válido que termine en .pdf',
    },
    hasTransTask: 'Hay una tarea de traducción en curso. Espere por favor.',
    hoverTrans: 'Colocar el cursor para traducir párrafos',
    initial: 'Traducir PDF',
    installText: 'Su solución integral para las traducciones cotidianas.',
    installText2: 'Por favor instale la extensión primero',
    installTips:
      'Instale la extensión para obtener aún más traducciones de PDF y traducciones de sitios web más fluidas:',
    kw: 'grapa',
    link: 'enlace',
    listError:
      'No se pudieron cargar los archivos PDF. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    memoTotalCount: '1 artículo en total',
    new: 'nuevo archivo',
    noId: 'ID de archivo no encontrado.',
    notPdf: 'Sólo archivos PDF.',
    okBtn: 'Borrar',
    okBtn2: 'Entiendo',
    original: 'Original',
    parallel: 'Paralelo',
    parseErrDialogContent:
      'Este enlace PDF no se puede traducir directamente. Descargue el PDF y cárguelo para traducirlo.',
    parseErrTips: 'No se pudo analizar el archivo',
    parseFileToast:
      'No se pudo analizar el archivo. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    pdfLoading: 'Analizando...',
    searchPlaceholder: 'Buscar',
    selectTrans: 'Seleccionar texto para traducir',
    shareBtn: 'Compartir',
    shareBtnText1: 'Generar enlace para compartir',
    shareBtnText2: 'Actualizar contenido compartido',
    shareModal: {
      cancel: 'Cancelar',
      copy: 'Copiar',
      copySucc: 'Enlace copiado',
      custom: 'Personalizar:',
      errMsg:
        'El contenido seleccionado aún no se ha traducido completamente. Traduzca antes de compartir.',
      gen: 'Compartir',
      modify: 'Editar',
      notSupport: 'Este idioma no es compatible actualmente.',
      page: 'paginas',
      pageNuErr: 'Por favor ingrese un número de página válido.',
      range: 'Alcance:',
      remain: 'restante',
      remain1: 'Tiempo total estimado:',
      search: 'Buscar',
      settings: 'Compartir configuración:',
      shareSetting: 'Compartir configuración',
      shareText: 'Compartir',
      shareTips: 'Comparte el enlace PDF traducido con tus amigos:',
      sourceLang: 'De:',
      target: 'A:',
      text1: 'Compartir configuración',
      text2: 'Generar enlace para compartir',
      totalPage: '1 página en total',
      trans: 'Traducir',
      translateTips:
        'Consejo: si la traducción tarda un poco, puedes cerrar esta ventana y compartirla más tarde.',
      translating: 'Traduciendo PDF...',
      update: 'Actualizar enlace compartido',
    },
    shareMsg1: 'Compartir enlace generado.',
    shareMsg2: 'Contenido compartido actualizado.',
    shareMsg3:
      'No se pudo generar el enlace para compartir. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    shareMsg4:
      'No se pudo actualizar el contenido compartido. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    shareTips: '¡Su traducción de PDF está lista! Compártelo con tus amigos.',
    start: 'Comience su prueba gratuita',
    thumbnail: 'Tabla de contenido',
    toast1: 'Cargando PDF. Inténtelo de nuevo más tarde.',
    toast2: 'El contenido del PDF es demasiado grande. Actualmente, el diálogo no es compatible.',
    toast3: 'Descargando. Inténtelo de nuevo una vez finalizada la descarga.',
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    trans: 'Traducción',
    transSucc: '¡Traducción exitosa!',
    unLoginToast: 'Inicie sesión para utilizar esta función.',
    uploadBtn: 'Subir',
    uploadErr:
      'Error al cargar el archivo. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    uploadMsg1: 'Arrastra y suelta tu archivo aquí.',
    uploadMsg2: 'Tipos de archivos admitidos: PDF |  Tamaño máximo de archivo: 100 MB',
    uploadMsg3: 'Haga clic para seleccionar o arrastre y suelte archivos aquí.',
    uploading: 'Subiendo',
    uploadingMsg: 'Subiendo. Espere por favor.',
    uploadingMsg1: 'El archivo se está cargando. Inténtelo de nuevo más tarde.',
    uploadingMsg2: 'Actualmente se está convirtiendo un archivo. Inténtelo de nuevo más tarde.',
    uploadingMsg3:
      'Error en la solicitud de progreso de la conversión. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    uploadingMsg4:
      'Noam no puede acceder a los archivos locales. Cargue manualmente un PDF para traducir.',
    uploadingMsg5:
      'No se pudo recuperar la URL del archivo. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    video: 'video',
    vipBannerBtn: 'Mejora',
    vipBannerTips: 'Has alcanzado tu límite de traducción de PDF para este mes.',
    webTrans: 'Referencia cruzada completa',
  },
  pricing: {
    FAQ: 'Preguntas frecuentes',
    FQAObj: {
      a1: 'Sí, Noam ofrece un plan gratuito que puedes utilizar de inmediato.',
      a2: 'Ofrecemos tres planes de membresía: Pro, Pro+ y Enterprise. Puedes elegir pagar mensual o anualmente. La facturación anual ofrece importantes ahorros.',
      a3: 'Todas las funciones están disponibles en ambos planes. La principal diferencia es el costo: las suscripciones anuales cuestan menos en general. Las suscripciones mensuales se facturan cada mes, mientras que los planes anuales se facturan una vez al año.',
      a4: 'Puede cancelar su suscripción en cualquier momento en la Configuración de su cuenta.',
      q1: '¿Existe un plan gratuito?',
      q2: '¿Qué planes de precios y opciones de pago están disponibles?',
      q3: '¿Cuál es la diferencia entre suscribirse mensualmente o anualmente?',
      q4: '¿Cómo cancelo mi suscripción?',
    },
    around: 'Alrededor',
    billedMonthly: 'Facturado mensualmente',
    billedYearly: 'Facturado anualmente',
    day: 'día',
    desc: 'Compara planes y elige el mejor',
    detailedCompare: 'Comparar planes',
    discount: '20% de descuento',
    enterprise: {
      benefit1: 'Atención al cliente prioritaria',
      benefit2: 'Múltiples usuarios',
      benefit3: 'Soluciones a medida',
      benefit5: 'Gerente de cuentas dedicado',
      btn: 'Contáctenos',
      recommendText: 'Planes personalizados（≥3人）',
      talkToUs: 'Ponte en contacto',
    },
    free: {
      benefit1: 'Traducción instantánea 100 veces/día',
      benefit2: 'Traducción y resumen del sitio web',
      benefit3: '30 traducciones de imágenes',
      benefit4: 'Traducción y resumen de vídeo',
      benefit5: '10 traducciones de PDF',
      benefit6: '5.000 fichas',
      btn: 'Pruébalo gratis',
      recommendText: 'Prueba gratuita',
    },
    imageTranslation: 'Traducción de imágenes',
    month: 'mes',
    monthly: 'Mensual',
    pageTitle: 'Precios',
    pdf: {
      chat: 'Consultas de chat en PDF',
      maxPage: 'Máx. Páginas por PDF',
      translation: 'Traducción de PDF',
    },
    preMonth: 'Por mes',
    pro: {
      benefit1: 'Atención al cliente prioritaria',
      benefit2: '1.000.000 tokens/mes',
      benefit3: 'Traducción y resumen del sitio web',
      benefit4: '200 traducciones de imágenes',
      benefit5: 'Traducción y resumen de vídeo',
      benefit6: '200 traducciones de PDF',
      btn: 'Empezar',
      recommendText: 'Más Popular',
    },
    proPlus: {
      benefit1: 'Atención al cliente prioritaria',
      benefit2: '3.000.000 tokens/mes',
      benefit3: 'Traducción y resumen del sitio web',
      benefit4: '500 traducciones de imágenes',
      benefit5: 'Traducción y resumen de vídeo',
      benefit6: '500 traducciones de PDF',
      recommendText: 'Mejor valor',
    },
    video: { summary: 'Resumen de vídeo', translation: 'Traducción de vídeos' },
    web: {
      contrastiveTranslation: 'Traducción bilingüe',
      dictionaryTranslation: 'Traducción de selección',
      hoverTranslation: 'Traductor de hover',
      instantTranslation: 'Traducción instantánea',
      summary: 'Resumen de la página web',
    },
    yearly: 'Anual',
  },
  privacy: {
    block1:
      'Noam ("Noam", "nosotros", "nuestro" o "nos") respeta su privacidad y se compromete a proteger cualquier información que obtengamos de usted o sobre usted. Esta Política de Privacidad describe nuestras prácticas con respecto a la recopilación o el uso de información personal derivada de su uso de nuestro sitio web, aplicaciones y servicios (colectivamente, los "Servicios"). Esta Política de Privacidad no se aplica al contenido que manejamos en nombre de nuestros clientes de productos comerciales, como nuestra API. El uso de estos datos está sujeto a nuestro acuerdo con el cliente, que cubre el acceso y el uso de estos productos.',
    block10:
      'Implementamos medidas técnicas, administrativas y organizativas comercialmente razonables para proteger la información personal en línea y fuera de línea contra pérdidas, uso indebido y acceso no autorizado, divulgación, alteración o actos hostiles. Sin embargo, ninguna transmisión por Internet o por correo electrónico puede garantizar una seguridad o precisión total. En particular, los correos electrónicos que nos envíen o nos envíen pueden no ser seguros. Por lo tanto, debe tener especial cuidado al decidir qué información enviarnos a través del servicio o del correo electrónico. Además, no somos responsables de ninguna obstrucción de la configuración de privacidad o las medidas de seguridad en el servicio o las configuraciones o medidas de seguridad de los sitios web de terceros. Conservaremos su información personal durante el tiempo que sea necesario para brindarle servicios o para otros fines comerciales legítimos, como resolver disputas, razones de seguridad o cumplir con nuestras obligaciones legales. El período de tiempo que conservemos información personal dependerá de muchos factores, como la cantidad, la naturaleza, la sensibilidad de la información, los riesgos potenciales de uso o divulgación no autorizados, los fines para los cuales procesamos la información y otras consideraciones legalmente vinculantes.',
    block11:
      'Podemos actualizar esta Política de Privacidad de vez en cuando. Cuando lo hagamos, publicaremos una versión actualizada en esta página, a menos que la ley aplicable exija lo contrario.',
    block12:
      'Si tiene alguna pregunta o inquietud sin respuesta sobre esta Política de Privacidad, comuníquese con nuestro servicio de atención al cliente. Correo electrónico:',
    block2:
      'Recopilamos la siguiente información personal ("Información personal") relacionada con usted:',
    block3:
      'Información proporcionada por usted: cuando crea una cuenta para utilizar nuestros servicios o comunicarse con nosotros, recopilamos información personal de la siguiente manera:',
    block4:
      'Información personal recibida automáticamente a partir de su uso de los servicios: cuando accede, utiliza o interactúa con los servicios, recibimos la siguiente información sobre su acceso, uso o interacción ("Información técnica"):',
    block5: 'Podemos utilizar información personal para los siguientes fines:',
    block6:
      'Resumen o información no identificada: podemos agregar o no identificar información personal para evitar su reutilización con fines de identificación y usar dicha información para analizar la efectividad de nuestros servicios, mejorar y agregar funciones a nuestros servicios, realizar investigaciones y para fines similares. . Además, podemos analizar el comportamiento general y las características de los usuarios de nuestros servicios y compartir información agregada con terceros, publicar dicha información agregada o ponerla a disposición de forma general. Podemos recopilar información agregada a través de los servicios, cookies y otros medios descritos en esta Política de Privacidad. Mantendremos y utilizaremos información no identificada de forma anónima o no identificada, y no intentaremos volver a identificar dicha información a menos que lo exija la ley.',
    block7:
      'En ciertos casos, podemos proporcionar su información personal a terceros sin previo aviso, a menos que lo exija la ley:',
    block8:
      'Dependiendo de su ubicación geográfica, las personas en el Espacio Económico Europeo, el Reino Unido y en todo el mundo pueden tener ciertos derechos legales con respecto a su información personal. Por ejemplo, es posible que tenga derecho a:',
    block9:
      'El servicio puede contener enlaces a otros sitios web que Noam no opera ni controla, incluidos los servicios de redes sociales ("Sitios web de terceros"). La información que comparta con sitios web de terceros se regirá por las políticas de privacidad y los términos de servicio específicos de los sitios web de terceros, no por esta Política de privacidad. Proporcionamos estos enlaces para su comodidad y no implican nuestro respaldo o revisión de estos sitios web. Comuníquese directamente con los sitios web de terceros para obtener información sobre sus políticas y términos de privacidad.',
    subBlock1:
      'Información de la cuenta: cuando crea una cuenta en nuestra plataforma, recopilamos información relacionada con su cuenta, incluido su nombre, información de contacto, credenciales de la cuenta, información de la tarjeta de pago e historial de transacciones ("Información de la cuenta").',
    subBlock10: 'Proporcionar, gestionar, mantener y/o analizar servicios;',
    subBlock11: 'Comunicándose con usted;',
    subBlock12: 'Desarrollar nuevos productos y servicios;',
    subBlock13:
      'Prevenir fraudes, actividades delictivas o uso indebido de nuestros servicios y proteger la seguridad de nuestros sistemas, arquitectura y redes de TI;',
    subBlock14: 'Realización de transferencias comerciales;',
    subBlock15:
      'Cumplir con obligaciones legales y procedimientos legales, protegiendo nuestros derechos, privacidad, seguridad o propiedad, así como los de nuestros afiliados, usted u otros terceros.',
    subBlock16:
      'Proveedores y proveedores de servicios: para ayudarnos a satisfacer las necesidades operativas comerciales y realizar ciertos servicios y funciones, podemos proporcionar información personal a proveedores y proveedores de servicios, incluidos proveedores de servicios de alojamiento, proveedores de servicios en la nube y otros proveedores de servicios de tecnología de la información, software de comunicación por correo electrónico. y proveedores de servicios de análisis web, etc. Estos socios accederán, procesarán o almacenarán información personal solo bajo nuestra dirección mientras desempeñan sus funciones para nosotros.',
    subBlock17:
      'Transferencias comerciales: si participamos en transacciones estratégicas, reorganizaciones, quiebras, adquisiciones por quiebra o transición de servicios a otro proveedor (colectivamente, "Transacciones"), su información personal y otra información se puede compartir con las contrapartes de la transacción y otras partes que ayudan en las Transacciones. durante la diligencia debida y transferidos como parte de las Transacciones junto con otros activos a una empresa sucesora o afiliada.',
    subBlock18:
      'Requisitos legales: podemos compartir su información personal con autoridades gubernamentales, pares de la industria u otros terceros, incluida su información de interacción con nuestros servicios, (1) si lo exige la ley o si creemos que dicha acción es necesaria para cumplir con las obligaciones legales; (2) para proteger y defender nuestros derechos o propiedad; (3) si determinamos unilateralmente que existe una violación de nuestros términos, políticas o leyes; (4) para detectar o prevenir fraudes u otras actividades ilegales; (5) para proteger nuestros productos, empleados o usuarios, o la seguridad pública o la integridad; o (vi) para defenderse de responsabilidad legal.',
    subBlock19: 'Acceder a su información personal e información sobre cómo se procesa.',
    subBlock2:
      'Contenido del usuario: cuando utiliza nuestros servicios, recopilamos información personal de sus entradas, cargas de archivos o comentarios que nos proporciona ("Contenido").',
    subBlock20: 'Solicitar la eliminación de su información personal de nuestros registros.',
    subBlock21: 'Corregir o actualizar su información personal.',
    subBlock22: 'Transferir su información personal a un tercero (portabilidad de datos).',
    subBlock23: 'Limite la forma en que procesamos su información personal.',
    subBlock24:
      'Retire su consentimiento: cuando dependamos del consentimiento como base legal para el procesamiento, puede retirar su consentimiento en cualquier momento.',
    subBlock25: 'Oponerse a cómo procesamos su información personal.',
    subBlock26: 'Mejorar nuestros servicios y realizar investigaciones;',
    subBlock3:
      'Información de comunicación: si nos envía mensajes, recopilaremos su nombre, información de contacto y el contenido de sus mensajes ("Información de comunicación").',
    subBlock4:
      'Información de redes sociales: tenemos páginas en sitios web de redes sociales como Instagram, Facebook, Medium, Twitter, YouTube y LinkedIn. Cuando interactúa con nuestras páginas de redes sociales, recopilamos la información personal que usted elige proporcionarnos, como sus datos de contacto ("Información social"). Además, las empresas que alojan nuestras páginas de redes sociales pueden proporcionarnos información y análisis agregados sobre nuestras actividades en las redes sociales.',
    subBlock5:
      'Datos de registro: Información enviada automáticamente por su navegador al utilizar nuestros servicios. Los datos de registro incluyen su dirección de Protocolo de Internet (IP), tipo y configuración del navegador, la fecha y hora de sus solicitudes y cómo interactúa con nuestro sitio web.',
    subBlock6:
      'Datos de uso: Podemos recopilar automáticamente información sobre su uso del servicio, como los tipos de contenido que ve o con el que interactúa, las funciones que utiliza, las acciones que realiza, así como su zona horaria, país, fecha y hora de acceso, agente de usuario y versión, tipo de computadora o dispositivo móvil y su conexión de computadora.',
    subBlock7:
      'Información del dispositivo: esto incluye el nombre del dispositivo, el sistema operativo, el identificador del dispositivo y el navegador que está utilizando. La información recopilada puede depender del tipo de dispositivo que esté utilizando y su configuración.',
    subBlock8:
      'Cookies: utilizamos cookies para operar y administrar nuestros servicios y mejorar su experiencia. Una "cookie" es información enviada por un sitio web que usted visita a su navegador. Puede configurar su navegador para que acepte todas las cookies, las rechace todas o le notifique cuando se proporciona una cookie para que pueda decidir si la acepta cada vez. Sin embargo, rechazar las cookies puede impedirle utilizar o afectar negativamente la visualización o funcionalidad de ciertas áreas o características del sitio web. Para obtener más información sobre las cookies, visite Todo sobre las cookies.',
    subBlock9:
      'Análisis: podemos utilizar varios productos de análisis en línea que utilizan cookies para ayudarnos a analizar cómo los usuarios utilizan nuestros servicios y mejorar su experiencia al utilizar los servicios.',
    subTitle1: 'Información personal que recopilamos',
    subTitle2: 'Cómo utilizamos la información personal',
    subTitle3: 'Divulgación de información personal',
    subTitle4: 'Tus derechos',
    subTitle5: 'Enlaces a otros sitios web',
    subTitle6: 'Seguridad y Retención de Información',
    subTitle7: 'Modificación de la Política de Privacidad',
    subTitle8: 'Cómo contactarnos',
    title: 'política de privacidad',
    updateTime: 'Actualizado: 15 de marzo de 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Aceptar todo',
    text: 'Para mejorar su experiencia, utilizamos cookies para personalizar contenidos y servicios.',
    title: 'Este sitio web utiliza cookies',
  },
  slogen: 'El asistente de IA más sencillo',
  stripe: {
    contact: '¡Contáctenos en contact@noam.tools si tiene alguna pregunta!',
    fail: {
      btn: 'Rever',
      text1: 'No pudimos procesar su pago.',
      text2: 'Comprueba tu conexión y vuelve a intentarlo.',
      title: 'Pago fallido',
    },
    succ: {
      btn: 'Vamos',
      text1: 'Bienvenido a Noam,',
      text2: '¡comencemos!',
      title: 'Felicidades',
    },
  },
  terms: {
    block1: '¡Gracias por usar Noam!',
    block10:
      '(a) Tarifas y Facturación. Pagará todas las tarifas ("Tarifas") de acuerdo con los precios y los términos de la página de precios correspondiente o según lo acordado por escrito entre nosotros. Nos reservamos el derecho de corregir errores o equivocaciones en los precios incluso si hemos emitido facturas o recibido el pago. Proporcionará información de facturación completa y precisa, incluido un método de pago válido y autorizado. Cobraremos su método de pago periódicamente según lo acordado, pero podemos cambiar razonablemente la fecha de facturación. Usted autoriza a Noam, sus afiliados y procesadores de pagos externos a cargar su método de pago. Si su pago falla, le enviaremos un aviso por escrito y podremos suspender el acceso al Servicio hasta que se reciba el pago. Salvo que se especifique lo contrario en este acuerdo, los pagos no son reembolsables.',
    block11:
      '(b) Impuestos. A menos que se indique lo contrario, las Tarifas no incluyen impuestos, derechos ni evaluaciones similares federales, estatales, locales y extranjeros ("Impuestos"). Usted es responsable de todos los impuestos asociados con sus compras, excluidos los impuestos basados ​​en nuestros ingresos netos que le facturaremos. Usted acepta pagar puntualmente dichos Impuestos y proporcionarnos declaraciones de privacidad legalmente válidas y obtener los consentimientos necesarios para manejar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable.',
    block12:
      '(c) Cambios de precios. Podemos cambiar nuestros precios notificándole a través de su cuenta y/o nuestro sitio web. Los aumentos de precios entrarán en vigor inmediatamente después de su publicación. Cualquier cambio de precio se aplicará a las tarifas de su cuenta después de la fecha de vigencia del cambio.',
    block13:
      '(d) Disputas y Pagos Atrasados. Si desea disputar alguna tarifa o impuesto, comuníquese con contact@noam.tools dentro de los treinta (30) días posteriores a la fecha de la factura.',
    block14:
      '(e) Uso gratuito. No puedes crear varias cuentas para obtener créditos gratis. Si determinamos que no está utilizando los créditos gratuitos de buena fe, podremos cobrarle tarifas estándar o dejar de brindarle acceso al Servicio.',
    block15:
      '(a) Confidencialidad. Es posible que tenga acceso a información confidencial de Noam. Solo puede utilizar información confidencial según lo permitido en estos términos con el fin de utilizar el Servicio. No puede revelar información confidencial a ningún tercero y protegerá la información confidencial de una manera no menos protectora que la que protege su propia información confidencial similar, al menos con un cuidado razonable. La información confidencial es información no pública designada como confidencial por Noam o que razonablemente debería tratarse como confidencial según las circunstancias, incluido el software, las especificaciones y otra información comercial no pública. La información confidencial no incluye información que: (1) esté generalmente disponible para el público sin culpa de su parte; (2) usted tenía en su poder sin ninguna obligación de confidencialidad antes de recibirlo bajo estos términos; (3) le sea revelada legalmente por un tercero sin ninguna obligación de confidencialidad; o (4) usted lo desarrolla de forma independiente sin utilizar información confidencial. Puede divulgar información confidencial si así lo exige la ley, un tribunal u otra orden gubernamental, pero debe notificar previamente por escrito a Noam de manera razonable y, en la medida de lo posible, hacer esfuerzos razonables para limitar el alcance de la divulgación, incluida ayudarnos. en oponerse a las solicitudes de divulgación.',
    block16:
      '(b) Seguridad. Debe implementar medidas razonables y apropiadas para ayudar a salvaguardar su acceso y uso del Servicio. Si descubre alguna vulnerabilidad o violación relacionada con su uso del Servicio, debe comunicarse de inmediato con Noam y proporcionar detalles de la vulnerabilidad o violación.',
    block17:
      '(c) Procesamiento de datos personales. Si utiliza el Servicio para procesar datos personales, debe proporcionar declaraciones de privacidad legalmente suficientes y obtener los consentimientos necesarios para procesar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable. .',
    block18:
      '(a) Terminación; Suspensión. Estos términos entrarán en vigencia desde el primer uso que haga del Servicio y permanecerán vigentes hasta su terminación. Puede rescindir estos términos en cualquier momento y por cualquier motivo interrumpiendo el uso del Servicio y el Contenido. Podemos rescindir estos términos con previo aviso por cualquier motivo. Podemos rescindir inmediatamente estos términos notificándole si incumple sustancialmente la Sección 2 (Requisitos de uso), la Sección 5 (Confidencialidad, seguridad y protección de datos), la Sección 8 (Resolución de disputas) o la Sección 9 (Términos generales), o si nuestras relación con cualquier proveedor de tecnología externo fuera de nuestro control, cambios o para el cumplimiento de leyes o solicitudes gubernamentales. Podemos suspender su acceso al Servicio si no cumple con estos términos, o si su uso representa un riesgo de seguridad para nosotros o cualquier tercero, o si sospechamos que su uso es fraudulento o puede exponernos a nosotros o a cualquier tercero a responsabilidad. .',
    block19:
      '(b) Efectos de la Terminación. Tras la rescisión, dejará de utilizar el Servicio y devolverá o destruirá de inmediato cualquier información confidencial según nuestras instrucciones. Las disposiciones de estos términos que por su naturaleza deberían sobrevivir a la terminación o vencimiento, incluidas, entre otras, las Secciones 3 y 5-9, seguirán vigentes.',
    block2:
      'Estos Términos de uso se aplican a su uso de los servicios de Noam, incluidas nuestras interfaces de programación de aplicaciones, software, herramientas, servicios de desarrollador, datos, documentación y sitio web (denominados colectivamente los "Servicios"). Al utilizar nuestros Servicios, usted acepta cumplir con estos términos. Nuestra Política de Privacidad explica cómo recopilamos y utilizamos información personal.',
    block20:
      '(a) Indemnización. Usted defenderá, indemnizará y nos eximirá a nosotros, a nuestros afiliados y a nuestro personal de cualquier reclamo, pérdida y gasto (incluidos los honorarios de abogados) que surjan de o estén relacionados con su uso del Servicio, incluido su Contenido, su uso de productos o servicios relacionados con el Servicio, y su violación de estos términos o la ley aplicable.',
    block21:
      '(b) Descargos de responsabilidad. El Servicio se proporciona "tal cual". En la medida permitida por la ley, nosotros y nuestros afiliados y licenciantes no hacemos representaciones ni garantías de ningún tipo con respecto al Servicio y no ofrecemos garantías, incluidas, entre otras, garantías de comerciabilidad, idoneidad para un propósito particular, calidad satisfactoria, no infracción. y disfrute tranquilo, y cualquier garantía que surja del curso del trato o uso del comercio. No garantizamos que el Servicio será ininterrumpido, preciso o libre de errores, o que cualquier Contenido será seguro, no se perderá ni se alterará.',
    block22:
      '(c) Limitación de responsabilidad. Ni nosotros, ni nuestras filiales ni nuestros licenciantes seremos responsables de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluida la pérdida de beneficios, la pérdida de reputación, la pérdida de uso, la pérdida de datos u otras pérdidas intangibles, incluso si Se nos ha informado de la posibilidad de que se produzcan tales daños. Según estos términos, nuestra responsabilidad total no excederá las Tarifas que pagó por el Servicio en los doce (12) meses anteriores al reclamo o cien dólares estadounidenses ($100), lo que sea mayor. Las limitaciones de esta sección se aplican en la medida máxima permitida por la ley aplicable.',
    block23:
      'Los Términos y su uso de los Servicios se rigen por las leyes de los Estados Unidos, excepto por las normas de conflicto de leyes de los EE. UU. Su uso de la Aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales. Cualquier acción o procedimiento legal relacionado con estos Términos se llevará a cabo exclusivamente en los tribunales de los Estados Unidos, y usted acepta someterse a la jurisdicción personal de dichos tribunales.',
    block24:
      '(a) Relación de las Partes. Noam y usted son contratistas independientes y ninguna de las partes tiene el poder de representar o vincular a la otra parte o asumir obligaciones para la otra parte sin el consentimiento previo por escrito de la otra parte.',
    block25:
      '(b) Uso de la marca. No puede utilizar el nombre, los logotipos ni las marcas comerciales de Noam ni de ninguna de sus filiales sin nuestro consentimiento previo por escrito.',
    block26:
      'Si se determina que alguna disposición de estos términos es inválida, ilegal o inaplicable, las disposiciones restantes permanecerán en pleno vigor y efecto.',
    block3:
      'Si eres menor de 18 años, debes obtener permiso de tus padres o tutores legales para utilizar el Servicio. Si utiliza el Servicio en nombre de otra persona o entidad, debe estar autorizado para aceptar estos términos en su nombre. Debe proporcionar información precisa y completa para registrarse para obtener una cuenta. No puede proporcionar sus credenciales de acceso ni su cuenta a nadie fuera de su organización, y es responsable de todas las actividades realizadas con sus credenciales.',
    block4:
      '(a) Uso del Servicio. Puede acceder al Servicio según estos términos y le otorgamos un derecho no exclusivo para utilizar el Servicio. Al utilizar el Servicio, cumplirá con estos términos y todas las leyes aplicables. Nosotros y nuestros afiliados poseemos todos los derechos, títulos e intereses en el Servicio.',
    block5:
      '(b) Retroalimentación. Agradecemos comentarios, ideas, sugerencias y mejoras. Si proporciona dicho contenido, podremos usarlo sin restricciones y sin compensación para usted.',
    block6:
      '(c) Restricciones. No puede: (1) utilizar el Servicio de una manera que infrinja, se apropie indebidamente o viole cualquier derecho de otros; (2) realizar ingeniería inversa, descompilar, desensamblar, traducir o intentar descubrir de otro modo el código fuente o los componentes subyacentes de los modelos, algoritmos y sistemas del Servicio (a menos que dichas restricciones estén prohibidas por la ley aplicable); (3) utilizar los resultados del Servicio para desarrollar modelos que compitan con Noam; (4) extraer datos o resultados del Servicio utilizando cualquier método automatizado o de programación a menos que lo permita la API, incluido el scraping, la recolección web o la extracción de datos web; (5) representar el resultado del Servicio como generado artificialmente cuando no lo es, o violar de otro modo nuestras políticas de uso; (6) comprar, vender o transferir claves API sin nuestro consentimiento previo; o (7) proporcionarnos cualquier información personal de niños menores de 13 años o la edad aplicable de consentimiento digital. Cumplirá con los límites de tarifas y otros requisitos de nuestra documentación.',
    block7:
      '(d) Servicios de terceros. Cualquier software, servicio u otro producto de terceros asociado con el Servicio se rige por sus propios términos y no somos responsables de los productos de terceros.',
    block8:
      '(a) Su contenido. Usted puede proporcionar información ("Entrada") al Servicio y recibir resultados ("Salida") generados y devueltos en función de la Entrada (denominados colectivamente "Contenido"). Entre las partes, y en la medida permitida por la ley aplicable, usted es propietario de todos los Aportes. Noam puede usar Contenido para proporcionar y mantener el Servicio, cumplir con las leyes aplicables y hacer cumplir nuestras políticas. Usted es responsable del Contenido, incluida la garantía de que no viole ninguna ley aplicable o estos términos.',
    block9:
      '(b) Precisión. La inteligencia artificial y el aprendizaje automático son campos de investigación en rápida evolución. Nos esforzamos continuamente por mejorar nuestro servicio para hacerlo más preciso, confiable, seguro y beneficioso. Dada la naturaleza probabilística del aprendizaje automático, el uso de nuestro servicio puede generar resultados incorrectos en ciertos casos, que pueden no reflejar con precisión personas, lugares o hechos reales. Debe evaluar adecuadamente la precisión de cualquier resultado según su caso de uso, incluso revisando manualmente los resultados.',
    subTitle1: 'Registro y Acceso',
    subTitle10: 'Divisibilidad',
    subTitle2: 'Requisitos de uso',
    subTitle3: 'Contenido',
    subTitle4: 'Tarifas y pago',
    subTitle5: 'Confidencialidad, Seguridad y Protección de Datos',
    subTitle6: 'Plazo y Terminación',
    subTitle7: 'Indemnización; Descargos de responsabilidad; Limitación de responsabilidad',
    subTitle8: 'Ley aplicable y jurisdicción',
    subTitle9: 'Términos generales',
    title: 'Términos de servicio',
    updateTime: 'Actualizado: 15 de marzo de 2024',
  },
  trialModal: {
    btn: 'Comience ahora (solo $0,99)',
    chat: 'ChatPDF',
    desc1: 'Después de la prueba, se le cobrará una tarifa anual de $180',
    desc2: 'Cancelar en cualquier momento',
    focus: 'Enfoque',
    hover: 'Traducción al pasar el cursor',
    image: 'Traducción de imágenes',
    installBtn: 'Instala ahora para recibir tu regalo',
    memo: 'Gestión de favoritos',
    pdf: 'Traducción paralela de PDF',
    realtime: 'Traducción instantánea',
    select: 'Traducción de selección de palabras',
    title: 'Regalo de bienvenida',
    trialText: 'Prueba de 7 días',
    video: 'Traducción de vídeos',
    videoSummary: 'Vídeo resumen',
    web: 'Traducción paralela',
    webSummary: 'Resumen web',
  },
  upgradeModal: {
    btn: 'Actualiza ahora',
    chat: {
      free: 'Has alcanzado el límite de ChatPDF',
      pro: 'Has alcanzado el límite de ChatPDF para este mes',
    },
    contactStr: 'Espere hasta el próximo reinicio o contáctenos en contact@noam.tools.',
    limitAlertStr: 'Has alcanzado el límite de tu plan gratuito. Actualiza para continuar.',
    more: 'Ver planos',
    pageLimit: { free: 'Has alcanzado el límite de páginas PDF.' },
    title: 'Recordatorio',
    upgradeAlertStr: 'Has alcanzado el límite de tu plan gratuito. Actualiza para continuar.',
    upload: {
      free: 'Has alcanzado tu límite de traducción de PDF',
      pro: 'Has alcanzado tu límite de traducción de PDF para este mes.',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Únete ahora',
      label: 'Beneficios para nuevos usuarios:',
      remain: 'Sólo quedan 30',
      text: '¡Solo 100 lugares disponibles! ¡Consigue el tuyo ahora!',
    },
  },
}

export default TRANSLATION
