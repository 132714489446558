const TRANSLATION = {
  buyModal: {
    afterFree: 'Na uw gratis proefperiode',
    btn: 'Probeer het gratis',
    freeTrial: 'Gratis proefperiode van 3 dagen',
    monthLabel: 'maand',
    selectLabel: 'Selecteer een abonnement',
    title: 'Abonnementsplan',
    yearLabel: 'jaar',
  },
  contact: {
    block1:
      'Voor maatwerk, vragen of suggesties kunt u contact met ons opnemen via contact@noam.tools. We zullen snel reageren.',
    block2: 'Steun ons door ons te beoordelen in de Google Apps Extension Store op ★★★★★.',
    block3: 'Help anderen door deze extensie te delen:',
    title: 'Neem contact met ons op',
  },
  help: {
    AquickOverviewOfNoam: 'Een snel overzicht van Noam',
    ChatPDF: 'Chat-pdf',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Beheer van favorieten',
    Fixedplugin: 'Vaste plug-in',
    Focusing: 'Focussen',
    Free: 'Vrij',
    FreeTrialBenefits: 'Gratis proefvoordelen',
    GoWithWikipedia: 'Ga met Wikipedia',
    GoWithYouTube: 'Ga met YouTube',
    HoverTranslation: 'Zweef vertaling',
    Howtouse: 'Hoe te gebruiken',
    ImageTranslation: 'Beeldvertaling',
    InstantTranslation: 'Onmiddellijke vertaling',
    Membership: 'Lidmaatschap',
    NoamHelp: 'Noam helpt je met vertalen en samenvatten',
    PDFparallelTranslation: 'PDF-parallelle vertaling',
    ParallelTranslation: 'Parallelle vertaling',
    SkipAllSteps: 'Sla alle stappen over',
    Summary: 'Samenvatting',
    Translation: 'Vertaling',
    TryItForFree: 'Probeer het gratis',
    Video: 'Video',
    VideoSummary: 'Video-samenvatting',
    VideoTranslation: 'Videovertaling',
    WebSummary: 'Websamenvatting',
    WordSelectionTranslation: 'Vertaling van woordselectie',
    benefits: 'Wij hebben voordelen voor u klaar!',
    dayFree: 'Gratis proefperiode van 3 dagen',
    done: 'Klaar',
    next: 'Volgende',
    pin: 'Pin Noam voor eenvoudigere toegang～～',
    planIsNotExist: 'Plan is niet beschikbaar',
    startWiki: 'Laten we beginnen met Wikimedia!',
    startYoutube: 'Laten we beginnen met YouTube!',
    try: 'Probeer het uit',
    tryNow: 'Probeer het nu eens!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tweetalige vertaling + chat-pdf',
      sub1: {
        desc: 'Met tweetalig lezen kunt u de originele tekst en de vertaling naast elkaar vergelijken, waardoor de leesefficiëntie wordt verbeterd.',
        title: 'Tweetalig lezen',
      },
      sub2: {
        desc: 'AI analyseert en vat samen, en kan uw vragen beantwoorden op basis van de PDF-inhoud.',
        title: 'Chat-pdf',
      },
      sub3: {
        desc: 'AI-aangedreven PDF-vertaling voor grotere nauwkeurigheid en efficiëntie.',
        title: 'Nauwkeurigheid en efficiëntie',
      },
      title: 'PDF-vertalen',
    },
    aiAssistant: {
      desc: 'Webvertaling slimmer en handiger maken',
      panel1: {
        desc: 'Met tweetalige kruisverwijzingen kunt u de originele tekst vergelijken met de vertaling, zodat u de tekst beter kunt begrijpen en uw leesefficiëntie kunt verbeteren.',
        title: 'Tweetalige vertaling',
      },
      panel2: {
        left: {
          desc: 'Wanneer u door een webpagina bladert, beweegt u de muis over de inhoud die u wilt vertalen om de vertaling te zien.',
          title: 'Zweef vertaling',
        },
        right: {
          desc: 'Wanneer u door een webpagina bladert, selecteert u de tekst die u wilt vertalen om de vertaling te zien.',
          title: 'Vertaling van tekstselectie',
        },
      },
      panel3: {
        one: {
          desc: "Vertaal en vat webpagina's en video-inhoud altijd en overal samen, voor een soepelere ervaring!",
          title: 'Aanwijzen en klikken',
        },
        three: {
          desc: 'Een AI-aangedreven groottaalmodel zorgt voor nauwkeurigheid en consistentie van vertalingen voor een verbeterde leeservaring.',
          title: 'AI-aangedreven',
        },
        two: {
          desc: 'Meertalige volledige dekking, tweetalig barrièrevrij lezen, vertaling met één klik - ervaar het verschil!',
          title: 'Uitgebreider en handiger',
        },
      },
      panel4: {
        tab1: {
          desc: 'Uw efficiëntie-expert - met behulp van ChatGPT vertaalt en vat Noam YouTube-video-inhoud samen om u tijd te besparen!',
          key: 'Video',
          title: 'Videovertaling en samenvatting',
        },
        tab2: {
          desc: 'Klik om afbeeldingen te vertalen terwijl u op internet surft.',
          key: 'Afbeelding',
          title: 'Afbeelding vertalen',
        },
        tab3: {
          desc: "Haalt onmiddellijk belangrijke informatie uit webpagina's en geeft een samenvatting, waardoor uw leesefficiëntie wordt verbeterd.",
          key: 'Web',
          title: 'Webvertalen en samenvatting',
        },
        tag1: 'Eenvoudig',
        tag2: 'Efficiënt',
        tag3: 'Gemakkelijk te begrijpen',
      },
      title: 'AI-assistent',
    },
    getStart: 'Aan de slag',
    getStartedForFree: 'Ga gratis aan de slag',
    howItWork: {
      desc: "Noam integreert AI-modellen om gebruikers een intelligente ervaring te bieden voor het vertalen en samenvatten van webpagina's in meerdere talen, het vertalen van PDF's en afbeeldingen, het vertalen en samenvatten van video's",
      title: 'Hoe het werkt',
    },
    lastBlock: { desc: 'Betere AI-vertaling voor een soepelere leeservaring' },
    top: {
      desc: "Noam is een gratis AI-browserextensie waarmee je webpagina's, video's en pdf-inhoud kunt vertalen en samenvatten.\\n Sluit je aan bij Noam en ervaar de magie!",
      title1: 'Eén magie',
      title2: 'Eén klik',
    },
    userReviews: {
      desc: 'Vijfsterrenrecensies in de Chrome Web Store',
      title: 'Gebruikersrecensies',
    },
  },
  installTips: {
    btn: 'Probeer het nu',
    text: 'Installeer "Noam Browser Plugin" om meer functies te ontgrendelen! Webkruisverwijzingen,\\n samenvattingen van webvideo\'s en meer!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amhaars',
    ar: 'Arabisch',
    as: 'Assamees',
    auto: 'Automatische detectie',
    az: 'Azerbeidzjaans',
    ba: 'Bashkir',
    bg: 'Bulgaars',
    bn: 'Bangla',
    bo: 'Tibetaans',
    bs: 'Bosnisch',
    ca: 'Catalaans',
    cs: 'Tsjechisch',
    cy: 'Welsh',
    da: 'Deens',
    de: 'Duits',
    dsb: 'Nedersorbisch',
    dv: 'Duikhi',
    el: 'Grieks',
    en: 'Engels',
    es: 'Spaans',
    et: 'Ests',
    eu: 'baskisch',
    fa: 'Perzisch',
    fi: 'Fins',
    fil: 'Filipijns',
    fj: 'Fijisch',
    fr: 'Frans',
    frCA: 'Frans (Canada)',
    ga: 'Iers',
    gl: 'Galicisch',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Hebreeuws',
    hi: 'Hindi',
    hr: 'Kroatisch',
    hsb: 'Boven-Sorbisch',
    ht: 'Haïtiaans Creools',
    hu: 'Hongaars',
    hy: 'Armeens',
    id: 'Indonesisch',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'IJslands',
    it: 'Italiaans',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latijn)',
    ja: 'Japanse',
    ka: 'Georgisch',
    kk: 'Kazachs',
    km: 'Khmer',
    kmr: 'Koerdisch (Noordelijk)',
    kn: 'Kanada',
    ko: 'Koreaans',
    ku: 'Koerdisch (centraal)',
    ky: 'Kirgizisch',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'Litouws',
    lug: 'Ganda',
    lv: 'Lets',
    lzh: 'Chinees (literair)',
    mai: 'Maithili',
    mg: 'Madagaskar',
    mi: 'Maori',
    mk: 'Macedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongools (Cyrillisch)',
    mnMong: 'Mongools (traditioneel)',
    mr: 'Marathi',
    ms: 'Maleis',
    mt: 'Maltees',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmees)',
    nb: 'Noors',
    ne: 'Nepalees',
    nl: 'Nederlands',
    noSearchLang: 'Taal niet ondersteund',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Pools',
    placeholder: 'Zoekopdracht',
    prs: 'Dari',
    ps: 'Pasjto',
    pt: 'Portugees (Brazilië)',
    ptPT: 'Portugees (Portugal)',
    ro: 'Roemeense',
    ru: 'Russisch',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singalees',
    sk: 'Slowaaks',
    sl: 'Sloveens',
    sm: 'Samoaans',
    sn: 'Shona',
    so: 'Somalisch',
    sq: 'Albanees',
    srCyrl: 'Servisch (Cyrillisch)',
    srLatn: 'Servisch (Latijn)',
    st: 'Sesotho',
    sv: 'Zweeds',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thais',
    ti: 'Tigrinya',
    tk: 'Turkmeens',
    tlhLatn: 'Klingon (Latijn)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongaans',
    tr: 'Turks',
    tt: 'Tataars',
    ty: 'Tahitiaans',
    ug: 'Oeigoers',
    uk: 'Oekraïens',
    ur: 'Urdu',
    uz: 'Oezbeeks (Latijn)',
    vi: 'Vietnamees',
    xh: 'Xhosa',
    yo: 'Joruba',
    yua: 'Yucateek Maya',
    yue: 'Kantonees (traditioneel)',
    zh: 'Chinese',
    zhHans: 'Chinees vereenvoudigd',
    zhHant: 'Traditioneel Chinees',
    zu: 'Zoeloe',
  },
  login: {
    and: 'En',
    btn: 'Log in met Google',
    btn1: 'Meld u nu aan',
    desc: 'Log in om meer ervaringen te ontgrendelen',
    policy: 'Privacybeleid',
    terms: 'Gebruiksvoorwaarden',
    tips: 'Door mij aan te melden, ga ik akkoord met de',
    title: 'Welkom bij Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Volgen', twitter: 'Twitteren' },
    legal: { policy: 'Privacybeleid', terms: 'Servicevoorwaarden', title: 'Legaal' },
    product: {
      image: 'Beeldvertaling',
      pdf: 'PDF-vertaling',
      title: 'Producten',
      video: 'Videovertaling en samenvatting',
      web: 'Webvertaling en samenvatting',
    },
    resources: { title: 'Bronnen' },
  },
  pageHeader: {
    contact: 'Contact',
    help: 'Hulp',
    logout: 'Uitloggen',
    logoutSucc: 'Uitgelogd.',
    pricing: 'Prijzen',
    settings: 'Instellingen',
    signUp: 'Inloggen',
  },
  pdf: {
    autoDetect: 'Automatische detectie',
    cancelBtn: 'Annuleren',
    cancelUpload: 'Annuleren',
    chatPdf: {
      Search: 'Zoekopdracht',
      cancel: 'Annuleren',
      chatTitle: 'Welkom bij Noam! Hier is een samenvatting van uw pdf:',
      clear: 'Duidelijk',
      clearContent:
        'Dit zal een nieuw gesprek starten. Het huidige gesprek wordt niet opgeslagen. Weet je het zeker?',
      clearDialogTitle: 'Bevestig alstublieft',
      clearSucc: 'Gewist',
      copy: 'Kopiëren',
      copySucc: 'Gekopieerd',
      delete: 'Verwijderen',
      deleteCancel: 'Annuleren',
      deleteContent: 'Deze PDF kan na verwijdering niet meer worden hersteld. Weet je het zeker?',
      deleteOk: 'ERROR: [Delete]',
      deleteSuccess: 'Verwijderd',
      deleteTitle: 'Bevestig verwijderen',
      expand: 'Instorten',
      historyTitle: 'Geschiedenis',
      listError:
        'Kan de leeslijst niet laden. Probeer het later opnieuw of neem contact op met de ondersteuning.',
      loading: 'Laden...',
      noDataDesc: 'Nog geen geschiedenis.',
      noMore: 'Geen inhoud meer.',
      placeholder: 'Vraag alles over deze PDF',
      summaryTitle: 'Mogelijk bent u geïnteresseerd in deze vragen:',
    },
    collectionEmpty: 'Je verzameling is leeg.',
    collectionTab: 'Opmerkingen',
    dataLoading: 'Laden...',
    dialogContent: 'Weet je zeker dat je dit wilt verwijderen?',
    dialogTitle: 'Bevestig alstublieft',
    download: {
      both: 'Tweetalig',
      btn: 'Redden',
      cancel:
        'Als u dit venster sluit, wordt de PDF-download geannuleerd. Weet u zeker dat u wilt annuleren?',
      desc: 'Opslaan op apparaat: downloadt vertalingen als afbeeldingen; Opslaan en afdrukken: downloadt vertalingen in het originele formaat, met kopieerondersteuning.',
      download: 'Downloaden',
      downloadMsg1: 'Voortgang PDF-vertaling:',
      downloadMsg2: 'Geschatte resterende tijd:',
      downloadSucc: 'PDF is klaar! Klik om op te slaan.',
      downloadTip: 'PDF downloaden. Sluit dit venster niet om downloadfouten te voorkomen.',
      error: 'Kan niet opslaan. Probeer in plaats daarvan af te drukken.',
      fileName: 'Bestandsnaam:',
      fileType: 'Bestandstype:',
      loading: 'Besparing...',
      loading2: 'Laden...',
      minute: 'Notulen...',
      msg1: 'Sommige vertalingen in dit document zijn langer dan de originele tekst en kunnen elkaar overlappen in het gedownloade bestand. Wij raden u aan om op de webpagina te lezen.',
      msg2: '1. De vertaling is nog niet voltooid. Download alstublieft nadat de vertaling is voltooid.',
      msg3: '2. Sommige vertalingen zijn te lang. Lees op de webpagina om tekstoverlapping te voorkomen.',
      msg4: 'Er worden vertaaltaken uitgevoerd. Probeer het opnieuw nadat ze zijn voltooid.',
      progress:
        'PDF vertalen. Voortgang: {{progress}}, geschatte resterende tijd: {{minutes}} minuten...',
      save: 'Opslaan op apparaat',
      savePrint: 'Opslaan en afdrukken',
      success: 'Gedownload',
      trans: 'Alleen vertaling',
      transContinue: 'Ga door met vertalen',
    },
    downloadBtn: 'Downloaden',
    dragTipModal: {
      black: 'ruimte',
      leftKey: 'linker knop',
      ok: 'Ik heb het',
      press: 'Druk op',
      tips: 'slepen en bekijken.',
    },
    entry: "PDF's",
    fileErrMsg:
      'Bestand lezen mislukt. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    fileMsg: 'Huidige bestandsgrootte:',
    fileMsg2: 'Upload een bestand dat kleiner is dan 300 MB.',
    freeBannerBtn: 'Upgraden',
    freeBannerTips: 'Limiet voor gratis pdf-vertalingen bereikt',
    guide: {
      placeholder: 'Plak hier de PDF-link',
      start: 'Begin met vertalen',
      text1: 'Tweetalig lezen',
      text2:
        'Lees tweetalig om de originele tekst en de vertaling naast elkaar te vergelijken, waardoor uw efficiëntie wordt verbeterd.',
      text3: 'Chat met uw PDF',
      text4:
        'AI-aangedreven analyse en samenvatting. Stel vragen over uw PDF en krijg direct antwoord.',
      text5: 'Nauwkeurig en efficiënt',
      text6: 'AI-aangedreven PDF-vertaling voor grotere nauwkeurigheid en efficiëntie.',
      title1: 'Tweetalige vertaling',
      title2: 'Chat met uw PDF',
      toast: 'Voer een geldige online PDF-link in die eindigt op .pdf',
    },
    hasTransTask: 'Er wordt een vertaaltaak uitgevoerd. Wacht alstublieft.',
    hoverTrans: "Beweeg om alinea's te vertalen",
    initial: 'PDF vertalen',
    installText: 'Uw totaaloplossing voor dagelijkse vertalingen.',
    installText2: 'Installeer eerst de extensie',
    installTips:
      'Installeer de extensie voor nog meer PDF-vertalingen en vlottere websitevertalingen:',
    kw: 'nietje',
    link: 'link',
    listError:
      "Kan PDF's niet laden. Probeer het later opnieuw of neem contact op met de ondersteuning.",
    memoTotalCount: 'Totaal 1 artikel',
    new: 'nieuw bestand',
    noId: 'Bestands-ID niet gevonden.',
    notPdf: 'Alleen PDF-bestanden.',
    okBtn: 'Verwijderen',
    okBtn2: 'Ik heb het',
    original: 'Origineel',
    parallel: 'Parallel',
    parseErrDialogContent:
      'Deze PDF-link kan niet rechtstreeks worden vertaald. Download de pdf en upload deze ter vertaling.',
    parseErrTips: 'Kan het bestand niet parseren',
    parseFileToast:
      'Kan het bestand niet parseren. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    pdfLoading: 'Parseren...',
    searchPlaceholder: 'Zoekopdracht',
    selectTrans: 'Selecteer Tekst om te vertalen',
    shareBtn: 'Deel',
    shareBtnText1: 'Genereer een deellink',
    shareBtnText2: 'Gedeelde inhoud bijwerken',
    shareModal: {
      cancel: 'Annuleren',
      copy: 'Kopiëren',
      copySucc: 'Link gekopieerd',
      custom: 'Aanpassen:',
      errMsg:
        'De geselecteerde inhoud is nog niet volledig vertaald. Vertaal alstublieft voordat u het deelt.',
      gen: 'Deel',
      modify: 'Bewerking',
      notSupport: 'Deze taal wordt momenteel niet ondersteund.',
      page: "Pagina's",
      pageNuErr: 'Voer een geldig paginanummer in.',
      range: 'Domein:',
      remain: 'overig',
      remain1: 'Totale geschatte tijd:',
      search: 'Zoekopdracht',
      settings: 'Instellingen voor delen:',
      shareSetting: 'Instellingen delen',
      shareText: 'Deel',
      shareTips: 'Deel de vertaalde PDF-link met je vrienden:',
      sourceLang: 'Van:',
      target: 'Naar:',
      text1: 'Instellingen delen',
      text2: 'Genereer een deellink',
      totalPage: 'Totaal 1 pagina',
      trans: 'Vertalen',
      translateTips:
        'Tip: Als de vertaling enige tijd duurt, kunt u dit venster sluiten en later delen.',
      translating: 'PDF vertalen...',
      update: 'Update de deellink',
    },
    shareMsg1: 'Deellink gegenereerd.',
    shareMsg2: 'Gedeelde inhoud bijgewerkt.',
    shareMsg3:
      'Kan de deellink niet genereren. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    shareMsg4:
      'Kan gedeelde inhoud niet updaten. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    shareTips: 'Uw PDF-vertaling is klaar! Deel het met je vrienden.',
    start: 'Start uw gratis proefperiode',
    thumbnail: 'Inhoudsopgave',
    toast1: 'PDF laden. Probeer het later opnieuw.',
    toast2: 'De PDF-inhoud is te groot. Dialoogvenster wordt momenteel niet ondersteund.',
    toast3: 'Downloaden. Probeer het opnieuw nadat het downloaden is voltooid.',
    toolbar: { Adjust: 'Aanpassen', autoFit: 'Automatisch aanpassen' },
    trans: 'Vertaling',
    transSucc: 'Vertaling succesvol!',
    unLoginToast: 'Meld u aan om deze functie te gebruiken.',
    uploadBtn: 'Uploaden',
    uploadErr:
      'Bestandsupload mislukt. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    uploadMsg1: 'ERROR: [Drag & drop your file here.]',
    uploadMsg2: 'Ondersteunde bestandstypen: PDF |  Maximale bestandsgrootte: 100 MB',
    uploadMsg3: 'Klik hier om bestanden te selecteren of te slepen en neer te zetten.',
    uploading: 'Uploaden',
    uploadingMsg: 'Uploaden. Wacht alstublieft.',
    uploadingMsg1: 'Bestand wordt geüpload. Probeer het later opnieuw.',
    uploadingMsg2: 'Er wordt momenteel een bestand geconverteerd. Probeer het later opnieuw.',
    uploadingMsg3:
      'Verzoek om conversievoortgang is mislukt. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    uploadingMsg4:
      'Noam heeft geen toegang tot lokale bestanden. Upload handmatig een PDF ter vertaling.',
    uploadingMsg5:
      'Kan de bestands-URL niet ophalen. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    video: 'video',
    vipBannerBtn: 'Upgraden',
    vipBannerTips: 'U heeft uw PDF-vertaallimiet voor deze maand bereikt.',
    webTrans: 'Volledige kruisverwijzing',
  },
  pricing: {
    FAQ: 'Veelgestelde vragen',
    FQAObj: {
      a1: 'Ja, Noam biedt een gratis abonnement dat je meteen kunt gebruiken.',
      a2: 'We bieden drie lidmaatschapsplannen: Pro, Pro+ en Enterprise. U kunt ervoor kiezen om maandelijks of jaarlijks te betalen. Jaarlijkse facturering biedt aanzienlijke besparingen.',
      a3: 'Alle functies zijn beschikbaar op beide abonnementen. Het belangrijkste verschil zijn de kosten: jaarabonnementen kosten over het algemeen minder. Maandabonnementen worden maandelijks gefactureerd, terwijl jaarabonnementen eenmaal per jaar worden gefactureerd.',
      a4: 'U kunt uw abonnement op elk gewenst moment opzeggen via uw accountinstellingen.',
      q1: 'Is er een gratis abonnement?',
      q2: 'Welke tariefplannen en betalingsopties zijn beschikbaar?',
      q3: 'Wat is het verschil tussen maandelijks en jaarlijks abonneren?',
      q4: 'Hoe zeg ik mijn abonnement op?',
    },
    around: 'Rondom',
    billedMonthly: 'Maandelijks gefactureerd',
    billedYearly: 'Jaarlijks gefactureerd',
    day: 'dag',
    desc: 'Vergelijk abonnementen en kies de beste',
    detailedCompare: 'Vergelijk abonnementen',
    discount: '20% korting',
    enterprise: {
      benefit1: 'Prioritaire klantenondersteuning',
      benefit2: 'Meerdere gebruikers',
      benefit3: 'Op maat gemaakte oplossingen',
      benefit5: 'Toegewijde accountmanager',
      btn: 'Neem contact met ons op',
      recommendText: 'Aangepaste plannen (≥3人)',
      talkToUs: 'Neem contact op',
    },
    free: {
      benefit1: 'Onmiddellijke vertaling 100 keer/dag',
      benefit2: 'Websitevertaling en samenvatting',
      benefit3: '30 beeldvertalingen',
      benefit4: 'Videovertaling en samenvatting',
      benefit5: '10 PDF-vertalingen',
      benefit6: '5.000 tokens',
      btn: 'Probeer het gratis',
      recommendText: 'Gratis proefperiode',
    },
    imageTranslation: 'Beeldvertaling',
    month: 'maand',
    monthly: 'Maandelijks',
    pageTitle: 'Prijzen',
    pdf: {
      chat: "PDF-chatquery's",
      maxPage: "Max. Pagina's per PDF",
      translation: 'PDF-vertaling',
    },
    preMonth: 'Per maand',
    pro: {
      benefit1: 'Prioritaire klantenondersteuning',
      benefit2: '1.000.000 tokens/maand',
      benefit3: 'Websitevertaling en samenvatting',
      benefit4: '200 beeldvertalingen',
      benefit5: 'Videovertaling en samenvatting',
      benefit6: '200 PDF-vertalingen',
      btn: 'Aan de slag',
      recommendText: 'Meest populair',
    },
    proPlus: {
      benefit1: 'Prioritaire klantenondersteuning',
      benefit2: '3.000.000 tokens/maand',
      benefit3: 'Websitevertaling en samenvatting',
      benefit4: '500 beeldvertalingen',
      benefit5: 'Videovertaling en samenvatting',
      benefit6: '500 PDF-vertalingen',
      recommendText: 'Beste waarde',
    },
    video: { summary: 'Video-samenvatting', translation: 'Videovertaling' },
    web: {
      contrastiveTranslation: 'Tweetalige vertaling',
      dictionaryTranslation: 'Selectie vertaling',
      hoverTranslation: 'Zweef vertaling',
      instantTranslation: 'Onmiddellijke vertaling',
      summary: 'Samenvatting van webpagina',
    },
    yearly: 'Jaarlijks',
  },
  privacy: {
    block1:
      'Noam ("Noam", "wij", "onze" of "ons") respecteert uw privacy en doet er alles aan om alle informatie die wij van u of over u verkrijgen te beschermen. Dit Privacybeleid beschrijft onze praktijken met betrekking tot het verzamelen of gebruiken van persoonlijke informatie over uw gebruik van onze website, applicaties en diensten (gezamenlijk de "Diensten"). Dit privacybeleid is niet van toepassing op de inhoud die we verwerken namens onze zakelijke productklanten, zoals onze API. Het gebruik van deze gegevens is onderworpen aan onze klantenovereenkomst, die de toegang tot en het gebruik van deze producten omvat.',
    block10:
      "We implementeren commercieel redelijke technische, administratieve en organisatorische maatregelen om online en offline persoonlijke informatie te beschermen tegen verlies, misbruik en ongeoorloofde toegang, openbaarmaking, wijziging of vijandige handelingen. Geen enkele verzending via internet of e-mail kan echter volledige veiligheid of nauwkeurigheid garanderen. Met name e-mails die naar ons of van ons worden verzonden, zijn mogelijk niet veilig. U moet dus bijzonder voorzichtig zijn bij het beslissen welke informatie u ons via de dienst of e-mail wilt sturen. Bovendien zijn wij niet verantwoordelijk voor enige belemmering van privacy-instellingen of beveiligingsmaatregelen in de service of beveiligingsinstellingen of beveiligingsmaatregelen van websites van derden. We bewaren uw persoonlijke gegevens zo lang als nodig is om u diensten te verlenen of voor andere legitieme zakelijke doeleinden, zoals het oplossen van geschillen, beveiligings- en veiligheidsredenen, of het voldoen aan onze wettelijke verplichtingen. Hoe lang we persoonlijke informatie bewaren, hangt af van vele factoren, zoals de hoeveelheid, de aard, de gevoeligheid van de informatie, de potentiële risico's van ongeoorloofd gebruik of openbaarmaking, de doeleinden waarvoor we de informatie verwerken en andere juridisch bindende overwegingen.",
    block11:
      'We kunnen dit privacybeleid van tijd tot tijd bijwerken. Wanneer we dit doen, zullen we een bijgewerkte versie op deze pagina plaatsen, tenzij anders vereist door de toepasselijke wetgeving.',
    block12:
      'Als u onbeantwoorde vragen of opmerkingen heeft over dit privacybeleid, kunt u contact opnemen met onze klantenservice. E-mail:',
    block2:
      'Wij verzamelen de volgende persoonlijke informatie ("Persoonlijke Informatie") met betrekking tot u:',
    block3:
      'Door u verstrekte informatie: Wanneer u een account aanmaakt om onze diensten te gebruiken of met ons te communiceren, verzamelen wij als volgt persoonlijke informatie:',
    block4:
      'Persoonlijke informatie die automatisch wordt ontvangen door uw gebruik van services: Wanneer u toegang krijgt tot de services, deze gebruikt of ermee communiceert, ontvangen wij de volgende informatie over uw toegang, gebruik of interactie ("Technische informatie"):',
    block5: 'We kunnen persoonlijke informatie gebruiken voor de volgende doeleinden:',
    block6:
      'Samenvatting of geanonimiseerde informatie: We kunnen persoonlijke informatie samenvoegen of anonimiseren om hergebruik ervan voor identificatiedoeleinden te voorkomen en dergelijke informatie gebruiken om de effectiviteit van onze diensten te analyseren, functies aan onze diensten te verbeteren en toe te voegen, onderzoek uit te voeren en voor soortgelijke doeleinden . Bovendien kunnen we het algemene gedrag en de kenmerken van onze servicegebruikers analyseren en geaggregeerde informatie delen met derden, dergelijke geaggregeerde informatie publiceren of algemeen beschikbaar maken. We kunnen geaggregeerde informatie verzamelen via de diensten, via cookies en via andere middelen die in dit Privacybeleid worden beschreven. We zullen geanonimiseerde informatie in een anonieme of geanonimiseerde vorm bewaren en gebruiken, en we zullen niet proberen dergelijke informatie opnieuw te identificeren, tenzij dit wettelijk vereist is.',
    block7:
      'In bepaalde gevallen kunnen wij uw persoonlijke gegevens zonder verdere kennisgeving aan derden verstrekken, tenzij dit wettelijk vereist is:',
    block8:
      'Afhankelijk van uw geografische locatie kunnen personen in de Europese Economische Ruimte, het Verenigd Koninkrijk en wereldwijd bepaalde wettelijke rechten hebben met betrekking tot hun persoonlijke gegevens. U heeft bijvoorbeeld mogelijk het recht om:',
    block9:
      'De dienst kan links bevatten naar andere websites die niet door Noam worden beheerd of gecontroleerd, inclusief sociale-mediadiensten ("websites van derden"). De informatie die u deelt met websites van derden valt onder het specifieke privacybeleid en de servicevoorwaarden van de websites van derden, en niet onder dit privacybeleid. Wij bieden deze links voor uw gemak aan en impliceren niet dat wij deze websites goedkeuren of beoordelen. Neem rechtstreeks contact op met websites van derden voor informatie over hun privacybeleid en voorwaarden.',
    subBlock1:
      'Accountinformatie: Wanneer u een account aanmaakt op ons platform, verzamelen wij informatie met betrekking tot uw account, waaronder uw naam, contactgegevens, accountgegevens, betaalkaartinformatie en transactiegeschiedenis ("Accountinformatie").',
    subBlock10: 'Het leveren, beheren, onderhouden en/of analyseren van diensten;',
    subBlock11: 'Met u communiceren;',
    subBlock12: 'Het ontwikkelen van nieuwe producten en diensten;',
    subBlock13:
      'Het voorkomen van fraude, criminele activiteiten of misbruik van onze diensten en het beschermen van de veiligheid van onze IT-systemen, architectuur en netwerken;',
    subBlock14: 'Het uitvoeren van bedrijfsoverdrachten;',
    subBlock15:
      'Het voldoen aan wettelijke verplichtingen en juridische procedures, het beschermen van onze rechten, privacy, veiligheid of eigendommen, evenals die van onze dochterondernemingen, u of andere derde partijen.',
    subBlock16:
      'Leveranciers en dienstverleners: Om ons te helpen bij het voldoen aan de operationele behoeften van het bedrijf en bij het uitvoeren van bepaalde diensten en functies, kunnen we persoonlijke informatie verstrekken aan leveranciers en dienstverleners, waaronder aanbieders van hostingdiensten, aanbieders van clouddiensten en andere dienstverleners op het gebied van informatietechnologie, e-mailcommunicatiesoftware en dienstverleners voor webanalyse, enz. Deze partners zullen persoonlijke informatie alleen op onze aanwijzing openen, verwerken of opslaan terwijl ze hun taken voor ons uitvoeren.',
    subBlock17:
      "Bedrijfsoverdrachten: Als we strategische transacties, reorganisaties, faillissementen, faillissementsovernames of dienstenovergangen naar een andere aanbieder uitvoeren (gezamenlijk 'Transacties' genoemd), kunnen uw persoonlijke gegevens en andere gegevens worden gedeeld met transactiepartners en andere partijen die assisteren bij de Transacties. tijdens due diligence en als onderdeel van de Transacties samen met andere activa overgedragen aan een opvolger of aangesloten onderneming.",
    subBlock18:
      'Wettelijke vereisten: We kunnen uw persoonlijke gegevens delen met overheidsinstanties, sectorgenoten of andere derde partijen, inclusief uw interactie-informatie met onze diensten, (1) indien vereist door de wet of als wij van mening zijn dat dergelijke actie noodzakelijk is om te voldoen aan wettelijke verplichtingen; (2) om onze rechten of eigendommen te beschermen en verdedigen; (3) als we eenzijdig vaststellen dat er sprake is van een schending van onze voorwaarden, ons beleid of onze wetten; (4) om fraude of andere illegale activiteiten op te sporen of te voorkomen; (5) om onze producten, werknemers of gebruikers, of de openbare veiligheid, beveiliging en integriteit te beschermen; of (vi) ter verdediging tegen wettelijke aansprakelijkheid.',
    subBlock19: 'Toegang tot uw persoonlijke gegevens en informatie over hoe deze worden verwerkt.',
    subBlock2:
      'Gebruikersinhoud: Wanneer u onze diensten gebruikt, verzamelen wij persoonlijke informatie uit uw invoer, bestandsuploads of aan ons verstrekte feedback ("Inhoud").',
    subBlock20: 'Verzoek om verwijdering van uw persoonlijke gegevens uit onze administratie.',
    subBlock21: 'Corrigeer of update uw persoonlijke gegevens.',
    subBlock22: 'Uw persoonlijke gegevens overdragen aan een derde partij (dataportabiliteit).',
    subBlock23: 'Beperk de manier waarop wij uw persoonlijke gegevens verwerken.',
    subBlock24:
      'Uw toestemming intrekken: als wij vertrouwen op toestemming als wettelijke basis voor verwerking, kunt u uw toestemming op elk gewenst moment intrekken.',
    subBlock25: 'Maak bezwaar tegen de manier waarop wij uw persoonlijke gegevens verwerken.',
    subBlock26: 'Het verbeteren van onze dienstverlening en het uitvoeren van onderzoek;',
    subBlock3:
      'Communicatie-informatie: Als u ons berichten stuurt, verzamelen wij uw naam, contactgegevens en de inhoud van uw berichten ("Communicatie-informatie").',
    subBlock4:
      "Sociale media-informatie: We hebben pagina's op sociale mediawebsites zoals Instagram, Facebook, Medium, Twitter, YouTube en LinkedIn. Wanneer u onze sociale mediapagina's gebruikt, verzamelen wij de persoonlijke informatie die u ons verstrekt, zoals uw contactgegevens (\"Sociale informatie\"). Bovendien kunnen de bedrijven die onze sociale mediapagina's hosten ons voorzien van geaggregeerde informatie en analyses over onze sociale media-activiteiten.",
    subBlock5:
      'Loggegevens: informatie die automatisch door uw browser wordt verzonden wanneer u onze diensten gebruikt. Loggegevens omvatten uw Internet Protocol (IP)-adres, browsertype en -instellingen, de datum en tijd van uw verzoeken en hoe u met onze website omgaat.',
    subBlock6:
      'Gebruiksgegevens: We kunnen automatisch informatie verzamelen over uw gebruik van de dienst, zoals de soorten inhoud die u bekijkt of waarmee u communiceert, de functies die u gebruikt, de acties die u onderneemt, evenals uw tijdzone, land, datum en tijd van toegang, user-agent en versie, type computer of mobiel apparaat en uw computerverbinding.',
    subBlock7:
      'Apparaatinformatie: Dit omvat de naam van het apparaat, het besturingssysteem, de apparaat-ID en de browser die u gebruikt. De verzamelde informatie kan afhankelijk zijn van het type apparaat dat u gebruikt en de instellingen ervan.',
    subBlock8:
      'Cookies: Wij gebruiken cookies om onze diensten te exploiteren en te beheren en uw ervaring te verbeteren. Een ‘cookie’ is informatie die door een website die u bezoekt naar uw browser wordt verzonden. U kunt uw browser zo instellen dat hij alle cookies accepteert, alle cookies weigert of u waarschuwt wanneer er een cookie wordt geplaatst, zodat u telkens kunt beslissen of u deze accepteert. Het weigeren van cookies kan echter tot gevolg hebben dat u bepaalde delen of functies van de website niet kunt gebruiken of de weergave of functionaliteit ervan negatief kunt beïnvloeden. Voor meer informatie over cookies kunt u terecht op Alles over Cookies.',
    subBlock9:
      'Analyse: We kunnen verschillende online analyseproducten gebruiken die cookies gebruiken om ons te helpen analyseren hoe gebruikers onze services gebruiken en om uw ervaring bij het gebruik van de services te verbeteren.',
    subTitle1: 'Persoonlijke informatie die we verzamelen',
    subTitle2: 'Hoe wij persoonlijke informatie gebruiken',
    subTitle3: 'Openbaarmaking van persoonlijke informatie',
    subTitle4: 'Uw rechten',
    subTitle5: 'Links naar andere websites',
    subTitle6: 'Beveiliging en bewaring van informatie',
    subTitle7: 'Wijziging van het privacybeleid',
    subTitle8: 'Hoe u contact met ons kunt opnemen',
    title: 'Privacybeleid',
    updateTime: 'Bijgewerkt: 15 maart 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accepteer alles',
    text: 'Om uw ervaring te verbeteren, gebruiken wij cookies om inhoud en diensten te personaliseren.',
    title: 'Deze website maakt gebruik van cookies',
  },
  slogen: 'De gemakkelijkste AI-assistent',
  stripe: {
    contact: 'Neem bij vragen contact met ons op via contact@noam.tools!',
    fail: {
      btn: 'Opnieuw proberen',
      text1: 'We konden uw betaling niet verwerken.',
      text2: 'Controleer uw verbinding en probeer het opnieuw.',
      title: 'Betaling mislukt',
    },
    succ: {
      btn: 'Laten we gaan',
      text1: 'Welkom bij Noam,',
      text2: 'laten we beginnen!',
      title: 'Gefeliciteerd',
    },
  },
  terms: {
    block1: 'Bedankt dat je Noam gebruikt!',
    block10:
      '(a) Kosten en facturering. U betaalt alle kosten ("Vergoedingen") volgens de prijzen en voorwaarden op de toepasselijke prijspagina of zoals schriftelijk tussen ons overeengekomen. We behouden ons het recht voor om prijsfouten of vergissingen te corrigeren, zelfs als we facturen hebben uitgegeven of betaling hebben ontvangen. U verstrekt volledige en nauwkeurige factuurgegevens, inclusief een geldige en geautoriseerde betalingsmethode. Wij zullen uw betalingsmethode periodiek in rekening brengen zoals overeengekomen, maar wij kunnen de factuurdatum redelijkerwijs wijzigen. U geeft Noam, haar dochterondernemingen en externe betalingsverwerkers toestemming om kosten in rekening te brengen via uw betalingsmethode. Als uw betaling mislukt, zullen wij u hiervan schriftelijk op de hoogte stellen en kunnen wij de toegang tot de Dienst opschorten totdat de betaling is ontvangen. Tenzij anders aangegeven in deze overeenkomst, zijn betalingen niet-restitueerbaar.',
    block11:
      '(b) Belastingen. Tenzij anders vermeld omvatten de vergoedingen geen federale, staats-, lokale en buitenlandse belastingen, heffingen en soortgelijke aanslagen ("Belastingen"). U bent verantwoordelijk voor alle belastingen die verband houden met uw aankopen, met uitzondering van belastingen op basis van ons netto-inkomen waarvoor wij u factureren. U stemt ermee in dergelijke belastingen onmiddellijk te betalen, ons rechtsgeldige privacyverklaringen te verstrekken en de noodzakelijke toestemmingen te verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving.',
    block12:
      '(c) Prijswijzigingen. Wij kunnen onze prijzen wijzigen door u hiervan op de hoogte te stellen via uw account en/of onze website. Prijsverhogingen worden onmiddellijk na plaatsing van kracht. Eventuele prijswijzigingen zijn van toepassing op de Vergoedingen voor uw account na de ingangsdatum van de wijziging.',
    block13:
      '(d) Geschillen en achterstallige betalingen. Als u eventuele vergoedingen of belastingen wilt betwisten, neem dan binnen dertig (30) dagen na de factuurdatum contact op met contact@noam.tools.',
    block14:
      '(e) Gratis gebruik. U mag niet meerdere accounts aanmaken om gratis credits te verkrijgen. Als we vaststellen dat u de gratis tegoeden niet te goeder trouw gebruikt, kunnen we u standaardkosten in rekening brengen of stoppen met het verlenen van toegang tot de Dienst.',
    block15:
      '(a) Vertrouwelijkheid. Mogelijk hebt u toegang tot vertrouwelijke informatie van Noam. U mag vertrouwelijke informatie alleen gebruiken zoals toegestaan ​​onder deze voorwaarden voor het gebruik van de Dienst. U mag geen vertrouwelijke informatie bekendmaken aan derden, en u zult vertrouwelijke informatie beschermen op een manier die niet minder beschermend is dan wanneer u uw eigen soortgelijke vertrouwelijke informatie beschermt, tenminste met redelijke zorg. Vertrouwelijke informatie is niet-openbare informatie die door Noam als vertrouwelijk is bestempeld of die onder de gegeven omstandigheden redelijkerwijs als vertrouwelijk moet worden behandeld, inclusief software, specificaties en andere niet-openbare bedrijfsinformatie. Vertrouwelijke informatie omvat geen informatie die: (1) zonder uw schuld algemeen beschikbaar wordt voor het publiek; (2) u in uw bezit had zonder enige verplichting tot vertrouwelijkheid voordat u het onder deze voorwaarden ontving; (3) op wettige wijze aan u wordt bekendgemaakt door een derde partij, zonder enige vertrouwelijkheidsverplichtingen; of (4) onafhankelijk door u is ontwikkeld zonder gebruik te maken van de vertrouwelijke informatie. U mag vertrouwelijke informatie openbaar maken indien vereist door de wet, de rechtbank of een ander overheidsbevel, maar u moet Noam hiervan vooraf op een redelijke manier schriftelijk op de hoogte stellen en, voor zover mogelijk, redelijke inspanningen leveren om de reikwijdte van de openbaarmaking te beperken, inclusief het assisteren van ons in het verzet tegen openbaarmakingsverzoeken.',
    block16:
      '(b) Beveiliging. U moet redelijke en passende maatregelen treffen om uw toegang tot en gebruik van de Dienst te helpen beveiligen. Als u kwetsbaarheden of overtredingen ontdekt die verband houden met uw gebruik van de Dienst, moet u onmiddellijk contact opnemen met Noam en details verstrekken over de kwetsbaarheid of overtreding.',
    block17:
      '((c) Verwerking van persoonlijke gegevens. Als u de Service gebruikt om persoonlijke gegevens te verwerken, moet u juridisch voldoende privacyverklaringen verstrekken en de noodzakelijke toestemming verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving. .',
    block18:
      '(a) Beëindiging; Oponthoud. Deze voorwaarden zijn van kracht vanaf uw eerste gebruik van de Dienst en blijven van kracht totdat ze worden beëindigd. U kunt deze voorwaarden op elk moment en om welke reden dan ook beëindigen door het gebruik van de Dienst en de Inhoud te staken. We kunnen deze voorwaarden om welke reden dan ook met voorafgaande kennisgeving beëindigen. We kunnen deze voorwaarden onmiddellijk beëindigen door u op de hoogte te stellen als u artikel 2 (Gebruiksvereisten), artikel 5 (Vertrouwelijkheid, beveiliging en gegevensbescherming), artikel 8 (Geschillenbeslechting) of artikel 9 (Algemene voorwaarden) wezenlijk schendt, of als onze relatie met een externe technologieleverancier die buiten onze macht ligt, wijzigingen of voor naleving van wetten of overheidsverzoeken. We kunnen uw toegang tot de Dienst opschorten als u deze voorwaarden niet naleeft, of als uw gebruik een veiligheidsrisico voor ons of een derde partij met zich meebrengt, of als we vermoeden dat uw gebruik frauduleus is of ons of een derde partij aan aansprakelijkheid kan blootstellen. .',
    block19:
      '(b) Gevolgen van beëindiging. Bij beëindiging stopt u met het gebruik van de Service en retourneert of vernietigt u onmiddellijk alle vertrouwelijke informatie zoals aangegeven door ons. De bepalingen van deze voorwaarden die door hun aard de beëindiging of afloop moeten overleven, inclusief maar niet beperkt tot secties 3 en 5-9, zullen van kracht blijven.',
    block2:
      'Deze Gebruiksvoorwaarden zijn van toepassing op uw gebruik van de diensten van Noam, inclusief onze applicatieprogrammeringsinterfaces, software, tools, ontwikkelaarsdiensten, gegevens, documentatie en website (gezamenlijk de "Diensten" genoemd). Door onze Services te gebruiken, gaat u ermee akkoord zich aan deze voorwaarden te houden. In ons privacybeleid wordt uitgelegd hoe we persoonlijke informatie verzamelen en gebruiken.',
    block20:
      '(a) Schadeloosstelling. U verdedigt, vrijwaart en houdt ons, onze dochterondernemingen en ons personeel schadeloos van en tegen eventuele claims, verliezen en kosten (inclusief advocatenhonoraria) die voortvloeien uit of verband houden met uw gebruik van de Service, inclusief uw Inhoud, uw gebruik van producten of diensten gerelateerd aan de Service, en uw overtreding van deze voorwaarden of toepasselijke wetgeving.',
    block21:
      '(b) Disclaimers. De Dienst wordt geleverd ‘as is’. Voor zover toegestaan ​​door de wet, geven wij en onze aangesloten bedrijven en licentiegevers geen verklaringen of garanties van welke aard dan ook met betrekking tot de Service en geven ze geen garanties, inclusief maar niet beperkt tot garanties van verkoopbaarheid, geschiktheid voor een bepaald doel, bevredigende kwaliteit, niet-inbreukmakendheid , en rustig genieten, en eventuele garanties die voortvloeien uit de handel of het gebruik van de handel. Wij garanderen niet dat de Dienst ononderbroken, accuraat of vrij van fouten zal zijn, of dat de Inhoud veilig zal zijn, niet verloren zal gaan of niet zal worden gewijzigd.',
    block22:
      '(c) Beperking van aansprakelijkheid. Noch wij, noch onze gelieerde ondernemingen, noch onze licentiegevers zijn aansprakelijk voor enige indirecte, incidentele, speciale, gevolg- of punitieve schade, inclusief winstderving, reputatieverlies, verlies van gebruik, verlies van gegevens of andere immateriële verliezen, zelfs als wij zijn op de hoogte gesteld van de mogelijkheid van dergelijke schade. Volgens deze voorwaarden zal onze totale aansprakelijkheid niet hoger zijn dan de Vergoedingen die u aan de Service hebt betaald in de twaalf (12) maanden voorafgaand aan de claim, of honderd Amerikaanse dollar ($100), afhankelijk van welke van de twee het grootst is. De beperkingen in dit artikel zijn van toepassing voor zover maximaal toegestaan ​​door de toepasselijke wetgeving.',
    block23:
      'Op de Voorwaarden en uw gebruik van de Services is het recht van de Verenigde Staten van toepassing, met uitzondering van de Amerikaanse conflictregels. Uw gebruik van de Applicatie kan ook onderworpen zijn aan andere lokale, provinciale, nationale of internationale wetten. Eventuele juridische stappen of procedures met betrekking tot deze Voorwaarden zullen uitsluitend worden voorgelegd aan de rechtbanken van de Verenigde Staten, en u stemt ermee in zich te onderwerpen aan de persoonlijke jurisdictie van dergelijke rechtbanken.',
    block24:
      '(a) Relatie tussen de partijen. Noam en u zijn onafhankelijke contractanten, en geen van beide partijen heeft de macht om de andere partij te vertegenwoordigen of te binden of verplichtingen voor de andere partij op zich te nemen zonder voorafgaande schriftelijke toestemming van de andere partij.',
    block25:
      "(b) Merkgebruik. U mag de naam, logo's of handelsmerken van Noam of een van haar dochterondernemingen niet gebruiken zonder onze voorafgaande schriftelijke toestemming.",
    block26:
      'Als wordt vastgesteld dat een bepaling van deze voorwaarden ongeldig, onwettig of niet-afdwingbaar is, blijven de overige bepalingen volledig van kracht.',
    block3:
      'Als je jonger bent dan 18 jaar, moet je toestemming krijgen van je ouders of wettelijke voogden om de Dienst te gebruiken. Als u de Service namens een andere persoon of entiteit gebruikt, moet u bevoegd zijn om deze voorwaarden namens hen te accepteren. U moet nauwkeurige en volledige informatie verstrekken om u voor een account te registreren. U mag uw toegangsgegevens of account niet aan iemand buiten uw organisatie verstrekken en u bent verantwoordelijk voor alle activiteiten die met uw inloggegevens worden uitgevoerd.',
    block4:
      '(a) Gebruik van de Dienst. U heeft onder deze voorwaarden toegang tot de Dienst en wij verlenen u een niet-exclusief recht om de Dienst te gebruiken. Wanneer u de Dienst gebruikt, zult u zich houden aan deze voorwaarden en alle toepasselijke wetten. Wij en onze dochterondernemingen bezitten alle rechten, titels en belangen in de Service.',
    block5:
      '(b) Feedback. Wij zijn blij met feedback, opmerkingen, ideeën, suggesties en verbeteringen. Als u dergelijke inhoud verstrekt, kunnen wij deze zonder enige beperking en zonder compensatie aan u gebruiken.',
    block6:
      '(c) Beperkingen. U mag niet: (1) de Dienst gebruiken op een manier die de rechten van anderen schendt, misbruikt of schendt; (2) de broncode of onderliggende componenten van de modellen, algoritmen en systemen van de Dienst reverse-engineeren, decompileren, demonteren, vertalen of anderszins proberen te ontdekken (tenzij dergelijke beperkingen door de toepasselijke wetgeving verboden zijn); (3) de output van de Dienst gebruiken om modellen te ontwikkelen die concurreren met Noam; (4) gegevens of uitvoer uit de Service extraheren met behulp van geautomatiseerde of programmeermethoden, tenzij toegestaan ​​via de API, inclusief scraping, webharvesting of webgegevensextractie; (5) de output van de Dienst weergeven als kunstmatig gegenereerd terwijl dit niet het geval is, of anderszins in strijd zijn met ons gebruiksbeleid; (6) API-sleutels kopen, verkopen of overdragen zonder onze voorafgaande toestemming; of (7) ons persoonlijke gegevens verstrekken van kinderen jonger dan 13 jaar of de toepasselijke leeftijd voor digitale toestemming. U voldoet aan alle tarieflimieten en andere vereisten in onze documentatie.',
    block7:
      '(d) Diensten van derden. Alle software, services of andere producten van derden die verband houden met de Service zijn onderworpen aan hun eigen voorwaarden, en wij zijn niet verantwoordelijk voor producten van derden.',
    block8:
      '(a) Uw inhoud. U kunt input ("Input") leveren aan de Dienst en output ("Output") ontvangen die is gegenereerd en geretourneerd op basis van de Input (gezamenlijk "Inhoud" genoemd). Tussen de partijen, en voor zover toegestaan ​​door de toepasselijke wetgeving, bent u eigenaar van alle Invoer. Noam kan Content gebruiken om de Service te leveren en te onderhouden, om te voldoen aan de toepasselijke wetgeving en om ons beleid af te dwingen. U bent verantwoordelijk voor de inhoud en zorgt er ook voor dat deze geen toepasselijke wetten of deze voorwaarden schendt.',
    block9:
      '(b) Nauwkeurigheid. Kunstmatige intelligentie en machinaal leren zijn snel evoluerende onderzoeksgebieden. We streven er voortdurend naar om onze service te verbeteren om deze nauwkeuriger, betrouwbaarder, veiliger en voordeliger te maken. Gezien de probabilistische aard van machine learning kan het gebruik van onze service in bepaalde gevallen resulteren in onjuiste resultaten, die mogelijk geen nauwkeurige weergave zijn van echte individuen, plaatsen of feiten. U moet de nauwkeurigheid van elke uitvoer op passende wijze beoordelen op basis van uw gebruiksscenario, onder meer door de uitvoer handmatig te beoordelen.',
    subTitle1: 'Registratie en toegang',
    subTitle10: 'Deelbaarheid',
    subTitle2: 'Gebruiksvereisten',
    subTitle3: 'Inhoud',
    subTitle4: 'Kosten en betaling',
    subTitle5: 'Vertrouwelijkheid, beveiliging en gegevensbescherming',
    subTitle6: 'Termijn en beëindiging',
    subTitle7: 'Schadeloosstelling; Disclaimers; Beperking van aansprakelijkheid',
    subTitle8: 'Toepasselijk recht en jurisdictie',
    subTitle9: 'Algemene voorwaarden',
    title: 'Servicevoorwaarden',
    updateTime: 'Bijgewerkt: 15 maart 2024',
  },
  trialModal: {
    btn: 'Ga nu aan de slag (slechts $ 0,99)',
    chat: 'ChatPDF',
    desc1: 'Na uw proefperiode wordt er een jaarlijkse vergoeding van $ 180 in rekening gebracht',
    desc2: 'Annuleer op elk gewenst moment',
    focus: 'Focussen',
    hover: 'Beweeg vertaling',
    image: 'Beeldvertaling',
    installBtn: 'Installeer nu om uw geschenk te ontvangen',
    memo: 'Beheer van favorieten',
    pdf: 'Parallelle vertaling van pdf',
    realtime: 'Directe vertaling',
    select: 'Vertaling van woordselectie',
    title: 'Welkomstgeschenk',
    trialText: '7-daagse proefperiode',
    video: 'Videovertaling',
    videoSummary: 'Video-samenvatting',
    web: 'Parallelle vertaling',
    webSummary: 'Websamenvatting',
  },
  upgradeModal: {
    btn: 'Upgrade nu',
    chat: {
      free: 'U heeft de ChatPDF-limiet bereikt',
      pro: 'Je hebt de ChatPDF-limiet voor deze maand bereikt',
    },
    contactStr:
      'Wacht alstublieft op de volgende reset of neem contact met ons op via contact@noam.tools.',
    limitAlertStr: 'U heeft uw gratis abonnementslimiet bereikt. Upgrade om door te gaan.',
    more: 'Bekijk plannen',
    pageLimit: { free: 'U heeft de PDF-paginalimiet bereikt' },
    title: 'Herinnering',
    upgradeAlertStr: 'U heeft uw gratis abonnementslimiet bereikt. Upgrade om door te gaan.',
    upload: {
      free: 'U heeft uw PDF-vertaallimiet bereikt',
      pro: 'U heeft uw PDF-vertaallimiet voor deze maand bereikt',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Sluit u nu aan',
      label: 'Nieuwe gebruikersvoordelen:',
      remain: 'Er zijn er nog maar 30 over',
      text: 'Nog maar 100 plekken beschikbaar! Grijp nu de jouwe!',
    },
  },
}

export default TRANSLATION
