const TRANSLATION = {
  buyModal: {
    afterFree: 'Sau khi dùng thử miễn phí',
    btn: 'Dùng thử miễn phí',
    freeTrial: 'Dùng thử miễn phí 3 ngày',
    monthLabel: 'tháng',
    selectLabel: 'Chọn một kế hoạch',
    title: 'Gói đăng ký',
    yearLabel: 'năm',
  },
  contact: {
    block1:
      'Để tùy chỉnh, đặt câu hỏi hoặc đề xuất, vui lòng liên hệ với chúng tôi theo địa chỉ contact@noam.tools. Chúng tôi sẽ phản hồi nhanh chóng.',
    block2:
      'Hãy ủng hộ chúng tôi bằng cách xếp hạng chúng tôi trên cửa hàng Tiện ích mở rộng của Google Apps tại ★★★★★.',
    block3: 'Giúp đỡ người khác bằng cách chia sẻ tiện ích mở rộng này:',
    title: 'Liên hệ với chúng tôi',
  },
  help: {
    AquickOverviewOfNoam: 'Tổng quan nhanh về Noam',
    ChatPDF: 'Trò chuyện PDF',
    ChatPDF2: 'Trò chuyệnPDF',
    FavoritesManagement: 'Quản lý yêu thích',
    Fixedplugin: 'Đã sửa lỗi plugin',
    Focusing: 'Lấy nét',
    Free: 'Miễn phí',
    FreeTrialBenefits: 'Lợi ích dùng thử miễn phí',
    GoWithWikipedia: 'Đi với Wikipedia',
    GoWithYouTube: 'Đi cùng YouTube',
    HoverTranslation: 'Dịch di chuột',
    Howtouse: 'Cách sử dụng',
    ImageTranslation: 'Dịch hình ảnh',
    InstantTranslation: 'Dịch tức thì',
    Membership: 'Tư cách thành viên',
    NoamHelp: 'Noam giúp bạn dịch và tóm tắt',
    PDFparallelTranslation: 'Dịch song song PDF',
    ParallelTranslation: 'Dịch song song',
    SkipAllSteps: 'Bỏ qua tất cả các bước',
    Summary: 'Bản tóm tắt',
    Translation: 'Dịch thuật',
    TryItForFree: 'Dùng thử miễn phí',
    Video: 'Băng hình',
    VideoSummary: 'Tóm tắt video',
    VideoTranslation: 'Dịch video',
    WebSummary: 'Tóm tắt trang web',
    WordSelectionTranslation: 'Dịch chọn từ',
    benefits: 'Chúng tôi có những lợi ích đang chờ bạn!',
    dayFree: 'Dùng thử miễn phí 3 ngày',
    done: 'Xong',
    next: 'Kế tiếp',
    pin: 'Ghim Noam để truy cập dễ dàng hơn～～',
    planIsNotExist: 'Kế hoạch không có sẵn',
    startWiki: 'Hãy bắt đầu với Wikimedia!',
    startYoutube: 'Hãy bắt đầu với YouTube!',
    try: 'Hãy thử nó',
    tryNow: 'Hãy thử ngay bây giờ!',
  },
  home: {
    PDFTranslate: {
      desc: 'Dịch song ngữ + Chat PDF',
      sub1: {
        desc: 'Đọc song ngữ cho phép bạn so sánh văn bản gốc và bản dịch song song, nâng cao hiệu quả đọc.',
        title: 'Đọc song ngữ',
      },
      sub2: {
        desc: 'AI phân tích, tóm tắt và có thể trả lời câu hỏi của bạn dựa trên nội dung PDF.',
        title: 'Trò chuyện PDF',
      },
      sub3: {
        desc: 'Bản dịch PDF được hỗ trợ bởi AI để có độ chính xác và hiệu quả cao hơn.',
        title: 'Độ chính xác và hiệu quả',
      },
      title: 'Dịch PDF',
    },
    aiAssistant: {
      desc: 'Làm cho việc dịch web thông minh hơn và thuận tiện hơn',
      panel1: {
        desc: 'Các bản dịch tham khảo chéo song ngữ giúp bạn so sánh văn bản gốc với bản dịch để bạn có thể hiểu rõ hơn và nâng cao hiệu quả đọc của mình.',
        title: 'Dịch thuật song ngữ',
      },
      panel2: {
        left: {
          desc: 'Khi duyệt một trang web, hãy di chuột qua nội dung cần dịch để xem bản dịch.',
          title: 'Dịch di chuột',
        },
        right: {
          desc: 'Khi duyệt một trang web, hãy chọn văn bản bạn muốn dịch để xem bản dịch.',
          title: 'Dịch lựa chọn văn bản',
        },
      },
      panel3: {
        one: {
          desc: 'Dịch và tóm tắt các trang web, nội dung video mọi lúc, mọi nơi để có trải nghiệm mượt mà hơn!',
          title: 'Trỏ và Bấm',
        },
        three: {
          desc: 'Mô hình ngôn ngữ lớn do AI điều khiển đảm bảo tính chính xác và nhất quán của bản dịch để nâng cao trải nghiệm đọc.',
          title: 'Điều khiển bằng AI',
        },
        two: {
          desc: 'Bảo hiểm đầy đủ đa ngôn ngữ, đọc song ngữ không rào cản, dịch chỉ bằng một cú nhấp chuột - hãy trải nghiệm sự khác biệt!',
          title: 'Toàn diện và thuận tiện hơn',
        },
      },
      panel4: {
        tab1: {
          desc: 'Chuyên gia về hiệu quả của bạn - sử dụng ChatGPT, Noam dịch và tóm tắt nội dung video YouTube để giúp bạn tiết kiệm thời gian!',
          key: 'Băng hình',
          title: 'Dịch & Tổng Hợp Video',
        },
        tab2: {
          desc: 'Bấm để dịch hình ảnh trong khi duyệt web.',
          key: 'Hình ảnh',
          title: 'Dịch hình ảnh',
        },
        tab3: {
          desc: 'Trích xuất ngay thông tin quan trọng từ các trang web và cung cấp bản tóm tắt, nâng cao hiệu quả đọc của bạn.',
          key: 'Web',
          title: 'Dịch & Tóm tắt Web',
        },
        tag1: 'Đơn giản',
        tag2: 'Có hiệu quả',
        tag3: 'Dễ hiểu',
      },
      title: 'Trợ lý AI',
    },
    getStart: 'Bắt đầu',
    getStartedForFree: 'Bắt đầu miễn phí',
    howItWork: {
      desc: 'Noam tích hợp các mô hình AI để cung cấp cho người dùng trải nghiệm thông minh về dịch và tóm tắt trang web đa ngôn ngữ, dịch PDF và hình ảnh, dịch và tóm tắt video',
      title: 'Nó hoạt động như thế nào',
    },
    lastBlock: { desc: 'Bản dịch AI tốt hơn để có trải nghiệm đọc mượt mà hơn' },
    top: {
      desc: 'Noam là một tiện ích mở rộng trình duyệt AI miễn phí giúp bạn dịch và tóm tắt các trang web, video và nội dung PDF.\\n Hãy tham gia cùng Noam và trải nghiệm điều kỳ diệu!',
      title1: 'Một phép thuật',
      title2: 'Một cú nhấp chuột',
    },
    userReviews: {
      desc: 'Đánh giá năm sao trên Cửa hàng Chrome trực tuyến',
      title: 'Đánh giá của người dùng',
    },
  },
  installTips: {
    btn: 'Hãy thử ngay bây giờ',
    text: 'Cài đặt "Plugin trình duyệt Noam" để mở khóa nhiều tính năng hơn! Tham khảo chéo trên web,\\n tóm tắt video trên web và hơn thế nữa!',
  },
  locale: {
    af: 'tiếng Afrikaans',
    am: 'tiếng Amharic',
    ar: 'tiếng Ả Rập',
    as: 'Tiếng Assam',
    auto: 'Tự động phát hiện',
    az: 'Tiếng Azerbaijan',
    ba: 'Bashkir',
    bg: 'tiếng Bungari',
    bn: 'Bangla',
    bo: 'tiếng Tây Tạng',
    bs: 'tiếng Bosnia',
    ca: 'tiếng Catalan',
    cs: 'tiếng Séc',
    cy: 'tiếng xứ Wales',
    da: 'tiếng Đan Mạch',
    de: 'tiếng Đức',
    dsb: 'Tiếng Hạ Sorbia',
    dv: 'lặn',
    el: 'tiếng Hy Lạp',
    en: 'Tiếng Anh',
    es: 'tiếng Tây Ban Nha',
    et: 'tiếng Estonia',
    eu: 'Tiếng Basque',
    fa: 'tiếng Ba Tư',
    fi: 'tiếng Phần Lan',
    fil: 'Tiếng Philippin',
    fj: 'tiếng Fiji',
    fr: 'người Pháp',
    frCA: 'Tiếng Pháp (Canada)',
    ga: 'người Ireland',
    gl: 'tiếng Galicia',
    gom: 'Konkani',
    gu: 'tiếng Gujarati',
    ha: 'Tiếng Hausa',
    he: 'tiếng Do Thái',
    hi: 'Tiếng Hindi',
    hr: 'tiếng Croatia',
    hsb: 'Tiếng Thượng Sorbia',
    ht: 'Tiếng Creole Haiti',
    hu: 'tiếng Hungary',
    hy: 'tiếng Armenia',
    id: 'tiếng Indonesia',
    ig: 'người Igbo',
    ikt: 'Inuinnaqtun',
    is: 'tiếng Iceland',
    it: 'người Ý',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (tiếng Latin)',
    ja: 'tiếng Nhật',
    ka: 'tiếng Gruzia',
    kk: 'Kazakhstan',
    km: 'tiếng Khmer',
    kmr: 'Người Kurd (Miền Bắc)',
    kn: 'tiếng Kannada',
    ko: 'Tiếng Hàn',
    ku: 'Tiếng Kurd (Miền Trung)',
    ky: 'Tiếng Kyrgyzstan',
    ln: 'lingala',
    lo: 'tiếng Lào',
    lt: 'tiếng Litva',
    lug: 'Ganda',
    lv: 'tiếng Latvia',
    lzh: 'Tiếng Trung (Văn học)',
    mai: 'Maithili',
    mg: 'Malagasy',
    mi: 'người Maori',
    mk: 'Tiếng Macedonia',
    ml: 'Mã Lai',
    mnCyrl: 'Tiếng Mông Cổ (chữ Cyrillic)',
    mnMong: 'Tiếng Mông Cổ (truyền thống)',
    mr: 'Tiếng Marathi',
    ms: 'Mã Lai',
    mt: 'tiếng Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Miến Điện)',
    nb: 'người Na Uy',
    ne: 'ERROR: [Nepali]',
    nl: 'tiếng Hà Lan',
    noSearchLang: 'Ngôn ngữ không được hỗ trợ',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Tiếng Punjab',
    pl: 'Đánh bóng',
    placeholder: 'Tìm kiếm',
    prs: 'Dari',
    ps: 'Tiếng Pa-tô',
    pt: 'Tiếng Bồ Đào Nha (Brazil)',
    ptPT: 'Tiếng Bồ Đào Nha (Bồ Đào Nha)',
    ro: 'người Rumani',
    ru: 'tiếng Nga',
    run: 'Rundi',
    rw: 'Tiếng Kinyarwanda',
    sd: 'tiếng Sindhi',
    si: 'Sinhala',
    sk: 'Tiếng Slovak',
    sl: 'tiếng Slovenia',
    sm: 'Tiếng Samoa',
    sn: 'Shona',
    so: 'tiếng Somali',
    sq: 'tiếng Albania',
    srCyrl: 'Tiếng Serbia (Cyrillic)',
    srLatn: 'Tiếng Serbia (tiếng Latinh)',
    st: 'Sesotho',
    sv: 'tiếng Thụy Điển',
    sw: 'tiếng Swahili',
    ta: 'Tiếng Tamil',
    te: 'tiếng Telugu',
    th: 'Thái',
    ti: 'Tigrinya',
    tk: 'người Thổ Nhĩ Kỳ',
    tlhLatn: 'Tiếng Klingon (tiếng Latinh)',
    tlhPiqd: 'Tiếng Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'tiếng Tonga',
    tr: 'tiếng Thổ Nhĩ Kỳ',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'người Duy Ngô Nhĩ',
    uk: 'tiếng Ukraina',
    ur: 'tiếng Urdu',
    uz: 'Tiếng Uzbek (tiếng Latinh)',
    vi: 'Tiếng Việt',
    xh: 'Xhosa',
    yo: 'Tiếng Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Tiếng Quảng Đông (truyền thống)',
    zh: 'Tiếng Trung',
    zhHans: 'Tiếng Trung giản thể',
    zhHant: 'Tiếng Trung phồn thể',
    zu: 'Tiếng Zulu',
  },
  login: {
    and: 'Và',
    btn: 'Đăng nhập bằng Google',
    btn1: 'Đăng nhập ngay',
    desc: 'Đăng nhập để mở khóa thêm trải nghiệm',
    policy: 'Chính sách bảo mật',
    terms: 'Điều khoản sử dụng',
    tips: 'Bằng cách đăng ký, tôi đồng ý với',
    title: 'Chào mừng đến với Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Theo', twitter: 'Twitter' },
    legal: { policy: 'Chính sách bảo mật', terms: 'Điều khoản dịch vụ', title: 'Hợp pháp' },
    product: {
      image: 'Dịch hình ảnh',
      pdf: 'Dịch PDF',
      title: 'Các sản phẩm',
      video: 'Dịch & Tổng Hợp Video',
      web: 'Dịch Web & Tổng Hợp',
    },
    resources: { title: 'Tài nguyên' },
  },
  pageHeader: {
    contact: 'Liên hệ',
    help: 'Giúp đỡ',
    logout: 'Đăng xuất',
    logoutSucc: 'Đã đăng xuất.',
    pricing: 'Định giá',
    settings: 'Cài đặt',
    signUp: 'Đăng nhập',
  },
  pdf: {
    autoDetect: 'Tự động phát hiện',
    cancelBtn: 'Hủy bỏ',
    cancelUpload: 'Hủy bỏ',
    chatPdf: {
      Search: 'Tìm kiếm',
      cancel: 'Hủy bỏ',
      chatTitle: 'Chào mừng đến với Noam! Đây là bản tóm tắt bản PDF của bạn:',
      clear: 'Thông thoáng',
      clearContent:
        'Điều này sẽ bắt đầu một cuộc trò chuyện mới. Cuộc trò chuyện hiện tại sẽ không được lưu. Bạn có chắc không?',
      clearDialogTitle: 'Vui lòng xác nhận',
      clearSucc: 'Đã xóa',
      copy: 'Sao chép',
      copySucc: 'Đã sao chép',
      delete: 'Xóa bỏ',
      deleteCancel: 'Hủy bỏ',
      deleteContent: 'Bản PDF này không thể phục hồi được sau khi xóa. Bạn có chắc không?',
      deleteOk: 'Xóa bỏ',
      deleteSuccess: 'Đã xóa',
      deleteTitle: 'Xác nhận Xóa',
      expand: 'Sụp đổ',
      historyTitle: 'Lịch sử',
      listError:
        'Không tải được danh sách đọc. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
      loading: 'Đang tải...',
      noDataDesc: 'Chưa có lịch sử.',
      noMore: 'Không còn nội dung nữa.',
      placeholder: 'Hỏi bất cứ điều gì về bản PDF này',
      summaryTitle: 'Có thể bạn quan tâm đến những câu hỏi này:',
    },
    collectionEmpty: 'Bộ sưu tập của bạn trống.',
    collectionTab: 'Ghi chú',
    dataLoading: 'Đang tải...',
    dialogContent: 'Bạn có chắc chắn muốn xóa cái này không?',
    dialogTitle: 'Vui lòng xác nhận',
    download: {
      both: 'song ngữ',
      btn: 'Cứu',
      cancel:
        'Đóng cửa sổ này sẽ hủy quá trình tải xuống bản PDF. Bạn có chắc chắn muốn hủy không?',
      desc: 'Lưu vào thiết bị: Tải bản dịch xuống dưới dạng hình ảnh; Lưu & In: Tải xuống bản dịch ở định dạng gốc, có hỗ trợ sao chép.',
      download: 'Tải xuống',
      downloadMsg1: 'Tiến trình dịch PDF:',
      downloadMsg2: 'Thời gian ước tính còn lại:',
      downloadSucc: 'PDF đã sẵn sàng! Bấm để lưu.',
      downloadTip: 'Đang tải xuống bản PDF. Đừng đóng cửa sổ này để tránh lỗi tải xuống.',
      error: 'Không lưu được. Thay vào đó hãy thử in.',
      fileName: 'Tên tập tin:',
      fileType: 'Loại tập tin:',
      loading: 'Đang lưu...',
      loading2: 'Đang tải...',
      minute: 'Phút...',
      msg1: 'Một số bản dịch trong tài liệu này dài hơn văn bản gốc và có thể trùng lặp trong tệp đã tải xuống. Chúng tôi khuyên bạn nên đọc trên trang web.',
      msg2: '1. Bản dịch chưa hoàn tất. Vui lòng tải xuống sau khi dịch xong.',
      msg3: '2. Một số bản dịch quá dài. Đọc trên trang web để tránh chồng chéo văn bản.',
      msg4: 'Công việc dịch thuật đang được tiến hành. Vui lòng thử lại sau khi hoàn tất.',
      progress: 'Dịch PDF. Tiến trình: {{progress}}, Thời gian còn lại ước tính: {{phút}} phút...',
      save: 'Lưu vào thiết bị',
      savePrint: 'Lưu & In',
      success: 'Đã tải xuống',
      trans: 'Chỉ dịch',
      transContinue: 'Tiếp tục dịch',
    },
    downloadBtn: 'Tải xuống',
    dragTipModal: {
      black: 'không gian',
      leftKey: 'nút trái',
      ok: 'Hiểu rồi',
      press: 'Nhấn',
      tips: 'để kéo và xem.',
    },
    entry: 'PDF',
    fileErrMsg: 'Đọc tệp không thành công. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    fileMsg: 'Kích thước tập tin hiện tại:',
    fileMsg2: 'Vui lòng tải lên một tệp nhỏ hơn 300 MB.',
    freeBannerBtn: 'Nâng cấp',
    freeBannerTips: 'Đã đạt đến giới hạn bản dịch PDF miễn phí',
    guide: {
      placeholder: 'Dán liên kết PDF vào đây',
      start: 'Bắt đầu dịch',
      text1: 'Đọc song ngữ',
      text2:
        'Đọc song ngữ để so sánh văn bản gốc và bản dịch song song, nâng cao hiệu quả của bạn.',
      text3: 'Trò chuyện với bản PDF của bạn',
      text4:
        'Phân tích và tóm tắt được hỗ trợ bởi AI. Đặt câu hỏi về tệp PDF của bạn và nhận được câu trả lời ngay lập tức.',
      text5: 'Chính xác & Hiệu quả',
      text6: 'Bản dịch PDF được hỗ trợ bởi AI để có độ chính xác và hiệu quả cao hơn.',
      title1: 'Dịch thuật song ngữ',
      title2: 'Trò chuyện với bản PDF của bạn',
      toast: 'Vui lòng nhập liên kết PDF trực tuyến hợp lệ có đuôi .pdf',
    },
    hasTransTask: 'Một tác vụ dịch thuật đang được tiến hành. Vui lòng chờ.',
    hoverTrans: 'Di chuột để dịch đoạn văn',
    initial: 'Dịch PDF',
    installText: 'Giải pháp toàn diện cho các bản dịch hàng ngày của bạn.',
    installText2: 'Vui lòng cài đặt tiện ích mở rộng trước',
    installTips:
      'Cài đặt tiện ích mở rộng để có nhiều bản dịch PDF hơn và bản dịch trang web mượt mà hơn:',
    kw: 'ghim',
    link: 'liên kết',
    listError: 'Không tải được tệp PDF. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    memoTotalCount: 'Tổng cộng 1 mặt hàng',
    new: 'tập tin mới',
    noId: 'Không tìm thấy ID tệp.',
    notPdf: 'Chỉ các tệp PDF.',
    okBtn: 'Xóa bỏ',
    okBtn2: 'Hiểu rồi',
    original: 'Nguyên bản',
    parallel: 'Song song',
    parseErrDialogContent:
      'Liên kết PDF này không thể được dịch trực tiếp. Vui lòng tải xuống bản PDF và tải nó lên để dịch.',
    parseErrTips: 'Không thể phân tích tệp',
    parseFileToast:
      'Không thể phân tích tệp. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    pdfLoading: 'Đang phân tích...',
    searchPlaceholder: 'Tìm kiếm',
    selectTrans: 'Chọn văn bản để dịch',
    shareBtn: 'Chia sẻ',
    shareBtnText1: 'Tạo liên kết chia sẻ',
    shareBtnText2: 'Cập nhật nội dung được chia sẻ',
    shareModal: {
      cancel: 'Hủy bỏ',
      copy: 'Sao chép',
      copySucc: 'Đã sao chép liên kết',
      custom: 'Tùy chỉnh:',
      errMsg: 'Nội dung được chọn chưa được dịch đầy đủ. Vui lòng dịch trước khi chia sẻ.',
      gen: 'Chia sẻ',
      modify: 'Biên tập',
      notSupport: 'Ngôn ngữ này hiện không được hỗ trợ.',
      page: 'Trang',
      pageNuErr: 'Vui lòng nhập số trang hợp lệ.',
      range: 'Phạm vi:',
      remain: 'còn lại',
      remain1: 'Tổng thời gian ước tính:',
      search: 'Tìm kiếm',
      settings: 'Cài đặt chia sẻ:',
      shareSetting: 'Cài đặt chia sẻ',
      shareText: 'Chia sẻ',
      shareTips: 'Chia sẻ liên kết PDF đã dịch với bạn bè của bạn:',
      sourceLang: 'Từ:',
      target: 'ĐẾN:',
      text1: 'Cài đặt chia sẻ',
      text2: 'Tạo liên kết chia sẻ',
      totalPage: 'Tổng cộng 1 trang',
      trans: 'Dịch',
      translateTips:
        'Mẹo: Nếu quá trình dịch mất nhiều thời gian, bạn có thể đóng cửa sổ này và chia sẻ sau.',
      translating: 'Dịch PDF...',
      update: 'Cập nhật liên kết chia sẻ',
    },
    shareMsg1: 'Chia sẻ liên kết được tạo ra.',
    shareMsg2: 'Đã cập nhật nội dung chia sẻ.',
    shareMsg3:
      'Không tạo được liên kết chia sẻ. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    shareMsg4:
      'Không cập nhật được nội dung được chia sẻ. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    shareTips: 'Bản dịch PDF của bạn đã sẵn sàng! Chia sẻ nó với bạn bè của bạn.',
    start: 'Bắt đầu dùng thử miễn phí của bạn',
    thumbnail: 'Mục lục',
    toast1: 'Đang tải PDF. Vui lòng thử lại sau.',
    toast2: 'Nội dung PDF quá lớn. Hộp thoại hiện không được hỗ trợ.',
    toast3: 'Đang tải xuống. Vui lòng thử lại sau khi quá trình tải xuống hoàn tất.',
    toolbar: { Adjust: 'Điều chỉnh', autoFit: 'Tự động điều chỉnh' },
    trans: 'Dịch thuật',
    transSucc: 'Dịch thành công!',
    unLoginToast: 'Vui lòng đăng nhập để sử dụng tính năng này.',
    uploadBtn: 'Tải lên',
    uploadErr:
      'Tải tệp lên không thành công. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    uploadMsg1: 'Kéo và thả tập tin của bạn vào đây.',
    uploadMsg2: 'Các loại tệp được hỗ trợ: PDF |  Kích thước tệp tối đa: 100MB',
    uploadMsg3: 'Bấm để chọn hoặc kéo thả file vào đây.',
    uploading: 'Đang tải lên',
    uploadingMsg: 'Đang tải lên. Vui lòng chờ.',
    uploadingMsg1: 'Tập tin đang được tải lên. Vui lòng thử lại sau.',
    uploadingMsg2: 'Một tập tin hiện đang được chuyển đổi. Vui lòng thử lại sau.',
    uploadingMsg3:
      'Yêu cầu tiến trình chuyển đổi không thành công. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    uploadingMsg4:
      'Noam không thể truy cập các tập tin cục bộ. Vui lòng tải lên bản PDF theo cách thủ công để dịch.',
    uploadingMsg5:
      'Không thể truy xuất URL tệp. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    video: 'băng hình',
    vipBannerBtn: 'Nâng cấp',
    vipBannerTips: 'Bạn đã đạt đến giới hạn bản dịch PDF trong tháng này.',
    webTrans: 'Tham khảo chéo đầy đủ',
  },
  pricing: {
    FAQ: 'Câu hỏi thường gặp',
    FQAObj: {
      a1: 'Có, Noam cung cấp gói miễn phí mà bạn có thể sử dụng ngay.',
      a2: 'Chúng tôi cung cấp ba gói thành viên: Pro, Pro+ và Enterprise. Bạn có thể chọn thanh toán hàng tháng hoặc hàng năm. Thanh toán hàng năm mang lại khoản tiết kiệm đáng kể.',
      a3: 'Tất cả các tính năng đều có sẵn trên cả hai gói. Sự khác biệt chính là chi phí: chi phí đăng ký hàng năm nhìn chung thấp hơn. Đăng ký hàng tháng được thanh toán mỗi tháng, trong khi gói hàng năm được thanh toán một lần mỗi năm.',
      a4: 'Bạn có thể hủy đăng ký của mình bất cứ lúc nào trong Cài đặt tài khoản.',
      q1: 'Có kế hoạch miễn phí không?',
      q2: 'Có những gói giá và tùy chọn thanh toán nào?',
      q3: 'Sự khác biệt giữa đăng ký hàng tháng và hàng năm là gì?',
      q4: 'Làm cách nào để hủy đăng ký của tôi?',
    },
    around: 'Xung quanh',
    billedMonthly: 'Thanh toán hàng tháng',
    billedYearly: 'Thanh toán hàng năm',
    day: 'ngày',
    desc: 'So sánh các gói và chọn gói tốt nhất',
    detailedCompare: 'So sánh các gói',
    discount: 'Giảm giá 20%',
    enterprise: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: 'Nhiều người dùng',
      benefit3: 'Giải pháp phù hợp',
      benefit5: 'Người quản lý tài khoản chuyên dụng',
      btn: 'Liên hệ với chúng tôi',
      recommendText: 'Gói tùy chỉnh（ ≥3人）',
      talkToUs: 'Liên hệ',
    },
    free: {
      benefit1: 'Dịch tức thời 100 lần/ngày',
      benefit2: 'Dịch thuật & tổng hợp website',
      benefit3: '30 bản dịch hình ảnh',
      benefit4: 'Dịch & Tổng Hợp Video',
      benefit5: '10 bản dịch PDF',
      benefit6: '5.000 token',
      btn: 'Dùng thử miễn phí',
      recommendText: 'Dùng thử miễn phí',
    },
    imageTranslation: 'Dịch hình ảnh',
    month: 'tháng',
    monthly: 'hàng tháng',
    pageTitle: 'Định giá',
    pdf: {
      chat: 'Truy vấn trò chuyện PDF',
      maxPage: 'Tối đa. Số trang trên mỗi PDF',
      translation: 'Dịch PDF',
    },
    preMonth: 'Mỗi tháng',
    pro: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: '1.000.000 token/tháng',
      benefit3: 'Dịch Website & Tổng Hợp',
      benefit4: '200 bản dịch hình ảnh',
      benefit5: 'Dịch & Tổng Hợp Video',
      benefit6: '200 bản dịch PDF',
      btn: 'Bắt đầu',
      recommendText: 'Phổ biến nhất',
    },
    proPlus: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: '3.000.000 token/tháng',
      benefit3: 'Dịch Website & Tổng Hợp',
      benefit4: '500 bản dịch hình ảnh',
      benefit5: 'Dịch & Tổng Hợp Video',
      benefit6: '500 bản dịch PDF',
      recommendText: 'Giá trị tốt nhất',
    },
    video: { summary: 'Tóm tắt video', translation: 'Dịch video' },
    web: {
      contrastiveTranslation: 'Dịch thuật song ngữ',
      dictionaryTranslation: 'Dịch tuyển chọn',
      hoverTranslation: 'Dịch di chuột',
      instantTranslation: 'Dịch tức thì',
      summary: 'Tóm tắt trang web',
    },
    yearly: 'Hàng năm',
  },
  privacy: {
    block1:
      'Noam ("Noam", "chúng tôi", "của chúng tôi" hoặc "chúng tôi") tôn trọng quyền riêng tư của bạn và cam kết bảo vệ mọi thông tin chúng tôi thu được từ bạn hoặc về bạn. Chính sách quyền riêng tư này mô tả các thông lệ của chúng tôi về việc thu thập hoặc sử dụng thông tin cá nhân từ việc bạn sử dụng trang web, ứng dụng và dịch vụ của chúng tôi (gọi chung là "Dịch vụ"). Chính sách quyền riêng tư này không áp dụng cho nội dung mà chúng tôi xử lý thay mặt cho khách hàng sử dụng sản phẩm kinh doanh của chúng tôi, chẳng hạn như API của chúng tôi. Việc sử dụng dữ liệu này phải tuân theo thỏa thuận khách hàng của chúng tôi, bao gồm quyền truy cập và sử dụng các sản phẩm này.',
    block10:
      'Chúng tôi thực hiện các biện pháp tổ chức, hành chính và kỹ thuật hợp lý về mặt thương mại để bảo vệ thông tin cá nhân trực tuyến và ngoại tuyến khỏi bị mất, lạm dụng và truy cập trái phép, tiết lộ, thay đổi hoặc hành động thù địch. Tuy nhiên, không có đường truyền internet hoặc email nào có thể đảm bảo tính bảo mật hoặc độ chính xác hoàn toàn. Đặc biệt, các email gửi cho chúng tôi hoặc từ chúng tôi có thể không an toàn. Vì vậy, bạn nên đặc biệt cẩn thận khi quyết định gửi thông tin nào cho chúng tôi thông qua dịch vụ hoặc email. Ngoài ra, chúng tôi không chịu trách nhiệm về bất kỳ sự cản trở nào đối với cài đặt quyền riêng tư hoặc biện pháp bảo mật trong dịch vụ hoặc cài đặt bảo mật hoặc biện pháp bảo mật của Trang web bên thứ ba. Chúng tôi sẽ lưu giữ thông tin cá nhân của bạn trong khoảng thời gian cần thiết để chúng tôi cung cấp cho bạn các dịch vụ hoặc cho các mục đích kinh doanh hợp pháp khác, chẳng hạn như giải quyết tranh chấp, lý do an ninh và an toàn hoặc tuân thủ các nghĩa vụ pháp lý của chúng tôi. Khoảng thời gian chúng tôi lưu giữ thông tin cá nhân sẽ phụ thuộc vào nhiều yếu tố, chẳng hạn như số lượng, tính chất, độ nhạy cảm của thông tin, rủi ro tiềm ẩn của việc sử dụng hoặc tiết lộ trái phép, mục đích mà chúng tôi xử lý thông tin và các cân nhắc ràng buộc về mặt pháp lý khác.',
    block11:
      'Thỉnh thoảng chúng tôi có thể cập nhật Chính sách quyền riêng tư này. Khi làm như vậy, chúng tôi sẽ đăng phiên bản cập nhật trên trang này, trừ khi luật hiện hành có yêu cầu khác.',
    block12:
      'Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào chưa được giải đáp về Chính sách quyền riêng tư này, vui lòng liên hệ với bộ phận dịch vụ khách hàng của chúng tôi. E-mail:',
    block2: 'Chúng tôi thu thập thông tin cá nhân sau ("Thông tin cá nhân") liên quan đến bạn:',
    block3:
      'Thông tin do bạn cung cấp: Khi bạn tạo tài khoản để sử dụng dịch vụ của chúng tôi hoặc liên lạc với chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân như sau:',
    block4:
      'Thông tin cá nhân được tự động nhận từ việc sử dụng dịch vụ của bạn: Khi bạn truy cập, sử dụng hoặc tương tác với các dịch vụ, chúng tôi sẽ nhận được thông tin sau về quyền truy cập, sử dụng hoặc tương tác của bạn ("Thông tin kỹ thuật"):',
    block5: 'Chúng tôi có thể sử dụng thông tin cá nhân cho các mục đích sau:',
    block6:
      'Tóm tắt hoặc Thông tin không xác định: Chúng tôi có thể tổng hợp hoặc hủy thông tin nhận dạng để ngăn chặn việc sử dụng lại thông tin đó cho mục đích nhận dạng và sử dụng thông tin đó để phân tích hiệu quả dịch vụ của chúng tôi, cải thiện và thêm tính năng cho dịch vụ của chúng tôi, tiến hành nghiên cứu và cho các mục đích tương tự . Ngoài ra, chúng tôi có thể phân tích hành vi và đặc điểm chung của người dùng dịch vụ của chúng tôi và chia sẻ thông tin tổng hợp với bên thứ ba, công bố thông tin tổng hợp đó hoặc cung cấp thông tin đó một cách rộng rãi. Chúng tôi có thể thu thập thông tin tổng hợp thông qua các dịch vụ, thông qua cookie và thông qua các phương tiện khác được mô tả trong Chính sách quyền riêng tư này. Chúng tôi sẽ duy trì và sử dụng thông tin không xác định ở dạng ẩn danh hoặc không xác định và chúng tôi sẽ không cố gắng xác định lại thông tin đó trừ khi luật pháp yêu cầu.',
    block7:
      'Trong một số trường hợp nhất định, chúng tôi có thể cung cấp thông tin cá nhân của bạn cho bên thứ ba mà không cần thông báo thêm cho bạn, trừ khi pháp luật yêu cầu:',
    block8:
      'Tùy thuộc vào vị trí địa lý của bạn, các cá nhân ở Khu vực Kinh tế Châu Âu, Vương quốc Anh và trên toàn cầu có thể có một số quyền theo luật định liên quan đến thông tin cá nhân của họ. Ví dụ: bạn có thể có quyền:',
    block9:
      'Dịch vụ này có thể chứa các liên kết đến các trang web khác không do Noam điều hành hoặc kiểm soát, bao gồm các dịch vụ truyền thông xã hội ("Trang web của bên thứ ba"). Thông tin bạn chia sẻ với Trang web của bên thứ ba sẽ chịu sự điều chỉnh của các chính sách quyền riêng tư và điều khoản dịch vụ cụ thể của Trang web bên thứ ba, không phải bởi Chính sách quyền riêng tư này. Chúng tôi cung cấp các liên kết này để thuận tiện và không ngụ ý sự chứng thực hoặc đánh giá của chúng tôi về các trang web này. Vui lòng liên hệ trực tiếp với các Trang web của Bên thứ ba để biết thông tin về các chính sách và điều khoản về quyền riêng tư của họ.',
    subBlock1:
      'Thông tin tài khoản: Khi bạn tạo tài khoản trên nền tảng của chúng tôi, chúng tôi sẽ thu thập thông tin liên quan đến tài khoản của bạn, bao gồm tên, thông tin liên hệ, thông tin xác thực tài khoản, thông tin thẻ thanh toán và lịch sử giao dịch ("Thông tin tài khoản").',
    subBlock10: 'Cung cấp, quản lý, bảo trì và/hoặc phân tích dịch vụ;',
    subBlock11: 'Giao tiếp với bạn;',
    subBlock12: 'Phát triển sản phẩm và dịch vụ mới;',
    subBlock13:
      'Ngăn chặn gian lận, hoạt động tội phạm hoặc lạm dụng dịch vụ của chúng tôi và bảo vệ tính bảo mật của hệ thống, kiến ​​trúc và mạng CNTT của chúng tôi;',
    subBlock14: 'Thực hiện chuyển nhượng kinh doanh;',
    subBlock15:
      'Tuân thủ các nghĩa vụ pháp lý và thủ tục pháp lý, bảo vệ quyền, quyền riêng tư, bảo mật hoặc tài sản của chúng tôi cũng như của các chi nhánh của chúng tôi, bạn hoặc các bên thứ ba khác.',
    subBlock16:
      'Nhà cung cấp và nhà cung cấp dịch vụ: Để hỗ trợ chúng tôi đáp ứng nhu cầu hoạt động kinh doanh và thực hiện một số dịch vụ và chức năng nhất định, chúng tôi có thể cung cấp thông tin cá nhân cho các nhà cung cấp và nhà cung cấp dịch vụ, bao gồm nhà cung cấp dịch vụ lưu trữ, nhà cung cấp dịch vụ đám mây và các nhà cung cấp dịch vụ công nghệ thông tin khác, phần mềm liên lạc qua email và các nhà cung cấp dịch vụ phân tích trang web, v.v. Các đối tác này sẽ chỉ truy cập, xử lý hoặc lưu trữ thông tin cá nhân theo chỉ đạo của chúng tôi trong khi thực hiện nghĩa vụ của họ đối với chúng tôi.',
    subBlock17:
      'Chuyển giao doanh nghiệp: Nếu chúng tôi tham gia vào các giao dịch chiến lược, tổ chức lại, phá sản, tiếp quản phá sản hoặc chuyển đổi dịch vụ sang nhà cung cấp khác (gọi chung là "Giao dịch"), thông tin cá nhân của bạn và các thông tin khác có thể được chia sẻ với các đối tác giao dịch và các bên khác hỗ trợ Giao dịch trong quá trình thẩm định và chuyển giao như một phần của Giao dịch cùng với các tài sản khác cho công ty kế thừa hoặc công ty liên kết.',
    subBlock18:
      'Yêu cầu pháp lý: Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các cơ quan chính phủ, các đồng nghiệp trong ngành hoặc các bên thứ ba khác, bao gồm thông tin tương tác của bạn với các dịch vụ của chúng tôi, (1) nếu pháp luật yêu cầu hoặc nếu chúng tôi tin rằng hành động đó là cần thiết để tuân thủ nghĩa vụ pháp lý; (2) để bảo vệ các quyền hoặc tài sản của chúng tôi; (3) nếu chúng tôi đơn phương xác định rằng có sự vi phạm các điều khoản, chính sách hoặc luật pháp của chúng tôi; (4) để phát hiện hoặc ngăn chặn gian lận hoặc các hoạt động bất hợp pháp khác; (5) để bảo vệ sản phẩm, nhân viên hoặc người dùng của chúng tôi hoặc an toàn, bảo mật, tính toàn vẹn của công cộng; hoặc (vi) để chống lại trách nhiệm pháp lý.',
    subBlock19: 'Truy cập thông tin cá nhân của bạn và thông tin về cách nó được xử lý.',
    subBlock2:
      'Nội dung của Người dùng: Khi bạn sử dụng dịch vụ của chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân từ thông tin đầu vào, tệp tải lên hoặc phản hồi của bạn cung cấp cho chúng tôi ("Nội dung").',
    subBlock20: 'Yêu cầu xóa thông tin cá nhân của bạn khỏi hồ sơ của chúng tôi.',
    subBlock21: 'Chỉnh sửa hoặc cập nhật thông tin cá nhân của bạn.',
    subBlock22: 'Chuyển thông tin cá nhân của bạn cho bên thứ ba (khả năng di chuyển dữ liệu).',
    subBlock23: 'Giới hạn cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subBlock24:
      'Rút lại sự đồng ý của bạn—trong trường hợp chúng tôi dựa vào sự đồng ý làm cơ sở pháp lý để xử lý, bạn có thể rút lại sự đồng ý của mình bất kỳ lúc nào.',
    subBlock25: 'Phản đối cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subBlock26: 'Cải thiện dịch vụ của chúng tôi và tiến hành nghiên cứu;',
    subBlock3:
      'Thông tin liên lạc: Nếu bạn gửi tin nhắn cho chúng tôi, chúng tôi sẽ thu thập tên, thông tin liên hệ và nội dung tin nhắn của bạn ("Thông tin liên lạc").',
    subBlock4:
      'Thông tin trên mạng xã hội: Chúng tôi có các trang trên các trang web truyền thông xã hội như Instagram, Facebook, Medium, Twitter, YouTube và LinkedIn. Khi bạn tương tác với các trang truyền thông xã hội của chúng tôi, chúng tôi sẽ thu thập thông tin cá nhân mà bạn chọn cung cấp cho chúng tôi, chẳng hạn như chi tiết liên hệ của bạn ("Thông tin xã hội"). Ngoài ra, các công ty lưu trữ các trang truyền thông xã hội của chúng tôi có thể cung cấp cho chúng tôi thông tin và phân tích tổng hợp về các hoạt động truyền thông xã hội của chúng tôi.',
    subBlock5:
      'Dữ liệu nhật ký: Thông tin được trình duyệt của bạn tự động gửi khi sử dụng dịch vụ của chúng tôi. Dữ liệu nhật ký bao gồm địa chỉ Giao thức Internet (IP), loại và cài đặt trình duyệt, ngày và giờ yêu cầu của bạn cũng như cách bạn tương tác với trang web của chúng tôi.',
    subBlock6:
      'Dữ liệu sử dụng: Chúng tôi có thể tự động thu thập thông tin về việc bạn sử dụng dịch vụ, chẳng hạn như loại nội dung bạn xem hoặc tương tác, các tính năng bạn sử dụng, hành động bạn thực hiện cũng như múi giờ, quốc gia, ngày và giờ của bạn. quyền truy cập, tác nhân người dùng và phiên bản, loại máy tính hoặc thiết bị di động và kết nối máy tính của bạn.',
    subBlock7:
      'Thông tin thiết bị: Thông tin này bao gồm tên thiết bị, hệ điều hành, số nhận dạng thiết bị và trình duyệt bạn đang sử dụng. Thông tin được thu thập có thể phụ thuộc vào loại thiết bị bạn đang sử dụng và cài đặt của thiết bị đó.',
    subBlock8:
      'Cookie: Chúng tôi sử dụng cookie để vận hành và quản lý các dịch vụ của mình cũng như cải thiện trải nghiệm của bạn. "Cookie" là thông tin được gửi bởi một trang web mà bạn truy cập tới trình duyệt của mình. Bạn có thể đặt trình duyệt của mình chấp nhận tất cả cookie, từ chối tất cả cookie hoặc thông báo cho bạn khi cookie được cung cấp để bạn có thể quyết định có chấp nhận nó mỗi lần hay không. Tuy nhiên, việc từ chối cookie có thể khiến bạn không thể sử dụng hoặc ảnh hưởng tiêu cực đến việc hiển thị hoặc chức năng của một số khu vực hoặc tính năng nhất định của trang web. Để biết thêm thông tin về cookie, vui lòng truy cập Tất cả về cookie.',
    subBlock9:
      'Phân tích: Chúng tôi có thể sử dụng nhiều sản phẩm phân tích trực tuyến khác nhau sử dụng cookie để giúp chúng tôi phân tích cách người dùng sử dụng dịch vụ của chúng tôi và cải thiện trải nghiệm của bạn khi sử dụng dịch vụ.',
    subTitle1: 'Thông tin cá nhân chúng tôi thu thập',
    subTitle2: 'Cách chúng tôi sử dụng thông tin cá nhân',
    subTitle3: 'Tiết lộ thông tin cá nhân',
    subTitle4: 'Quyền của bạn',
    subTitle5: 'Liên kết đến các trang web khác',
    subTitle6: 'Bảo mật và lưu giữ thông tin',
    subTitle7: 'Sửa đổi chính sách quyền riêng tư',
    subTitle8: 'Cách liên hệ với chúng tôi',
    title: 'Chính sách bảo mật',
    updateTime: 'Cập nhật: ngày 15 tháng 3 năm 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Chấp nhận tất cả',
    text: 'Để cải thiện trải nghiệm của bạn, chúng tôi sử dụng cookie để cá nhân hóa nội dung và dịch vụ.',
    title: 'Trang web này sử dụng cookie',
  },
  slogen: 'Trợ lý AI dễ dàng nhất',
  stripe: {
    contact: 'Hãy liên hệ với chúng tôi theo địa chỉ contact@noam.tools nếu có bất kỳ câu hỏi nào!',
    fail: {
      btn: 'Thử lại',
      text1: 'Chúng tôi không thể xử lý khoản thanh toán của bạn.',
      text2: 'Hãy kiểm tra kết nối của bạn và thử lại.',
      title: 'Thanh toán không thành công',
    },
    succ: {
      btn: 'Đi thôi',
      text1: 'Chào mừng đến với Noam,',
      text2: 'hãy bắt đầu!',
      title: 'Chúc mừng',
    },
  },
  terms: {
    block1: 'Cảm ơn bạn đã sử dụng Noam!',
    block10:
      '(a) Phí và Thanh toán. Bạn sẽ thanh toán tất cả các khoản phí ("Phí") theo giá cả và các điều khoản trên trang định giá hiện hành hoặc theo thỏa thuận bằng văn bản giữa chúng ta. Chúng tôi có quyền sửa các lỗi hoặc sai sót về giá ngay cả khi chúng tôi đã xuất hóa đơn hoặc nhận được khoản thanh toán. Bạn sẽ cung cấp thông tin thanh toán đầy đủ và chính xác, bao gồm phương thức thanh toán hợp lệ và được ủy quyền. Chúng tôi sẽ tính phí theo phương thức thanh toán của bạn định kỳ theo thỏa thuận, nhưng chúng tôi có thể thay đổi ngày thanh toán một cách hợp lý. Bạn ủy quyền cho Noam, các chi nhánh của Noam và bộ xử lý thanh toán bên thứ ba tính phí vào phương thức thanh toán của bạn. Nếu khoản thanh toán của bạn không thành công, chúng tôi sẽ cung cấp cho bạn thông báo bằng văn bản và có thể tạm dừng quyền truy cập vào Dịch vụ cho đến khi nhận được khoản thanh toán. Trừ khi có quy định khác trong thỏa thuận này, các khoản thanh toán sẽ không được hoàn lại.',
    block11:
      '(b) Thuế. Trừ khi có quy định khác, Phí không bao gồm bất kỳ khoản thuế, nghĩa vụ và đánh giá tương tự nào của liên bang, tiểu bang, địa phương và nước ngoài ("Thuế"). Bạn chịu trách nhiệm thanh toán tất cả các loại Thuế liên quan đến giao dịch mua hàng của mình, ngoại trừ Thuế dựa trên thu nhập ròng mà chúng tôi sẽ lập hoá đơn cho bạn. Bạn đồng ý thanh toán kịp thời các khoản Thuế đó và cung cấp cho chúng tôi các tuyên bố về quyền riêng tư có giá trị về mặt pháp lý cũng như nhận được sự đồng ý cần thiết để xử lý dữ liệu đó và bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành.',
    block12:
      '(c) Thay đổi giá. Chúng tôi có thể thay đổi giá bằng cách thông báo cho bạn thông qua tài khoản của bạn và/hoặc trang web của chúng tôi. Việc tăng giá sẽ có hiệu lực ngay sau khi đăng. Mọi thay đổi về giá sẽ áp dụng cho Phí cho tài khoản của bạn sau ngày thay đổi có hiệu lực.',
    block13:
      '(d) Tranh chấp và thanh toán quá hạn. Nếu bạn muốn tranh chấp bất kỳ khoản Phí hoặc Thuế nào, vui lòng liên hệ với contact@noam.tools trong vòng ba mươi (30) ngày kể từ ngày lập hóa đơn.',
    block14:
      '(e) Sử dụng miễn phí. Bạn không được tạo nhiều tài khoản để nhận tín dụng miễn phí. Nếu chúng tôi xác định rằng bạn không sử dụng các khoản tín dụng miễn phí một cách thiện chí, chúng tôi có thể tính phí tiêu chuẩn cho bạn hoặc ngừng cung cấp quyền truy cập vào Dịch vụ.',
    block15:
      '(a) Tính bảo mật. Bạn có thể có quyền truy cập vào thông tin bí mật của Noam. Bạn chỉ có thể sử dụng thông tin bí mật được cho phép theo các điều khoản này cho mục đích sử dụng Dịch vụ. Bạn không được tiết lộ thông tin bí mật cho bất kỳ bên thứ ba nào và bạn sẽ bảo vệ thông tin bí mật theo cách bảo vệ không kém gì việc bạn bảo vệ thông tin bí mật tương tự của chính mình, ít nhất là với sự cẩn thận hợp lý. Thông tin bí mật là thông tin không công khai được Noam chỉ định là bí mật hoặc cần được coi là bí mật một cách hợp lý trong các trường hợp, bao gồm phần mềm, thông số kỹ thuật và thông tin kinh doanh không công khai khác. Thông tin bí mật không bao gồm thông tin: (1) được công bố rộng rãi mà không có lỗi từ phía bạn; (2) bạn đã sở hữu nó mà không có bất kỳ nghĩa vụ bảo mật nào trước khi nhận nó theo các điều khoản này; (3) được bên thứ ba tiết lộ hợp pháp cho bạn mà không có bất kỳ nghĩa vụ bảo mật nào; hoặc (4) được bạn phát triển độc lập mà không sử dụng thông tin bí mật. Bạn có thể tiết lộ thông tin bí mật nếu luật pháp, tòa án hoặc lệnh khác của chính phủ yêu cầu, nhưng bạn phải cung cấp thông báo trước bằng văn bản cho Noam theo cách hợp lý và trong phạm vi có thể, thực hiện những nỗ lực hợp lý để hạn chế phạm vi tiết lộ, bao gồm cả việc hỗ trợ chúng tôi phản đối yêu cầu tiết lộ thông tin.',
    block16:
      '(b) An ninh. Bạn phải thực hiện các biện pháp hợp lý và phù hợp để giúp bảo vệ quyền truy cập và sử dụng Dịch vụ của bạn. Nếu bạn phát hiện bất kỳ lỗ hổng hoặc vi phạm nào liên quan đến việc bạn sử dụng Dịch vụ, bạn phải liên hệ kịp thời với Noam và cung cấp thông tin chi tiết về lỗ hổng hoặc vi phạm.',
    block17:
      '((c) Xử lý dữ liệu cá nhân. Nếu bạn sử dụng Dịch vụ để xử lý dữ liệu cá nhân, bạn phải cung cấp các tuyên bố về quyền riêng tư đầy đủ về mặt pháp lý và có được sự đồng ý cần thiết để xử lý dữ liệu đó và bạn đảm bảo với chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành .',
    block18:
      '(a) Chấm dứt; Đình chỉ. Các điều khoản này sẽ có hiệu lực kể từ lần đầu tiên bạn sử dụng Dịch vụ và sẽ tiếp tục có hiệu lực cho đến khi chấm dứt. Bạn có thể chấm dứt các điều khoản này bất cứ lúc nào vì bất kỳ lý do gì bằng cách ngừng sử dụng Dịch vụ và Nội dung. Chúng tôi có thể chấm dứt các điều khoản này nếu có thông báo trước vì bất kỳ lý do gì. Chúng tôi có thể chấm dứt ngay các điều khoản này bằng cách thông báo cho bạn nếu bạn vi phạm nghiêm trọng Mục 2 (Yêu cầu sử dụng), Mục 5 (Bảo mật, bảo mật và bảo vệ dữ liệu), Mục 8 (Giải quyết tranh chấp) hoặc Mục 9 (Điều khoản chung) hoặc nếu chúng tôi mối quan hệ với bất kỳ nhà cung cấp công nghệ bên thứ ba nào ngoài những thay đổi trong tầm kiểm soát của chúng tôi hoặc để tuân thủ luật pháp hoặc yêu cầu của chính phủ. Chúng tôi có thể tạm dừng quyền truy cập Dịch vụ của bạn nếu bạn không tuân thủ các điều khoản này hoặc nếu việc sử dụng của bạn gây rủi ro bảo mật cho chúng tôi hoặc bất kỳ bên thứ ba nào hoặc nếu chúng tôi nghi ngờ việc sử dụng của bạn là gian lận hoặc có thể khiến chúng tôi hoặc bất kỳ bên thứ ba nào phải chịu trách nhiệm pháp lý .',
    block19:
      '(b) Ảnh hưởng của việc chấm dứt. Sau khi chấm dứt, bạn sẽ ngừng sử dụng Dịch vụ và nhanh chóng trả lại hoặc hủy mọi thông tin bí mật theo chỉ dẫn của chúng tôi. Các quy định của các điều khoản này mà về bản chất sẽ có hiệu lực khi chấm dứt hoặc hết hạn, bao gồm nhưng không giới hạn ở Mục 3 và 5-9, sẽ vẫn có hiệu lực.',
    block2:
      'Các Điều khoản sử dụng này áp dụng cho việc bạn sử dụng các dịch vụ của Noam, bao gồm giao diện lập trình ứng dụng, phần mềm, công cụ, dịch vụ dành cho nhà phát triển, dữ liệu, tài liệu và trang web của chúng tôi (gọi chung là "Dịch vụ"). Bằng cách sử dụng Dịch vụ của chúng tôi, bạn đồng ý tuân theo các điều khoản này. Chính sách quyền riêng tư của chúng tôi giải thích cách chúng tôi thu thập và sử dụng thông tin cá nhân.',
    block20:
      '(a) Bồi thường. Bạn sẽ bảo vệ, bồi thường và giữ cho chúng tôi, các chi nhánh và nhân viên của chúng tôi không bị tổn hại trước mọi khiếu nại, tổn thất và chi phí (bao gồm cả phí luật sư) phát sinh từ hoặc liên quan đến việc bạn sử dụng Dịch vụ, bao gồm cả Nội dung, của bạn. việc sử dụng các sản phẩm hoặc dịch vụ liên quan đến Dịch vụ và việc bạn vi phạm các điều khoản này hoặc luật hiện hành.',
    block21:
      '(b) Tuyên bố từ chối trách nhiệm. Dịch vụ được cung cấp "nguyên trạng". Trong phạm vi được pháp luật cho phép, chúng tôi, các chi nhánh và người cấp phép của chúng tôi không đưa ra bất kỳ tuyên bố hay bảo đảm nào về Dịch vụ và không đưa ra bất kỳ bảo đảm nào, bao gồm nhưng không giới hạn ở các bảo đảm về khả năng bán được, sự phù hợp cho một mục đích cụ thể, chất lượng đạt yêu cầu, không vi phạm và sự tận hưởng yên tĩnh cũng như mọi bảo đảm phát sinh trong quá trình giao dịch hoặc sử dụng giao dịch. Chúng tôi không đảm bảo rằng Dịch vụ sẽ không bị gián đoạn, chính xác hoặc không có lỗi hoặc bất kỳ Nội dung nào sẽ được bảo mật, không bị mất hoặc không bị thay đổi.',
    block22:
      '(c) Giới hạn trách nhiệm pháp lý. Chúng tôi, các chi nhánh hoặc người cấp phép của chúng tôi sẽ không chịu trách nhiệm về bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, do hậu quả hoặc trừng phạt nào, bao gồm mất lợi nhuận, mất danh tiếng, mất sử dụng, mất dữ liệu hoặc các tổn thất vô hình khác, ngay cả khi chúng tôi đã được thông báo về khả năng xảy ra những thiệt hại như vậy. Theo các điều khoản này, tổng trách nhiệm pháp lý của chúng tôi sẽ không vượt quá Phí bạn đã trả cho Dịch vụ trong mười hai (12) tháng trước yêu cầu bồi thường hoặc một trăm đô la Mỹ ($100), tùy theo số tiền nào lớn hơn. Những hạn chế trong phần này áp dụng trong phạm vi tối đa được pháp luật hiện hành cho phép.',
    block23:
      'Các Điều khoản và việc bạn sử dụng Dịch vụ chịu sự điều chỉnh của luật pháp Hoa Kỳ, ngoại trừ các quy định về xung đột luật pháp của Hoa Kỳ. Việc bạn sử dụng Ứng dụng cũng có thể phải tuân theo luật pháp địa phương, tiểu bang, quốc gia hoặc quốc tế khác. Mọi hành động pháp lý hoặc thủ tục tố tụng liên quan đến các Điều khoản này sẽ được đưa ra độc quyền tại các tòa án của Hoa Kỳ và bạn đồng ý tuân theo thẩm quyền cá nhân của các tòa án đó.',
    block24:
      '(a) Mối quan hệ của các Bên. Noam và bạn là những nhà thầu độc lập và không bên nào có quyền đại diện hoặc ràng buộc bên kia hoặc đảm nhận nghĩa vụ cho bên kia mà không có sự đồng ý trước bằng văn bản của bên kia.',
    block25:
      '(b) Sử dụng thương hiệu. Bạn không được sử dụng tên, logo hoặc nhãn hiệu của Noam hoặc bất kỳ chi nhánh nào của nó mà không có sự đồng ý trước bằng văn bản của chúng tôi.',
    block26:
      'Nếu bất kỳ điều khoản nào trong các điều khoản này được xác định là không hợp lệ, bất hợp pháp hoặc không thể thi hành thì các điều khoản còn lại sẽ vẫn có đầy đủ hiệu lực.',
    block3:
      'Nếu bạn dưới 18 tuổi, bạn phải được sự cho phép của cha mẹ hoặc người giám hộ hợp pháp để sử dụng Dịch vụ. Nếu bạn đang sử dụng Dịch vụ thay mặt cho một cá nhân hoặc tổ chức khác, bạn phải được ủy quyền để thay mặt họ chấp nhận các điều khoản này. Bạn phải cung cấp thông tin chính xác và đầy đủ để đăng ký tài khoản. Bạn không được cung cấp thông tin xác thực truy cập hoặc tài khoản của mình cho bất kỳ ai bên ngoài tổ chức của bạn và bạn chịu trách nhiệm về mọi hoạt động được thực hiện bằng thông tin xác thực của mình.',
    block4:
      '(a) Sử dụng Dịch vụ. Bạn có thể truy cập Dịch vụ theo các điều khoản này và chúng tôi cấp cho bạn quyền không độc quyền để sử dụng Dịch vụ. Khi sử dụng Dịch vụ, bạn sẽ tuân thủ các điều khoản này và tất cả các luật hiện hành. Chúng tôi và các chi nhánh của chúng tôi sở hữu mọi quyền, quyền sở hữu và lợi ích đối với Dịch vụ.',
    block5:
      '(b) Phản hồi. Chúng tôi hoan nghênh phản hồi, nhận xét, ý tưởng, đề xuất và cải tiến. Nếu bạn cung cấp bất kỳ nội dung nào như vậy, chúng tôi có thể sử dụng nội dung đó mà không có bất kỳ hạn chế nào và không phải bồi thường cho bạn.',
    block6:
      '(c) Hạn chế. Bạn không được: (1) sử dụng Dịch vụ theo cách xâm phạm, chiếm dụng hoặc vi phạm bất kỳ quyền nào của người khác; (2) đảo ngược kỹ thuật, dịch ngược, tháo rời, dịch hoặc cố gắng khám phá mã nguồn hoặc các thành phần cơ bản của mô hình, thuật toán và hệ thống Dịch vụ (trừ khi những hạn chế đó bị cấm theo luật hiện hành); (3) sử dụng đầu ra của Dịch vụ để phát triển các mô hình cạnh tranh với Noam; (4) trích xuất dữ liệu hoặc đầu ra từ Dịch vụ bằng bất kỳ phương pháp lập trình hoặc tự động nào trừ khi được API cho phép, bao gồm cả việc thu thập dữ liệu, thu thập web hoặc trích xuất dữ liệu web; (5) thể hiện đầu ra của Dịch vụ là được tạo một cách giả tạo trong khi thực tế không phải vậy, hoặc vi phạm chính sách sử dụng của chúng tôi; (6) mua, bán hoặc chuyển giao khóa API mà không có sự đồng ý trước của chúng tôi; hoặc (7) cung cấp cho chúng tôi bất kỳ thông tin cá nhân nào của trẻ em dưới 13 tuổi hoặc độ tuổi chấp thuận kỹ thuật số hiện hành. Bạn sẽ tuân thủ mọi giới hạn tỷ lệ và các yêu cầu khác trong tài liệu của chúng tôi.',
    block7:
      '(d) Dịch vụ của bên thứ ba. Mọi phần mềm, dịch vụ hoặc sản phẩm khác của bên thứ ba liên quan đến Dịch vụ đều chịu sự điều chỉnh của các điều khoản riêng và chúng tôi không chịu trách nhiệm về các sản phẩm của bên thứ ba.',
    block8:
      '(a) Nội dung của bạn. Bạn có thể cung cấp đầu vào ("Đầu vào") cho Dịch vụ và nhận đầu ra ("Đầu ra") được tạo và trả về dựa trên Đầu vào (gọi chung là "Nội dung"). Giữa các bên và trong phạm vi được luật pháp hiện hành cho phép, bạn sở hữu tất cả Thông tin đầu vào. Noam có thể sử dụng Nội dung để cung cấp và duy trì Dịch vụ, tuân thủ luật pháp hiện hành và thực thi các chính sách của chúng tôi. Bạn chịu trách nhiệm về Nội dung, bao gồm cả việc đảm bảo rằng Nội dung đó không vi phạm bất kỳ luật hiện hành nào hoặc các điều khoản này.',
    block9:
      '(b) Độ chính xác. Trí tuệ nhân tạo và học máy đang là những lĩnh vực nghiên cứu đang phát triển nhanh chóng. Chúng tôi không ngừng nỗ lực cải thiện dịch vụ của mình để làm cho dịch vụ đó chính xác hơn, đáng tin cậy hơn, an toàn hơn và mang lại lợi ích hơn. Do tính chất xác suất của học máy, việc sử dụng dịch vụ của chúng tôi có thể dẫn đến kết quả đầu ra không chính xác trong một số trường hợp nhất định, điều này có thể không phản ánh chính xác các cá nhân, địa điểm hoặc sự kiện thực tế. Bạn nên đánh giá một cách thích hợp độ chính xác của bất kỳ kết quả đầu ra nào dựa trên trường hợp sử dụng của mình, bao gồm cả việc xem xét các kết quả đầu ra theo cách thủ công.',
    subTitle1: 'Đăng ký và truy cập',
    subTitle10: 'Tính tách rời',
    subTitle2: 'Yêu cầu sử dụng',
    subTitle3: 'Nội dung',
    subTitle4: 'Phí và thanh toán',
    subTitle5: 'Bảo mật, bảo mật và bảo vệ dữ liệu',
    subTitle6: 'Thời hạn và chấm dứt',
    subTitle7: 'Bồi thường; Tuyên bố miễn trừ trách nhiệm; Giới hạn trách nhiệm pháp lý',
    subTitle8: 'Luật điều chỉnh và thẩm quyền',
    subTitle9: 'Điều khoản chung',
    title: 'Điều khoản dịch vụ',
    updateTime: 'Cập nhật: ngày 15 tháng 3 năm 2024',
  },
  trialModal: {
    btn: 'Bắt đầu ngay bây giờ（Chỉ $0,99）',
    chat: 'Trò chuyệnPDF',
    desc1: 'Sau khi dùng thử, bạn sẽ phải trả phí hàng năm là $180',
    desc2: 'Hủy bất cứ lúc nào',
    focus: 'Lấy nét',
    hover: 'Dịch di chuột',
    image: 'Dịch hình ảnh',
    installBtn: 'Cài đặt ngay để nhận quà',
    memo: 'Quản lý yêu thích',
    pdf: 'Dịch song song PDF',
    realtime: 'Dịch tức thời',
    select: 'Dịch chọn từ',
    title: 'Quà chào mừng',
    trialText: 'Dùng thử 7 ngày',
    video: 'Dịch video',
    videoSummary: 'Tóm tắt video',
    web: 'Dịch song song',
    webSummary: 'Tóm tắt web',
  },
  upgradeModal: {
    btn: 'Nâng cấp ngay',
    chat: {
      free: 'Bạn đã đạt đến giới hạn ChatPDF',
      pro: 'Bạn đã đạt đến giới hạn ChatPDF cho tháng này',
    },
    contactStr:
      'Vui lòng đợi lần đặt lại tiếp theo hoặc liên hệ với chúng tôi theo địa chỉ contact@noam.tools.',
    limitAlertStr: 'Bạn đã đạt đến giới hạn gói miễn phí của mình. Nâng cấp để tiếp tục.',
    more: 'Xem kế hoạch',
    pageLimit: { free: 'Bạn đã đạt đến giới hạn trang PDF' },
    title: 'Lời nhắc nhở',
    upgradeAlertStr: 'Bạn đã đạt đến giới hạn gói miễn phí của mình. Nâng cấp để tiếp tục.',
    upload: {
      free: 'Bạn đã đạt đến giới hạn dịch PDF của mình',
      pro: 'Bạn đã đạt đến giới hạn bản dịch PDF trong tháng này',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Tham gia ngay',
      label: 'Lợi ích của người dùng mới:',
      remain: 'Chỉ còn 30',
      text: 'Chỉ có 100 điểm có sẵn! Hãy sở hữu ngay!',
    },
  },
}

export default TRANSLATION
