const TRANSLATION = {
  buyModal: {
    afterFree: '免費試用後',
    btn: '免費試用',
    freeTrial: '3 天免費試用',
    monthLabel: '月',
    selectLabel: '選擇一個計劃',
    title: '訂閱計劃',
    yearLabel: '年',
  },
  contact: {
    block1: '如需客製化、疑問或建議，請透過 contact@noam.tools 與我們聯繫。我們會及時回覆。',
    block2: '請在 Google Apps Extension 商店中為我們評分 ★★★★★ 來支持我們。',
    block3: '透過分享此擴充功能來幫助其他人：',
    title: '聯絡我們',
  },
  help: {
    AquickOverviewOfNoam: '諾姆快速概述',
    ChatPDF: '聊天PDF',
    ChatPDF2: '聊天PDF',
    FavoritesManagement: '收藏管理',
    Fixedplugin: '固定插件',
    Focusing: '聚焦',
    Free: '自由的',
    FreeTrialBenefits: '免費試用的好處',
    GoWithWikipedia: '使用維基百科',
    GoWithYouTube: '與 YouTube 一起使用',
    HoverTranslation: '懸停翻譯',
    Howtouse: '如何使用',
    ImageTranslation: '影像翻譯',
    InstantTranslation: '即時翻譯',
    Membership: '會員資格',
    NoamHelp: 'Noam幫你翻譯總結',
    PDFparallelTranslation: 'PDF 平行翻譯',
    ParallelTranslation: '平行翻譯',
    SkipAllSteps: '跳過所有步驟',
    Summary: '概括',
    Translation: '翻譯',
    TryItForFree: '免費試用',
    Video: '影片',
    VideoSummary: '影片摘要',
    VideoTranslation: '影片翻譯',
    WebSummary: '網路摘要',
    WordSelectionTranslation: '選詞翻譯',
    benefits: '我們還有福利等著你！',
    dayFree: '3 天免費試用',
    done: '完畢',
    next: '下一個',
    pin: 'Pin Noam 更方便訪問～～',
    planIsNotExist: '計劃不可用',
    startWiki: '讓我們從維基媒體開始吧！',
    startYoutube: '讓我們從 YouTube 開始吧！',
    try: '嘗試一下',
    tryNow: '現在就嘗試一下吧！',
  },
  home: {
    PDFTranslate: {
      desc: '雙語翻譯+聊天PDF',
      sub1: { desc: '雙語閱讀可讓您並排比較原文和譯文，提升閱讀效率。', title: '雙語閱讀' },
      sub2: { desc: 'AI根據PDF內容進行分析總結，並解答您的問題。', title: '聊天PDF' },
      sub3: { desc: 'AI 支援的 PDF 翻譯可提高準確性和效率。', title: '準確性和效率' },
      title: 'PDF翻譯',
    },
    aiAssistant: {
      desc: '讓網路翻譯更聰明、更便捷',
      panel1: {
        desc: '雙語交叉引用翻譯可幫助您將原文與譯文進行比較，以便您更好地理解並提高閱讀效率。',
        title: '雙語翻譯',
      },
      panel2: {
        left: { desc: '瀏覽網頁時，將滑鼠停留在需要翻譯的內容上即可查看翻譯。', title: '懸停翻譯' },
        right: { desc: '瀏覽網頁時，選擇要翻譯的文字即可查看翻譯。', title: '文字選擇翻譯' },
      },
      panel3: {
        one: { desc: '隨時隨地翻譯總結網頁和影片內容，體驗更流暢！', title: '點選' },
        three: {
          desc: '人工智慧驅動的大語言模型可確保翻譯的準確性和一致性，從而增強閱讀體驗。',
          title: '人工智慧驅動',
        },
        two: {
          desc: '多語言全覆蓋，雙語無障礙閱讀，一鍵翻譯－體驗不一樣！',
          title: '更全面、更便利',
        },
      },
      panel4: {
        tab1: {
          desc: '您的效率專家 - Noam 使用 ChatGPT 翻譯和總結 YouTube 影片內容，以節省您的時間！',
          key: '影片',
          title: '影片翻譯及總結',
        },
        tab2: { desc: '點選可在瀏覽網頁時翻譯圖像。', key: '影像', title: '影像翻譯' },
        tab3: {
          desc: '即時提取網頁關鍵資訊並提供摘要，提高您的閱讀效率。',
          key: '網路',
          title: '網路翻譯與摘要',
        },
        tag1: '簡單的',
        tag2: '高效率的',
        tag3: '易於理解',
      },
      title: '人工智慧助手',
    },
    getStart: '開始使用',
    getStartedForFree: '免費開始使用',
    howItWork: {
      desc: 'Noam整合AI模型，為使用者提供跨語言網頁翻譯和摘要、PDF和圖片翻譯、影片翻譯和摘要的智慧體驗',
      title: '它是如何運作的',
    },
    lastBlock: { desc: '更好的AI翻譯，閱讀體驗更流暢' },
    top: {
      desc: 'Noam 是一款免費的 AI 瀏覽器擴充程序，可協助您翻譯和總結網頁、影片和 PDF 內容。',
      title1: '一個魔法',
      title2: '一鍵點擊',
    },
    userReviews: { desc: 'Chrome 網路應用程式商店的五星評論', title: '用戶評論' },
  },
  installTips: {
    btn: '立即嘗試',
    text: '安裝「Noam瀏覽器外掛」即可解鎖更多功能！網路交叉引用、\\n 網路影片摘要等等！',
  },
  locale: {
    af: '南非荷蘭語',
    am: '阿姆哈拉語',
    ar: '阿拉伯',
    as: '阿薩姆語',
    auto: '自動偵測',
    az: '亞塞拜然語',
    ba: '巴什基爾語',
    bg: '保加利亞語',
    bn: '孟加拉語',
    bo: '藏',
    bs: '波士尼亞語',
    ca: '加泰隆尼亞語',
    cs: '捷克語',
    cy: '威爾斯語',
    da: '丹麥語',
    de: '德文',
    dsb: '下索布語',
    dv: '迪維希語',
    el: '希臘文',
    en: '英語',
    es: '西班牙語',
    et: '愛沙尼亞語',
    eu: '巴斯克',
    fa: '波斯語',
    fi: '芬蘭',
    fil: '菲律賓人',
    fj: '斐濟語',
    fr: '法語',
    frCA: '法語（加拿大）',
    ga: '愛爾蘭語',
    gl: '加利西亞語',
    gom: '孔卡尼',
    gu: '古吉拉特語',
    ha: '豪薩語',
    he: '希伯來文',
    hi: '印地語',
    hr: '克羅埃西亞語',
    hsb: '上索布語',
    ht: '海地克里奧爾語',
    hu: '匈牙利',
    hy: '亞美尼亞語',
    id: '印尼',
    ig: '伊博語',
    ikt: '伊努因納克頓',
    is: '冰島語',
    it: '義大利語',
    iu: '因紐特語',
    iuLatn: '因紐特語（拉丁語）',
    ja: '日本人',
    ka: '喬治亞語',
    kk: '哈薩克語',
    km: '高棉語',
    kmr: '庫德語（北部）',
    kn: '卡納達語',
    ko: '韓國人',
    ku: '庫德語（中）',
    ky: '吉爾吉斯斯坦',
    ln: '林加拉語',
    lo: '寮國',
    lt: '立陶宛語',
    lug: '幹達',
    lv: '拉脫維亞語',
    lzh: '中文（文學）',
    mai: '邁蒂利',
    mg: '馬爾加什',
    mi: '毛利人',
    mk: '馬其頓語',
    ml: '馬拉雅拉姆語',
    mnCyrl: '蒙古語（西里爾字母）',
    mnMong: '蒙古語（繁體）',
    mr: '馬拉地語',
    ms: '馬來語',
    mt: '馬爾他語',
    mww: '苗族',
    my: '緬甸（緬語）',
    nb: '挪威',
    ne: '尼泊爾語',
    nl: '荷蘭語',
    noSearchLang: '不支援的語言',
    nso: '塞索托薩萊博阿',
    nya: '尼亞尼亞',
    or: '奧迪亞',
    otq: '克雷塔羅奧托米',
    pa: '旁遮普語',
    pl: '拋光',
    placeholder: '搜尋',
    prs: '達裡語',
    ps: '普什圖語',
    pt: '葡萄牙語（巴西）',
    ptPT: '葡萄牙語（葡萄牙）',
    ro: '羅馬尼亞語',
    ru: '俄文',
    run: '倫迪',
    rw: '基尼亞盧安達語',
    sd: '信德語',
    si: '僧伽羅語',
    sk: '斯洛伐克語',
    sl: '斯洛維尼亞語',
    sm: '薩摩亞人',
    sn: '紹納語',
    so: '索馬利亞',
    sq: '阿爾巴尼亞語',
    srCyrl: '塞爾維亞語（西里爾字母）',
    srLatn: '塞爾維亞語（拉丁語）',
    st: '塞索托語',
    sv: '瑞典',
    sw: '斯瓦希里語',
    ta: '泰米爾語',
    te: '泰盧固語',
    th: '泰國',
    ti: '提格里尼亞',
    tk: '土庫曼語',
    tlhLatn: '克林貢語（拉丁文）',
    tlhPiqd: '克林貢語 (pIqaD)',
    tn: '茨瓦納語',
    to: '東加',
    tr: '土耳其',
    tt: '韃靼人',
    ty: '大溪地',
    ug: '維吾爾族',
    uk: '烏克蘭',
    ur: '烏爾都語',
    uz: '烏茲別克語（拉丁語）',
    vi: '越南語',
    xh: '科薩語',
    yo: '約魯巴語',
    yua: '尤卡坦瑪雅',
    yue: '粵語（繁體）',
    zh: '中國人',
    zhHans: '簡體中文',
    zhHant: '繁體中文',
    zu: '祖魯語',
  },
  login: {
    and: '和',
    btn: '使用谷歌登入',
    btn1: '立即登入',
    desc: '登入以解鎖更多體驗',
    policy: '隱私權政策',
    terms: '使用條款',
    tips: '註冊即表示我同意',
    title: '歡迎來到諾姆',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: '跟隨', twitter: '嘰嘰喳喳' },
    legal: { policy: 'ERROR: [Privacy Policy]', terms: '服務條款', title: '合法的' },
    product: {
      image: '影像翻譯',
      pdf: 'PDF翻譯',
      title: '產品',
      video: '影片翻譯及總結',
      web: '網路翻譯與摘要',
    },
    resources: { title: '資源' },
  },
  pageHeader: {
    contact: '接觸',
    help: '幫助',
    logout: '退出',
    logoutSucc: '已退出。',
    pricing: '定價',
    settings: 'ERROR: [Settings]',
    signUp: '登入',
  },
  pdf: {
    autoDetect: '自動偵測',
    cancelBtn: '取消',
    cancelUpload: '取消',
    chatPdf: {
      Search: '搜尋',
      cancel: '取消',
      chatTitle: '歡迎來到諾姆！以下是您的 PDF 的摘要：',
      clear: '清除',
      clearContent: '這將開始一個新的對話。當前對話不會被儲存。你確定嗎？',
      clearDialogTitle: '請確認',
      clearSucc: '已清除',
      copy: '複製',
      copySucc: '已複製',
      delete: '刪除',
      deleteCancel: '取消',
      deleteContent: '此 PDF 一旦刪除就無法恢復。你確定嗎？',
      deleteOk: '刪除',
      deleteSuccess: '已刪除',
      deleteTitle: '確認刪除',
      expand: '坍塌',
      historyTitle: '歷史',
      listError: '無法載入閱讀清單。請稍後重試，或聯絡支援人員。',
      loading: '載入中...',
      noDataDesc: '還沒有歷史記錄。',
      noMore: '沒有更多內容了。',
      placeholder: '詢問有關此 PDF 的任何問題',
      summaryTitle: '您可能對這些問題感興趣：',
    },
    collectionEmpty: '你的收藏是空的。',
    collectionTab: '筆記',
    dataLoading: '載入中...',
    dialogContent: '您確定要刪除此內容嗎？',
    dialogTitle: '請確認',
    download: {
      both: '雙語',
      btn: '節省',
      cancel: '關閉此視窗將取消 PDF 下載。您確定要取消嗎？',
      desc: '儲存到設備：將翻譯下載為圖像；儲存並列印：以原始格式下載翻譯，並支援複製。',
      download: '下載',
      downloadMsg1: 'PDF翻譯進度：',
      downloadMsg2: '預計剩餘時間：',
      downloadSucc: 'PDF 已準備好！單擊儲存。',
      downloadTip: '下載 PDF。不要關閉此視窗以避免下載錯誤。',
      error: '保存失敗。請嘗試列印。',
      fileName: '檔案名稱：',
      fileType: '文件類型：',
      loading: '儲存...',
      loading2: '載入中...',
      minute: '分鐘...',
      msg1: '本文檔中的某些翻譯比原始文字長，並且可能在下載的檔案中重疊。我們建議您在網頁上閱讀。',
      msg2: '1. 翻譯尚未完成。翻譯完成後請下載。',
      msg3: '2.有些翻譯太長。在網頁上閱讀以避免文字重疊。',
      msg4: '翻譯任務正在進行中。請在完成後重試。',
      progress: '翻譯 PDF。進度：{{progress}}，預計剩餘時間：{{分鐘}} 分鐘...',
      save: '儲存到設備',
      savePrint: '儲存並列印',
      success: '已下載',
      trans: '僅翻譯',
      transContinue: '繼續翻譯',
    },
    downloadBtn: '下載',
    dragTipModal: {
      black: '空間',
      leftKey: '左邊按鈕',
      ok: '知道了',
      press: '按',
      tips: '拖曳並查看。',
    },
    entry: 'PDF 文件',
    fileErrMsg: '文件讀取失敗。請稍後重試，或聯絡支援人員。',
    fileMsg: '目前檔案大小：',
    fileMsg2: '請上傳小於 300MB 的檔案。',
    freeBannerBtn: '升級',
    freeBannerTips: '免費 PDF 翻譯已達到限制',
    guide: {
      placeholder: '將 PDF 連結貼到此處',
      start: '開始翻譯',
      text1: '雙語閱讀',
      text2: '雙語閱讀，並排比較原文和譯文，提高效率。',
      text3: '與您的 PDF 聊天',
      text4: '人工智慧驅動的分析和總結。提出有關 PDF 的問題並立即獲得答案。',
      text5: '準確且高效',
      text6: 'AI 支援的 PDF 翻譯可提高準確性和效率。',
      title1: '雙語翻譯',
      title2: '與您的 PDF 聊天',
      toast: '請輸入以 .pdf 結尾的有效在線 PDF 鏈接',
    },
    hasTransTask: '翻譯任務正在進行中。請稍等。',
    hoverTrans: '懸停翻譯段落',
    initial: '翻譯 PDF',
    installText: '您日常翻譯的一站式解決方案。',
    installText2: '請先安裝擴充',
    installTips: '安裝擴充功能以獲得更多 PDF 翻譯和更流暢的網站翻譯：',
    kw: '主食',
    link: '關聯',
    listError: '無法載入 PDF。請稍後重試，或聯絡支援人員。',
    memoTotalCount: '共 1 項',
    new: '新文件',
    noId: '未找到文件 ID。',
    notPdf: '僅限 PDF 檔案。',
    okBtn: '刪除',
    okBtn2: '知道了',
    original: '原來的',
    parallel: '平行線',
    parseErrDialogContent: '此 PDF 連結無法直接翻譯。請下載 PDF 並上傳進行翻譯。',
    parseErrTips: '解析文件失敗',
    parseFileToast: '無法解析文件。請稍後重試，或聯絡支援人員。',
    pdfLoading: '正在解析...',
    searchPlaceholder: '搜尋',
    selectTrans: '選擇要翻譯的文本',
    shareBtn: '分享',
    shareBtnText1: '產生分享連結',
    shareBtnText2: '更新分享內容',
    shareModal: {
      cancel: '取消',
      copy: '複製',
      copySucc: '連結已複製',
      custom: '客製化：',
      errMsg: '所選內容尚未完全翻譯。分享前請翻譯一下。',
      gen: '分享',
      modify: '編輯',
      notSupport: '目前不支援該語言。',
      page: '頁數',
      pageNuErr: '請輸入有效的頁碼。',
      range: '範圍：',
      remain: '其餘的',
      remain1: '預計總時間：',
      search: '搜尋',
      settings: '分享設定：',
      shareSetting: '共享設定',
      shareText: '分享',
      shareTips: '與您的朋友分享翻譯後的 PDF 連結：',
      sourceLang: '從：',
      target: '到：',
      text1: '共享設定',
      text2: '產生分享連結',
      totalPage: '共 1 頁',
      trans: '翻譯',
      translateTips: '提示：如果翻譯需要一段時間，您可以關閉此視窗並稍後共用。',
      translating: '正在翻譯 PDF...',
      update: '更新分享連結',
    },
    shareMsg1: '已產生分享連結。',
    shareMsg2: '共享內容已更新。',
    shareMsg3: '無法產生分享連結。請稍後重試，或聯絡支援人員。',
    shareMsg4: '無法更新共享內容。請稍後重試，或聯絡支援人員。',
    shareTips: '您的 PDF 翻譯已準備就緒！與您的朋友分享。',
    start: '開始免費試用',
    thumbnail: '目錄',
    toast1: '正在加載 PDF。請稍後重試。',
    toast2: 'PDF 內容太大。目前不支援對話框。',
    toast3: '正在下載。請下載完成後重試。',
    toolbar: { Adjust: '調整', autoFit: '自動調整' },
    trans: '翻譯',
    transSucc: '翻譯成功！',
    unLoginToast: '請登入才能使用此功能。',
    uploadBtn: '上傳',
    uploadErr: '文件上傳失敗。請稍後重試，或聯絡支援人員。',
    uploadMsg1: '將您的文件拖放到此處。',
    uploadMsg2: '支援的文件類型：PDF |  最大檔案大小：100MB',
    uploadMsg3: '按一下此處選擇或拖放檔案。',
    uploading: '上傳中',
    uploadingMsg: '正在上傳。請稍等。',
    uploadingMsg1: '文件正在上傳。請稍後重試。',
    uploadingMsg2: '目前正在轉換檔案。請稍後重試。',
    uploadingMsg3: '轉換進度請求失敗。請稍後重試，或聯絡支援人員。',
    uploadingMsg4: 'Noam 無法存取本機檔案。請手動上傳 PDF 進行翻譯。',
    uploadingMsg5: '檢索文件 URL 失敗。請稍後重試，或聯絡支援人員。',
    video: '影片',
    vipBannerBtn: '升級',
    vipBannerTips: '您已達到本月的 PDF 翻譯限制。',
    webTrans: '完整的交叉參考',
  },
  pricing: {
    FAQ: '常問問題',
    FQAObj: {
      a1: '是的，Noam 提供您可以立即使用的免費方案。',
      a2: '我們提供三種會員方案：Pro、Pro+ 和 Enterprise。您可以選擇按月或按年付款。按年計費可以節省大量費用。',
      a3: '兩個方案均提供所有功能。主要差異在於成本：年度訂閱整體成本較低。包月訂閱按月計費，而包年套餐則每年計費一次。',
      a4: '您可以隨時在帳戶設定中取消訂閱。',
      q1: '有免費方案嗎？',
      q2: '有哪些定價方案和付款方式可供選擇？',
      q3: '每月訂閱與每年訂閱有什麼不同？',
      q4: '我該如何取消我的訂閱？',
    },
    around: '大約',
    billedMonthly: '按月計費',
    billedYearly: '按年計費',
    day: '天',
    desc: '比較計劃並選擇最佳的',
    detailedCompare: '比較計劃',
    discount: '20% 折扣',
    enterprise: {
      benefit1: '優先客戶支持',
      benefit2: '多用戶',
      benefit3: '量身訂製的解決方案',
      benefit5: '專屬客戶經理',
      btn: '聯絡我們',
      recommendText: '客製化方案（≥3人）',
      talkToUs: '聯絡我們',
    },
    free: {
      benefit1: '即時翻譯100次/天',
      benefit2: '網站翻譯及摘要',
      benefit3: '30 影像翻譯',
      benefit4: '影片翻譯及總結',
      benefit5: '10 個 PDF 翻譯',
      benefit6: '5,000 個代幣',
      btn: '免費試用',
      recommendText: '免費試用',
    },
    imageTranslation: '影像翻譯',
    month: '月',
    monthly: '每月',
    pageTitle: '定價',
    pdf: { chat: 'PDF 聊天查詢', maxPage: '最大限度。每個 PDF 的頁數', translation: 'PDF翻譯' },
    preMonth: '每月',
    pro: {
      benefit1: '優先客戶支持',
      benefit2: '1,000,000 個代幣/月',
      benefit3: '網站翻譯及摘要',
      benefit4: '200 影像翻譯',
      benefit5: '影片翻譯及總結',
      benefit6: '200 個 PDF 翻譯',
      btn: '開始使用',
      recommendText: '最受歡迎',
    },
    proPlus: {
      benefit1: '優先客戶支持',
      benefit2: '3,000,000 個代幣/月',
      benefit3: '網站翻譯及摘要',
      benefit4: '500 影像翻譯',
      benefit5: '影片翻譯及總結',
      benefit6: '500 個 PDF 翻譯',
      recommendText: '最超值',
    },
    video: { summary: '影片摘要', translation: '影片翻譯' },
    web: {
      contrastiveTranslation: '雙語翻譯',
      dictionaryTranslation: '選擇翻譯',
      hoverTranslation: '懸停翻譯',
      instantTranslation: '即時翻譯',
      summary: '網頁摘要',
    },
    yearly: '每年',
  },
  privacy: {
    block1:
      'Noam（「Noam」、「我們」、「我們的」或「我們的」）尊重您的隱私，並致力於保護我們從您那裡獲得的或關於您的任何資訊。本隱私權政策描述了我們在您使用我們的網站、應用程式和服務（統稱為「服務」）時收集或使用個人資訊的做法。本隱私權政策不適用於我們代表我們的業務產品客戶處理的內容，例如我們的 API。這些數據的使用須遵守我們的客戶協議，其中涵蓋了這些產品的存取和使用。',
    block10:
      '我們實施商業上合理的技術、管理和組織措施，以保護線上和線下個人資訊免遭遺失、濫用以及未經授權的存取、揭露、變更或敵對行為。然而，任何網路或電子郵件傳輸都不能保證完全的安全性或準確性。特別是，發送給我們或我們發送的電子郵件可能不安全。因此，您在決定透過服務或電子郵件向我們發送哪些訊息時應特別小心。此外，我們對服務中的隱私設定或安全措施或第三方網站的安全設定或安全措施的任何阻礙不承擔任何責任。我們將在為您提供服務或其他合法商業目的（例如解決爭議、安全原因或遵守我們的法律義務）所需的時間內保留您的個人資訊。我們保留個人資訊的時間長短將取決於許多因素，例如資訊的數量、性質、敏感性、未經授權使用或揭露的潛在風險、我們處理資訊的目的以及其他具有法律約束力的考慮因素。',
    block11:
      '我們可能會不時更新本隱私權政策。當我們這樣做時，我們將在此頁面上發布更新版本，除非適用法律另有要求。',
    block12: '如果您對本隱私權政策有任何未解答的問題或疑慮，請聯絡我們的客戶服務。電子郵件：',
    block2: '我們收集以下與您相關的個人資訊（「個人資訊」）：',
    block3: '您提供的資訊：當您建立帳戶以使用我們的服務或與我們溝通時，我們會收集以下個人資訊：',
    block4:
      '在您使用服務時自動接收的個人資訊：當您存取、使用服務或與服務互動時，我們會收到以下有關您的存取、使用或互動的資訊（「技術資訊」）：',
    block5: '我們可能將個人資訊用於以下目的：',
    block6:
      '摘要或去識別化信息：我們可能會匯總或去識別化個人信息，以防止將其重新用於識別目的，並使用此類信息來分析我們服務的有效性、改進和添加我們服務的功能、進行研究以及用於類似目的。此外，我們可能會分析我們服務使用者的一般行為和特徵，並與第三方共享匯總資訊、發布此類匯總資訊或將其公開。我們可能會透過服務、cookie 以及本隱私權政策中所述的其他方式收集匯總資訊。我們將以匿名或去識別化的形式維護和使用去識別化訊息，除非法律要求，否則我們不會嘗試重新識別此類資訊。',
    block7: '在某些情況下，我們可能會向第三方提供您的個人信息，恕不另行通知，除非法律要求：',
    block8:
      '根據您所在的地理位置，歐洲經濟區、英國和全球範圍內的個人可能對其個人資訊擁有某些法定權利。例如，您可能有權：',
    block9:
      '本服務可能包含非 Noam 運營或控制的其他網站的鏈接，包括社交媒體服務（「第三方網站」）。您與第三方網站分享的資訊將受第三方網站的特定隱私權政策和服務條款的約束，而不是受本隱私權政策的約束。我們提供這些連結是為了方便，並不代表我們認可或審查這些網站。請直接聯絡第三方網站以取得有關其隱私權政策和條款的資訊。',
    subBlock1:
      '帳戶資訊：當您在我們的平台上建立帳戶時，我們會收集與您的帳戶相關的信息，包括您的姓名、聯絡資訊、帳戶憑證、支付卡資訊和交易記錄（「帳戶資訊」）。',
    subBlock10: '提供、管理、維護和/或分析服務；',
    subBlock11: '與您溝通；',
    subBlock12: '開發新產品和服務；',
    subBlock13: '防止詐欺、犯罪活動或濫用我們的服務，並保護我們的 IT 系統、架構和網路的安全；',
    subBlock14: '進行業務轉讓；',
    subBlock15:
      '遵守法律義務和法律程序，保護我們以及我們的附屬公司、您或其他第三方的權利、隱私、安全或財產。',
    subBlock16:
      '供應商和服務提供者：為了協助我們滿足業務營運需求並執行某些服務和功能，我們可能會向供應商和服務提供者提供個人資訊，包括託管服務提供者、雲端服務提供者和其他資訊技術服務提供者、電子郵件通訊軟體以及網路分析服務提供者等。',
    subBlock17:
      '業務轉讓：如果我們進行策略性交易、重組、破產、破產接管或將服務轉移給其他提供者（統稱為「交易」），您的個人資訊和其他資訊可能會與交易對方和協助交易的其他方共享在盡職調查期間，並作為交易的一部分與其他資產一起轉讓給繼任者或關聯公司。',
    subBlock18:
      '法律要求：我們可能會與政府機構、行業同行或其他第三方共享您的個人信息，包括您與我們的服務的交互信息，(1) 如果法律要求或如果我們認為此類行動對於遵守法律義務是必要的； (2) 保護和捍衛我們的權利或財產； (3) 如果我們單方面確定存在違反我們的條款、政策或法律的行為； (4) 發現或防止詐欺或其他非法活動； (5) 保護我們的產品、員工或用戶，或公共安全、保全、完整性； (vi) 抗辯法律責任。',
    subBlock19: '存取您的個人資訊以及有關如何處理該資訊的資訊。',
    subBlock2:
      '使用者內容：當您使用我們的服務時，我們會從您的輸入、文件上傳或提供給我們的回饋中收集個人資訊（「內容」）。',
    subBlock20: '請求從我們的記錄中刪除您的個人資訊。',
    subBlock21: '更正或更新您的個人資訊。',
    subBlock22: '將您的個人資訊轉移給第三方（資料可攜性）。',
    subBlock23: '限制我們處理您的個人資訊的方式。',
    subBlock24: '撤回您的同意－如果我們依賴同意作為處理的法律依據，您可以隨時撤回您的同意。',
    subBlock25: '反對我們處理您的個人資訊的方式。',
    subBlock26: '改善我們的服務並進行研究；',
    subBlock3:
      '通訊資訊：如果您向我們發送訊息，我們將收集您的姓名、聯絡資訊和訊息內容（「通訊資訊」）。',
    subBlock4:
      '社群媒體資訊：我們在 Instagram、Facebook、Medium、Twitter、YouTube 和 LinkedIn 等社群媒體網站上設有頁面。當您與我們的社交媒體頁面互動時，我們會收集您選擇提供給我們的個人訊息，例如您的聯絡資訊（「社交資訊」）。此外，託管我們社交媒體頁面的公司可能會向我們提供有關我們社交媒體活動的總結資訊和分析。',
    subBlock5:
      '日誌資料：您的瀏覽器在使用我們的服務時自動發送的訊息。日誌資料包括您的網際網路通訊協定 (IP) 位址、瀏覽器類型和設定、您提出要求的日期和時間以及您與我們網站互動的方式。',
    subBlock6:
      '使用資料：我們可能會自動收集有關您使用服務的信息，例如您查看或互動的內容類型、您使用的功能、您採取的操作，以及您的時區、國家/地區、日期和時間訪問權限、用戶代理程式和版本、電腦或行動裝置類型以及您的電腦連線。',
    subBlock7:
      '設備資訊：這包括設備的名稱、作業系統、設備識別碼和您正在使用的瀏覽器。收集的資訊可能取決於您使用的設備類型及其設定。',
    subBlock8:
      'Cookie：我們使用 Cookie 來營運和管理我們的服務並改善您的體驗。 「cookie」是您造訪的網站傳送到您的瀏覽器的資訊。您可以將瀏覽器設定為接受所有cookie、拒絕所有cookie，或在提供cookie時通知您，以便您每次決定是否接受。然而，拒絕 cookie 可能會阻止您使用網站某些區域或功能的顯示或功能，或對網站的某些區域或功能的顯示或功能產生負面影響。有關 Cookie 的更多信息，請訪問關於 Cookie 的所有信息。',
    subBlock9:
      '分析：我們可能會使用各種使用cookie的線上分析產品來幫助我們分析使用者如何使用我們的服務並改善您在使用服務時的體驗。',
    subTitle1: '我們收集的個人資訊',
    subTitle2: '我們如何使用個人資訊',
    subTitle3: '個人資訊的揭露',
    subTitle4: '您的權利',
    subTitle5: '其他網站的連結',
    subTitle6: '資訊的安全和保留',
    subTitle7: '隱私權政策的修改',
    subTitle8: '如何聯絡我們',
    title: '隱私權政策',
    updateTime: '更新日期：2024 年 3 月 15 日',
  },
  privacyCollectionPanel: {
    acceptBtn: '全部接受',
    text: '為了改善您的體驗，我們使用 cookie 來個人化內容和服務。',
    title: '本網站使用cookies',
  },
  slogen: '最簡單的AI助手',
  stripe: {
    contact: '如有任何疑問，請透過 contact@noam.tools 與我們聯繫！',
    fail: {
      btn: '重試',
      text1: '我們無法處理您的付款。',
      text2: '檢查您的連線並重試。',
      title: '付款失敗',
    },
    succ: { btn: '我們走吧', text1: '歡迎來到諾姆，', text2: '讓我們開始吧！', title: '恭喜你' },
  },
  terms: {
    block1: '感謝您使用諾姆！',
    block10:
      '(a) 費用和帳單。您將根據適用定價頁面上的定價和條款或我們之間書面同意的價格和條款支付所有費用（「費用」）。即使我們已開立發票或收到付款，我們也保留糾正定價錯誤或失誤的權利。您將提供完整且準確的帳單信息，包括有效且授權的付款方式。我們將按照約定定期向您的付款方式收取費用，但我們可能會合理更改計費日期。您授權 Noam、其附屬公司和第三方支付處理商透過您的付款方式收費。如果您的付款失敗，我們將向您發出書面通知，並可能暫停您對服務的訪問，直到收到付款。除非本協議另有規定，否則付款不可退還。',
    block11:
      '(b) 稅收。除非另有說明，費用不包括任何聯邦、州、地方和外國稅費、關稅和類似評估（「稅費」）。您負責與您的購買相關的所有稅費，不包括我們將向您開立發票的基於我們淨收入的稅費。您同意及時繳納此類稅款並向我們提供具有法律效力的隱私權聲明並獲得處理此類資料所需的同意，並且您向我們保證您按照適用法律處理此類資料。',
    block12:
      '(c) 價格變動。我們可能會透過您的帳戶和/或我們的網站通知您來更改我們的價格。價格上漲將在發布後立即生效。任何價格變更將在變更生效日期後適用於您帳戶的費用。',
    block13:
      '(d) 爭議和逾期付款。如果您對任何費用或稅金有異議，請在發票日期後三十 (30) 天內聯絡 contact@noam.tools。',
    block14:
      '(e) 免費使用。您不得建立多個帳戶來獲得免費積分。如果我們確定您沒有善意地使用免費積分，我們可能會向您收取標準費用或停止提供對服務的存取權。',
    block15:
      '(a) 保密。您可能有權存取諾姆的機密資訊。您只能在本條款允許的情況下使用機密資訊來使用本服務。您不得向任何第三方披露機密信息，並且您保護機密信息的方式不得低於保護您自己的類似機密信息的程度，至少要採取合理的謹慎態度。機密資訊是指 Noam 指定為機密的非公開資訊或在具體情況下應合理視為機密的非公開訊息，包括軟體、規格和其他非公開商業資訊。機密資訊不包括以下資訊：(1) 在您沒有過失的情況下公開為公眾所知的資訊； (2) 您在根據本條款收到該資訊之前沒有任何保密義務； (3) 由第三方合法向您揭露且不承擔保密義務的； (4) 由您自行開發，未使用保密資訊。如果法律、法院或其他政府命令要求，您可以披露機密信息，但您必須以合理的方式向 Noam 提供事先書面通知，並儘可能做出合理的努力來限制披露範圍，包括協助我們反對披露請求。',
    block16:
      '(b) 安全。您必須採取合理且適當的措施來幫助保護您對服務的存取和使用。如果您發現與使用服務相關的任何漏洞或違規行為，您必須立即聯絡 Noam 並提供漏洞或違規行為的詳細資訊。',
    block17:
      '((c) 個人資料的處理。如果您使用本服務處理個人數據，您必須提供合法充分的隱私權聲明並獲得處理此類資料所需的同意，並且您向我們保證您按照適用法律處理此類數據。',
    block18:
      '(a) 終止；暫停。這些條款將從您首次使用本服務時生效，並將一直有效直至終止。您可以隨時以任何理由透過停止使用服務和內容來終止這些條款。我們可以出於任何原因在事先通知的情況下終止這些條款。如果您嚴重違反第 2 條（使用要求）、第 5 條（保密、安全和資料保護）、第 8 條（爭議解決）或第 9 條（一般條款），或如果我們的與我們無法控制的任何第三方技術提供者的關係發生變化，或為了遵守法律或政府要求。如果您未能遵守這些條款，或您的使用對我們或任何第三方構成安全風險，或者我們懷疑您的使用具有欺詐性或可能使我們或任何第三方承擔責任，我們可能會暫停您對服務的訪問。',
    block19:
      '(b) 終止的影響。終止後，您將停止使用服務並按照我們的指示立即歸還或銷毀任何機密資訊。本條款中依其性質應在終止或期滿後繼續有效的條款，包括但不限於第 3 條和第 5-9 條，應繼續有效。',
    block2:
      '這些使用條款適用於您對 Noam 服務的使用，包括我們的應用程式介面、軟體、工具、開發人員服務、資料、文件和網站（統稱為「服務」）。使用我們的服務即表示您同意遵守這些條款。我們的隱私權政策解釋了我們如何收集和使用個人資訊。',
    block20:
      '(a) 賠償。對於因您使用本服務（包括您的內容、您的資訊）而引起的或與之相關的任何索賠、損失和費用（包括律師費），您將為我們、我們的關聯公司和我們的人員進行辯護、賠償並使我們免受損害。',
    block21:
      '(b) 免責聲明。服務以「現況」提供。在法律允許的範圍內，我們以及我們的關聯公司和授權人不對服務做出任何形式的陳述或保證，也不作任何保證，包括但不限於適銷性、特定用途的適用性、令人滿意的品質、不侵權的保證、安靜的享受，以及交易或貿易使用過程中產生的任何保證。我們不保證服務不會中斷、準確或無錯誤，也不保證任何內容安全、不遺失或不更改。',
    block22:
      '(c) 責任限制。我們、我們的關聯公司或我們的授權人均不對任何間接、偶然、特殊、後果性或懲罰性損害負責，包括利潤損失、聲譽損失、使用損失、資料遺失或其他無形損失，即使我們已被告知發生此類損害的可能性。根據本條款，我們的總責任不會超過您在提出索賠之前十二 (12) 個月內向服務支付的費用或一百美元 ($100)，以較高者為準。本節中的限制在適用法律允許的最大範圍內適用。',
    block23:
      '除美國法律衝突規則外，本條款以及您對服務的使用均受美國法律管轄。您對應用程式的使用也可能受到其他地方、州、國家或國際法律的約束。與這些條款相關的任何法律訴訟或程序將僅在美國法院提起，並且您同意接受此類法院的屬人管轄權。',
    block24:
      '(a) 雙方的關係。諾姆和您是獨立承包商，未經另一方事先書面同意，任何一方均無權代表另一方或約束另一方或為另一方承擔義務。',
    block25:
      '(b) 品牌使用。未經我們事先書面同意，您不得使用 Noam 或其任何附屬公司的名稱、標誌或商標。',
    block26: '如果這些條款的任何規定被確定為無效、非法或不可執行，其餘規定將仍然具有完全效力。',
    block3:
      '如果您未滿18歲，您必須獲得父母或法定監護人的許可才能使用本服務。如果您代表其他人或實體使用本服務，您必須被授權代表他們接受這些條款。您必須提供準確、完整的資訊才能註冊帳戶。您不得向組織外部的任何人提供您的存取憑證或帳戶，並且您對使用您的憑證進行的所有活動負責。',
    block4:
      '(a) 服務的使用。您可以根據這些條款存取服務，並且我們授予您使用服務的非專有權利。使用本服務時，您將遵守本條款和所有適用的法律。我們和我們的附屬公司擁有服務的所有權利、所有權和利益。',
    block5:
      '(b) 回饋。我們歡迎回饋、意見、想法、建議和改進。如果您提供任何此類內容，我們可以不受任何限制地使用它，並且無需向您提供補償。',
    block6:
      '(c) 限制。您不得：(1) 以侵害、盜用或侵害他人任何權利的方式使用本服務； (2) 進行逆向工程、反編譯、反組譯、翻譯或以其他方式嘗試發現服務模型、演算法和系統的原始碼或底層元件（除非適用法律禁止此類限制）； (3) 使用服務的產出來發展與Noam競爭的模型； (4) 使用任何自動化或程式方法從服務中提取資料或輸出，除非透過 API 允許，包括抓取、網路收集或網路資料提取； (5) 將服務的輸出描述為人工生成，而實際上並非人工生成，或以其他方式違反我們的使用政策； (6) 未經我們事先同意購買、出售或轉讓 API 金鑰； (7) 提供我們 13 歲或適用的數字同意年齡以下兒童的任何個人資訊。您將遵守我們文件中的任何速率限制和其他要求。',
    block7:
      '(d) 第三方服務。與服務相關的任何第三方軟體、服務或其他產品均受其自身條款的約束，我們不對第三方產品負責。',
    block8:
      '(a) 您的內容。您可以向服務提供輸入（「輸入」）並接收基於輸入產生和傳回的輸出（「輸出」）（統稱為「內容」）。在雙方之間，在適用法律允許的範圍內，您擁有所有意見。 Noam 可以使用內容來提供和維護服務、遵守適用法律並執行我們的政策。您對內容負責，包括確保其不違反任何適用法律或這些條款。',
    block9:
      '(b) 準確性。人工智慧和機器學習是快速發展的研究領域。我們不斷努力改善我們的服務，使其更加準確、可靠、安全和有益。鑑於機器學習的機率性質，在某些情況下使用我們的服務可能會導致錯誤的輸出，這可能無法準確反映真實的個人、地點或事實。您應該根據您的用例適當評估任何輸出的準確性，包括手動檢查輸出。',
    subTitle1: '註冊和訪問',
    subTitle10: '可分割性',
    subTitle2: '使用要求',
    subTitle3: '內容',
    subTitle4: '費用和付款',
    subTitle5: '保密、安全和資料保護',
    subTitle6: '期限和終止',
    subTitle7: '保障;免責聲明；責任限制',
    subTitle8: '適用法律和管轄權',
    subTitle9: '一般條款',
    title: '服務條款',
    updateTime: '更新日期：2024 年 3 月 15 日',
  },
  trialModal: {
    btn: '立即開始（僅需$0.99）',
    chat: '聊天PDF',
    desc1: '試用後，您將被收取 180 美元的年費',
    desc2: '隨時取消',
    focus: '聚焦',
    hover: '懸停翻譯',
    image: '圖片翻譯',
    installBtn: '立即安裝即可領取您的禮物',
    memo: '收藏管理',
    pdf: 'PDF 平行翻譯',
    realtime: '即時翻譯',
    select: '選詞翻譯',
    title: '歡迎禮物',
    trialText: '7 天試用',
    video: '影片翻譯',
    videoSummary: '影片摘要',
    web: '平行翻譯',
    webSummary: '網路摘要',
  },
  upgradeModal: {
    btn: '立即升級',
    chat: { free: "ERROR: [You've reached the ChatPDF limit]", pro: '您已達到本月 ChatPDF 限制' },
    contactStr: '請等待下次重置，或透過 contact@noam.tools 與我們聯繫。',
    limitAlertStr: '您已達到免費方案的限制。升級才能繼續。',
    more: '查看計劃',
    pageLimit: { free: '您已達到 PDF 頁數限制' },
    title: '提醒',
    upgradeAlertStr: '您已達到免費方案的限制。升級才能繼續。',
    upload: { free: '您已達到 PDF 翻譯限制', pro: '您已達到本月的 PDF 翻譯限制' },
  },
  welfare: {
    newUser: {
      joinBtn: '立即加入',
      label: '新用戶福利：',
      remain: '僅剩 30 名',
      text: '僅剩 100 個名額！立即購買！',
    },
  },
}

export default TRANSLATION
