const TRANSLATION = {
  buyModal: {
    afterFree: '무료 평가판 이후',
    btn: '무료로 사용해 보세요',
    freeTrial: '3일 무료 평가판',
    monthLabel: '월',
    selectLabel: '요금제 선택',
    title: '구독 계획',
    yearLabel: '년도',
  },
  contact: {
    block1:
      '맞춤화, 질문 또는 제안 사항이 있는 경우 contact@noam.tools로 문의해 주세요. 신속하게 답변드리겠습니다.',
    block2: '★★★★★의 Google Apps Extension 스토어에서 평가하여 지원해 주세요.',
    block3: '이 확장 프로그램을 공유하여 다른 사람들을 도와주세요:',
    title: '문의하기',
  },
  help: {
    AquickOverviewOfNoam: 'Noam에 대한 간략한 개요',
    ChatPDF: '채팅 PDF',
    ChatPDF2: '채팅PDF',
    FavoritesManagement: '즐겨찾기 관리',
    Fixedplugin: '고정 플러그인',
    Focusing: '집중',
    Free: '무료',
    FreeTrialBenefits: '무료 평가판 혜택',
    GoWithWikipedia: '위키피디아를 이용하세요',
    GoWithYouTube: '유튜브와 함께하세요',
    HoverTranslation: '호버 번역',
    Howtouse: '사용방법',
    ImageTranslation: '이미지 번역',
    InstantTranslation: '즉석 번역',
    Membership: '회원',
    NoamHelp: 'Noam은 번역과 요약을 도와드립니다.',
    PDFparallelTranslation: 'PDF 병렬 번역',
    ParallelTranslation: '병렬 번역',
    SkipAllSteps: '모든 단계 건너뛰기',
    Summary: '요약',
    Translation: '번역',
    TryItForFree: '무료로 사용해 보세요',
    Video: '동영상',
    VideoSummary: '영상 요약',
    VideoTranslation: '영상번역',
    WebSummary: '웹 요약',
    WordSelectionTranslation: '단어 선택 번역',
    benefits: '혜택이 여러분을 기다리고 있습니다!',
    dayFree: '3일 무료 평가판',
    done: '완료',
    next: '다음',
    pin: '더 쉬운 접근을 위한 핀번호~~',
    planIsNotExist: '요금제를 사용할 수 없습니다.',
    startWiki: '위키미디어부터 시작해 보세요!',
    startYoutube: '유튜브부터 시작해 보세요!',
    try: '사용해 보세요',
    tryNow: '지금 시도해 보세요!',
  },
  home: {
    PDFTranslate: {
      desc: '이중언어 번역 + 채팅 PDF',
      sub1: {
        desc: '이중 언어 읽기를 사용하면 원문과 번역문을 나란히 비교하여 읽기 효율성을 높일 수 있습니다.',
        title: '이중 언어 읽기',
      },
      sub2: {
        desc: 'AI는 PDF 콘텐츠를 기반으로 분석 및 요약하고 질문에 답할 수 있습니다.',
        title: '채팅 PDF',
      },
      sub3: {
        desc: '정확성과 효율성을 높이기 위한 AI 기반 PDF 번역입니다.',
        title: '정확성과 효율성',
      },
      title: 'PDF 번역',
    },
    aiAssistant: {
      desc: '웹 번역을 더욱 스마트하고 편리하게 만들기',
      panel1: {
        desc: '이중 언어 상호 참조 번역은 원본 텍스트와 번역문을 비교하여 더 잘 이해하고 읽기 효율성을 향상시키는 데 도움이 됩니다.',
        title: '이중언어 번역',
      },
      panel2: {
        left: {
          desc: '웹페이지를 탐색할 때 번역이 필요한 콘텐츠 위로 마우스를 가져가면 번역 내용을 볼 수 있습니다.',
          title: '호버 번역',
        },
        right: {
          desc: '웹페이지를 탐색할 때 번역하려는 텍스트를 선택하면 번역 내용을 볼 수 있습니다.',
          title: '텍스트 선택 번역',
        },
      },
      panel3: {
        one: {
          desc: '언제 어디서나 웹페이지와 동영상 콘텐츠를 번역하고 요약하여 더욱 원활한 경험을 선사하세요!',
          title: '포인트 앤 클릭',
        },
        three: {
          desc: 'AI 기반 대형 언어 모델은 향상된 읽기 경험을 위해 번역 정확성과 일관성을 보장합니다.',
          title: 'AI 기반',
        },
        two: {
          desc: '다국어 전체 범위, 이중 언어 장애 없는 읽기, 원클릭 번역 - 차이를 경험해 보세요!',
          title: '보다 포괄적이고 편리함',
        },
      },
      panel4: {
        tab1: {
          desc: '효율성 전문가 - Noam은 ChatGPT를 사용하여 YouTube 비디오 콘텐츠를 번역하고 요약하여 시간을 절약합니다!',
          key: '동영상',
          title: '영상번역 및 요약',
        },
        tab2: {
          desc: '웹을 탐색하는 동안 이미지를 번역하려면 클릭하세요.',
          key: '영상',
          title: '이미지 번역',
        },
        tab3: {
          desc: '웹 페이지에서 주요 정보를 즉시 추출하고 요약을 제공하여 독서 효율성을 높입니다.',
          key: '편물',
          title: '웹 번역 및 요약',
        },
        tag1: '단순한',
        tag2: '효율적인',
        tag3: '이해하기 쉽다',
      },
      title: 'AI 어시스턴트',
    },
    getStart: '시작하기',
    getStartedForFree: '무료로 시작해보세요',
    howItWork: {
      desc: 'Noam은 AI 모델을 통합하여 사용자에게 언어 간 웹 페이지 번역 및 요약, PDF 및 이미지 번역, 비디오 번역 및 요약에 대한 지능형 경험을 제공합니다.',
      title: '작동 방식',
    },
    lastBlock: { desc: '보다 원활한 읽기 경험을 위한 향상된 AI 번역' },
    top: {
      desc: 'Noam은 웹페이지, 비디오, PDF 콘텐츠를 번역하고 요약하는 데 도움이 되는 무료 AI 브라우저 확장 프로그램입니다.\\n Noam에 가입하여 마법을 경험하세요!',
      title1: '원 매직',
      title2: '원클릭',
    },
    userReviews: { desc: 'Chrome 웹 스토어의 별 5개 리뷰', title: '사용자 리뷰' },
  },
  installTips: {
    btn: '지금 사용해 보세요',
    text: '더 많은 기능을 잠금 해제하려면 "Noam 브라우저 플러그인"을 설치하세요! 웹 상호 참조,\\n 웹 비디오 요약 등!',
  },
  locale: {
    af: '아프리카 어',
    am: '암하라어',
    ar: '아라비아 말',
    as: '아삼어',
    auto: '자동 감지',
    az: '아제르바이잔',
    ba: '바쉬르어',
    bg: '불가리아 사람',
    bn: '벵골어',
    bo: '티베트어',
    bs: '보스니아어',
    ca: '카탈로니아 사람',
    cs: '체코 사람',
    cy: '웨일스 말',
    da: '덴마크 말',
    de: '독일 사람',
    dsb: '하부소르비아어',
    dv: '디베히',
    el: '그리스 사람',
    en: '영어',
    es: '스페인 사람',
    et: '에스토니아 사람',
    eu: '바스크 사람',
    fa: '페르시아 인',
    fi: '핀란드어',
    fil: '필리핀 사람',
    fj: '피지어',
    fr: '프랑스 국민',
    frCA: '프랑스어(캐나다)',
    ga: '아일랜드',
    gl: '갈리시아어',
    gom: '콘카니',
    gu: '구자라트어',
    ha: '하우사어',
    he: '헤브라이 사람',
    hi: '힌디 어',
    hr: '크로아티아어',
    hsb: '어퍼 소르비아어',
    ht: '아이티 크리올어',
    hu: '헝가리 인',
    hy: '아르메니아 사람',
    id: '인도네시아 인',
    ig: '이보어',
    ikt: '이누이나크툰',
    is: '아이슬란드어',
    it: '이탈리아 사람',
    iu: '이누이트어',
    iuLatn: '이누이트어(라틴어)',
    ja: '일본어',
    ka: '그루지야 사람',
    kk: '카자흐어',
    km: '크메르어',
    kmr: '쿠르드어(북부)',
    kn: '칸나다어',
    ko: '한국인',
    ku: '쿠르드어(중부)',
    ky: '키르기스어',
    ln: '링갈라',
    lo: '라오스',
    lt: '리투아니아 사람',
    lug: '간다',
    lv: '라트비아 사람',
    lzh: '중국어(문학)',
    mai: '마이틸리',
    mg: '마다가스카르 사람',
    mi: '마오리족',
    mk: '마케도니아 어',
    ml: '말라얄람어',
    mnCyrl: '몽골어(키릴 문자)',
    mnMong: '몽골어(번체)',
    mr: '마라티어',
    ms: '말레이 사람',
    mt: '몰티즈',
    mww: '몽 다우',
    my: '미얀마(버마어)',
    nb: '노르웨이 인',
    ne: '네팔어',
    nl: '네덜란드 사람',
    noSearchLang: '지원되지 않는 언어',
    nso: '세소토 사 레보아',
    nya: '냥자',
    or: '오디아',
    otq: '케레타로 오토미',
    pa: '펀잡어',
    pl: '광택',
    placeholder: '찾다',
    prs: '다리',
    ps: '파슈토어',
    pt: '포르투갈어(브라질)',
    ptPT: '포르투갈어(포르투갈)',
    ro: '루마니아 사람',
    ru: '러시아인',
    run: '룬디어',
    rw: '키냐르완다어',
    sd: '신디어',
    si: '신할라어',
    sk: '슬로바키아 사람',
    sl: '슬로베니아',
    sm: '사모아어',
    sn: '쇼나',
    so: '소말리아어',
    sq: '알바니아',
    srCyrl: '세르비아어(키릴 문자)',
    srLatn: '세르비아어(라틴어)',
    st: '세소토어',
    sv: '스웨덴어',
    sw: '스와힐리어',
    ta: '타밀 사람',
    te: '텔루구어',
    th: '태국어',
    ti: '티그리냐',
    tk: '투르크멘 말',
    tlhLatn: '클링온어(라틴어)',
    tlhPiqd: '클링온어(pIqaD)',
    tn: '세츠와나어',
    to: '통가어',
    tr: '터키어',
    tt: '타타르어',
    ty: '타히티어',
    ug: '위구르어',
    uk: '우크라이나 말',
    ur: '우르두어',
    uz: '우즈벡어(라틴어)',
    vi: '베트남 사람',
    xh: '코사어',
    yo: '요루바어',
    yua: '유카텍 마야',
    yue: '광둥어(번체)',
    zh: '중국인',
    zhHans: '중국어 간체',
    zhHant: '중국어 번체',
    zu: '줄루 족',
  },
  login: {
    and: '그리고',
    btn: '구글로 로그인',
    btn1: '지금 로그인',
    desc: '더 많은 경험을 잠금해제하려면 로그인하세요',
    policy: '개인 정보 보호 정책',
    terms: '이용약관',
    tips: '가입함으로써 다음 사항에 동의합니다.',
    title: '노암에 오신 것을 환영합니다',
  },
  pageFooter: {
    follow: { facebook: '페이스북', title: '따르다', twitter: '지저귀다' },
    legal: { policy: '개인 정보 보호 정책', terms: '서비스 약관', title: '합법적인' },
    product: {
      image: '이미지 번역',
      pdf: 'PDF 번역',
      title: '제품',
      video: '영상번역 및 요약',
      web: '웹 번역 및 요약',
    },
    resources: { title: '자원' },
  },
  pageHeader: {
    contact: '연락하다',
    help: '돕다',
    logout: '로그아웃',
    logoutSucc: '로그아웃되었습니다.',
    pricing: '가격',
    settings: '설정',
    signUp: '로그인',
  },
  pdf: {
    autoDetect: '자동 감지',
    cancelBtn: '취소',
    cancelUpload: '취소',
    chatPdf: {
      Search: '찾다',
      cancel: '취소',
      chatTitle: '노암에 오신 것을 환영합니다! PDF 요약은 다음과 같습니다.',
      clear: '분명한',
      clearContent: '그러면 새로운 대화가 시작됩니다. 현재 대화는 저장되지 않습니다. 확실합니까?',
      clearDialogTitle: '확인해주세요',
      clearSucc: '삭제됨',
      copy: '복사',
      copySucc: '복사됨',
      delete: '삭제',
      deleteCancel: '취소',
      deleteContent: '이 PDF는 삭제되면 복구할 수 없습니다. 확실합니까?',
      deleteOk: '삭제',
      deleteSuccess: '삭제됨',
      deleteTitle: '삭제 확인',
      expand: '무너지다',
      historyTitle: '역사',
      listError: '읽기 목록을 로드하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
      loading: '로드 중...',
      noDataDesc: '아직 기록이 없습니다.',
      noMore: '더 이상 콘텐츠가 없습니다.',
      placeholder: '이 PDF에 관해 무엇이든 물어보세요',
      summaryTitle: '다음 질문에 관심이 있을 수 있습니다.',
    },
    collectionEmpty: '귀하의 컬렉션이 비어 있습니다.',
    collectionTab: '메모',
    dataLoading: '로드 중...',
    dialogContent: '정말로 삭제하시겠습니까?',
    dialogTitle: '확인해주세요',
    download: {
      both: '이중 언어',
      btn: '구하다',
      cancel: '이 창을 닫으면 PDF 다운로드가 취소됩니다. 취소하시겠습니까?',
      desc: '장치에 저장: 번역을 이미지로 다운로드합니다. 저장 및 인쇄: 복사 지원과 함께 원본 형식으로 번역을 다운로드합니다.',
      download: '다운로드',
      downloadMsg1: 'PDF 번역 진행 상황:',
      downloadMsg2: '남은 예상 시간:',
      downloadSucc: 'PDF가 준비되었습니다! 저장하려면 클릭하세요.',
      downloadTip: 'PDF 다운로드 중. 다운로드 오류를 방지하려면 이 창을 닫지 마세요.',
      error: '저장하지 못했습니다. 대신 인쇄해 보세요.',
      fileName: '파일 이름:',
      fileType: '파일 유형:',
      loading: '절약...',
      loading2: '로드 중...',
      minute: '분...',
      msg1: '이 문서의 일부 번역은 원본 텍스트보다 길어서 다운로드한 파일과 겹칠 수 있습니다. 웹페이지에서 읽기를 권장합니다.',
      msg2: '1. 아직 번역이 완료되지 않았습니다. 번역이 완료된 후 다운로드하시기 바랍니다.',
      msg3: '2. 일부 번역이 너무 깁니다. 텍스트가 겹치지 않도록 웹페이지에서 읽으세요.',
      msg4: '번역 작업이 진행 중입니다. 완료한 후 다시 시도해 주세요.',
      progress: 'PDF 번역. 진행률: {{progress}}, 남은 예상 시간: {{분}}분...',
      save: '장치에 저장',
      savePrint: '저장 및 인쇄',
      success: '다운로드됨',
      trans: '번역만',
      transContinue: '계속 번역하기',
    },
    downloadBtn: '다운로드',
    dragTipModal: {
      black: '공간',
      leftKey: '왼쪽 버튼',
      ok: '알았어요',
      press: '누르다',
      tips: '끌어서 볼 수 있습니다.',
    },
    entry: 'PDF',
    fileErrMsg: '파일 읽기에 실패했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    fileMsg: '현재 파일 크기:',
    fileMsg2: '300MB 이하의 파일을 업로드해주세요.',
    freeBannerBtn: '치받이',
    freeBannerTips: '무료 PDF 번역 한도에 도달했습니다.',
    guide: {
      placeholder: '여기에 PDF 링크를 붙여넣으세요.',
      start: '번역 시작',
      text1: '이중 언어 읽기',
      text2: '이중 언어로 읽으면 원본 텍스트와 번역을 나란히 비교하여 효율성을 높일 수 있습니다.',
      text3: 'PDF와 채팅',
      text4: 'AI 기반 분석 및 요약. PDF에 대해 질문하고 즉시 답변을 얻으세요.',
      text5: '정확하고 효율적',
      text6: '정확성과 효율성을 높이기 위한 AI 기반 PDF 번역입니다.',
      title1: '이중언어 번역',
      title2: 'PDF와 채팅',
      toast: '.pdf로 끝나는 유효한 온라인 PDF 링크를 입력하세요.',
    },
    hasTransTask: '번역 작업이 진행 중입니다. 기다리세요.',
    hoverTrans: '마우스를 올려서 단락 번역',
    initial: 'PDF 번역',
    installText: '일상의 번역을 위한 원스톱 솔루션',
    installText2: '먼저 확장 프로그램을 설치하세요.',
    installTips: '더 많은 PDF 번역과 더 원활한 웹사이트 번역을 위해 확장 프로그램을 설치하세요.',
    kw: '스테이플',
    link: '링크',
    listError: 'PDF를 로드하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    memoTotalCount: '총 1개 항목',
    new: '새 파일',
    noId: '파일 ID를 찾을 수 없습니다.',
    notPdf: 'PDF 파일만 가능합니다.',
    okBtn: '삭제',
    okBtn2: '알았어요',
    original: '원래의',
    parallel: '평행한',
    parseErrDialogContent:
      '이 PDF 링크는 직접 번역될 수 없습니다. PDF를 다운로드하여 번역용으로 업로드하세요.',
    parseErrTips: '파일을 구문 분석하지 못했습니다.',
    parseFileToast: '파일을 구문 분석하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    pdfLoading: '파싱 ​​중...',
    searchPlaceholder: '찾다',
    selectTrans: '번역할 텍스트 선택',
    shareBtn: '공유하다',
    shareBtnText1: '공유 링크 생성',
    shareBtnText2: '공유 콘텐츠 업데이트',
    shareModal: {
      cancel: '취소',
      copy: '복사',
      copySucc: '링크가 복사되었습니다',
      custom: '사용자 정의:',
      errMsg: '선택한 콘텐츠가 아직 완전히 번역되지 않았습니다. 공유하기 전에 번역해 주세요.',
      gen: '공유하다',
      modify: '편집하다',
      notSupport: '이 언어는 현재 지원되지 않습니다.',
      page: '페이지',
      pageNuErr: '유효한 페이지 번호를 입력하세요.',
      range: '범위:',
      remain: '남은',
      remain1: '총 예상 시간:',
      search: '찾다',
      settings: '공유 설정:',
      shareSetting: '공유 설정',
      shareText: '공유하다',
      shareTips: '번역된 PDF 링크를 친구들과 공유하세요:',
      sourceLang: '에서:',
      target: '에게:',
      text1: '공유 설정',
      text2: '공유 링크 생성',
      totalPage: '총 1페이지',
      trans: '번역하다',
      translateTips: '팁: 번역하는 데 시간이 오래 걸리면 이 창을 닫고 나중에 공유할 수 있습니다.',
      translating: 'PDF 번역 중...',
      update: '공유 링크 업데이트',
    },
    shareMsg1: '공유 링크가 생성되었습니다.',
    shareMsg2: '공유 콘텐츠가 업데이트되었습니다.',
    shareMsg3: '공유 링크를 생성하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    shareMsg4: '공유 콘텐츠를 업데이트하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    shareTips: 'PDF 번역이 준비되었습니다! 친구들과 공유해보세요.',
    start: '무료 평가판을 시작하세요',
    thumbnail: '목차',
    toast1: 'PDF를 로드하는 중입니다. 나중에 다시 시도해 주세요.',
    toast2: 'PDF 내용이 너무 큽니다. 대화상자는 현재 지원되지 않습니다.',
    toast3: '다운로드 중입니다. 다운로드가 완료된 후 다시 시도해 주세요.',
    toolbar: { Adjust: '조정하다', autoFit: '자동 맞춤' },
    trans: '번역',
    transSucc: '번역 성공!',
    unLoginToast: '이 기능을 사용하려면 로그인하세요.',
    uploadBtn: '업로드',
    uploadErr: '파일 업로드에 실패했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    uploadMsg1: '여기에 파일을 드래그 앤 드롭하세요.',
    uploadMsg2: '지원되는 파일 유형: PDF |  최대 파일 크기: 100MB',
    uploadMsg3: '여기에서 파일을 선택하거나 드래그 앤 드롭하려면 클릭하세요.',
    uploading: '업로드 중',
    uploadingMsg: '업로드 중입니다. 기다리세요.',
    uploadingMsg1: '파일을 업로드하는 중입니다. 나중에 다시 시도해 주세요.',
    uploadingMsg2: '현재 파일이 변환 중입니다. 나중에 다시 시도해 주세요.',
    uploadingMsg3: '변환 진행 요청이 실패했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    uploadingMsg4: 'Noam은 로컬 파일에 액세스할 수 없습니다. 번역할 PDF를 수동으로 업로드하세요.',
    uploadingMsg5: '파일 URL을 검색하지 못했습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    video: '동영상',
    vipBannerBtn: '치받이',
    vipBannerTips: '이번 달 PDF 번역 한도에 도달했습니다.',
    webTrans: '전체 상호 참조',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: '예, Noam은 즉시 사용할 수 있는 무료 플랜을 제공합니다.',
      a2: 'Pro, Pro+, Enterprise의 세 가지 멤버십 플랜을 제공합니다. 월별 또는 연간 지불을 선택할 수 있습니다. 연간 청구를 통해 상당한 비용 절감 효과를 얻을 수 있습니다.',
      a3: '두 플랜 모두에서 모든 기능을 사용할 수 있습니다. 가장 큰 차이점은 비용입니다. 연간 구독 비용이 전반적으로 저렴합니다. 월간 구독은 매달 청구되며, 연간 요금제는 1년에 한 번 청구됩니다.',
      a4: '계정 설정에서 언제든지 구독을 취소할 수 있습니다.',
      q1: '무료 플랜이 있나요?',
      q2: '어떤 가격 계획과 결제 옵션을 사용할 수 있나요?',
      q3: '월간 구독과 연간 구독의 차이점은 무엇인가요?',
      q4: '구독을 어떻게 취소하나요?',
    },
    around: '약',
    billedMonthly: '월별 청구',
    billedYearly: '매년 청구됨',
    day: '낮',
    desc: '계획을 비교하고 가장 좋은 것을 선택하십시오',
    detailedCompare: '요금제 비교',
    discount: '20% 할인',
    enterprise: {
      benefit1: '우선 고객 지원',
      benefit2: '여러 사용자',
      benefit3: '맞춤형 솔루션',
      benefit5: '전담 계정 관리자',
      btn: '문의하기',
      recommendText: '맞춤형 플랜(≥3인)',
      talkToUs: '연락하세요',
    },
    free: {
      benefit1: '즉석번역 100회/일',
      benefit2: '웹사이트 번역 및 요약',
      benefit3: '30개의 이미지 번역',
      benefit4: '영상번역 및 요약',
      benefit5: '10개의 PDF 번역',
      benefit6: '토큰 5,000개',
      btn: '무료로 사용해 보세요',
      recommendText: '무료 평가판',
    },
    imageTranslation: '이미지 번역',
    month: '월',
    monthly: '월간 간행물',
    pageTitle: '가격',
    pdf: { chat: 'PDF 채팅 쿼리', maxPage: '최대. PDF당 페이지 수', translation: 'PDF 번역' },
    preMonth: '월별',
    pro: {
      benefit1: '우선 고객 지원',
      benefit2: '1,000,000개 토큰/월',
      benefit3: '웹사이트 번역 및 요약',
      benefit4: '200개의 이미지 번역',
      benefit5: '영상번역 및 요약',
      benefit6: '200개의 PDF 번역',
      btn: '시작하기',
      recommendText: '가장 인기있는',
    },
    proPlus: {
      benefit1: '우선 고객 지원',
      benefit2: '3,000,000개 토큰/월',
      benefit3: '웹사이트 번역 및 요약',
      benefit4: '500개의 이미지 번역',
      benefit5: '영상번역 및 요약',
      benefit6: '500개의 PDF 번역',
      recommendText: '최고의 가치',
    },
    video: { summary: '영상 요약', translation: '영상번역' },
    web: {
      contrastiveTranslation: '이중언어 번역',
      dictionaryTranslation: '선택 번역',
      hoverTranslation: '호버 번역',
      instantTranslation: '즉석 번역',
      summary: '웹페이지 요약',
    },
    yearly: '매년',
  },
  privacy: {
    block1:
      'Noam("Noam", "당사", "당사의" 또는 "당사")은 귀하의 개인정보를 존중하며 당사가 귀하로부터 또는 귀하에 대해 얻은 모든 정보를 보호하기 위해 최선을 다하고 있습니다. 본 개인정보 보호정책은 귀하의 당사 웹사이트, 애플리케이션 및 서비스(통칭하여 "서비스") 사용 시 개인정보 수집 또는 사용에 관한 당사의 관행을 설명합니다. 본 개인정보 보호정책은 API와 같은 비즈니스 제품 고객을 대신하여 당사가 처리하는 콘텐츠에는 적용되지 않습니다. 이 데이터의 사용에는 해당 제품에 대한 액세스 및 사용이 포함된 고객 계약이 적용됩니다.',
    block10:
      '당사는 손실, 오용, 무단 접근, 공개, 변경 또는 적대적 행위로부터 온라인 및 오프라인 개인정보를 보호하기 위해 상업적으로 합당한 기술적, 관리적, 조직적 조치를 취합니다. 그러나 인터넷이나 이메일 전송은 완전한 보안이나 정확성을 보장할 수 없습니다. 특히, 당사로 전송되거나 당사로부터 발송된 이메일은 안전하지 않을 수 있습니다. 따라서 서비스나 이메일을 통해 당사에 보낼 정보를 결정할 때 특히 주의해야 합니다. 또한 당사는 서비스나 제3자 웹사이트의 보안 설정 또는 보안 조치의 개인정보 설정 또는 보안 조치를 방해하는 것에 대해 책임을 지지 않습니다. 당사는 귀하에게 서비스를 제공하거나 분쟁 해결, 보안 및 안전상의 이유 또는 법적 의무 준수와 같은 기타 합법적인 비즈니스 목적을 위해 필요한 기간 동안 귀하의 개인정보를 보관합니다. 당사가 개인정보를 보관하는 기간은 정보의 양, 성격, 민감도, 무단 사용 또는 공개의 잠재적 위험, 정보 처리 목적, 기타 법적 구속력이 있는 고려사항 등 다양한 요인에 따라 달라집니다.',
    block11:
      '당사는 수시로 본 개인정보 보호정책을 업데이트할 수 있습니다. 그렇게 하면 해당 법률에서 달리 요구하지 않는 한 이 페이지에 업데이트된 버전을 게시할 것입니다.',
    block12:
      '본 개인정보 보호정책에 대해 해결되지 않은 질문이나 우려 사항이 있는 경우 당사 고객 서비스에 문의하시기 바랍니다. 이메일:',
    block2: '당사는 귀하와 관련하여 다음과 같은 개인정보("개인정보")를 수집합니다.',
    block3:
      '귀하가 제공한 정보: 귀하가 당사 서비스를 이용하거나 당사와 커뮤니케이션하기 위해 계정을 생성할 때 당사는 다음과 같이 개인정보를 수집합니다.',
    block4:
      '귀하의 서비스 사용으로 인해 자동으로 수신되는 개인 정보: 귀하가 서비스에 액세스, 사용 또는 상호 작용할 때 당사는 귀하의 액세스, 사용 또는 상호 작용에 대한 다음 정보("기술 정보")를 수신합니다.',
    block5: '당사는 다음과 같은 목적으로 개인정보를 사용할 수 있습니다.',
    block6:
      '요약 또는 식별되지 않은 정보: 당사는 식별 목적으로 재사용되는 것을 방지하기 위해 개인 정보를 집계하거나 식별 해제할 수 있으며 해당 정보를 사용하여 서비스 효율성을 분석하고, 서비스 기능을 개선 및 추가하고, 연구를 수행하고, 유사한 목적으로 사용할 수 있습니다. . 또한 당사는 서비스 사용자의 일반적인 행동과 특성을 분석하고 집계된 정보를 제3자와 공유하거나 집계된 정보를 게시하거나 일반에 공개할 수 있습니다. 당사는 서비스, 쿠키 및 본 개인정보 보호정책에 설명된 기타 수단을 통해 집계된 정보를 수집할 수 있습니다. 우리는 식별되지 않은 정보를 익명 또는 식별되지 않은 형식으로 유지하고 사용할 것이며, 법에서 요구하지 않는 한 그러한 정보를 재식별하려고 시도하지 않을 것입니다.',
    block7:
      '어떤 경우에는 법에서 요구하지 않는 한 당사는 귀하에게 추가 통지 없이 귀하의 개인정보를 제3자에게 제공할 수 있습니다.',
    block8:
      '귀하의 지리적 위치에 따라 유럽 경제 지역, 영국 및 전 세계의 개인은 자신의 개인 정보와 관련하여 특정 법적 권리를 가질 수 있습니다. 예를 들어, 귀하는 다음과 같은 권리를 가질 수 있습니다:',
    block9:
      '이 서비스에는 소셜 미디어 서비스를 포함하여 Noam이 운영하거나 통제하지 않는 다른 웹사이트("제3자 웹사이트")에 대한 링크가 포함될 수 있습니다. 귀하가 제3자 웹사이트와 공유하는 정보에는 본 개인정보 보호정책이 아닌 제3자 웹사이트의 특정 개인정보 보호정책 및 서비스 약관이 적용됩니다. 우리는 편의를 위해 이러한 링크를 제공하며 이러한 웹사이트에 대한 당사의 보증이나 검토를 의미하지 않습니다. 개인정보 보호정책 및 약관에 대한 정보는 제3자 웹사이트에 직접 문의하시기 바랍니다.',
    subBlock1:
      '계정 정보: 귀하가 당사 플랫폼에서 계정을 생성할 때 당사는 귀하의 이름, 연락처 정보, 계정 자격 증명, 결제 카드 정보 및 거래 내역("계정 정보")을 포함하여 귀하의 계정과 관련된 정보를 수집합니다.',
    subBlock10: '서비스 제공, 관리, 유지 및/또는 분석',
    subBlock11: '귀하와의 의사소통',
    subBlock12: '새로운 제품 및 서비스 개발',
    subBlock13:
      '사기, 범죄 행위 또는 서비스 오용을 방지하고 IT 시스템, 아키텍처 및 네트워크의 보안을 보호합니다.',
    subBlock14: '사업 양도 수행',
    subBlock15:
      '법적 의무 및 법적 절차를 준수하고 당사는 물론 계열사, 귀하 또는 기타 제3자의 권리, 개인 정보 보호, 보안 또는 재산을 보호합니다.',
    subBlock16:
      '공급업체 및 서비스 제공업체: 비즈니스 운영 요구 사항을 충족하고 특정 서비스 및 기능을 수행하는 데 도움을 주기 위해 당사는 호스팅 서비스 제공업체, 클라우드 서비스 제공업체, 기타 정보 기술 서비스 제공업체, 이메일 통신 소프트웨어를 포함한 공급업체 및 서비스 제공업체에 개인정보를 제공할 수 있습니다. , 웹 분석 서비스 제공자 등. 이러한 파트너는 당사를 위해 업무를 수행하는 동안 당사의 지시에 따라서만 개인 정보에 액세스, 처리 또는 저장합니다.',
    subBlock17:
      '사업 양도: 당사가 전략적 거래, 조직 개편, 파산, 파산 인수 또는 다른 제공업체로의 서비스 전환(총칭하여 "거래")을 하는 경우, 귀하의 개인 정보 및 기타 정보는 거래 상대방 및 거래를 지원하는 기타 당사자와 공유될 수 있습니다. 실사 중에 거래의 일부로 다른 자산과 함께 후임자 또는 계열사에 양도됩니다.',
    subBlock18:
      '법적 요구 사항: 당사는 (1) 법률에서 요구하거나 법적 의무를 준수하기 위해 그러한 조치가 필요하다고 판단하는 경우; (2) 당사의 권리나 재산을 보호하고 방어하기 위해; (3) 당사의 약관, 정책 또는 법률을 위반했다고 당사가 일방적으로 판단한 경우 (4) 사기 또는 기타 불법 행위를 탐지하거나 예방하기 위해; (5) 당사의 제품, 직원, 사용자 또는 공공의 안전, 보안, 무결성을 보호하기 위해; 또는 (vi) 법적 책임을 방어하기 위해.',
    subBlock19: '귀하의 개인정보와 해당 정보가 처리되는 방법에 대한 정보에 접근하세요.',
    subBlock2:
      '사용자 콘텐츠: 귀하가 당사 서비스를 이용할 때 당사는 귀하가 입력한 내용, 파일 업로드 또는 당사에 제공된 피드백("콘텐츠")을 통해 개인정보를 수집합니다.',
    subBlock20: '당사 기록에서 귀하의 개인 정보를 삭제하도록 요청하십시오.',
    subBlock21: '개인정보를 수정하거나 업데이트하세요.',
    subBlock22: '귀하의 개인정보를 제3자에게 이전합니다(데이터 이동성).',
    subBlock23: '귀하의 개인 정보를 처리하는 방법을 제한하십시오.',
    subBlock24:
      '동의 철회 – 당사가 동의를 처리의 법적 근거로 삼는 경우 귀하는 언제든지 동의를 철회할 수 있습니다.',
    subBlock25: '당사가 귀하의 개인 정보를 처리하는 방법에 반대하십시오.',
    subBlock26: '서비스 개선 및 연구 수행',
    subBlock3:
      '통신 정보: 귀하가 당사에 메시지를 보내는 경우 당사는 귀하의 이름, 연락처 정보 및 메시지 내용("통신 정보")을 수집합니다.',
    subBlock4:
      '소셜 미디어 정보: 당사는 Instagram, Facebook, Medium, Twitter, YouTube 및 LinkedIn과 같은 소셜 미디어 웹사이트에 페이지를 보유하고 있습니다. 귀하가 당사의 소셜 미디어 페이지와 상호 작용할 때 당사는 귀하의 연락처 정보("사회 정보")와 같이 귀하가 당사에 제공하기로 선택한 개인 정보를 수집합니다. 또한 당사의 소셜 미디어 페이지를 호스팅하는 회사는 당사의 소셜 미디어 활동에 대한 집계된 정보와 분석을 제공할 수 있습니다.',
    subBlock5:
      '로그 데이터: 당사 서비스 이용 시 귀하의 브라우저가 자동으로 전송한 정보입니다. 로그 데이터에는 귀하의 인터넷 프로토콜(IP) 주소, 브라우저 유형 및 설정, 요청 날짜 및 시간, 귀하가 당사 웹사이트와 상호 작용하는 방식이 포함됩니다.',
    subBlock6:
      '사용 데이터: 당사는 귀하가 보거나 상호 작용하는 콘텐츠 유형, 사용하는 기능, 취하는 조치, 시간대, 국가, 날짜 및 시간 등 귀하의 서비스 사용에 대한 정보를 자동으로 수집할 수 있습니다. 액세스, 사용자 에이전트 및 버전, 컴퓨터 또는 모바일 장치 유형 및 컴퓨터 연결.',
    subBlock7:
      '장치 정보: 여기에는 장치 이름, 운영 체제, 장치 식별자 및 사용 중인 브라우저가 포함됩니다. 수집되는 정보는 귀하가 사용하는 장치 유형과 설정에 따라 달라질 수 있습니다.',
    subBlock8:
      '쿠키: 당사는 서비스를 운영 및 관리하고 귀하의 경험을 개선하기 위해 쿠키를 사용합니다. "쿠키"는 귀하가 방문한 웹사이트가 귀하의 브라우저로 보내는 정보입니다. 귀하는 브라우저를 설정하여 모든 쿠키를 허용하거나, 모든 쿠키를 거부하거나, 쿠키가 제공될 때 이를 통보하여 매번 허용 여부를 결정할 수 있습니다. 그러나 쿠키를 거부하면 웹사이트의 특정 영역이나 기능의 표시나 기능을 사용하지 못하거나 부정적인 영향을 미칠 수 있습니다. 쿠키에 대한 자세한 내용을 보려면 쿠키에 관한 모든 것을 방문하십시오.',
    subBlock9:
      '분석: 당사는 사용자가 당사 서비스를 사용하는 방식을 분석하고 서비스 사용 시 귀하의 경험을 개선하는 데 도움을 주기 위해 쿠키를 사용하는 다양한 온라인 분석 제품을 사용할 수 있습니다.',
    subTitle1: '당사가 수집하는 개인정보',
    subTitle2: '당사가 개인정보를 사용하는 방법',
    subTitle3: '개인정보 공개',
    subTitle4: '귀하의 권리',
    subTitle5: '다른 웹사이트에 대한 링크',
    subTitle6: '정보의 보안 및 보유',
    subTitle7: '개인정보 보호정책 수정',
    subTitle8: '저희에게 연락하는 방법',
    title: '개인 정보 보호 정책',
    updateTime: '업데이트 날짜: 2024년 3월 15일',
  },
  privacyCollectionPanel: {
    acceptBtn: '모두 수락',
    text: '귀하의 경험을 향상시키기 위해 당사는 쿠키를 사용하여 콘텐츠와 서비스를 개인화합니다.',
    title: '이 웹사이트는 쿠키를 사용합니다',
  },
  slogen: '가장 쉬운 AI 비서',
  stripe: {
    contact: '질문이 있으시면 contact@noam.tools로 연락주세요!',
    fail: {
      btn: '다시 해 보다',
      text1: '결제를 처리할 수 없습니다.',
      text2: '연결을 확인하고 다시 시도하세요.',
      title: '결제 실패',
    },
    succ: {
      btn: '갑시다',
      text1: '노암에 오신 것을 환영합니다.',
      text2: '시작하자!',
      title: '축하해요',
    },
  },
  terms: {
    block1: '노암을 이용해 주셔서 감사합니다!',
    block10:
      '(a) 수수료 및 청구. 귀하는 해당 가격 책정 페이지의 가격 및 조건에 따라 또는 당사 간에 서면으로 합의한 대로 모든 수수료("수수료")를 지불해야 합니다. 우리는 송장을 발행했거나 지불금을 받은 경우에도 가격 오류나 실수를 수정할 권리를 보유합니다. 귀하는 유효하고 승인된 지불 방법을 포함하여 완전하고 정확한 청구 정보를 제공해야 합니다. 당사는 합의된 대로 정기적으로 귀하의 지불 방법에 비용을 청구하지만 청구 날짜를 합리적으로 변경할 수 있습니다. 귀하는 Noam, 그 계열사 및 제3자 결제 처리업체가 귀하의 결제 방법으로 요금을 청구하도록 승인합니다. 귀하의 결제가 실패할 경우 당사는 귀하에게 서면 통지를 제공하고 결제가 완료될 때까지 서비스에 대한 액세스를 일시 중지할 수 있습니다. 본 계약에 달리 명시된 경우를 제외하고 결제 금액은 환불되지 않습니다.',
    block11:
      '(b) 세금. 달리 명시하지 않는 한 수수료에는 연방, 주, 지방, 해외 세금, 관세 및 유사한 평가액("세금")이 포함되지 않습니다. 귀하는 당사가 귀하에게 송장을 발행하는 순이익에 기초한 세금을 제외하고 귀하의 구매와 관련된 모든 세금에 대한 책임이 있습니다. 귀하는 해당 세금을 즉시 납부하고 법적으로 유효한 개인 정보 보호 정책을 당사에 제공하며 해당 데이터 처리에 필요한 동의를 얻는 데 동의하며 해당 법률에 따라 해당 데이터를 처리할 것을 당사에 보증합니다.',
    block12:
      '(c) 가격 변경. 당사는 귀하의 계정 및/또는 당사 웹사이트를 통해 귀하에게 통지함으로써 가격을 변경할 수 있습니다. 가격 인상은 게시 즉시 적용됩니다. 모든 가격 변경은 변경 발효일 이후 귀하 계정의 수수료에 적용됩니다.',
    block13:
      '(d) 분쟁 및 연체된 지불. 수수료 또는 세금에 대해 이의를 제기하려면 청구서 날짜로부터 30일 이내에 contact@noam.tools에 문의하십시오.',
    block14:
      '(e) 무료 사용. 무료 크레딧을 얻기 위해 여러 계정을 만들 수 없습니다. 귀하가 무료 크레딧을 선의로 사용하지 않는다고 당사가 판단하는 경우, 당사는 귀하에게 표준 요금을 청구하거나 서비스에 대한 액세스 제공을 중단할 수 있습니다.',
    block15:
      '(a) 기밀성. 귀하는 Noam의 기밀 정보에 접근할 수 있습니다. 귀하는 서비스 이용 목적으로 본 약관에 따라 허용된 기밀 정보만 사용할 수 있습니다. 귀하는 기밀 정보를 제3자에게 공개할 수 없으며, 최소한 합당한 주의를 기울여 자신의 유사한 기밀 정보를 보호하는 것과 마찬가지로 보호적인 방식으로 기밀 정보를 보호해야 합니다. 기밀 정보는 소프트웨어, 사양, 기타 비공개 비즈니스 정보를 포함하여 Noam이 기밀로 지정했거나 상황에 따라 합리적으로 기밀로 취급되어야 하는 비공개 정보입니다. 기밀 정보에는 다음 정보가 포함되지 않습니다. (1) 귀하의 잘못 없이 일반 대중에게 공개되는 정보; (2) 귀하는 본 약관에 따라 정보를 받기 전에 기밀 유지 의무 없이 이를 소유하고 있었습니다. (3) 기밀 유지 의무 없이 제3자에 의해 귀하에게 합법적으로 공개되었습니다. 또는 (4) 기밀 정보를 사용하지 않고 귀하가 독립적으로 개발한 것입니다. 귀하는 법률, 법원 또는 기타 정부 명령에 의해 요구되는 경우 기밀 정보를 공개할 수 있습니다. 그러나 귀하는 합리적인 방식으로 Noam에게 사전 서면 통지를 제공해야 하며 가능한 한 공개 범위를 제한하기 위해 당사에 대한 지원을 포함하여 합당한 노력을 기울여야 합니다. 공개 요청에 반대합니다.',
    block16:
      '(b) 보안. 귀하는 서비스에 대한 액세스 및 사용을 보호하는 데 도움이 되는 합리적이고 적절한 조치를 구현해야 합니다. 서비스 사용과 관련하여 취약점이나 위반 사항을 발견한 경우 즉시 Noam에 연락하여 취약점이나 위반 사항에 대한 세부 정보를 제공해야 합니다.',
    block17:
      '((c) 개인 데이터 처리. 귀하가 개인 데이터를 처리하기 위해 서비스를 사용하는 경우, 귀하는 법적으로 충분한 개인 정보 보호 정책을 제공하고 해당 데이터 처리에 필요한 동의를 얻어야 하며 해당 법률에 따라 해당 데이터를 처리할 것을 당사에 보증합니다. .',
    block18:
      '(a) 종료; 보류. 본 약관은 귀하가 서비스를 처음 사용한 시점부터 유효하며 종료될 때까지 유효합니다. 귀하는 서비스 및 콘텐츠의 사용을 중단하여 언제든지 어떤 이유로든 본 약관을 종료할 수 있습니다. 당사는 어떤 이유로든 사전 통지를 통해 본 약관을 종료할 수 있습니다. 당사는 귀하가 섹션 2(사용 요구 사항), 섹션 5(기밀 유지, 보안 및 데이터 보호), 섹션 8(분쟁 해결) 또는 섹션 9(일반 약관)을 심각하게 위반하는 경우 귀하에게 통지하여 이 약관을 즉시 종료할 수 있습니다. 당사가 통제할 수 없는 변경 사항을 넘어서거나 법률이나 정부 요청을 준수하기 위해 제3자 기술 제공자와의 관계. 귀하가 본 약관을 준수하지 않거나, 귀하의 사용이 당사 또는 제3자에게 보안 위험을 초래하거나, 귀하의 사용이 사기라고 의심되거나 당사 또는 제3자가 책임을 질 수 있는 경우, 당사는 귀하의 서비스 액세스를 일시 중단할 수 있습니다. .',
    block19:
      '(b) 종료의 효과. 종료 시 귀하는 서비스 사용을 중단하고 당사의 지시에 따라 모든 기밀 정보를 즉시 반환하거나 파기해야 합니다. 섹션 3 및 5-9를 포함하되 이에 국한되지 않고 본질적으로 해지 또는 만료 후에도 존속되어야 하는 본 약관의 조항은 존속됩니다.',
    block2:
      '본 이용 약관은 애플리케이션 프로그래밍 인터페이스, 소프트웨어, 도구, 개발자 서비스, 데이터, 문서 및 웹사이트를 포함한 Noam 서비스(통칭하여 "서비스") 사용에 적용됩니다. 당사 서비스를 이용함으로써 귀하는 본 약관을 준수할 것에 동의하게 됩니다. 당사의 개인 정보 보호 정책은 당사가 개인 정보를 수집하고 사용하는 방법을 설명합니다.',
    block20:
      '(a) 면책. 귀하는 귀하의 콘텐츠를 포함하여 귀하의 서비스 사용으로 인해 발생하거나 이와 관련하여 발생하는 모든 청구, 손실 및 비용(변호사 비용 포함)으로부터 당사, 당사 계열사 및 당사 직원을 피해 없이 변호하고 면책하며 보호해야 합니다. 서비스와 관련된 제품 또는 서비스의 사용 및 귀하의 본 약관 또는 해당 법률 위반.',
    block21:
      '(b) 면책조항. 서비스는 "있는 그대로" 제공됩니다. 법률이 허용하는 한도 내에서 당사, 당사의 계열사 및 라이센스 제공자는 서비스와 관련하여 어떠한 종류의 진술이나 보증도 하지 않으며 상품성, 특정 목적에의 적합성, 만족스러운 품질, 비침해에 대한 보증을 포함하되 이에 국한되지 않는 어떠한 보증도 하지 않습니다. , 조용한 즐거움, 거래 과정이나 거래 사용으로 인해 발생하는 모든 보증. 당사는 서비스가 중단되지 않고, 정확하고, 오류가 없다는 점이나 콘텐츠가 안전하고, 손실되지 않거나, 변경되지 않을 것이라고 보증하지 않습니다.',
    block22:
      '(c) 책임의 제한. 당사, 계열사, 라이센스 제공자는 이익 손실, 평판 손실, 사용 손실, 데이터 손실 또는 기타 무형적 손실을 포함하여 간접적, 우발적, 특수적, 결과적 또는 징벌적 손해에 대해 책임을 지지 않습니다. 우리는 그러한 손해가 발생할 가능성에 대해 통보받았습니다. 본 약관에 따라 당사의 총 책임은 청구 전 12개월 동안 귀하가 서비스에 지불한 요금 또는 미화 100달러($100) 중 더 큰 금액을 초과하지 않습니다. 이 섹션의 제한 사항은 해당 법률에서 허용하는 최대 범위까지 적용됩니다.',
    block23:
      '본 약관 및 귀하의 서비스 이용에는 미국 법률 충돌 규정을 제외하고 미국 법률이 적용됩니다. 귀하의 애플리케이션 사용에는 기타 지역, 주, 국가 또는 국제법이 적용될 수도 있습니다. 본 약관과 관련된 모든 법적 조치 또는 절차는 미국 법원에서만 진행되며 귀하는 해당 법원의 대인 관할권에 따르는 데 동의합니다.',
    block24:
      '(a) 당사자의 관계. Noam과 ​​귀하는 독립 계약자이며, 어느 당사자도 상대방의 사전 서면 동의 없이 상대방을 대표하거나 구속할 권한이 없으며 상대방에 대한 의무를 질 수 없습니다.',
    block25:
      '(b) 브랜드 사용. 귀하는 당사의 사전 서면 동의 없이 Noam 또는 그 계열사의 이름, 로고 또는 상표를 사용할 수 없습니다.',
    block26:
      '본 약관의 일부 조항이 유효하지 않거나, 불법적이거나, 시행할 수 없는 것으로 판단되는 경우에도 나머지 조항은 완전한 효력을 유지합니다.',
    block3:
      '귀하가 18세 미만인 경우 서비스를 이용하려면 부모 또는 법적 보호자로부터 허가를 받아야 합니다. 귀하가 다른 개인이나 단체를 대신하여 서비스를 사용하는 경우 귀하는 해당 개인이나 단체를 대신하여 본 약관에 동의할 수 있는 권한을 받아야 합니다. 계정에 등록하려면 정확하고 완전한 정보를 제공해야 합니다. 귀하는 조직 외부의 누구에게도 귀하의 액세스 자격 증명이나 계정을 제공할 수 없으며 귀하의 자격 증명을 사용하여 수행된 모든 활동에 대한 책임은 귀하에게 있습니다.',
    block4:
      '(a) 서비스 이용. 귀하는 본 약관에 따라 서비스에 액세스할 수 있으며 당사는 귀하에게 서비스를 사용할 수 있는 비독점적 권리를 부여합니다. 서비스를 이용할 때 귀하는 본 약관과 모든 관련 법률을 준수해야 합니다. 당사와 계열사는 서비스에 대한 모든 권리, 소유권 및 이익을 보유합니다.',
    block5:
      '(b) 피드백. 우리는 피드백, 의견, 아이디어, 제안 및 개선 사항을 환영합니다. 귀하가 그러한 콘텐츠를 제공하는 경우 당사는 어떠한 제한이나 보상 없이 해당 콘텐츠를 사용할 수 있습니다.',
    block6:
      '(c) 제한사항. 귀하는 다음을 수행할 수 없습니다. (1) 타인의 권리를 침해, 유용 또는 위반하는 방식으로 서비스를 사용하는 것; (2) 서비스 모델, 알고리즘 및 시스템의 소스 코드나 기본 구성요소를 발견하기 위한 리버스 엔지니어링, 디컴파일, 분해, 번역 또는 기타 시도(해당 법률에 의해 그러한 제한이 금지되지 않는 한) (3) 서비스의 결과물을 사용하여 Noam과 ​​경쟁하는 모델을 개발합니다. (4) 스크래핑, 웹 수확 또는 웹 데이터 추출을 포함하여 API를 통해 허용되지 않는 한 자동화된 방법이나 프로그래밍 방법을 사용하여 서비스에서 데이터 또는 출력을 추출합니다. (5) 서비스의 출력이 인위적으로 생성되지 않았음에도 불구하고 인위적으로 생성된 것으로 표현하거나 당사의 사용 정책을 위반하는 행위, (6) 사전 동의 없이 API 키를 구매, 판매 또는 양도합니다. 또는 (7) 13세 미만 또는 해당 디지털 동의 연령 미만 어린이의 개인 정보를 당사에 제공합니다. 귀하는 당사 문서에 명시된 요금 제한 및 기타 요구 사항을 준수해야 합니다.',
    block7:
      '(d) 제3자 서비스. 서비스와 관련된 모든 제3자 소프트웨어, 서비스 또는 기타 제품에는 자체 약관이 적용되며 당사는 제3자 제품에 대해 책임을 지지 않습니다.',
    block8:
      '(a) 귀하의 콘텐츠. 귀하는 서비스에 입력("입력")을 제공하고 입력("콘텐츠"로 통칭)을 기반으로 생성 및 반환된 출력("출력")을 받을 수 있습니다. 당사자 간에, 그리고 해당 법률이 허용하는 한도 내에서 귀하는 모든 입력물을 소유합니다. Noam은 서비스를 제공 및 유지하고 관련 법률을 준수하며 정책을 시행하기 위해 콘텐츠를 사용할 수 있습니다. 귀하는 해당 법률이나 본 약관을 위반하지 않는지 확인하는 것을 포함하여 콘텐츠에 대한 책임을 집니다.',
    block9:
      '(b) 정확성. 인공지능과 머신러닝은 빠르게 발전하는 연구 분야입니다. 우리는 서비스를 더욱 정확하고 신뢰할 수 있으며 안전하고 유익하게 만들기 위해 지속적으로 노력하고 있습니다. 기계 학습의 확률적 특성을 고려할 때 당사 서비스를 사용하면 경우에 따라 잘못된 출력이 발생할 수 있으며, 이는 실제 개인, 장소 또는 사실을 정확하게 반영하지 못할 수 있습니다. 출력을 수동으로 검토하는 등 사용 사례에 따라 출력의 정확성을 적절하게 평가해야 합니다.',
    subTitle1: '등록 및 액세스',
    subTitle10: '분리 가능성',
    subTitle2: '사용 요구 사항',
    subTitle3: '콘텐츠',
    subTitle4: '수수료 및 결제',
    subTitle5: '기밀성, 보안 및 데이터 보호',
    subTitle6: '기간 및 종료',
    subTitle7: '보증; 면책조항 책임의 제한',
    subTitle8: '준거법 및 관할권',
    subTitle9: '일반 약관',
    title: '서비스 약관',
    updateTime: '업데이트 날짜: 2024년 3월 15일',
  },
  trialModal: {
    btn: '지금 시작하세요(단 $0.99)',
    chat: '채팅PDF',
    desc1: '평가판 사용 후에는 연간 $180의 수수료가 부과됩니다.',
    desc2: '언제든지 취소',
    focus: '집중',
    hover: '호버 번역',
    image: '이미지 번역',
    installBtn: '지금 설치하고 선물을 받으세요',
    memo: '즐겨찾기 관리',
    pdf: 'PDF 병렬 번역',
    realtime: '즉석 번역',
    select: '단어 선택 번역',
    title: '환영 선물',
    trialText: '7일 평가판',
    video: '비디오 번역',
    videoSummary: '영상 요약',
    web: '병렬 번역',
    webSummary: '웹 요약',
  },
  upgradeModal: {
    btn: '지금 업그레이드',
    chat: { free: 'ChatPDF 한도에 도달했습니다.', pro: '이번 달 ChatPDF 한도에 도달했습니다.' },
    contactStr: '다음 재설정을 기다리시거나 contact@noam.tools로 문의해 주세요.',
    limitAlertStr: '무료 요금제 한도에 도달했습니다. 계속하려면 업그레이드하세요.',
    more: '계획 보기',
    pageLimit: { free: 'PDF 페이지 제한에 도달했습니다.' },
    title: '알림',
    upgradeAlertStr: '무료 요금제 한도에 도달했습니다. 계속하려면 업그레이드하세요.',
    upload: { free: 'PDF 번역 한도에 도달했습니다.', pro: '이번 달 PDF 번역 한도에 도달했습니다.' },
  },
  welfare: {
    newUser: {
      joinBtn: '지금 가입하세요',
      label: '신규 사용자 혜택:',
      remain: '30개만 남았습니다',
      text: '단 100개 자리만 이용 가능! 지금 바로 구매하세요!',
    },
  },
}

export default TRANSLATION
