const TRANSLATION = {
  buyModal: {
    afterFree: 'Après votre essai gratuit',
    btn: 'Essayez-le gratuitement',
    freeTrial: 'Essai gratuit de 3 jours',
    monthLabel: 'mois',
    selectLabel: 'Sélectionnez un forfait',
    title: "Plan d'abonnement",
    yearLabel: 'année',
  },
  contact: {
    block1:
      'Pour une personnalisation, des questions ou des suggestions, veuillez nous contacter à contact@noam.tools. Nous vous répondrons rapidement.',
    block2:
      "Soutenez-nous en nous évaluant sur la boutique d'extensions Google Apps à l'adresse ★★★★★.",
    block3: 'Aidez les autres en partageant cette extension :',
    title: 'Contactez-nous',
  },
  help: {
    AquickOverviewOfNoam: 'Un aperçu rapide de Noam',
    ChatPDF: 'Chat PDF',
    ChatPDF2: 'ChatPDF',
    FavoritesManagement: 'Gestion des favoris',
    Fixedplugin: 'Plugin fixe',
    Focusing: 'Mise au point',
    Free: 'Gratuit',
    FreeTrialBenefits: "Avantages de l'essai gratuit",
    GoWithWikipedia: 'Allez avec Wikipédia',
    GoWithYouTube: 'Allez avec YouTube',
    HoverTranslation: 'Traduction du survol',
    Howtouse: 'Comment utiliser',
    ImageTranslation: 'Traduction d’images',
    InstantTranslation: 'Traduction instantanée',
    Membership: 'Adhésion',
    NoamHelp: 'Noam vous aide à traduire et résumer',
    PDFparallelTranslation: 'Traduction parallèle PDF',
    ParallelTranslation: 'Traduction parallèle',
    SkipAllSteps: 'Passer toutes les étapes',
    Summary: 'Résumé',
    Translation: 'Traduction',
    TryItForFree: 'Essayez-le gratuitement',
    Video: 'Vidéo',
    VideoSummary: 'Résumé vidéo',
    VideoTranslation: 'Traduction vidéo',
    WebSummary: 'Résumé Web',
    WordSelectionTranslation: 'Traduction de sélection de mots',
    benefits: 'Nous avons des avantages qui vous attendent !',
    dayFree: 'Essai gratuit de 3 jours',
    done: 'Fait',
    next: 'Suivant',
    pin: 'Épinglez Noam pour un accès plus facile～～',
    planIsNotExist: "Le forfait n'est pas disponible",
    startWiki: 'Commençons par Wikimédia !',
    startYoutube: 'Commençons par YouTube !',
    try: 'Essayez-le',
    tryNow: 'Essayez-le maintenant !',
  },
  home: {
    PDFTranslate: {
      desc: 'Traduction Bilingue + Chat PDF',
      sub1: {
        desc: "La lecture bilingue vous permet de comparer le texte original et la traduction côte à côte, améliorant ainsi l'efficacité de la lecture.",
        title: 'Lecture bilingue',
      },
      sub2: {
        desc: "L'IA analyse et résume et peut répondre à vos questions en fonction du contenu du PDF.",
        title: 'Chat PDF',
      },
      sub3: {
        desc: "Traduction PDF basée sur l'IA pour une plus grande précision et efficacité.",
        title: 'Précision et efficacité',
      },
      title: 'PDF Traduire',
    },
    aiAssistant: {
      desc: 'Rendre la traduction Web plus intelligente et plus pratique',
      panel1: {
        desc: 'Les traductions bilingues avec références croisées vous aident à comparer le texte original avec la traduction afin que vous puissiez mieux comprendre et améliorer votre efficacité de lecture.',
        title: 'Traduction bilingue',
      },
      panel2: {
        left: {
          desc: 'Lorsque vous parcourez une page Web, survolez le contenu que vous devez traduire pour voir la traduction.',
          title: 'Traduction du survol',
        },
        right: {
          desc: 'Lorsque vous parcourez une page Web, sélectionnez le texte que vous souhaitez traduire pour voir la traduction.',
          title: 'Traduction de sélection de texte',
        },
      },
      panel3: {
        one: {
          desc: 'Traduisez et résumez des pages Web et du contenu vidéo à tout moment et en tout lieu, pour une expérience plus fluide !',
          title: 'Pointer et cliquer',
        },
        three: {
          desc: "Un grand modèle linguistique basé sur l'IA garantit l'exactitude et la cohérence de la traduction pour une expérience de lecture améliorée.",
          title: "Piloté par l'IA",
        },
        two: {
          desc: 'Couverture complète multilingue, lecture bilingue sans obstacle, traduction en un clic : découvrez la différence !',
          title: 'Plus complet et plus pratique',
        },
      },
      panel4: {
        tab1: {
          desc: 'Votre expert en efficacité – grâce à ChatGPT, Noam traduit et résume le contenu vidéo YouTube pour vous faire gagner du temps !',
          key: 'Vidéo',
          title: 'Traduction et résumé vidéo',
        },
        tab2: {
          desc: 'Cliquez pour traduire des images lorsque vous naviguez sur le Web.',
          key: 'Image',
          title: 'Image Traduire',
        },
        tab3: {
          desc: 'Extrait instantanément les informations clés des pages Web et fournit un résumé, améliorant ainsi votre efficacité de lecture.',
          key: 'Internet',
          title: 'Traduction et résumé Web',
        },
        tag1: 'Simple',
        tag2: 'Efficace',
        tag3: 'Facile à comprendre',
      },
      title: 'Assistant IA',
    },
    getStart: 'Commencer',
    getStartedForFree: 'Commencez gratuitement',
    howItWork: {
      desc: "Noam intègre des modèles d'IA pour offrir aux utilisateurs une expérience intelligente pour la traduction et le résumé de pages Web multilingues, la traduction de PDF et d'images, la traduction et le résumé de vidéos.",
      title: 'Comment ça marche',
    },
    lastBlock: { desc: 'Une meilleure traduction IA pour une expérience de lecture plus fluide' },
    top: {
      desc: 'Noam est une extension de navigateur IA gratuite qui vous aide à traduire et à résumer des pages Web, des vidéos et du contenu PDF.\\n Rejoignez Noam et vivez la magie !',
      title1: 'Une magie',
      title2: 'Un clic',
    },
    userReviews: {
      desc: 'Avis cinq étoiles sur le Chrome Web Store',
      title: 'Avis des utilisateurs',
    },
  },
  installTips: {
    btn: 'Essayez-le maintenant',
    text: 'Installez « Noam Browser Plugin » pour débloquer plus de fonctionnalités ! Références croisées Web,\\n résumés de vidéos Web et bien plus !',
  },
  locale: {
    af: 'afrikaans',
    am: 'Amharique',
    ar: 'arabe',
    as: 'Assamais',
    auto: 'Détection automatique',
    az: 'azerbaïdjanais',
    ba: 'Bachkir',
    bg: 'bulgare',
    bn: 'Bangla',
    bo: 'Tibétain',
    bs: 'bosniaque',
    ca: 'catalan',
    cs: 'tchèque',
    cy: 'gallois',
    da: 'danois',
    de: 'Allemand',
    dsb: 'Bas-sorabe',
    dv: 'Divéhi',
    el: 'grec',
    en: 'Anglais',
    es: 'Espagnol',
    et: 'estonien',
    eu: 'Basque',
    fa: 'persan',
    fi: 'finlandais',
    fil: 'Philippin',
    fj: 'Fidjien',
    fr: 'Français',
    frCA: 'Français (Canada)',
    ga: 'irlandais',
    gl: 'Galicien',
    gom: 'Konkani',
    gu: 'gujarati',
    ha: 'Haoussa',
    he: 'hébreu',
    hi: 'hindi',
    hr: 'croate',
    hsb: 'Haut-sorabe',
    ht: 'Créole haïtien',
    hu: 'hongrois',
    hy: 'arménien',
    id: 'indonésien',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandais',
    it: 'italien',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latin)',
    ja: 'japonais',
    ka: 'géorgien',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurde (du Nord)',
    kn: 'Kannada',
    ko: 'coréen',
    ku: 'Kurde (central)',
    ky: 'Kirghize',
    ln: 'Lingala',
    lo: 'Laotien',
    lt: 'lituanien',
    lug: 'Ganda',
    lv: 'letton',
    lzh: 'Chinois (littéraire)',
    mai: 'Maïthili',
    mg: 'malgache',
    mi: 'Maori',
    mk: 'Macédonien',
    ml: 'Malayalam',
    mnCyrl: 'Mongol (cyrillique)',
    mnMong: 'Mongol (traditionnel)',
    mr: 'Marathi',
    ms: 'malais',
    mt: 'maltais',
    mww: 'Hmong Daw',
    my: 'Myanmar (birman)',
    nb: 'norvégien',
    ne: 'Népalais',
    nl: 'Néerlandais',
    noSearchLang: 'Langue non prise en charge',
    nso: 'Sesotho à Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Pendjabi',
    pl: 'polonais',
    placeholder: 'Recherche',
    prs: 'Dari',
    ps: 'pachtou',
    pt: 'Portugais (Brésil)',
    ptPT: 'Portugais (Portugal)',
    ro: 'roumain',
    ru: 'russe',
    run: 'Rundi',
    rw: 'kinyarwanda',
    sd: 'Sindhi',
    si: 'Cinghalais',
    sk: 'slovaque',
    sl: 'slovène',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanais',
    srCyrl: 'Serbe (cyrillique)',
    srLatn: 'serbe (latin)',
    st: 'Sésotho',
    sv: 'suédois',
    sw: 'Swahili',
    ta: 'Tamoul',
    te: 'Télougou',
    th: 'thaïlandais',
    ti: 'Tigrinya',
    tk: 'Turkmènes',
    tlhLatn: 'Klingon (latin)',
    tlhPiqd: 'Klingon (pIqaD)',
    tn: 'Setswana',
    to: 'Tongien',
    tr: 'turc',
    tt: 'tatar',
    ty: 'tahitien',
    ug: 'Ouïghour',
    uk: 'ukrainien',
    ur: 'Ourdou',
    uz: 'Ouzbek (latin)',
    vi: 'vietnamien',
    xh: 'Xhosa',
    yo: 'Yorouba',
    yua: 'Maya du Yucatèque',
    yue: 'Cantonais (traditionnel)',
    zh: 'Chinois',
    zhHans: 'Chinois simplifié',
    zhHant: 'Chinois traditionnel',
    zu: 'zoulou',
  },
  login: {
    and: 'et',
    btn: 'Connectez-vous avec Google',
    btn1: 'Connectez-vous maintenant',
    desc: "Connectez-vous pour débloquer plus d'expériences",
    policy: 'politique de confidentialité',
    terms: "Conditions d'utilisation",
    tips: "En m'inscrivant, j'accepte le",
    title: 'Bienvenue à Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Suivre', twitter: 'Gazouillement' },
    legal: {
      policy: 'politique de confidentialité',
      terms: "Conditions d'utilisation",
      title: 'Légal',
    },
    product: {
      image: 'Traduction d’images',
      pdf: 'Traduction PDF',
      title: 'Produits',
      video: 'Traduction et résumé vidéo',
      web: 'Traduction Web et résumé',
    },
    resources: { title: 'Ressources' },
  },
  pageHeader: {
    contact: 'Contact',
    help: 'Aide',
    logout: 'Se déconnecter',
    logoutSucc: 'Déconnecté.',
    pricing: 'Tarifs',
    settings: 'Paramètres',
    signUp: 'Se connecter',
  },
  pdf: {
    autoDetect: 'Détection automatique',
    cancelBtn: 'Annuler',
    cancelUpload: 'Annuler',
    chatPdf: {
      Search: 'Recherche',
      cancel: 'Annuler',
      chatTitle: 'Bienvenue à Noam ! Voici un résumé de votre PDF :',
      clear: 'Clair',
      clearContent:
        'Cela démarrera une nouvelle conversation. La conversation en cours ne sera pas enregistrée. Es-tu sûr?',
      clearDialogTitle: 'Veuillez confirmer',
      clearSucc: 'Effacé',
      copy: 'Copie',
      copySucc: 'Copié',
      delete: 'Supprimer',
      deleteCancel: 'Annuler',
      deleteContent: 'Ce PDF ne peut pas être récupéré une fois supprimé. Es-tu sûr?',
      deleteOk: 'Supprimer',
      deleteSuccess: 'Supprimé',
      deleteTitle: 'Confirmer la suppression',
      expand: 'Effondrement',
      historyTitle: 'Histoire',
      listError:
        "Échec du chargement de la liste de lecture. Veuillez réessayer plus tard ou contacter l'assistance.",
      loading: 'Chargement...',
      noDataDesc: "Pas d'historique pour l'instant.",
      noMore: 'Plus de contenu.',
      placeholder: "Demandez n'importe quoi sur ce PDF",
      summaryTitle: 'Ces questions pourraient vous intéresser :',
    },
    collectionEmpty: 'Votre collection est vide.',
    collectionTab: 'Remarques',
    dataLoading: 'Chargement...',
    dialogContent: 'Êtes-vous sûr de vouloir supprimer ceci ?',
    dialogTitle: 'Veuillez confirmer',
    download: {
      both: 'Bilingue',
      btn: 'Sauvegarder',
      cancel:
        'La fermeture de cette fenêtre annulera le téléchargement du PDF. Êtes-vous sûr de vouloir annuler ?',
      desc: "Enregistrer sur l'appareil : télécharge les traductions sous forme d'images ; Enregistrer et imprimer : télécharge les traductions dans le format original, avec prise en charge de la copie.",
      download: 'Télécharger',
      downloadMsg1: 'Progrès de la traduction PDF :',
      downloadMsg2: 'Temps restant estimé :',
      downloadSucc: 'Le PDF est prêt ! Cliquez pour enregistrer.',
      downloadTip:
        'Téléchargement du PDF. Ne fermez pas cette fenêtre pour éviter les erreurs de téléchargement.',
      error: "Échec de l'enregistrement. Essayez plutôt d'imprimer.",
      fileName: 'Nom de fichier:',
      fileType: 'Type de fichier :',
      loading: 'Économie...',
      loading2: 'Chargement...',
      minute: 'Minutes...',
      msg1: 'Certaines traductions de ce document sont plus longues que le texte original et peuvent se chevaucher dans le fichier téléchargé. Nous vous recommandons de lire sur la page Web.',
      msg2: "1. La traduction n'est pas encore terminée. Veuillez télécharger une fois la traduction terminée.",
      msg3: '2. Certaines traductions sont trop longues. Lisez sur la page Web pour éviter les chevauchements de texte.',
      msg4: "Des tâches de traduction sont en cours. Veuillez réessayer une fois qu'ils auront terminé.",
      progress:
        'Traduction de PDF. Progression : {{progress}}, Temps restant estimé : {{minutes}} minutes...',
      save: "Enregistrer sur l'appareil",
      savePrint: 'Enregistrer et imprimer',
      success: 'Téléchargé',
      trans: 'Traduction uniquement',
      transContinue: 'Continuer la traduction',
    },
    downloadBtn: 'Télécharger',
    dragTipModal: {
      black: 'espace',
      leftKey: 'bouton gauche',
      ok: "J'ai compris",
      press: 'Presse',
      tips: 'à faire glisser et à visualiser.',
    },
    entry: 'PDF',
    fileErrMsg:
      "La lecture du fichier a échoué. Veuillez réessayer plus tard ou contacter l'assistance.",
    fileMsg: 'Taille actuelle du fichier :',
    fileMsg2: 'Veuillez télécharger un fichier inférieur à 300 Mo.',
    freeBannerBtn: 'Mise à niveau',
    freeBannerTips: 'Limite de traductions PDF gratuites atteinte',
    guide: {
      placeholder: 'Collez le lien PDF ici',
      start: 'Commencer la traduction',
      text1: 'Lecture bilingue',
      text2:
        'Lisez de manière bilingue pour comparer le texte original et la traduction côte à côte, améliorant ainsi votre efficacité.',
      text3: 'Discutez avec votre PDF',
      text4:
        "Analyse et synthèse basées sur l'IA. Posez des questions sur votre PDF et obtenez des réponses instantanées.",
      text5: 'Précis et efficace',
      text6: "Traduction PDF basée sur l'IA pour une plus grande précision et efficacité.",
      title1: 'Traduction bilingue',
      title2: 'Discutez avec votre PDF',
      toast: 'Veuillez saisir un lien PDF en ligne valide se terminant par .pdf',
    },
    hasTransTask: "Une tâche de traduction est en cours. S'il vous plaît, attendez.",
    hoverTrans: 'Survoler pour traduire des paragraphes',
    initial: 'Traduire un PDF',
    installText: 'Votre solution unique pour les traductions quotidiennes.',
    installText2: "Veuillez d'abord installer l'extension",
    installTips:
      "Installez l'extension pour encore plus de traductions PDF et des traductions de sites Web plus fluides :",
    kw: 'agrafe',
    link: 'lien',
    listError:
      "Échec du chargement des PDF. Veuillez réessayer plus tard ou contacter l'assistance.",
    memoTotalCount: '1 article au total',
    new: 'nouveau fichier',
    noId: 'ID de fichier introuvable.',
    notPdf: 'Fichiers PDF uniquement.',
    okBtn: 'Supprimer',
    okBtn2: "J'ai compris",
    original: 'Original',
    parallel: 'Parallèle',
    parseErrDialogContent:
      'Ce lien PDF ne peut pas être traduit directement. Veuillez télécharger le PDF et le télécharger pour traduction.',
    parseErrTips: "Échec de l'analyse du fichier",
    parseFileToast:
      "Échec de l'analyse du fichier. Veuillez réessayer plus tard ou contacter l'assistance.",
    pdfLoading: 'Analyse...',
    searchPlaceholder: 'Recherche',
    selectTrans: 'Sélectionnez le texte à traduire',
    shareBtn: 'Partager',
    shareBtnText1: 'Générer un lien de partage',
    shareBtnText2: 'Mettre à jour le contenu partagé',
    shareModal: {
      cancel: 'Annuler',
      copy: 'Copie',
      copySucc: 'Lien copié',
      custom: 'Personnaliser :',
      errMsg:
        "Le contenu sélectionné n'a pas encore été entièrement traduit. Veuillez traduire avant de partager.",
      gen: 'Partager',
      modify: 'Modifier',
      notSupport: "Cette langue n'est actuellement pas prise en charge.",
      page: 'Pages',
      pageNuErr: 'Veuillez entrer un numéro de page valide.',
      range: 'Portée:',
      remain: 'restant',
      remain1: 'Durée totale estimée :',
      search: 'Recherche',
      settings: 'Paramètres de partage :',
      shareSetting: 'Paramètres de partage',
      shareText: 'Partager',
      shareTips: 'Partagez le lien PDF traduit avec vos amis :',
      sourceLang: 'Depuis:',
      target: 'À:',
      text1: 'Paramètres de partage',
      text2: 'Générer un lien de partage',
      totalPage: '1 page au total',
      trans: 'Traduire',
      translateTips:
        'Astuce : Si la traduction prend du temps, vous pouvez fermer cette fenêtre et la partager plus tard.',
      translating: 'Traduction de PDF...',
      update: 'Mettre à jour le lien de partage',
    },
    shareMsg1: 'Lien de partage généré.',
    shareMsg2: 'Contenu partagé mis à jour.',
    shareMsg3:
      "Échec de la génération du lien de partage. Veuillez réessayer plus tard ou contacter l'assistance.",
    shareMsg4:
      "Échec de la mise à jour du contenu partagé. Veuillez réessayer plus tard ou contacter l'assistance.",
    shareTips: 'Votre traduction PDF est prête ! Partagez-le avec vos amis.',
    start: 'Commencez votre essai gratuit',
    thumbnail: 'Table des matières',
    toast1: 'Chargement du PDF. Veuillez réessayer plus tard.',
    toast2:
      "Le contenu PDF est trop volumineux. La boîte de dialogue n'est actuellement pas prise en charge.",
    toast3: 'Téléchargement. Veuillez réessayer une fois le téléchargement terminé.',
    toolbar: { Adjust: 'Ajuster', autoFit: 'Ajustement automatique' },
    trans: 'Traduction',
    transSucc: 'Traduction réussie !',
    unLoginToast: 'Veuillez vous connecter pour utiliser cette fonctionnalité.',
    uploadBtn: 'Télécharger',
    uploadErr:
      "Le téléchargement du fichier a échoué. Veuillez réessayer plus tard ou contacter l'assistance.",
    uploadMsg1: 'Faites glisser et déposez votre fichier ici.',
    uploadMsg2: 'Types de fichiers pris en charge : PDF |  Taille maximale du fichier : 100 Mo',
    uploadMsg3: 'Cliquez pour sélectionner ou glisser-déposer des fichiers ici.',
    uploading: 'Téléchargement',
    uploadingMsg: "Téléchargement. S'il vous plaît, attendez.",
    uploadingMsg1: 'Le fichier est en cours de téléchargement. Veuillez réessayer plus tard.',
    uploadingMsg2: 'Un fichier est en cours de conversion. Veuillez réessayer plus tard.',
    uploadingMsg3:
      "La demande de progression de la conversion a échoué. Veuillez réessayer plus tard ou contacter l'assistance.",
    uploadingMsg4:
      'Noam ne peut pas accéder aux fichiers locaux. Veuillez télécharger manuellement un PDF pour traduction.',
    uploadingMsg5:
      "Échec de la récupération de l'URL du fichier. Veuillez réessayer plus tard ou contacter l'assistance.",
    video: 'vidéo',
    vipBannerBtn: 'Mise à niveau',
    vipBannerTips: 'Vous avez atteint votre limite de traduction PDF pour ce mois.',
    webTrans: 'Référence croisée complète',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Oui, Noam propose un forfait gratuit que vous pouvez utiliser immédiatement.',
      a2: "Nous proposons trois plans d'adhésion : Pro, Pro+ et Enterprise. Vous pouvez choisir de payer mensuellement ou annuellement. La facturation annuelle permet des économies significatives.",
      a3: 'Toutes les fonctionnalités sont disponibles sur les deux forfaits. La principale différence réside dans le coût : les abonnements annuels coûtent globalement moins cher. Les abonnements mensuels sont facturés chaque mois, tandis que les forfaits annuels sont facturés une fois par an.',
      a4: 'Vous pouvez annuler votre abonnement à tout moment dans les paramètres de votre compte.',
      q1: 'Existe-t-il un forfait gratuit ?',
      q2: 'Quels plans tarifaires et options de paiement sont disponibles ?',
      q3: 'Quelle est la différence entre un abonnement mensuel et annuel ?',
      q4: 'Comment résilier mon abonnement ?',
    },
    around: 'Autour',
    billedMonthly: 'Facturé mensuellement',
    billedYearly: 'Facturé annuellement',
    day: 'jour',
    desc: 'Comparez les forfaits et choisissez le meilleur',
    detailedCompare: 'Comparez les forfaits',
    discount: '20 % de réduction',
    enterprise: {
      benefit1: 'Assistance client prioritaire',
      benefit2: 'Plusieurs utilisateurs',
      benefit3: 'Solutions sur mesure',
      benefit5: 'Gestionnaire de compte dédié',
      btn: 'Contactez-nous',
      recommendText: 'Forfaits personnalisés（≥3人）',
      talkToUs: 'Entrer en contact',
    },
    free: {
      benefit1: 'Traduction instantanée 100 fois/jour',
      benefit2: 'Traduction et résumé du site Web',
      benefit3: "30 traductions d'images",
      benefit4: 'Traduction et résumé vidéo',
      benefit5: '10 traductions PDF',
      benefit6: '5 000 jetons',
      btn: 'Essayez-le gratuitement',
      recommendText: 'Essai gratuit',
    },
    imageTranslation: 'Traduction d’images',
    month: 'mois',
    monthly: 'Mensuel',
    pageTitle: 'Tarifs',
    pdf: {
      chat: 'Requêtes de discussion PDF',
      maxPage: 'Max. Pages par PDF',
      translation: 'Traduction PDF',
    },
    preMonth: 'Par mois',
    pro: {
      benefit1: 'Assistance client prioritaire',
      benefit2: '1 000 000 de jetons/mois',
      benefit3: 'Traduction et résumé du site Web',
      benefit4: "200 traductions d'images",
      benefit5: 'Traduction et résumé vidéo',
      benefit6: '200 traductions PDF',
      btn: 'Commencer',
      recommendText: 'Les plus populaires',
    },
    proPlus: {
      benefit1: 'Assistance client prioritaire',
      benefit2: '3 000 000 de jetons/mois',
      benefit3: 'Traduction et résumé du site Web',
      benefit4: "500 traductions d'images",
      benefit5: 'Traduction et résumé vidéo',
      benefit6: '500 traductions PDF',
      recommendText: 'Meilleur rapport qualité-prix',
    },
    video: { summary: 'Résumé vidéo', translation: 'Traduction vidéo' },
    web: {
      contrastiveTranslation: 'Traduction bilingue',
      dictionaryTranslation: 'Traduction de sélection',
      hoverTranslation: 'Traduction du survol',
      instantTranslation: 'Traduction instantanée',
      summary: 'Résumé de la page Web',
    },
    yearly: 'Annuel',
  },
  privacy: {
    block1:
      "Noam (« Noam », « nous », « notre » ou « notre ») respecte votre vie privée et s'engage à protéger toute information que nous obtenons de vous ou vous concernant. Cette politique de confidentialité décrit nos pratiques concernant la collecte ou l'utilisation d'informations personnelles provenant de votre utilisation de notre site Web, de nos applications et de nos services (collectivement, les « Services »). Cette politique de confidentialité ne s'applique pas au contenu que nous traitons au nom de nos clients de produits professionnels, tels que notre API. L'utilisation de ces données est soumise à notre accord client qui couvre l'accès et l'utilisation de ces produits.",
    block10:
      "Nous mettons en œuvre des mesures techniques, administratives et organisationnelles commercialement raisonnables pour protéger les informations personnelles en ligne et hors ligne contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, l'altération ou les actes hostiles. Cependant, aucune transmission par Internet ou par courrier électronique ne peut garantir une sécurité ou une exactitude totale. En particulier, les e-mails qui nous sont envoyés ou que nous émanons peuvent ne pas être sécurisés. Vous devez donc être particulièrement prudent lorsque vous décidez quelles informations nous envoyer via le service ou par courrier électronique. De plus, nous ne sommes pas responsables de toute obstruction des paramètres de confidentialité ou des mesures de sécurité dans le service ou des paramètres de sécurité ou des mesures de sécurité des sites Web tiers. Nous conserverons vos informations personnelles aussi longtemps que nécessaire pour vous fournir des services ou à d'autres fins commerciales légitimes, telles que la résolution de litiges, des raisons de sécurité et de sûreté, ou le respect de nos obligations légales. La durée de conservation des informations personnelles dépend de nombreux facteurs, tels que la quantité, la nature, la sensibilité des informations, les risques potentiels d'utilisation ou de divulgation non autorisée, les finalités pour lesquelles nous traitons les informations et d'autres considérations juridiquement contraignantes.",
    block11:
      'Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Lorsque nous le ferons, nous publierons une version mise à jour sur cette page, sauf disposition contraire de la loi applicable.',
    block12:
      'Si vous avez des questions ou des préoccupations sans réponse concernant cette politique de confidentialité, veuillez contacter notre service client. E-mail:',
    block2:
      'Nous collectons les informations personnelles suivantes (« Informations personnelles ») vous concernant :',
    block3:
      'Informations fournies par vous : Lorsque vous créez un compte pour utiliser nos services ou communiquer avec nous, nous collectons des informations personnelles comme suit :',
    block4:
      'Informations personnelles reçues automatiquement lors de votre utilisation des services : lorsque vous accédez, utilisez ou interagissez avec les services, nous recevons les informations suivantes concernant votre accès, votre utilisation ou votre interaction ("Informations techniques") :',
    block5: 'Nous pouvons utiliser les informations personnelles aux fins suivantes :',
    block6:
      "Informations résumées ou anonymisées : nous pouvons regrouper ou anonymiser des informations personnelles pour empêcher leur réutilisation à des fins d'identification et utiliser ces informations pour analyser l'efficacité de nos services, améliorer et ajouter des fonctionnalités à nos services, mener des recherches et à des fins similaires. . De plus, nous pouvons analyser le comportement général et les caractéristiques des utilisateurs de nos services et partager des informations agrégées avec des tiers, publier ces informations agrégées ou les rendre généralement accessibles. Nous pouvons collecter des informations agrégées via les services, via des cookies et par d'autres moyens décrits dans la présente politique de confidentialité. Nous conserverons et utiliserons les informations anonymisées sous une forme anonyme ou anonymisée, et nous ne tenterons pas de réidentifier ces informations, sauf si la loi l'exige.",
    block7:
      "Dans certains cas, nous pouvons fournir vos informations personnelles à des tiers sans préavis, sauf si la loi l'exige :",
    block8:
      "En fonction de votre situation géographique, les personnes vivant dans l'Espace économique européen, au Royaume-Uni et dans le monde peuvent bénéficier de certains droits légaux concernant leurs informations personnelles. Par exemple, vous pourriez avoir le droit de :",
    block9:
      "Le service peut contenir des liens vers d'autres sites Web non exploités ou contrôlés par Noam, y compris des services de médias sociaux (« Sites Web tiers »). Les informations que vous partagez avec des sites Web tiers seront régies par les politiques de confidentialité spécifiques et les conditions de service des sites Web tiers, et non par la présente politique de confidentialité. Nous fournissons ces liens pour plus de commodité et n'implique pas notre approbation ou notre examen de ces sites Web. Veuillez contacter directement les sites Web tiers pour obtenir des informations sur leurs politiques et conditions de confidentialité.",
    subBlock1:
      "Informations sur le compte : lorsque vous créez un compte sur notre plateforme, nous collectons des informations relatives à votre compte, notamment votre nom, vos coordonnées, vos identifiants de compte, vos informations de carte de paiement et l'historique des transactions (« Informations sur le compte »).",
    subBlock10: 'Fournir, gérer, maintenir et/ou analyser des services ;',
    subBlock11: 'Communiquer avec vous ;',
    subBlock12: 'Développer de nouveaux produits et services ;',
    subBlock13:
      "Prévenir la fraude, les activités criminelles ou l'utilisation abusive de nos services et protéger la sécurité de nos systèmes informatiques, de notre architecture et de nos réseaux ;",
    subBlock14: "Réaliser des transmissions d'entreprises ;",
    subBlock15:
      "Se conformer aux obligations légales et aux procédures judiciaires, protéger nos droits, notre vie privée, notre sécurité ou nos biens, ainsi que ceux de nos sociétés affiliées, de vous ou d'autres tiers.",
    subBlock16:
      "Fournisseurs et prestataires de services : pour nous aider à répondre aux besoins opérationnels de l'entreprise et à exécuter certains services et fonctions, nous pouvons fournir des informations personnelles à des fournisseurs et prestataires de services, notamment des fournisseurs de services d'hébergement, des fournisseurs de services cloud et d'autres fournisseurs de services informatiques, des logiciels de communication par courrier électronique. , et les fournisseurs de services d'analyse Web, etc. Ces partenaires accéderont, traiteront ou stockeront les informations personnelles uniquement selon nos instructions dans l'exercice de leurs fonctions pour nous.",
    subBlock17:
      "Transferts d'entreprises : si nous nous engageons dans des transactions stratégiques, des réorganisations, des faillites, des rachats en cas de faillite ou une transition de services vers un autre fournisseur (collectivement, les « Transactions »), vos informations personnelles et d'autres informations peuvent être partagées avec des contreparties à la transaction et d'autres parties participant aux Transactions. au cours de la diligence raisonnable et transférés dans le cadre des Transactions avec d'autres actifs à une société successeur ou affiliée.",
    subBlock18:
      "Exigences légales : nous pouvons partager vos informations personnelles avec des autorités gouvernementales, des pairs du secteur ou d'autres tiers, y compris vos informations d'interaction avec nos services, (1) si la loi l'exige ou si nous pensons qu'une telle action est nécessaire pour se conformer aux obligations légales ; (2) pour protéger et défendre nos droits ou nos biens ; (3) si nous déterminons unilatéralement qu'il y a une violation de nos conditions, politiques ou lois ; (4) pour détecter ou prévenir la fraude ou d'autres activités illégales ; (5) pour protéger nos produits, nos employés ou nos utilisateurs, ou la sécurité, la sécurité et l'intégrité du public ; ou (vi) pour vous défendre contre toute responsabilité légale.",
    subBlock19:
      'Accédez à vos informations personnelles et aux informations sur la manière dont elles sont traitées.',
    subBlock2:
      'Contenu utilisateur : lorsque vous utilisez nos services, nous collectons des informations personnelles à partir de vos entrées, téléchargements de fichiers ou commentaires qui nous sont fournis ("Contenu").',
    subBlock20: 'Demander la suppression de vos informations personnelles de nos dossiers.',
    subBlock21: 'Corrigez ou mettez à jour vos informations personnelles.',
    subBlock22: 'Transférez vos informations personnelles à un tiers (portabilité des données).',
    subBlock23: 'Limitez la manière dont nous traitons vos informations personnelles.',
    subBlock24:
      'Retirer votre consentement : lorsque nous nous appuyons sur le consentement comme base juridique du traitement, vous pouvez retirer votre consentement à tout moment.',
    subBlock25: 'Vous opposer à la manière dont nous traitons vos informations personnelles.',
    subBlock26: 'Améliorer nos services et mener des recherches ;',
    subBlock3:
      'Informations de communication : si vous nous envoyez des messages, nous collecterons votre nom, vos coordonnées et le contenu de vos messages ("Informations de communication").',
    subBlock4:
      "Informations sur les réseaux sociaux : nous avons des pages sur des sites de réseaux sociaux tels qu'Instagram, Facebook, Medium, Twitter, YouTube et LinkedIn. Lorsque vous interagissez avec nos pages de réseaux sociaux, nous collectons les informations personnelles que vous choisissez de nous fournir, telles que vos coordonnées (« Informations sociales »). De plus, les sociétés hébergeant nos pages de réseaux sociaux peuvent nous fournir des informations et des analyses globales sur nos activités sur les réseaux sociaux.",
    subBlock5:
      "Données de journal : informations automatiquement envoyées par votre navigateur lors de l'utilisation de nos services. Les données du journal incluent votre adresse IP (Internet Protocol), le type et les paramètres de votre navigateur, la date et l'heure de vos demandes et la manière dont vous interagissez avec notre site Web.",
    subBlock6:
      "Données d'utilisation : nous pouvons collecter automatiquement des informations sur votre utilisation du service, telles que les types de contenu que vous consultez ou avec lesquels vous interagissez, les fonctionnalités que vous utilisez, les actions que vous effectuez, ainsi que votre fuseau horaire, votre pays, la date et l'heure de votre utilisation. accès, agent utilisateur et version, type d'ordinateur ou d'appareil mobile et connexion à votre ordinateur.",
    subBlock7:
      "Informations sur l'appareil : cela inclut le nom de l'appareil, le système d'exploitation, l'identifiant de l'appareil et le navigateur que vous utilisez. Les informations collectées peuvent dépendre du type d'appareil que vous utilisez et de ses paramètres.",
    subBlock8:
      "Cookies : Nous utilisons des cookies pour exploiter et gérer nos services et améliorer votre expérience. Un « cookie » est une information envoyée par un site Internet que vous visitez à votre navigateur. Vous pouvez configurer votre navigateur pour qu'il accepte tous les cookies, rejette tous les cookies ou vous avertisse lorsqu'un cookie est fourni afin que vous puissiez décider de l'accepter ou non à chaque fois. Cependant, refuser les cookies peut vous empêcher d’utiliser ou affecter négativement l’affichage ou la fonctionnalité de certaines zones ou fonctionnalités du site Internet. Pour plus d’informations sur les cookies, veuillez visiter Tout sur les cookies.",
    subBlock9:
      "Analyse : nous pouvons utiliser divers produits d'analyse en ligne qui utilisent des cookies pour nous aider à analyser la façon dont les utilisateurs utilisent nos services et à améliorer votre expérience lors de l'utilisation des services.",
    subTitle1: 'Informations personnelles que nous collectons',
    subTitle2: 'Comment nous utilisons les informations personnelles',
    subTitle3: 'Divulgation de renseignements personnels',
    subTitle4: 'Vos droits',
    subTitle5: "Liens vers d'autres sites Web",
    subTitle6: 'Sécurité et conservation des informations',
    subTitle7: 'Modification de la politique de confidentialité',
    subTitle8: 'Comment nous contacter',
    title: 'politique de confidentialité',
    updateTime: 'Mise à jour : 15 mars 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accepter tout',
    text: 'Pour améliorer votre expérience, nous utilisons des cookies pour personnaliser le contenu et les services.',
    title: 'Ce site utilise des cookies',
  },
  slogen: "L'assistant IA le plus simple",
  stripe: {
    contact: 'Contactez-nous à contact@noam.tools pour toute question !',
    fail: {
      btn: 'Réessayer',
      text1: "Nous n'avons pas pu traiter votre paiement.",
      text2: 'Vérifiez votre connexion et réessayez.',
      title: 'Échec du paiement',
    },
    succ: {
      btn: 'Allons-y',
      text1: 'Bienvenue à Noam,',
      text2: 'commençons !',
      title: 'Félicitations',
    },
  },
  terms: {
    block1: "Merci d'utiliser Noam !",
    block10:
      "(a) Frais et facturation. Vous paierez tous les frais (« Frais ») conformément aux tarifs et aux conditions indiqués sur la page de tarification applicable ou comme convenu par écrit entre nous. Nous nous réservons le droit de corriger les erreurs ou erreurs de prix même si nous avons émis des factures ou reçu un paiement. Vous fournirez des informations de facturation complètes et précises, y compris un mode de paiement valide et autorisé. Nous facturerons votre mode de paiement sur une base périodique comme convenu, mais nous pouvons raisonnablement modifier la date de facturation. Vous autorisez Noam, ses sociétés affiliées et les processeurs de paiement tiers à facturer votre mode de paiement. Si votre paiement échoue, nous vous enverrons un avis écrit et pourrons suspendre l'accès au Service jusqu'à ce que le paiement soit reçu. Sauf indication contraire dans le présent accord, les paiements ne sont pas remboursables.",
    block11:
      "(b) Impôts. Sauf indication contraire, les frais n'incluent pas les taxes, droits et évaluations similaires fédéraux, étatiques, locaux et étrangers (« taxes »). Vous êtes responsable de toutes les taxes associées à vos achats, à l'exclusion des taxes basées sur notre revenu net que nous vous facturerons. Vous acceptez de payer rapidement ces taxes, de nous fournir des déclarations de confidentialité légalement valables et d'obtenir les consentements nécessaires pour traiter ces données, et vous nous garantissez que vous traitez ces données conformément à la loi applicable.",
    block12:
      "(c) Modifications de prix. Nous pouvons modifier nos prix en vous en informant via votre compte et/ou notre site Web. Les augmentations de prix entreront en vigueur immédiatement après leur publication. Toute modification de prix s'appliquera aux frais de votre compte après la date d'entrée en vigueur de la modification.",
    block13:
      '(d) Litiges et paiements en retard. Si vous souhaitez contester des frais ou taxes, veuillez contacter contact@noam.tools dans les trente (30) jours suivant la date de facture.',
    block14:
      "(e) Utilisation gratuite. Vous ne pouvez pas créer plusieurs comptes pour obtenir des crédits gratuits. Si nous déterminons que vous n'utilisez pas les crédits gratuits de bonne foi, nous pouvons vous facturer des frais standard ou cesser de fournir l'accès au Service.",
    block15:
      "(a) Confidentialité. Vous pouvez avoir accès aux informations confidentielles de Noam. Vous ne pouvez utiliser les informations confidentielles que dans la mesure permise par les présentes conditions dans le but d'utiliser le Service. Vous ne pouvez divulguer d’informations confidentielles à aucun tiers et vous protégerez les informations confidentielles d’une manière non moins protectrice que vous protégez vos propres informations confidentielles similaires, au moins avec un soin raisonnable. Les informations confidentielles sont des informations non publiques désignées comme confidentielles par Noam ou qui devraient raisonnablement être traitées comme confidentielles dans les circonstances, y compris les logiciels, les spécifications et autres informations commerciales non publiques. Les informations confidentielles n'incluent pas les informations qui : (1) deviennent généralement accessibles au public sans faute de votre part ; (2) vous l'aviez en votre possession sans aucune obligation de confidentialité avant de le recevoir selon les présentes conditions ; (3) vous est légalement divulgué par un tiers sans aucune obligation de confidentialité ; ou (4) est développé indépendamment par vous sans utiliser les informations confidentielles. Vous pouvez divulguer des informations confidentielles si la loi, un tribunal ou toute autre ordonnance gouvernementale l'exige, mais vous devez fournir un préavis écrit à Noam de manière raisonnable et, dans la mesure du possible, faire des efforts raisonnables pour limiter la portée de la divulgation, y compris en nous aidant. en s'opposant aux demandes de divulgation.",
    block16:
      'b) Sécurité. Vous devez mettre en œuvre des mesures raisonnables et appropriées pour aider à protéger votre accès et votre utilisation du Service. Si vous découvrez des vulnérabilités ou des violations liées à votre utilisation du Service, vous devez rapidement contacter Noam et fournir des détails sur la vulnérabilité ou la violation.',
    block17:
      '((c) Traitement des données personnelles. Si vous utilisez le Service pour traiter des données personnelles, vous devez fournir des déclarations de confidentialité légalement suffisantes et obtenir les consentements nécessaires pour traiter ces données, et vous nous garantissez que vous traitez ces données conformément à la loi applicable. .',
    block18:
      "(a) Résiliation ; Suspension. Ces conditions entreront en vigueur dès votre première utilisation du Service et resteront en vigueur jusqu'à leur résiliation. Vous pouvez résilier ces conditions à tout moment pour quelque raison que ce soit en cessant d'utiliser le Service et le Contenu. Nous pouvons résilier ces conditions avec préavis pour quelque raison que ce soit. Nous pouvons immédiatement résilier ces conditions en vous avertissant si vous enfreignez de manière substantielle la section 2 (Conditions d'utilisation), la section 5 (Confidentialité, sécurité et protection des données), la section 8 (Résolution des litiges) ou la section 9 (Conditions générales), ou si notre relation avec tout fournisseur de technologie tiers indépendant de notre volonté, ou pour le respect de la loi ou des demandes gouvernementales. Nous pouvons suspendre votre accès au Service si vous ne respectez pas ces conditions, ou si votre utilisation présente un risque de sécurité pour nous ou tout tiers, ou si nous soupçonnons que votre utilisation est frauduleuse ou peut nous exposer, nous ou tout tiers, à une responsabilité. .",
    block19:
      "(b) Effets de la résiliation. En cas de résiliation, vous cesserez d'utiliser le Service et retournerez ou détruirez rapidement toute information confidentielle selon nos instructions. Les dispositions des présentes conditions qui, de par leur nature, devraient survivre à la résiliation ou à l'expiration, y compris, mais sans s'y limiter, les sections 3 et 5 à 9, resteront en vigueur.",
    block2:
      "Ces conditions d'utilisation s'appliquent à votre utilisation des services de Noam, y compris nos interfaces de programmation d'applications, nos logiciels, nos outils, nos services de développement, nos données, notre documentation et notre site Web (collectivement appelés les « Services »). En utilisant nos Services, vous acceptez de respecter ces conditions. Notre politique de confidentialité explique comment nous collectons et utilisons les informations personnelles.",
    block20:
      "(a) Indemnisation. Vous nous défendrez, indemniserez et nous dégagerez de toute responsabilité, ainsi que nos sociétés affiliées et notre personnel, contre toute réclamation, perte et dépense (y compris les honoraires d'avocat) découlant de ou liés à votre utilisation du Service, y compris votre Contenu, votre l'utilisation de produits ou de services liés au Service et votre violation de ces conditions ou de la loi applicable.",
    block21:
      "(b) Avis de non-responsabilité. Le Service est fourni « tel quel ». Dans la mesure permise par la loi, nous et nos sociétés affiliées et concédants de licence ne faisons aucune déclaration ou garantie d'aucune sorte concernant le Service et n'offrons aucune garantie, y compris, mais sans s'y limiter, les garanties de qualité marchande, d'adéquation à un usage particulier, de qualité satisfaisante, de non-contrefaçon. , et la jouissance tranquille, ainsi que toutes les garanties découlant du cours des transactions ou de l'usage du commerce. Nous ne garantissons pas que le Service sera ininterrompu, précis ou sans erreur, ni que tout Contenu sera sécurisé, non perdu ou modifié.",
    block22:
      "(c) Limitation de responsabilité. Ni nous, ni nos sociétés affiliées, ni nos concédants de licence ne serons responsables de tout dommage indirect, accidentel, spécial, consécutif ou punitif, y compris la perte de profits, la perte de réputation, la perte d'utilisation, la perte de données ou d'autres pertes intangibles, même si nous avons été informés de la possibilité de tels dommages. Selon ces conditions, notre responsabilité totale ne dépassera pas les frais que vous avez payés au service au cours des douze (12) mois précédant la réclamation ou cent dollars américains (100 $), selon le montant le plus élevé. Les limitations de cette section s'appliquent dans la mesure maximale permise par la loi applicable.",
    block23:
      "Les Conditions et votre utilisation des Services sont régies par les lois des États-Unis, à l'exception des règles américaines de conflit de lois. Votre utilisation de l’Application peut également être soumise à d’autres lois locales, étatiques, nationales ou internationales. Toute action ou procédure judiciaire relative aux présentes Conditions sera intentée exclusivement devant les tribunaux des États-Unis, et vous acceptez de vous soumettre à la juridiction personnelle de ces tribunaux.",
    block24:
      "(a) Relation entre les parties. Noam et vous êtes des entrepreneurs indépendants, et aucune des parties n'a le pouvoir de représenter ou de lier l'autre partie ou d'assumer des obligations pour l'autre partie sans le consentement écrit préalable de l'autre partie.",
    block25:
      "(b) Utilisation de la marque. Vous ne pouvez pas utiliser le nom, les logos ou les marques commerciales de Noam ou de l'une de ses sociétés affiliées sans notre consentement écrit préalable.",
    block26:
      'Si une disposition de ces conditions est jugée invalide, illégale ou inapplicable, les dispositions restantes resteront pleinement en vigueur.',
    block3:
      "Si vous avez moins de 18 ans, vous devez obtenir l'autorisation de vos parents ou tuteurs légaux pour utiliser le Service. Si vous utilisez le Service au nom d'une autre personne ou entité, vous devez être autorisé à accepter ces conditions en son nom. Vous devez fournir des informations exactes et complètes pour créer un compte. Vous ne pouvez pas fournir vos identifiants d'accès ou votre compte à quiconque en dehors de votre organisation, et vous êtes responsable de toutes les activités menées à l'aide de vos identifiants.",
    block4:
      "(a) Utilisation du Service. Vous pouvez accéder au Service selon ces conditions et nous vous accordons un droit non exclusif d'utiliser le Service. Lorsque vous utilisez le Service, vous respecterez ces conditions et toutes les lois applicables. Nous et nos sociétés affiliées détenons tous les droits, titres et intérêts dans le Service.",
    block5:
      "(b) Commentaires. Nous apprécions les commentaires, les idées, les suggestions et les améliorations. Si vous fournissez un tel contenu, nous pouvons l'utiliser sans aucune restriction et sans compensation pour vous.",
    block6:
      "(c) Restrictions. Vous ne pouvez pas : (1) utiliser le Service d'une manière qui enfreint, détourne ou viole les droits d'autrui ; (2) faire de l'ingénierie inverse, décompiler, désassembler, traduire ou tenter de toute autre manière de découvrir le code source ou les composants sous-jacents des modèles, algorithmes et systèmes du Service (sauf si de telles restrictions sont interdites par la loi applicable) ; (3) utiliser les résultats du Service pour développer des modèles concurrents de Noam ; (4) extraire des données ou des résultats du Service à l'aide de méthodes automatisées ou de programmation, sauf autorisation via l'API, y compris le scraping, la récolte Web ou l'extraction de données Web ; (5) représenter le résultat du Service comme généré artificiellement alors qu'il ne l'est pas, ou violer de toute autre manière nos politiques d'utilisation ; (6) acheter, vendre ou transférer des clés API sans notre consentement préalable ; ou (7) nous fournir des informations personnelles sur des enfants de moins de 13 ans ou de l'âge applicable du consentement numérique. Vous respecterez toutes les limites de taux et autres exigences indiquées dans notre documentation.",
    block7:
      '(d) Services tiers. Tous les logiciels, services ou autres produits tiers associés au Service sont régis par leurs propres conditions et nous ne sommes pas responsables des produits tiers.',
    block8:
      "(a) Votre contenu. Vous pouvez fournir une entrée (« Entrée ») au Service et recevoir une sortie (« Sortie ») générée et renvoyée en fonction de l'entrée (collectivement appelée « Contenu »). Entre les parties, et dans la mesure permise par la loi applicable, vous êtes propriétaire de toutes les Entrées. Noam peut utiliser le Contenu pour fournir et maintenir le Service, se conformer aux lois applicables et appliquer nos politiques. Vous êtes responsable du contenu, notamment en vous assurant qu'il ne viole aucune loi applicable ou ces conditions.",
    block9:
      "(b) Précision. L’intelligence artificielle et l’apprentissage automatique sont des domaines de recherche en évolution rapide. Nous nous efforçons continuellement d'améliorer notre service pour le rendre plus précis, fiable, sécurisé et avantageux. Compte tenu de la nature probabiliste de l'apprentissage automatique, l'utilisation de notre service peut entraîner des résultats incorrects dans certains cas, qui peuvent ne pas refléter avec précision des individus, des lieux ou des faits réels. Vous devez évaluer de manière appropriée l'exactitude de tout résultat en fonction de votre cas d'utilisation, notamment en examinant manuellement les résultats.",
    subTitle1: 'Inscription et accès',
    subTitle10: 'Divisibilité',
    subTitle2: "Exigences d'utilisation",
    subTitle3: 'Contenu',
    subTitle4: 'Frais et paiement',
    subTitle5: 'Confidentialité, sécurité et protection des données',
    subTitle6: 'Durée et résiliation',
    subTitle7: 'Indemnité; Avis de non-responsabilité ; Limitation de responsabilité',
    subTitle8: 'Loi applicable et juridiction',
    subTitle9: 'Conditions générales',
    title: "Conditions d'utilisation",
    updateTime: 'Mise à jour : 15 mars 2024',
  },
  trialModal: {
    btn: 'Commencez maintenant (seulement 0,99 $)',
    chat: 'ChatPDF',
    desc1: 'Après votre essai, des frais annuels de 180 $ vous seront facturés.',
    desc2: 'Annuler à tout moment',
    focus: 'Mise au point',
    hover: 'Traduction survolée',
    image: "Traduction d'images",
    installBtn: 'Installez maintenant pour recevoir votre cadeau',
    memo: 'Gestion des favoris',
    pdf: 'Traduction parallèle PDF',
    realtime: 'Traduction instantanée',
    select: 'Traduction de sélection de mots',
    title: 'Cadeau de bienvenue',
    trialText: 'Essai de 7 jours',
    video: 'Traduction vidéo',
    videoSummary: 'Résumé vidéo',
    web: 'Traduction parallèle',
    webSummary: 'Résumé Web',
  },
  upgradeModal: {
    btn: 'Mettre à niveau maintenant',
    chat: {
      free: 'Vous avez atteint la limite ChatPDF',
      pro: 'Vous avez atteint la limite ChatPDF pour ce mois',
    },
    contactStr:
      'Veuillez attendre la prochaine réinitialisation ou contactez-nous à contact@noam.tools.',
    limitAlertStr:
      'Vous avez atteint la limite de votre forfait gratuit. Mettez à niveau pour continuer.',
    more: 'Voir les forfaits',
    pageLimit: { free: 'Vous avez atteint la limite de pages PDF' },
    title: 'Rappel',
    upgradeAlertStr:
      'Vous avez atteint la limite de votre forfait gratuit. Mettez à niveau pour continuer.',
    upload: {
      free: 'Vous avez atteint votre limite de traduction PDF',
      pro: 'Vous avez atteint votre limite de traduction PDF pour ce mois',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Inscrivez-vous maintenant',
      label: 'Avantages pour les nouveaux utilisateurs :',
      remain: "Il n'en reste que 30",
      text: 'Seulement 100 places disponibles ! Prenez le vôtre maintenant !',
    },
  },
}

export default TRANSLATION
